import { Link, LinkProps } from 'components'
import React from 'react'

export const DropdownLink: React.FC<LinkProps> = ({ children, ...props }) => (
  <Link
    kind="plain"
    className="w-full"
    {...props}
    data-cy-id={`dropdownLink.${props.to}`}
  >
    <li className="block py-10 px-18 hover:bg-grey-200 truncate">{children}</li>
  </Link>
)
