import { t, Trans } from '@lingui/macro'
import { Input, TextArea } from 'components'
import { LogoDropzone } from 'containers/Logo'
import { QueryLoader } from 'containers/QueryLoader'
import { useToasts } from 'containers/Toast'
import { GraphQLError } from 'graphql'
import { debounce } from 'lodash'
import { NextPage } from 'next'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ErrorOption, useForm } from 'react-hook-form'
import {
  ToastKind,
  UpdateBankConfigInput,
  useApplyBankEnvChangesMutation,
  useBankConfigBySlugQuery,
  useBankConfigDiffBySlugQuery,
  useBankCredentialsBySlugQuery,
  useBankEnvironmentsListQuery,
  useDiscardBankEnvChangesMutation,
  useUpdateBankEnvConfigMutation,
} from 'types'
import { useFeature } from 'utils/features'
import { useAsyncCallback } from 'utils/handleError'
import { useFileUpload, useUploadFormErrorHandler } from 'utils/hooks'
import { Router } from 'utils/router'
import { getObjectDiff, patterns, uriMaxLength } from 'utils/utils'
import { v4 } from 'uuid'
import { BankConfigDiff } from './BankConfigDiff'

type BankConfigFormInputs = Omit<
  UpdateBankConfigInput,
  'client_id_whitelist'
> & {
  client_id_whitelist: string
}

interface BankConfigFormProps {
  slug: string
  envSlug: string
  envId: string
}

export const BankConfigForm: NextPage<BankConfigFormProps> = ({
  slug,
  envSlug,
  envId,
}) => {
  const configQuery = useBankConfigBySlugQuery({
    variables: { slug: envSlug },
  })
  const diffQuery = useBankConfigDiffBySlugQuery({
    variables: { slug: envSlug },
  })

  const { refetch: refetchCredentials } = useBankCredentialsBySlugQuery({
    variables: { slug: envSlug },
  })

  const configData = configQuery.data?.bankEnvironment?.updatedConfig
  const diff = diffQuery.data?.bankEnvironment?.pendingConfigChanges

  const [updateConfig] = useUpdateBankEnvConfigMutation()

  const {
    register,
    setValue,
    getValues,
    errors,
    reset,
    handleSubmit,
    formState,
    setError,
    clearErrors,
  } = useForm<BankConfigFormInputs>({ reValidateMode: 'onSubmit' })

  const handleUploadError = useUploadFormErrorHandler(
    'logo_id',
    setError,
    clearErrors
  )
  const [uploading, uploadFile] = useFileUpload(undefined, handleUploadError)

  useEffect(() => {
    register(
      { name: 'logo_id' },
      {
        validate: (file) =>
          !!file || !!configData?.logo_id || t`form.error.required`,
      }
    )
  }, [register, configData?.logo_id])

  const formInitialized = useRef(false)
  const [textAreaKey, setTextAreaKey] = useState(v4())
  useEffect(() => {
    // prevent resetting form values on every refetch
    if (formInitialized.current === false && configData) {
      formInitialized.current = true
      reset({
        ...configData,
        logo_id: configData?.logo_id?.file.id,
        client_id_whitelist: configData.client_id_whitelist.join('\n'),
      })

      // force textarea to rerender
      setTextAreaKey(v4())
    }
  }, [reset, configData])

  const { refetch: refetchEnvironmentsList } = useBankEnvironmentsListQuery({
    variables: { slug },
  })

  const updateEnvConfig = useAsyncCallback(async () => {
    const values = getValues()

    if (
      !values.client_id_whitelist
        .split('\n')
        .filter((line) => line !== '')
        .every((line) => patterns.uuid.test(line))
    ) {
      setError('client_id_whitelist', {
        type: 'pattern',
        message: t`form.error.patternUuid`,
      })
      return
    } else {
      clearErrors('client_id_whitelist')
    }

    const data = {
      ...getObjectDiff(values, configData),
      client_id_whitelist: values.client_id_whitelist
        ?.split('\n')
        .filter((val) => val !== ''),
    }

    try {
      await updateConfig({
        variables: { envId, data },
      })
    } catch (ex: any) {
      const gqlErrors: GraphQLError[] = ex.graphQLErrors ?? []

      for (const error of gqlErrors) {
        switch (error?.extensions?.errorCode) {
          case 'BANK_CONFIG_WHITELIST_ID_DOES_NOT_EXIST':
            setError('client_id_whitelist', {
              type: 'manual',
              message: error.extensions.errorMessage,
            })
            return
        }
      }

      throw ex
    }

    await configQuery.refetch()
    await diffQuery.refetch()
  }, [updateConfig, uploadFile, configQuery.refetch, diffQuery.refetch])

  const { addToast } = useToasts()
  const [apply] = useApplyBankEnvChangesMutation()
  const applyChanges = useAsyncCallback(
    handleSubmit(async () => {
      if (!envId) {
        throw 'Invalid environment ID'
      }

      try {
        await apply({ variables: { envId } })
      } catch (ex: any) {
        const gqlErrors: GraphQLError[] = ex.graphQLErrors ?? []

        for (const error of gqlErrors) {
          switch (error?.extensions?.errorCode) {
            case 'BANK_CONFIG_WHITELIST_ID_DOES_NOT_EXIST':
              setError('client_id_whitelist', {
                type: 'manual',
                message: error.extensions.errorMessage,
              })
              window.scrollTo()
              return
            case 'BANK_ENVIRONMENT_UNSUCCESSFUL_REGISTRATION':
              addToast({
                kind: ToastKind.Error,
                content: t`bank.environment.config.applyFailed`,
              })
              return
            case 'DISCOVERY_ENDPOINT_URI_INVALID':
              addToast({
                kind: ToastKind.Error,
                content: t`bank.environment.config.discoveryUriInvalid`,
              })
              return
          }
        }

        throw ex
      }

      await refetchEnvironmentsList()
      await configQuery.refetch()
      await diffQuery.refetch()
      await refetchCredentials()

      await Router.pushRoute(
        `/banks/${slug}/environments/${envSlug}/credentials`
      )
      document.body.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }),
    [envId, apply, configQuery.refetch, diffQuery.refetch]
  )

  const [discard] = useDiscardBankEnvChangesMutation()
  const discardChanges = useAsyncCallback(async () => {
    if (!envId) {
      throw 'Invalid environment ID'
    }

    await discard({ variables: { envId } })

    formInitialized.current = false // reset form values
    await configQuery.refetch()
    await diffQuery.refetch()
  }, [envId, discard, configQuery.refetch, diffQuery.refetch])

  const debouncedPostFormData = debounce(async () => {
    await updateEnvConfig()
  }, 300)

  // require client ID whitelist if multiple environments can be configured
  const requireWhitelist = !useFeature('SINGLE_BANK_ENV')

  return (
    <>
      <h2 className="heading-h2 mt-40 mb-24">
        <Trans id="bank.environment.config.heading">
          Bank environment config
        </Trans>
      </h2>
      <QueryLoader queries={[configQuery, diffQuery]}>
        <form
          onSubmit={applyChanges}
          onChange={debouncedPostFormData}
          className="form-mono-font mb-40"
        >
          <Input
            containerClassName="mb-20 lg:my-10"
            name="discovery_endpoint_uri"
            ref={register({
              required: t`form.error.required`,
              pattern: {
                value: patterns.url,
                message: t`form.error.patternUrl`,
              },
            })}
            required
            labelPosition="left"
            errors={errors.discovery_endpoint_uri}
            data-cy-id="bank-settings-discovery_endpoint_uri"
            label={
              <Trans id="bank.environment.config.discovery_endpoint_uri">
                Discovery Endpoint URI
              </Trans>
            }
            labelTooltip={t`bank.environment.config.discovery_endpoint_uri.tooltip`}
            maxLength={uriMaxLength}
          />

          <LogoDropzone
            data-cy-id="bank-settings-logo_id"
            name="logo_id"
            label={
              <Trans id="bank.environment.config.logo_id">
                Bank Environment Logo
              </Trans>
            }
            labelPosition="left"
            labelTooltip={t`bank.environment.config.logo_id.tooltip`}
            descriptionPosition="right"
            errors={errors.logo_id}
            onSelectFile={async (file) => {
              let fileInfo
              if (file) {
                fileInfo = await uploadFile(file, 'LOGO')
              }
              setValue('logo_id', fileInfo?.fileId, { shouldDirty: true })
            }}
            currentLogo={configData?.logo_id}
            loading={uploading}
            stateful={false}
          />
          <div className="mb-20" />

          <Input
            containerClassName="mb-20 lg:my-10"
            name="name"
            ref={register({
              required: t`form.error.required`,
              pattern: {
                message: t`form.error.name`,
                value: patterns.name,
              },
            })}
            required
            labelPosition="left"
            errors={errors.name}
            data-cy-id="bank-settings-name"
            label={
              <Trans id="bank.environment.config.name">
                Bank Environment Name
              </Trans>
            }
            labelTooltip={t`bank.environment.config.name.tooltip`}
          />

          <Input
            className="mb-20 lg:my-10"
            labelPosition="left"
            readOnly
            data-cy-id="bank-settings-slug"
            defaultValue={configData?.slug ?? undefined}
            label={
              <Trans id="bank.environment.config.slug">
                Bank Environment Slug
              </Trans>
            }
            labelTooltip={t`bank.environment.config.slug.tooltip`}
          />

          <TextArea
            className="mb-20 lg:my-10"
            textAreaClassName="font-mono text-14 leading-22"
            name="client_id_whitelist"
            ref={register({
              required: requireWhitelist && t`form.error.required`,
              validate: (value: string) =>
                value
                  .split('\n')
                  .filter((line) => line !== '')
                  .every((line) => patterns.uuid.test(line)) ||
                t`form.error.patternUuid`,
            })}
            labelPosition="left"
            errors={errors.client_id_whitelist}
            data-cy-id="bank.environment.config.client_id_whitelist"
            label={
              <Trans id="bank.environment.config.client_id_whitelist">
                Client ID whitelist
              </Trans>
            }
            labelTooltip={t`bank.environment.config.client_id_whitelist.tooltip`}
            lineNumbers
            initialRows={configData?.client_id_whitelist.length || 1}
            maxLineLength={100}
            key={textAreaKey} // forces rerender on discard changes
          />

          <BankConfigDiff
            diff={diff}
            submitting={formState.isSubmitting}
            onReset={discardChanges}
          />
        </form>
      </QueryLoader>
    </>
  )
}
