import { Trans } from '@lingui/macro'
import Axios from 'axios'
import { Button } from 'components'
import getConfig from 'next/config'
import React from 'react'
import { useAsyncCallback } from 'utils/handleError'
import { Router } from 'utils/router'

const BASE_URL = getConfig().publicRuntimeConfig.BASE_URL

type TicketTransitionButtonProps = {
  issueKey: string
}

export const ResolveTicketButton: React.FC<TicketTransitionButtonProps> = ({
  issueKey,
}) => {
  const resolveTicket = useAsyncCallback(async () => {
    await Axios.post(`${BASE_URL}/api/tickets/resolve?key=${issueKey}`)

    // replace with the current route re-runs getInitialProps
    await Router.replaceRoute(`/support/${issueKey}`, { scroll: false })
  }, [])

  return (
    <Button
      onClick={resolveTicket}
      data-cy-id="support.ticket.sidebar.markResolved"
      className="mb-40"
    >
      <Trans id="support.ticket.sidebar.markResolved">
        Mark ticket as resolved
      </Trans>
    </Button>
  )
}

export const ReopenTicketButton: React.FC<TicketTransitionButtonProps> = ({
  issueKey,
}) => {
  const reopenTicket = useAsyncCallback(async () => {
    await Axios.post(`${BASE_URL}/api/tickets/reopen?key=${issueKey}`)

    // replace with the current route re-runs getInitialProps
    await Router.replaceRoute(`/support/${issueKey}`, { scroll: false })
  }, [])

  return (
    <Button
      onClick={reopenTicket}
      data-cy-id="support.ticket.sidebar.reopen"
      className="mb-40"
    >
      <Trans id="support.ticket.sidebar.reopen">Reopen ticket</Trans>
    </Button>
  )
}
