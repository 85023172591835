import { RawRuleFrom } from '@casl/ability'
import Axios from 'axios'
import getConfig from 'next/config'
import { parseCookies } from 'nookies'

const { BACKEND_URL } = getConfig().publicRuntimeConfig

const axios = Axios.create({
  baseURL: `${BACKEND_URL}/api/v1`,
})

interface RulesResponse {
  rules: RawRuleFrom<[string, string], any>[]
}

const CaslClient = {
  getRules: (accessToken?: string) => {
    const token = accessToken ?? parseCookies()?.access_token
    if (!token) {
      return { data: { rules: [] } }
    }

    return axios.get<RulesResponse>('/rules', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  },
}

export default CaslClient
