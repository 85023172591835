import { parseCookies } from 'nookies'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { setCookie } from './cookie'

const features: FeatureFlag[] = [
  {
    name: 'news',
    description: 'Enables news page navbar link',
    defaultValue: false,
  },
  {
    name: 'SINGLE_BANK_ENV',
    description:
      'If enabled, restricts creating bank environments and allows empty client ID whitelist',
    defaultValue: false,
  },
  {
    name: 'app_production',
    description: 'Enables app production environment',
    defaultValue: false,
  },
  {
    name: 'app_products',
    description: 'Enables app product selection',
    defaultValue: false,
  },
  {
    name: 'organizations',
    description:
      'Enables organizations dashboard, creating new organizations & organization detail',
    defaultValue: false,
  },
  {
    name: 'support',
    description: 'Enables support center',
    defaultValue: false,
  },
  {
    name: 'reports',
    description: 'Enables reports center',
    defaultValue: false,
  },
  {
    name: 'link_bankid',
    description: 'Enables button in profile to link Bank iD to current account',
    defaultValue: false,
  },
  {
    name: 'signing',
    description: 'Enables signing in product wizard',
    defaultValue: false,
  },
  {
    name: 'business_detailed_reports',
    description: 'Enables sep business detailed reports',
    defaultValue: false,
  },
  {
    name: 'app_delete',
    description: 'Enables app deletion',
    defaultValue: false,
  },
]

export function getFeatures(): FeatureFlag[] {
  const overrides = (process.env.FEATURES ?? '')
    .split(',')
    .filter(Boolean)
    .map((str) => str.split('='))
    .map(([name, value]) => {
      if (!features.find((f) => f.name === name)) {
        console.warn(`Unknown feature: '${name}'`)
      }

      if (value !== 'on' && value !== 'off') {
        console.warn(
          `Invalid feature flag value: '${value}'. Expected format flag1=on,flag2=off`
        )
      }

      return { name, defaultValue: value === 'on' }
    })

  const result = features.map((feature) => {
    const override = overrides.find((f) => f.name === feature.name) ?? {}
    return { ...feature, ...override }
  })

  return result
}

function deserializeCookies(
  header?: string
): Record<string, boolean | undefined> {
  return typeof window !== 'undefined'
    ? JSON.parse(atob(header || '') || '{}')
    : JSON.parse(Buffer.from(header || '', 'base64').toString('utf-8') || '{}')
}

function serializeCookies(cookies: Record<string, boolean | undefined>) {
  return btoa(JSON.stringify(cookies))
}

interface FeatureFlagContext {
  features: FeatureFlag[]
  setFeature(name: string, enabled?: boolean): void
}

const FeatureContext = React.createContext<FeatureFlagContext>(null!)

interface WithFeaturesProps {}

export const withFeatures =
  (initialCookies?: Record<string, string>, initialFeatures?: FeatureFlag[]) =>
  (Component: any): React.FC<WithFeaturesProps> =>
  (props) => {
    initialCookies = initialCookies || parseCookies()

    const [modifiedFeatures, setModifiedFeatures] = useState<
      Record<string, boolean | undefined>
    >(deserializeCookies(initialCookies.features))

    const setFeature = useCallback(
      (featureName: string, enabled?: boolean) => {
        setModifiedFeatures((modified) => ({
          ...modified,
          [featureName]: enabled,
        }))
      },
      [setModifiedFeatures]
    )

    useEffect(() => {
      setCookie(null, 'features', serializeCookies(modifiedFeatures), {
        maxAge: 7 * 24 * 60 * 60,
      })
    }, [modifiedFeatures])

    const ctxValue = useMemo<FeatureFlagContext>(
      () => ({
        features: (initialFeatures || []).map((x) => ({
          ...x,
          enabled: modifiedFeatures[x.name],
        })),
        setFeature: setFeature,
      }),
      [modifiedFeatures, setFeature]
    )

    return (
      <FeatureContext.Provider value={ctxValue}>
        <Component {...props} />
      </FeatureContext.Provider>
    )
  }

export const useFeatureContext = () => useContext(FeatureContext)
export const useFeatureFlags = () => useContext(FeatureContext).features
export const useSetFeature = () => useContext(FeatureContext).setFeature
export const useFeatureMeta = (name: string) =>
  useContext(FeatureContext).features.find((x) => x.name === name)
export const useFeature = (name: string) => {
  const defaultFlag = useFeatureMeta(name)?.defaultValue
  const flag = useFeatureMeta(name)?.enabled
  return flag ?? defaultFlag
}
