import { Trans } from '@lingui/macro'
import classNames from 'classnames'
import { Link, Markdown } from 'components'
import Logo from 'icons/Logo.svg'
import React from 'react'
import { useFooterQuery } from 'types/cms'
import { useLocale } from 'utils/withLang'
import css from './Footer.module.css'

export const Footer: React.FC = () => {
  const locale = useLocale()
  const footerQuery = useFooterQuery({
    variables: { locale },
    context: { endpoint: 'cms' },
  })
  const footer = footerQuery.data?.footer?.data?.attributes

  return (
    <footer
      className={classNames('bg-primary-darker py-40 sm:py-70', css.footer)}
    >
      <div className="container mx-auto px-20 text-white-60 flex flex-col md:flex-row justify-between md:space-x-60 space-y-60 md:space-y-0">
        <div className="flex-1">
          <Link to="/" className="block mb-32" data-cy-id="footer.links.home">
            <Logo
              title={<Trans id="common.bankId">Bank iD</Trans>}
              className="fill-white-100"
            />
          </Link>
          <p className="text-14 leading-22 mb-20 max-w-320">{footer?.text}</p>
          {footer?.email && (
            <Link
              href={`mailto:${footer.email}`}
              kind="plain"
              className="text-white-100"
              data-cy-id="footer.email"
            >
              {footer.email}
            </Link>
          )}
        </div>
        <div className="flex-1 flex flex-col sm:flex-row space-y-60 sm:space-y-0">
          {footer?.navigation && (
            <div className="flex-1">
              <div className="text-12 uppercase tracking-wider mb-32 text-white-60">
                {footer.navigation?.data?.attributes?.title}
              </div>
              <Markdown>{footer.navigation.data?.attributes?.content}</Markdown>
            </div>
          )}
          {footer?.documents && (
            <div className="flex-1">
              <p className="text-12 uppercase tracking-wider mb-32 text-white-60">
                {footer.documents.data?.attributes?.title}
              </p>
              <Markdown>{footer.documents.data?.attributes?.content}</Markdown>
            </div>
          )}
        </div>
      </div>
    </footer>
  )
}
