/**
 * Helper for local storage access.
 *
 * @param key Local storage key
 *
 * @returns [get, set] tuple
 */
export function useLocalStorage(key: string) {
  const get = () => {
    if (typeof window !== 'undefined') {
      return window.localStorage.getItem(key) ?? undefined
    }
  }

  const set = (value: string | undefined) => {
    if (typeof window !== 'undefined') {
      if (value === undefined) {
        window.localStorage.removeItem(key)
        return
      }
      window.localStorage.setItem(key, value)
    }
  }

  return [get, set] as const
}
