import { t, Trans } from '@lingui/macro'
import classNames from 'classnames'
import { UncontrolledToggle } from 'components'
import { QueryLoader } from 'containers/QueryLoader'
import BellIcon from 'icons/bell.svg'
import Spinner from 'icons/spinner.svg'
import Tick from 'icons/tick.svg'
import React, { ChangeEvent, useCallback, useState } from 'react'
import {
  EventType,
  useNotificationPreferencesQuery,
  useUpdateNotificationPreferenceMutation,
  useUpdateUserMutation,
} from 'types'

t`profile.notificationPreferences.enableAll.label`
t`profile.notificationPreferences.APP_DELETED.label`
t`profile.notificationPreferences.APP_PRODUCTION_DISABLED.label`
t`profile.notificationPreferences.APP_PRODUCTION_ENABLED.label`
t`profile.notificationPreferences.APP_PRODUCTION_CONFIG_CHANGED.label`
t`profile.notificationPreferences.USER_PASSWORD_CHANGED.label`

type NotificationToggleProps = {
  eventType: EventType | 'enableAll'
  state?: boolean
  disabled?: boolean
  userId?: string
}

const NotificationToggle: React.FC<NotificationToggleProps> = ({
  eventType,
  state,
  disabled,
  userId,
}) => {
  const [loading, setLoading] = useState(false)
  const [saved, setSaved] = useState(false)

  const [updateNotificationPreference] =
    useUpdateNotificationPreferenceMutation()
  const [updateUser] = useUpdateUserMutation()
  const { refetch } = useNotificationPreferencesQuery()

  const handleChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      setLoading(true)
      if (eventType === 'enableAll') {
        await updateUser({
          variables: {
            userId: userId!,
            user: {
              emailNotificationsEnabled: event.target.checked,
            },
          },
        })
      } else {
        await updateNotificationPreference({
          variables: {
            userId: userId!,
            data: {
              emailEnabled: event.target.checked,
              eventType,
            },
          },
        })
      }
      await refetch()
      setLoading(false)
      setSaved(true)
      await new Promise((res) => setTimeout(res, 2500))
      setSaved(false)
    },
    [updateNotificationPreference, updateUser, eventType, refetch, userId]
  )

  return (
    <div className="flex flex-col-reverse sm:flex-row justify-between mb-28">
      <label
        htmlFor={eventType}
        className={classNames(
          'w-full overflow-x-hidden overflow-ellipsis mt-6 sm:mt-0 text-black-900',
          disabled && 'opacity-50'
        )}
      >
        <Trans id={`profile.notificationPreferences.${eventType}.label`} />
      </label>
      <div className="flex items-center space-x-10">
        {loading && <Spinner height={20} width={20} />}
        {saved && <Tick height={18} width={18} className="opacity-75" />}
        {state !== undefined && (
          <UncontrolledToggle
            name={eventType}
            checked={state}
            onChange={handleChange}
            data-cy-id={`profile.notificationPreferences.${eventType}`}
            fullWidth={false}
            disabled={disabled}
          />
        )}
      </div>
    </div>
  )
}

export const NotificationPreferences: React.FC = () => {
  const notificationPreferencesQuery = useNotificationPreferencesQuery()
  const user = notificationPreferencesQuery.data?.currentUser

  return (
    <>
      <div className="flex items-center mb-24">
        <BellIcon className="fill-primary-darker inline mr-14" height={24} />
        <h4 className="heading-h2 inline">
          <Trans id="profile.notificationPreferences.heading">
            E-mail notifications
          </Trans>
        </h4>
      </div>
      <div className="mb-24 text-grey-900">
        <QueryLoader queries={[notificationPreferencesQuery]}>
          <NotificationToggle
            eventType="enableAll"
            state={user?.emailNotificationsEnabled}
            userId={user?.id}
          />

          <p className="mb-28">
            <Trans id="profile.notificationPreferences.setIndividually">
              Below you can set which events you would like to be notified of by
              e-mail. All events will always show up in your notification center
              here on the portal.
            </Trans>
          </p>

          {user?.notificationPreferences?.map((preference) => (
            <NotificationToggle
              eventType={preference.type}
              state={preference.emailEnabled}
              userId={user?.id}
              key={preference.type}
              disabled={!user?.emailNotificationsEnabled}
            />
          ))}
        </QueryLoader>
      </div>
    </>
  )
}
