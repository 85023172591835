import { t } from '@lingui/macro'
import { AxiosError } from 'axios'
import { useToasts } from 'containers'
import FileServiceClient, {
  FileCategory,
  FileInfo,
} from 'data/FileServiceClient'
import { useCallback, useState } from 'react'
import { ErrorOption, UseFormMethods } from 'react-hook-form'
import { ToastKind } from 'types'
import { handleError } from 'utils/handleError'

t`form.error.fileUploadError.FILE_SIZE_LIMIT_EXCEEDED`
t`form.error.fileUploadError.IMAGE_RESOLUTION_TOO_LARGE`
t`form.error.fileUploadError.IMAGE_NOT_SQUARE`
t`form.error.fileUploadError.IMAGE_TOO_SMALL`
t`form.error.fileUploadError.IMAGE_TYPE_NOT_SUPPORTED`
t`form.error.fileUploadError.FILE_NAME_INVALID_CHARACTERS`
t`form.error.fileUploadError.FILE_TYPE_NOT_SUPPORTED`
t`form.error.fileUploadError.IMAGE_NOT_VALID`
t`form.error.fileUploadError.GENERIC`

interface FileUploadError {
  code:
    | 'FILE_SIZE_LIMIT_EXCEEDED'
    | 'IMAGE_RESOLUTION_TOO_LARGE'
    | 'IMAGE_NOT_SQUARE'
    | 'USER_NOT_PRESENT_IN_JWT'
    | 'IMAGE_TOO_SMALL'
    | 'IMAGE_TYPE_NOT_SUPPORTED'
    | 'FILE_NAME_INVALID_CHARACTERS'
    | 'FILE_TYPE_NOT_SUPPORTED'
    | 'IMAGE_NOT_VALID'
    | 'GENERIC'
  message: string
  timeStamp: string
  traceId: string
}

/**
 * File upload helper.
 *
 * @param onUploadFinished Callback to be invoked after upload finishes
 *
 * @returns [uploading, handler] tuple
 */
export function useFileUpload(
  onUploadFinished?: (fileInfo: FileInfo) => void | Promise<void>,
  onError?: (error?: ErrorOption) => void
) {
  const [uploading, setUploading] = useState(false)
  const { addToast } = useToasts()

  const uploadHandler = useCallback(
    async (file: File, context: FileCategory) => {
      onError?.(undefined)
      setUploading(true)
      try {
        const res = await FileServiceClient.uploadFile(file, context)
        await onUploadFinished?.(res.data)
        return res.data
      } catch (ex: any) {
        if (ex.isAxiosError && onError) {
          const err: AxiosError<FileUploadError> = ex
          return onError({
            message: `form.error.fileUploadError.${
              err.response?.data.code ?? 'GENERIC'
            }`,
          })
        }

        addToast({
          kind: ToastKind.Error,
          content: t`form.error.fileUploadFailed`,
        })
        handleError(ex)
      } finally {
        setUploading(false)
      }
    },
    [setUploading, onUploadFinished, addToast, onError]
  )

  return [uploading, uploadHandler] as const
}

export function useUploadFormErrorHandler(
  fieldName: string,
  setError: UseFormMethods['setError'],
  clearErrors: UseFormMethods['clearErrors']
) {
  return useCallback(
    (errorMessage?: ErrorOption) => {
      if (errorMessage) {
        setError(fieldName, errorMessage)
      } else {
        clearErrors(fieldName)
      }
    },
    [setError, clearErrors, fieldName]
  )
}
