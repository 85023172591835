import { t } from '@lingui/macro'
import { default as classNames } from 'classnames'
import React, { forwardRef } from 'react'
import type { TestIdProp } from 'types'
import {
  ValidationContainer,
  ValidationContainerProps,
} from './ValidationContainer'

export interface Option<TValue extends string = string> {
  label: string
  value: TValue
}

export interface SelectProps extends ValidationContainerProps, TestIdProp {
  name: string
  options: string[] | Option[]
  defaultValue?: string
  disabled?: boolean
  className?: string
  onChange?: (value: string) => void
  multiple?: boolean
  required?: boolean
}

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      name,
      options,
      defaultValue,
      disabled,
      onChange,
      multiple,
      required,
      ...props
    },
    ref
  ) => {
    const opts =
      typeof options[0] === 'string'
        ? (options as string[]).map((opt) => ({ label: opt, value: opt }))
        : (options as Option[])

    return (
      <ValidationContainer {...props} required={required}>
        <select
          ref={ref}
          name={name}
          className={classNames(
            'leading-loose appearance-none border rounded-4 bg-grey-200 border-grey-300',
            'flex items-center py-4 pl-8',
            !multiple && 'bg-chevron bg-right-10 bg-no-repeat pr-30 truncate',
            disabled ? 'opacity-75' : 'cursor-pointer',
            props.fullWidth && 'w-full'
          )}
          disabled={disabled}
          onBlur={(v) => onChange?.(v.target.value)}
          onChange={(v) => onChange?.(v.target.value)}
          multiple={multiple}
        >
          {opts.map(({ label, value }) => (
            <option key={value} value={value} selected={value === defaultValue}>
              {t({ id: label })}
            </option>
          ))}
        </select>
      </ValidationContainer>
    )
  }
)

Select.defaultProps = {
  labelPosition: 'left',
}
