import { t, Trans } from '@lingui/macro'
import { Select, SelectProps } from 'components'
import React, { forwardRef } from 'react'
import { TokenEndpointAuthMethod } from 'types'

type AuthMethodLabels = {
  [key in TokenEndpointAuthMethod]: string
}

const labels: AuthMethodLabels = {
  // CLIENT_SECRET_BASIC: t`app.environment.config.token_endpoint_auth_method.CLIENT_SECRET_BASIC`
  CLIENT_SECRET_JWT: t`app.environment.config.token_endpoint_auth_method.CLIENT_SECRET_JWT`,
  CLIENT_SECRET_POST: t`app.environment.config.token_endpoint_auth_method.CLIENT_SECRET_POST`,
  PRIVATE_KEY_JWT: t`app.environment.config.token_endpoint_auth_method.PRIVATE_KEY_JWT`,
}

const options = Object.entries(labels).map(([value, label]) => ({
  value,
  label,
}))

export const AuthMethodSelect = forwardRef<
  HTMLSelectElement,
  Omit<SelectProps, 'name' | 'options'>
>((props, ref) => (
  <Select
    ref={ref}
    rules={{ required: true }}
    className="my-16"
    options={options}
    name="token_endpoint_auth_method"
    label={
      <Trans id="app.environment.config.token_endpoint_auth_method">
        Token endpoint auth method
      </Trans>
    }
    labelTooltip={t`app.environment.config.token_endpoint_auth_method.tooltip`}
    {...props}
  />
))
