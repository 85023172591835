import { useCallback, KeyboardEventHandler } from 'react'

/**
 * Watched for designated keys being pressed.
 *
 * @param keys Keys to watch for
 *
 * @returns Callback factory function
 */
export function useKeys(keys: string | string[]) {
  return useCallback(
    (callback: () => void): KeyboardEventHandler =>
      (event) => {
        if (
          (typeof keys === 'string' && event.key === keys) ||
          keys.includes(event.key)
        ) {
          event.preventDefault()
          callback()
        }
      },
    [keys]
  )
}
