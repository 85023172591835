import classNames from 'classnames'
import LogoPlaceholder from 'icons/placeholder.svg'
import React from 'react'
import { Image, ImageProps } from './Image'

interface LogoProps extends ImageProps {
  subject?: 'app' | 'bank'
}

const logoClassNames =
  'border-transparent rounded-lg flex-shrink-0 object-cover overflow-hidden truncate'

export const Logo: React.FC<LogoProps> = ({ className, ...props }) => {
  if (!props.id && !props.media) {
    return (
      <LogoPlaceholder
        className={classNames('flex-shrink-0', logoClassNames, className)}
        width={props.opts?.width ?? props.size}
        height={props.opts?.height ?? props.size}
        aria-hidden
      />
    )
  }

  return <Image className={classNames(logoClassNames, className)} {...props} />
}

Logo.defaultProps = {
  subject: 'app',
  size: 64,
}
