import { Link } from 'components'
import CrossIcon from 'icons/close.svg'
import FileIcon from 'icons/file.svg'
import React from 'react'
import classNames from 'classnames'

export type FileThumbnailProps = {
  url?: string
  link?: string
  filename?: string
  onRemove?: () => void
  kind?: 'regular' | 'comment'
}

export const FileThumbnail: React.FC<FileThumbnailProps> = ({
  url,
  link,
  filename,
  onRemove,
  kind,
}) => {
  const thumbnail = (
    <div
      className={classNames(
        'text-14 break-all pb-10 mx-12',
        kind === 'regular' && 'w-160',
        kind === 'comment' && 'flex items-center mb-12'
      )}
    >
      <div
        className={classNames(
          'rounded-8 bg-grey-200 relative flex justify-center items-center',
          kind === 'regular' && 'w-160 h-80 mb-8',
          kind === 'comment' && 'w-60 h-36 mr-12'
        )}
        style={url ? { background: `url(${url}) center no-repeat` } : undefined}
      >
        {onRemove && (
          <div className="absolute top-0 right-0 z-10 m-4">
            <Link onClick={onRemove} data-cy-id={`fileThumbnail.removeFile`}>
              <CrossIcon className="fill-grey-400 h-16 w-16 m-8" />
            </Link>
          </div>
        )}
        {!url && <FileIcon />}
      </div>
      {filename}
    </div>
  )

  if (link) {
    return (
      <Link href={link} openInNewTab data-cy-id={`fileThumbnail.openFile`}>
        {thumbnail}
      </Link>
    )
  }

  return thumbnail
}

FileThumbnail.defaultProps = {
  kind: 'regular',
}
