import { t } from '@lingui/macro'
import classNames from 'classnames'
import React from 'react'

export interface StatusLightProps {
  kind: 'n/a' | 'success' | 'warning' | 'error'
  className?: string
}

t`common.n/a`
t`common.success`
t`common.warning`
t`common.error`

export const StatusLight: React.FC<StatusLightProps> = ({
  kind,
  className,
}) => (
  <div
    className={classNames(
      'w-20 h-20 border border-black-100 rounded-full',
      kind === 'n/a' && 'bg-grey-200',
      kind === 'success' && 'bg-utility-success',
      kind === 'warning' && 'bg-utility-danger',
      kind === 'error' && 'bg-utility-error',
      className
    )}
    title={t({ id: `common.${kind}` })}
  />
)
