import { t, Trans } from '@lingui/macro'
import classNames from 'classnames'
import { Avatar, Button, Dropzone, Link } from 'components'
import { QueryLoader, useToasts } from 'containers'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
  ToastKind,
  useAvatarQuery,
  useCurrentUserQuery,
  useUpdateUserMutation,
} from 'types'
import { useAsyncCallback } from 'utils/handleError'
import { useFileUpload, useUploadFormErrorHandler } from 'utils/hooks'
import { Router } from 'utils/router'

export const AvatarSection: React.FC = () => {
  const avatarQuery = useAvatarQuery()
  const avatar = avatarQuery.data?.currentUser?.avatar

  return (
    <div className="flex flex-col items-center sm:flex-row mb-40">
      <QueryLoader queries={[avatarQuery]}>
        <Avatar media={avatar} size={60} alt={t`profile.avatar.alt`} />

        <div className="text-grey-800 flex flex-col ml-24 mr-10 mt-10 sm:mt-0">
          {avatar ? (
            <div>
              <Trans id="profile.avatar.yourAvatar">Your profile picture</Trans>
            </div>
          ) : (
            <Trans id="profile.avatar.createAvatar">
              Add a profile picture
            </Trans>
          )}
          <div className="flex">
            <Link
              className="block mt-4"
              to="/profile/change-picture"
              data-cy-id="profile.avatar.change"
            >
              {avatar ? (
                <Trans id="profile.avatar.change">Change picture</Trans>
              ) : (
                <Trans id="profile.avatar.uploadNew">Upload new picture</Trans>
              )}
            </Link>
          </div>
        </div>
      </QueryLoader>
    </div>
  )
}

type UpdateAvatarFormData = {
  file: File
}

export const UpdateAvatarForm: React.FC = () => {
  const avatarQuery = useAvatarQuery()
  const userId = avatarQuery.data?.currentUser?.id
  const avatar = avatarQuery.data?.currentUser?.avatar

  const {
    register,
    handleSubmit,
    formState,
    errors,
    setValue,
    setError,
    clearErrors,
  } = useForm<UpdateAvatarFormData>()

  useEffect(() => {
    register({ name: 'file' })
  }, [register])

  const handleUploadError = useUploadFormErrorHandler(
    'file',
    setError,
    clearErrors
  )

  const [, uploadFile] = useFileUpload(undefined, handleUploadError)

  const [updateUser] = useUpdateUserMutation()
  const { addToast } = useToasts()
  const { refetch: refetchCurrentUser } = useCurrentUserQuery()

  const handleSubmitForm = useAsyncCallback(
    handleSubmit(async ({ file }) => {
      if (!userId) {
        throw new Error('Invalid user ID')
      }
      const fileInfo = await uploadFile(file, 'AVATAR')
      if (!fileInfo) {
        return
      }

      await updateUser({
        variables: { userId, user: { avatarFileId: fileInfo.fileId } },
      })
      addToast({
        kind: ToastKind.Success,
        content: t`profile.avatar.updateSuccessful`,
      })
      await avatarQuery.refetch()
      await refetchCurrentUser()
      Router.pushRoute('profile')
    }),
    [updateUser, userId, addToast, avatarQuery]
  )

  const removeAvatar = useAsyncCallback(async () => {
    if (!userId) {
      throw new Error('Invalid user ID')
    }
    await updateUser({
      variables: { userId, user: { avatarFileId: '' } },
    })
    addToast({
      kind: ToastKind.Success,
      content: t`profile.avatar.removeSuccessful`,
    })
    await avatarQuery.refetch()
    await refetchCurrentUser()
    Router.pushRoute('profile')
  }, [avatarQuery.data?.currentUser?.id, updateUser, addToast])

  return (
    <form onSubmit={handleSubmitForm}>
      <Dropzone
        name="avatarFileId"
        label={
          <Trans id="profile.avatar.chooseAvatar">
            Choose a profile picture
          </Trans>
        }
        errors={errors.file}
        imageOnly
        onSelectFile={(file) => setValue('file', file, { shouldDirty: true })}
        data-cy-id="profile.avatar.avatarDropzone"
        preview={(url) => (
          <img
            src={url}
            alt={t`profile.avatar.avatarDropzone.preview.alt`}
            className="h-100 w-100 m-10 object-cover rounded-full border-2 border-black-100"
          />
        )}
        description={
          <p className="text-grey-600 text-14 leading-18">
            <Trans id="profile.avatarRequirements">
              Your profile picture must be at least 128 by 128 px,
              maximum&nbsp;10&nbsp;MB.
            </Trans>
          </p>
        }
        descriptionPosition="bottom"
      />
      <div
        className={classNames(
          'flex mt-30',
          avatar ? 'justify-between' : 'justify-end'
        )}
      >
        {avatar && (
          <Button
            onClick={removeAvatar}
            data-cy-id="profile.removeAvatarButton"
            kind="danger-transparent"
          >
            <Trans id="profile.avatar.remove">Remove picture</Trans>
          </Button>
        )}
        <Button
          loading={formState.isSubmitting}
          disabled={!formState.isDirty}
          isFormSubmit
          data-cy-id="profile.saveChangesButton"
        >
          <Trans id="profile.saveChanges">Save changes</Trans>
        </Button>
      </div>
    </form>
  )
}
