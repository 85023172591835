import classNames from 'classnames'
import { LightBoxProvider } from 'components'
import { Authorized, InvitationList, ToastContainer } from 'containers'
import React, { useEffect, useState } from 'react'
import { Footer } from './Footer'
import { Header, HEADER_HEIGHT } from './Header'
import { Meta } from './Meta'
import { useIsLoggedIn } from 'utils/withAuth'

interface LayoutProps {
  title?: string
  requireLogin?: boolean
  requireBank?: boolean
  fullHeight?: boolean
  forceFullHeight?: boolean
  background?: 'white' | 'grey'
  header?: boolean
  footer?: boolean
}

export const Layout: React.FunctionComponent<LayoutProps> = ({
  children,
  title = 'Bank iD dev portal',
  requireLogin,
  requireBank,
  fullHeight,
  forceFullHeight,
  background,
  header,
  footer,
}) => {
  // measure banner height to calculate correct height for full height layouts
  const [bannerHeight, setBannerHeight] = useState(0)
  useEffect(() => {
    setBannerHeight(document.getElementById('infoBanner')?.clientHeight ?? 0)
  }, [])
  const visiblePageHeight = `calc(100% - ${
    header ? HEADER_HEIGHT : 0
  }px - ${bannerHeight}px - 1px)`

  return (
    <>
      <Meta title={title} />
      {header && <Header />}

      <ToastContainer />
      <main
        style={{
          minHeight: fullHeight ? visiblePageHeight : undefined,
          height: forceFullHeight ? visiblePageHeight : undefined,
        }}
        className={classNames(background === 'grey' && 'bg-grey-100')}
      >
        <Authorized requireLogin={requireLogin} requireBank={requireBank}>
          <LightBoxProvider>{children}</LightBoxProvider>
        </Authorized>
      </main>

      {footer && <Footer />}

      {useIsLoggedIn() && <InvitationList />}
    </>
  )
}

Layout.defaultProps = {
  background: 'white',
  fullHeight: true,
  header: true,
  footer: true,
}
