import classNames from 'classnames'
import type { ClickableProps } from 'components'
import { Clickable } from 'components'
import React from 'react'
import type { TestIdProp } from 'types'

interface TabProps extends ClickableProps, TestIdProp {
  active?: boolean
  'data-cy-id': string
}

export const Tab: React.FC<TabProps> = ({
  active,
  children,
  className,
  ...props
}) => {
  return (
    <Clickable
      fullWidth
      className={classNames(
        'text-16 py-15 flex-grow relative block text-center',
        active ? 'text-primary font-medium' : 'text-grey-800',
        className
      )}
      {...props}
    >
      {children}
      {active && <div className="absolute h-4 bg-primary w-full bottom-0" />}
    </Clickable>
  )
}
