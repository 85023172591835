import { t, Trans } from '@lingui/macro'
import { Link, Tooltip } from 'components'
import { useToasts } from 'containers/Toast'
import RetryIcon from 'icons/retry.svg'
import React from 'react'
import { ToastKind, useResendInvitationMutation } from 'types'
import { useAsyncCallback } from 'utils/handleError'

type ResendInvitationButtonProps = {
  invitationId: string
}

export const ResendInvitationButton: React.FC<ResendInvitationButtonProps> = ({
  invitationId,
}) => {
  const [resendInvitation] = useResendInvitationMutation({
    variables: { invitationId },
  })

  const { addToast } = useToasts()
  const resend = useAsyncCallback(async () => {
    await resendInvitation()

    addToast({
      kind: ToastKind.Success,
      content: t`users.pendingInvitation.resendSuccessful`,
    })
  }, [addToast, resendInvitation])

  return (
    <Tooltip
      kind="dark"
      id="users.pendingInvitation.resend.tooltip"
      displayTip={
        <Trans id="users.pendingInvitation.resend.tooltip">
          Send a new invitation and reset the expiration period.
        </Trans>
      }
    >
      <Link
        kind="text"
        onClick={resend}
        data-cy-id="users.pendingInvitation.resend"
      >
        <RetryIcon className="inline-block -mt-4 mr-4" />
        <Trans id="users.pendingInvitation.resend">Resend</Trans>
      </Link>
    </Tooltip>
  )
}
