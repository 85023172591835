import classNames from 'classnames'
import React from 'react'
import { TableColumn } from './common'

interface TableCellProps {
  column: TableColumn<any>
  rowData: any
}

export const TableCell: React.FC<TableCellProps> = (props) => {
  const style = {
    width: props.column.width !== 'auto' ? props.column.width : undefined,
    maxWidth:
      props.column.maxWidth !== 'auto' ? props.column.maxWidth : undefined,
  }

  return (
    <div
      role="cell"
      className={classNames(
        'px-16 py-11',
        props.column.width === 'auto' && 'flex-grow',
        props.column.align === 'center' && 'text-center',
        props.column.align === 'right' && 'text-right'
      )}
      style={style}
    >
      {props.column.render(props.rowData)}
    </div>
  )
}
