import { NextPage } from 'next'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { Trans } from '@lingui/macro'
import { Toggle } from 'components'
import { useForm } from 'react-hook-form'
import {
  ServiceType,
  useBankEnvServicesBySlugQuery,
  useUpdateBankEnvServicesMutation,
} from 'types'
import { useAsyncCallback } from 'utils/handleError'
import { QueryLoader, useBankPermissions } from 'containers'

interface BankServicesProps {
  slug: string
  envSlug: string
  envId: string
}

export const BankServices: NextPage<BankServicesProps> = ({
  slug,
  envSlug,
  envId,
}) => {
  const servicesQuery = useBankEnvServicesBySlugQuery({
    variables: { slug: envSlug },
  })

  const servicesData = servicesQuery.data?.bankEnvironment?.services

  const { control, setValue } = useForm<any>({ reValidateMode: 'onSubmit' })

  const [services, setServices] = useState<Array<ServiceType>>([])
  const [inProgress, setInProgress] = useState<boolean>(false)

  const [updateServicesMut] = useUpdateBankEnvServicesMutation()

  const isChecked = (service: ServiceType) => {
    return servicesData ? servicesData.indexOf(service) > -1 : false
  }

  const hasPermission = useBankPermissions()

  const updateServices = useAsyncCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      setInProgress(true)
      const { name, checked } = e.currentTarget

      const tempServices = services.filter((x) => x !== name)
      if (checked) {
        tempServices.push(name as ServiceType)
      }

      try {
        await updateServicesMut({
          variables: { envId, data: tempServices },
        })
        setServices(tempServices)
      } catch (ex: any) {
        setValue(name, !checked)
        throw ex
      } finally {
        setInProgress(false)
      }
    },
    [updateServicesMut, services]
  )

  useEffect(() => {
    if (servicesData) {
      setServices(servicesData)
    }
  }, [servicesData])

  return (
    <>
      <h2 className="heading-h2 mt-40 mb-24">
        <Trans id="bank.environment.services.heading">Supported services</Trans>
      </h2>
      <QueryLoader queries={[servicesQuery]}>
        <form className="form-mono-font">
          <Toggle
            className="my-16"
            name={ServiceType.Authentication}
            control={control}
            labelPosition="left"
            data-cy-id="bank.environment.services.authentication"
            onChange={updateServices}
            defaultChecked={isChecked(ServiceType.Authentication)}
            disabled={inProgress || !hasPermission('ENV:W')}
            label={
              <Trans id="bank.environment.services.authentication">
                Authentication
              </Trans>
            }
          />
          <Toggle
            className="my-16"
            name={ServiceType.SignSinglePdf}
            control={control}
            labelPosition="left"
            data-cy-id="bank.environment.services.signSinglePdf"
            onChange={updateServices}
            defaultChecked={isChecked(ServiceType.SignSinglePdf)}
            disabled={inProgress || !hasPermission('ENV:W')}
            label={
              <Trans id="bank.environment.services.signSinglePdf">
                Sign single PDF
              </Trans>
            }
          />
          <Toggle
            className="my-16"
            name={ServiceType.SignMultiplePdf}
            control={control}
            labelPosition="left"
            data-cy-id="bank.environment.services.signMultiplePdf"
            onChange={updateServices}
            defaultChecked={isChecked(ServiceType.SignMultiplePdf)}
            disabled={inProgress || !hasPermission('ENV:W')}
            label={
              <Trans id="bank.environment.services.signMultiplePdf">
                Sign multiple PDF
              </Trans>
            }
          />
          <Toggle
            className="my-16"
            name={ServiceType.SignSo}
            control={control}
            labelPosition="left"
            data-cy-id="bank.environment.services.signSo"
            onChange={updateServices}
            defaultChecked={isChecked(ServiceType.SignSo)}
            disabled={inProgress || !hasPermission('ENV:W')}
            label={
              <Trans id="bank.environment.services.signSo">
                Statement sign
              </Trans>
            }
          />
          <Toggle
            className="my-16"
            name={ServiceType.Notification}
            control={control}
            labelPosition="left"
            data-cy-id="bank.environment.services.notification"
            onChange={updateServices}
            defaultChecked={isChecked(ServiceType.Notification)}
            disabled={inProgress || !hasPermission('ENV:W')}
            label={
              <Trans id="bank.environment.services.notification">
                Notification
              </Trans>
            }
          />
          <Toggle
            className="my-16"
            name={ServiceType.UniqueIdentity}
            control={control}
            labelPosition="left"
            data-cy-id="bank.environment.services.uniqueIdentity"
            onChange={updateServices}
            defaultChecked={isChecked(ServiceType.UniqueIdentity)}
            disabled={inProgress || !hasPermission('ENV:W')}
            label={
              <Trans id="bank.environment.services.uniqueIdentity">
                Unique identity
              </Trans>
            }
          />
          <Toggle
            className="my-16"
            name={ServiceType.QualifiedSignature}
            control={control}
            labelPosition="left"
            data-cy-id="bank.environment.services.qualifiedSignature"
            onChange={updateServices}
            defaultChecked={isChecked(ServiceType.QualifiedSignature)}
            disabled={inProgress || !hasPermission('ENV:W')}
            label={
              <Trans id="bank.environment.services.qualifiedSignature">
                Qualified signature
              </Trans>
            }
          />
        </form>
      </QueryLoader>
    </>
  )
}
