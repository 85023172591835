import { RefObject, useEffect } from 'react'

/**
 * Watched for clicks outside provided ref
 *
 * @param ref       Element ref to be watched
 * @param callback  Callback to be invoked on click
 */
export function useOutsideClick<TRef extends HTMLElement>(
  ref: RefObject<TRef>,
  callback: () => void
) {
  useEffect(() => {
    function handleOutsideClick(e: any) {
      if (!ref?.current?.contains(e.target)) {
        callback()
      }
    }

    document.addEventListener('click', handleOutsideClick)
    return () => document.removeEventListener('click', handleOutsideClick)
  }, [callback, ref])
}
