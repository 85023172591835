import { QueryResult } from '@apollo/client'
import { Trans } from '@lingui/macro'
import React from 'react'
import SpinnerIcon from 'icons/spinner.svg'

interface QueryLoaderProps {
  queries?: false | QueryResult | (false | QueryResult)[]
  errorComponent?: React.ReactNode | null
  loaderComponent?: React.ReactNode | null
}

export const QueryLoader: React.FC<QueryLoaderProps> = ({
  queries,
  children,
  errorComponent,
  loaderComponent,
}) => {
  if (queries) {
    const qs = Array.isArray(queries) ? queries : [queries]

    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain -- optional chaining cannot be used here
    const hasFailed = !!qs.find((query) => query && query.error)
    const isLoading = !!qs.find(
      (query) => !query || (!query.data && query.loading)
    )

    if (hasFailed) {
      return <>{errorComponent}</>
    }

    if (isLoading) {
      return <>{loaderComponent}</>
    }
  }

  if (typeof children === 'function') {
    return <>{children()}</>
  } else {
    return <>{children}</>
  }
}

QueryLoader.defaultProps = {
  errorComponent: (
    <div className="flex flex-row justify-center items-center h-300 w-full">
      <Trans id="error.dataLoadingError">
        There was an error loading data.
      </Trans>
    </div>
  ),
  loaderComponent: (
    <div className="flex flex-row justify-center items-center h-300 w-full">
      <SpinnerIcon height={64} width={64} />
    </div>
  ),
}
