import { t, Trans } from '@lingui/macro'
import { Checkbox, RadioInput, Table } from 'components'
import { TableColumn } from 'components/Table/common'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useFeature } from 'utils/features'
import { isPermissionValid, Permission, PermissionLevel } from './common'

interface PermissionData {
  id: string
  permission: Permission
  level: PermissionLevel
}

type PermissionsState = Partial<Record<Permission, PermissionLevel>>

const initialAppPermissions: PermissionsState = {
  APL: 'R',
  USR: 'N',
  SBX: 'N',
  PROD: 'N',
  // FNC: 'N',
  // LGL: 'N',
  // ADT: 'N',
}

const initialBankPermissions: PermissionsState = {
  BNK: 'R',
  USR: 'N',
  // FNC: 'N',
  // ADT: 'N',
  DOC: 'N',
  ENV: 'N',
  // OUT: 'N',
  APL: 'N',
  REP: 'N',
}

const initialOrgPermissions: PermissionsState = {
  ORG: 'R',
  USR: 'N',
  // FNC: 'N',
  LGL: 'N',
  REP: 'N',
}

export type PermissionKind = 'app' | 'bank' | 'organization'

export const sortPermissions =
  (type: PermissionKind) => (a: string, b: string) => {
    const order = Object.keys(
      type === 'app'
        ? initialAppPermissions
        : type === 'bank'
        ? initialBankPermissions
        : initialOrgPermissions
    )
    return order.indexOf(a.split(':')[0]) - order.indexOf(b.split(':')[0])
  }

interface PermissionsContext {
  setPermission: (permission: Permission, level: PermissionLevel) => void
  disabledPermissions?: string[] | 'all'
  type: PermissionKind
}

const PermissionsFormContext = createContext<PermissionsContext>(null!)

interface PermissionControlProps {
  permission: Permission
  level: PermissionLevel
  value: PermissionLevel
}

const PermissionRadioButton: React.FC<PermissionControlProps> = ({
  permission,
  level,
  value,
}) => {
  const { setPermission, disabledPermissions, type } = useContext(
    PermissionsFormContext
  )
  const isEnabled =
    disabledPermissions !== 'all' &&
    !disabledPermissions?.includes(permission) &&
    isPermissionValid(type, permission, value)

  return (
    <RadioInput
      name={permission}
      value={level}
      checked={
        level === value ||
        (level === 'RT' && value === 'R') ||
        (level === 'RS' && value === 'R')
      } // ugly hack for checkbox permissions
      disabled={!isEnabled}
      onChange={() => setPermission(permission, value)}
    />
  )
}

interface PermissionCheckboxProps extends PermissionControlProps {
  type: string
  disabled?: boolean
  defaultChecked?: boolean
}

t`permissions.bank.APL:RT`
t`permissions.app.PROD:RS`

const PermissionCheckbox: React.FC<PermissionCheckboxProps> = ({
  permission,
  level,
  value,
  type,
  disabled,
  defaultChecked,
}) => {
  const { setPermission } = useContext(PermissionsFormContext)

  return (
    <div className="text-14 my-14">
      <Checkbox
        name={`${permission}:${value}`}
        label={<Trans id={`permissions.${type}.${permission}:${value}`} />}
        defaultChecked={defaultChecked || level === value}
        onChange={(e) =>
          setPermission(permission, e.target.checked ? value : 'R')
        }
        disabled={disabled}
      />
    </div>
  )
}

interface PermissionLabelProps {
  permission: Permission
}

const PermissionLabel: React.FC<PermissionLabelProps> = ({ permission }) => {
  const { type } = useContext(PermissionsFormContext)

  return (
    <p>
      <Trans id={`permissions.${type}.${permission}`} />
    </p>
  )
}

interface PermissionRowProps {
  permission: Permission
  level: PermissionLevel
}

const PermissionRow: React.FC<PermissionRowProps> = ({ permission, level }) => {
  const { type } = useContext(PermissionsFormContext)

  return (
    <div className="self-start">
      <PermissionLabel permission={permission} />
      {type === 'bank' && permission === 'APL' && level !== 'N' && (
        <PermissionCheckbox
          permission="APL"
          level={level}
          value="RT"
          type={type}
        />
      )}
      {type === 'app' && permission === 'PROD' && level !== 'N' && (
        <PermissionCheckbox
          permission="PROD"
          // we need to remount the component so defaultChecked is updated on `level` change,
          // this is a hack, if you change component's key, it remounts
          key={level}
          level={level}
          value="RS"
          type={type}
          defaultChecked={level === 'RWS'}
          disabled={level === 'RWS'}
        />
      )}
    </div>
  )
}

const PermissionsTableColumns: TableColumn<PermissionData>[] = [
  {
    key: 'permission',
    title: <Trans id="permissions.permission">Permission</Trans>,
    width: 'auto',
    render: (row) => (
      <PermissionRow permission={row.permission} level={row.level} />
    ),
  },
  {
    key: 'noaccess',
    title: <Trans id="permissions.noAccess">No Access</Trans>,
    align: 'center',
    width: 110,
    render: (row) => (
      <PermissionRadioButton
        permission={row.permission}
        level={row.level}
        value="N"
      />
    ),
  },
  {
    key: 'read',
    title: <Trans id="permissions.read">Read</Trans>,
    align: 'center',
    width: 90,
    render: (row) => (
      <PermissionRadioButton
        permission={row.permission}
        level={row.level}
        value="R"
      />
    ),
  },
  {
    key: 'readwrite',
    title: <Trans id="permissions.readWrite">Read & Write</Trans>,
    align: 'center',
    width: 90,
    render: (row) => {
      return (
        <PermissionRadioButton
          permission={row.permission}
          level={row.level}
          value={row.permission === 'PROD' ? 'RWS' : 'RW'}
        />
      )
    },
  },
]

const stringifyPermissions = (permissions: PermissionsState) =>
  Object.entries(permissions)
    .filter((val) => val[1] !== 'N')
    .map((val) => val.join(':'))

interface PermissionsInputProps {
  onChange: (permissions: string[]) => void
  userPermissions?: string[]
  type: 'app' | 'bank' | 'organization'
  disabledPermissions?: string[] | 'all'
}

export const PermissionsInput: React.FC<PermissionsInputProps> = ({
  onChange,
  userPermissions,
  type,
  disabledPermissions,
}) => {
  const [permissions, setPermissions] = useState<PermissionsState>({
    ...(type === 'app' ? initialAppPermissions : {}),
    ...(type === 'bank' ? initialBankPermissions : {}),
    ...(type === 'organization' ? initialOrgPermissions : {}),
  })

  // update permissions in case userPermissions loads after first render
  useEffect(() => {
    setPermissions((permissions) => ({
      ...permissions,
      ...Object.fromEntries(
        userPermissions?.map((perm) => perm.split(':')) ?? []
      ),
    }))
  }, [userPermissions])

  const setPermission = useCallback(
    (permission: Permission, level: PermissionLevel) => {
      if (isPermissionValid(type, permission, level)) {
        setPermissions((state) => ({ ...state, [permission]: level }))
      }
    },
    [type]
  )

  const enableReports = useFeature('reports')

  const tableData = useMemo(
    () =>
      Object.entries(permissions)
        .filter(([key, _]) => enableReports || key !== 'REP')
        .filter(([key, _]) => key !== 'PRD')
        .map((val) => ({
          id: val[0],
          permission: val[0],
          level: val[1],
        })),
    [permissions, enableReports]
  )

  // update permissions on change
  useEffect(() => {
    onChange(stringifyPermissions(permissions))
  }, [permissions, onChange])

  return (
    <PermissionsFormContext.Provider
      value={{ setPermission, disabledPermissions, type }}
    >
      <Table
        columns={PermissionsTableColumns}
        data={tableData}
        alignRows="start"
      />
    </PermissionsFormContext.Provider>
  )
}

t`users.permissions.app.ADT:R.text`
t`users.permissions.app.ADT:R.title`
t`users.permissions.app.ADT:RW.text`
t`users.permissions.app.ADT:RW.title`
t`users.permissions.app.APL:R.text`
t`users.permissions.app.APL:R.title`
t`users.permissions.app.APL:RW.text`
t`users.permissions.app.APL:RW.title`
t`users.permissions.app.FNC:R.text`
t`users.permissions.app.FNC:R.title`
t`users.permissions.app.FNC:RW.text`
t`users.permissions.app.FNC:RW.title`
t`users.permissions.app.LGL:R.text`
t`users.permissions.app.LGL:R.title`
t`users.permissions.app.LGL:RW.text`
t`users.permissions.app.LGL:RW.title`
t`users.permissions.app.PRD:R.text`
t`users.permissions.app.PRD:R.title`
t`users.permissions.app.PRD:RW.text`
t`users.permissions.app.PRD:RW.title`
t`users.permissions.app.PROD:R.text`
t`users.permissions.app.PROD:R.title`
t`users.permissions.app.PROD:RS.text`
t`users.permissions.app.PROD:RS.title`
t`users.permissions.app.PROD:RWS.text`
t`users.permissions.app.PROD:RWS.title`
t`users.permissions.app.SBX:R.text`
t`users.permissions.app.SBX:R.title`
t`users.permissions.app.SBX:RW.text`
t`users.permissions.app.SBX:RW.title`
t`users.permissions.app.USR:R.text`
t`users.permissions.app.USR:R.title`
t`users.permissions.app.USR:RW.text`
t`users.permissions.app.USR:RW.title`

t`users.permissions.bank.ADT:R.text`
t`users.permissions.bank.ADT:R.title`
t`users.permissions.bank.ADT:RW.text`
t`users.permissions.bank.ADT:RW.title`
t`users.permissions.bank.APL:R.text`
t`users.permissions.bank.APL:R.title`
t`users.permissions.bank.APL:RT.text`
t`users.permissions.bank.APL:RT.title`
t`users.permissions.bank.DOC:R.text`
t`users.permissions.bank.DOC:R.title`
t`users.permissions.bank.FNC:R.text`
t`users.permissions.bank.FNC:R.title`
t`users.permissions.bank.FNC:RW.text`
t`users.permissions.bank.FNC:RW.title`
t`users.permissions.bank.USR:R.text`
t`users.permissions.bank.USR:R.title`
t`users.permissions.bank.USR:RW.text`
t`users.permissions.bank.USR:RW.title`
t`users.permissions.bank.BNK:R.text`
t`users.permissions.bank.BNK:R.title`
t`users.permissions.bank.BNK:RW.text`
t`users.permissions.bank.BNK:RW.title`
t`users.permissions.bank.ENV:R.text`
t`users.permissions.bank.ENV:R.title`
t`users.permissions.bank.ENV:RW.text`
t`users.permissions.bank.ENV:RW.title`
t`users.permissions.bank.OUT:R.text`
t`users.permissions.bank.OUT:R.title`
t`users.permissions.bank.OUT:RW.text`
t`users.permissions.bank.OUT:RW.title`

t`users.permissions.organization.ORG:R.text`
t`users.permissions.organization.ORG:R.title`
t`users.permissions.organization.ORG:RW.text`
t`users.permissions.organization.ORG:RW.title`
t`users.permissions.organization.USR:R.text`
t`users.permissions.organization.USR:R.title`
t`users.permissions.organization.USR:RW.text`
t`users.permissions.organization.USR:RW.title`
t`users.permissions.organization.FNC:R.text`
t`users.permissions.organization.FNC:R.title`
t`users.permissions.organization.FNC:RW.text`
t`users.permissions.organization.FNC:RW.title`
t`users.permissions.organization.LGL:R.text`
t`users.permissions.organization.LGL:R.title`
t`users.permissions.organization.LGL:RW.text`
t`users.permissions.organization.LGL:RW.title`

t`users.permissions.tooltip`
