import { Ability } from '@casl/ability'
import { AbilityContext } from 'containers'
import { detectSubjectType } from 'containers/Layout/Can'
import App, { AppContext } from 'next/app'
import getConfig from 'next/config'
import { parseCookies } from 'nookies'
import React, { Dispatch, SetStateAction, useContext } from 'react'
import { withFeatures } from 'utils/features'
import { init } from 'utils/sentry'
import { withApollo } from 'utils/withApollo'
import withAuth from 'utils/withAuth'
import { withCookieConsent } from 'utils/withCookieConsent'
import { withInfoBanner } from 'utils/withInfoBanner'
import { withLang } from 'utils/withLang'
import './global.css'
import './swagger-ui.css'

// initialize Sentry
const SENTRY_DSN = getConfig().publicRuntimeConfig.SENTRY_DSN
const SENTRY_ENVIRONMENT = getConfig().publicRuntimeConfig.ENVIRONMENT
const SENTRY_RELEASE = process.env.SENTRY_RELEASE
init(SENTRY_RELEASE, SENTRY_DSN, SENTRY_ENVIRONMENT)

interface GlobalState {
  docsExpanded: boolean
}

const initialGlobalState: GlobalState = {
  docsExpanded: false,
}

interface GlobalStore {
  state: GlobalState
  setGlobalState: Dispatch<SetStateAction<GlobalState>>
}

const GlobalStateContext = React.createContext<GlobalStore>(null!)

export function useGlobalState() {
  return useContext(GlobalStateContext)
}

class MyApp extends App<
  {
    cookies: Record<string, string>
    nonce?: string
    features: { name: string; description?: string; defaultValue: boolean }[]
    rules: any[]
    err: any
  },
  unknown,
  GlobalState
> {
  state: GlobalState = initialGlobalState

  static async getInitialProps(ctx: AppContext) {
    // calls page's `getInitialProps` and fills `appProps.pageProps`
    const appProps = await App.getInitialProps(ctx as any)
    const cookies = parseCookies(ctx.ctx)
    const nonce = (ctx.ctx.res as any)?.locals?.nonce

    return {
      ...appProps,
      cookies,
      nonce,
    }
  }

  public render() {
    const { Component, pageProps, cookies, nonce, err } = this.props

    let features = this.props.features
    let rules = this.props.rules

    if (typeof window !== 'undefined' && 'FEATURES' in window) {
      features = window.FEATURES
    }

    if (typeof window !== 'undefined' && 'RULES' in window) {
      rules = window.RULES
    }

    const ActualComponent = withCookieConsent(
      cookies,
      nonce,
      withInfoBanner(cookies, withFeatures(cookies, features)(Component))
    )
    const ability = new Ability(rules, { detectSubjectType })

    return (
      <GlobalStateContext.Provider
        value={{
          state: this.state,
          setGlobalState: (nextState: SetStateAction<GlobalState>) => {
            this.setState((prevState) =>
              typeof nextState === 'function' ? nextState(prevState) : nextState
            )
          },
        }}
      >
        <AbilityContext.Provider value={ability}>
          <ActualComponent {...pageProps} err={err} />
        </AbilityContext.Provider>
      </GlobalStateContext.Provider>
    )
  }
}

export default withApollo({ ssr: true })(
  withAuth(withLang(MyApp as any) as any) as any
)
