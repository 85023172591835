import { Trans } from '@lingui/macro'
import classNames from 'classnames'
import { Tooltip } from 'components'
import React, { useMemo } from 'react'
import {
  AppEnvironmentScope,
  AvailabilityType,
  ProductPrice,
  useProductsPricesQuery,
} from 'types'
import { useKeys } from 'utils/hooks'
import WarningIcon from 'icons/warning-circle.svg'

type TariffCardProps = {
  isTariff: boolean
  onSelect: (isTariff: boolean) => void
  selected: boolean
  disabled?: boolean
}

const TariffCard: React.FC<TariffCardProps> = ({
  isTariff,
  onSelect,
  selected,
  disabled = false,
}) => {
  const handleSelect = () => onSelect(isTariff)
  const handleKeyUp = useKeys([' ', 'Enter'])(handleSelect)

  return (
    <div
      className={classNames(
        'relative p-24 mb-24 flex-1 rounded-8',
        'cursor-pointer shadow-thin',
        selected ? 'border-2 border-primary' : 'border border-grey-200',
        disabled ? 'cursor-not-allowed opacity-50' : 'hover:shadow'
      )}
      role="option"
      aria-disabled={disabled}
      tabIndex={disabled ? -1 : 0}
      onClick={disabled ? undefined : handleSelect}
      onKeyUp={disabled ? undefined : handleKeyUp}
      aria-selected={selected}
    >
      <div
        className={classNames(
          'absolute right-0 mr-24 rounded-full w-24 h-24',
          selected
            ? 'bg-primary bg-tick bg-center bg-no-repeat'
            : 'border border-grey-300'
        )}
      />

      <p className="font-semibold mb-20">
        {isTariff ? (
          <Trans id="app.products.update.tariffChoice.payPerUser">
            Pay per unique user
          </Trans>
        ) : (
          <Trans id="app.products.update.tariffChoice.payPerApiCall">
            Pay per unique API call
          </Trans>
        )}
      </p>

      <p className="text-14 leading-22 text-grey-800">
        {isTariff ? (
          <Trans id="app.products.update.tariffChoice.payPerUser.description">
            While the subscription is active, the end user can repeatedly use
            the service and you are able to get data update notifications when
            user data changes. After the subscription expires, new subscription
            is started once the end user uses the service again.
          </Trans>
        ) : (
          <Trans id="app.products.update.tariffChoice.payPerApiCall.description">
            You pay a one-time fee for each API call.
          </Trans>
        )}
      </p>
    </div>
  )
}

type FinalProductCardProps = {
  productPrices?: ProductPrice[]
  isTariff?: boolean
}

const FinalProductCard = ({
  productPrices,
  isTariff,
}: FinalProductCardProps) => {
  const sortedPrices = [...productPrices!].sort((a, b) => {
    if (a.name === 'SIGN') {
      return -1 // Place SIGN at the top
    }
    if (b.name === 'SIGN') {
      return 1 // Place SIGN at the top
    }
    if (a.name === 'QSIGN') {
      return -1 // Place QSIGN second
    }
    if (b.name === 'QSIGN') {
      return 1 // Place QSIGN second
    }
    return a.priority - b.priority // Sort remaining items by price
  })

  return (
    <div
      className={classNames(
        'relative p-24 mb-24 flex-1 rounded-8 shadow-thin border border-grey-200'
      )}
    >
      <div
        className="flex flex-row justify-around items-center"
        style={{ minHeight: 150 }}
      >
        <div className="flex flex-col" style={{ width: 200 }}>
          <h2>
            <Trans id="app.environments.config.billingSettings.chosenProduct">
              Chosen product
            </Trans>
          </h2>
          {productPrices && productPrices?.length > 0 && (
            <h2 className="heading-h2 inline">
              {sortedPrices[productPrices?.length - 1].name}
            </h2>
          )}
        </div>
        <div className="flex flex-row" style={{ width: 350 }}>
          <table className="min-w-full">
            <thead>
              <tr>
                <th className="text-14 leading-22 text-grey-800 text-left">
                  <Trans id="app.environments.config.billingSettings.productName">
                    Product name
                  </Trans>
                </th>
                <th className="text-14 leading-22 text-grey-800 text-right">
                  <Trans id="app.environments.config.billingSettings.price">
                    Price without VAT
                  </Trans>
                </th>
                <th style={{ width: 20 }}></th>
              </tr>
            </thead>
            <tbody>
              {sortedPrices &&
                sortedPrices?.length > 0 &&
                sortedPrices.map((productPrice) => (
                  <tr key={productPrice.name}>
                    <td className="text-grey-800">{productPrice.name}</td>
                    <td className="text-right">{productPrice.price} Kč</td>
                    {(productPrice.name === 'SIGN' ||
                      productPrice.name === 'QSIGN') &&
                    isTariff ? (
                      <td style={{ width: 20 }}>
                        <Tooltip
                          kind="dark"
                          displayTip={
                            <Trans
                              id={
                                'app.environments.config.billingSettings.pricePerApi'
                              }
                            />
                          }
                          id="billing.warning"
                          className="ml-6"
                        >
                          <WarningIcon className="mb-2" />
                        </Tooltip>
                      </td>
                    ) : (
                      <td style={{ width: 20 }}></td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

type TariffChoiceProps = {
  onSelectTariff: (isTariff: boolean) => void
  isTariff: boolean
  scopes: AppEnvironmentScope[]
  appId: string
  activeProductIsTariff?: boolean
  activeProducts: string[]
}

export const ProductResult = ({
  onSelectTariff,
  isTariff,
  scopes,
  appId,
  activeProductIsTariff,
  activeProducts,
}: TariffChoiceProps) => {
  const variables = useMemo(() => {
    return {
      appId: appId ?? '',
      scopes: scopes
        .filter((scope) => scope.scopeLevel !== 'UNUSED')
        .filter((scope) =>
          !isTariff ? scope.available.includes(AvailabilityType.Call) : scope
        )
        .map((scope) => scope.type.scope),
      isTariff: isTariff,
    }
  }, [appId, isTariff, activeProducts]) // Only get product prices variables when tariff or active products changes

  const productsPricesQuery = useProductsPricesQuery({
    variables: variables,
    fetchPolicy: 'network-only',
  })

  const activeProductPrices: ProductPrice[] = []

  productsPricesQuery.data?.productsPrices?.forEach((productPrice) => {
    if (productPrice !== null && productPrice !== undefined)
      activeProductPrices.push(productPrice)
  })

  return (
    <>
      <div
        className="flex flex-col xl:flex-row mt-10 xl:space-x-24 max-w-none md:max-w-500 xl:max-w-none"
        role="listbox"
      >
        <TariffCard
          isTariff={false}
          onSelect={onSelectTariff}
          selected={isTariff === false}
        />
        <TariffCard
          isTariff={true}
          onSelect={onSelectTariff}
          selected={isTariff === true}
        />
      </div>
      <FinalProductCard
        productPrices={activeProductPrices ?? []}
        isTariff={isTariff}
      />
    </>
  )
}
