// Disable TS an Eslint checks for conditional webpack alias
// eslint-disable-next-line import/no-unresolved
import getCatalog from '@catalogs'
import { i18n } from '@lingui/core'
import { I18nProvider, useLingui } from '@lingui/react'
import parser from 'accept-language-parser'
import { cs, en } from 'make-plural/plurals'
import { NextPage, NextPageContext } from 'next'
import { parseCookies } from 'nookies'
import React, { useRef } from 'react'
import { setCookie } from './cookie'

export type LocaleType = 'cs' | 'en'

const LANG_COOKIE_NAME = 'lang'
const DEFAULT_LOCALE: LocaleType = 'en'
export const LOCALES: LocaleType[] = ['cs', 'en']
export const LOCALE_CODES: Record<string, string> = {
  en: 'en-US',
  cs: 'cs-CZ',
}

i18n.loadLocaleData({
  en: { plurals: en },
  cs: { plurals: cs },
})

export function getLocale(ctx?: NextPageContext): LocaleType | undefined {
  const cookies = parseCookies(ctx)
  if (cookies.lang && LOCALES.includes(cookies.lang as LocaleType)) {
    return cookies.lang as LocaleType
  }
}

export function setLocale(locale: LocaleType, ctx?: NextPageContext) {
  setCookie(ctx, LANG_COOKIE_NAME, locale, {
    maxAge: 365 * 24 * 60 * 60,
  })
}

export function detectLocale(ctx: NextPageContext): LocaleType {
  let locale = DEFAULT_LOCALE

  const activeLocale = getLocale(ctx)
  if (activeLocale) {
    locale = activeLocale
  } else if (ctx.req?.headers['accept-language']) {
    const lang = parser.pick(LOCALES, ctx.req.headers['accept-language'], {
      loose: true,
    })
    if (lang) {
      locale = lang
    }
  }

  setLocale(locale, ctx)
  return locale
}

export function useLocale() {
  return useLingui().i18n.locale as LocaleType
}

export interface WithLangProps {
  locale: string
}

export function withLang(Component: any) {
  const WithLang: NextPage<WithLangProps> = ({ locale, ...props }) => {
    const firstRender = useRef(true)

    if (firstRender.current) {
      const catalog = getCatalog(locale)
      i18n.load(locale, catalog as any)
      i18n.activate(locale)
      firstRender.current = false
    }

    return (
      <I18nProvider i18n={i18n} forceRenderOnLocaleChange={false}>
        <Component {...props} />
      </I18nProvider>
    )
  }

  WithLang.getInitialProps = async (ctx) => {
    let pageProps = {}
    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx)
    }

    const context = (ctx as any).ctx ?? ctx
    const locale = detectLocale(context)

    return { ...pageProps, locale }
  }

  return WithLang
}
