import { useRef, useState, useEffect, useCallback } from 'react'

/**
 * Watches dimensions of element assigned returned ref.
 *
 * @returns Object { width, height, ref }
 */
export function useDimensions<TRef extends HTMLElement>() {
  const [width, setWidth] = useState<number | undefined>(undefined)
  const [height, setHeight] = useState<number | undefined>(undefined)
  const ref = useRef<TRef>(null)

  const updateDimensions = useCallback(() => {
    if (ref?.current) {
      setWidth(ref.current.clientWidth)
      setHeight(ref.current.clientHeight)
    }
  }, [])

  useEffect(() => {
    updateDimensions()
    window.addEventListener('resize', updateDimensions)
    return () => window.removeEventListener('resize', updateDimensions)
  }, [updateDimensions])

  return {
    width,
    height,
    ref,
  }
}
