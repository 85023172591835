import { Clickable } from 'components/Clickable'
import { Trans } from '@lingui/macro'
import React from 'react'
import Clipboard from 'icons/clipboard.svg'
import CheckMark from 'icons/white-check-mark.svg'
import useClipboard from 'react-use-clipboard'
import { Tooltip } from 'components/Tooltip'

interface CopyToClipboardProps {
  value?: string | null
  successDuration?: number
  className?: string
  testId?: string
}

export const CopyToClipboard: React.FC<CopyToClipboardProps> = ({
  value,
  successDuration = 2000,
  className,
  testId,
}) => {
  const [copied, copy] = useClipboard(value ?? '', { successDuration })

  return (
    <Clickable
      className={className}
      onClick={copy}
      data-cy-id={`copy.${testId}`}
    >
      {copied ? (
        <CheckMark className="fill-grey-800" />
      ) : (
        <Tooltip
          id="input.copyTooltip"
          kind="dark"
          displayTip={<Trans id="input.copyTooltip">Copy to clipboard</Trans>}
        >
          <Clipboard className="cursor-pointer" />
        </Tooltip>
      )}
    </Clickable>
  )
}
