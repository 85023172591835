import { t, Trans } from '@lingui/macro'
import classNames from 'classnames'
import type { ValidationContainerProps } from 'components'
import { Link, ValidationContainer } from 'components'
import CrossIcon from 'icons/close.svg'
import React, { forwardRef, useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { FieldErrors } from 'react-hook-form'
import type { TestIdProp } from 'types'
import SpinnerIcon from 'icons/spinner.svg'

export interface DropzoneProps extends ValidationContainerProps, TestIdProp {
  content?: (filename?: string) => React.ReactNode
  preview?: (previewUrl: string) => React.ReactNode
  disabled?: boolean
  autoComplete?: string
  className?: string
  onSelectFile?: (file?: File) => void | Promise<void>
  imageOnly?: boolean
  mimeTypes?: string[]
  readOnly?: boolean
  enableRemove?: boolean
  stateful?: boolean
  loading?: boolean
}

const imageMimeTypes = ['image/bmp', 'image/jpg', 'image/jpeg', 'image/png']

const maxFileSize = 10 * 1e6 // 10 MB

export const Dropzone = forwardRef<HTMLInputElement, DropzoneProps>(
  (
    {
      content,
      preview,
      disabled,
      autoComplete,
      className,
      onSelectFile,
      imageOnly,
      mimeTypes,
      readOnly,
      enableRemove,
      stateful,
      loading,
      ...props
    },
    ref
  ) => {
    const [file, setFile] = useState<File | undefined>()
    const [previewUrl, setPreviewUrl] = useState<string | undefined>()
    const [errors, setErrors] = useState<FieldErrors | undefined>()

    const onDrop = useCallback(
      async ([file]: File[]) => {
        if (!file) {
          return
        }

        setErrors(undefined)

        if (file.size > maxFileSize) {
          setErrors({ message: t`form.error.fileTooLarge` })
          setPreviewUrl(undefined)
          setFile(undefined)
          return
        }

        if (imageOnly) {
          const image = new Image()
          image.src = URL.createObjectURL(file)

          image.onload = async () => {
            if (image.width < 1024 || image.height < 1024) {
              setErrors({
                message: t`form.error.fileUploadError.IMAGE_TOO_SMALL`,
              })
              setPreviewUrl(undefined)
              setFile(undefined)
              return
            }

            stateful && setPreviewUrl(URL.createObjectURL(file))
            await onSelectFile?.(file)
            stateful && setFile(file)
          }

          image.onerror = () => {
            setErrors({ message: t`form.error.fileUploadError.GENERIC` })
            setPreviewUrl(undefined)
            setFile(undefined)
          }
        } else {
          await onSelectFile?.(file)
          stateful && setFile(file)
        }
      },
      [setFile, onSelectFile, imageOnly, stateful]
    )

    useEffect(
      () => () => {
        if (previewUrl) {
          URL.revokeObjectURL(previewUrl)
        }
      },
      [previewUrl]
    )

    const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      maxFiles: 1,
      accept: mimeTypes ?? (imageOnly ? imageMimeTypes : undefined),
    })

    return (
      <ValidationContainer {...props} errors={{ ...errors, ...props.errors }}>
        <div className="relative w-full">
          <div
            className={classNames(
              'relative',
              'border rounded w-full focus:outline-none',
              'flex flex-col justify-center items-center',
              'text-16 text-grey-600 py-8',
              props.errors && 'border-red-500',
              !readOnly && 'cursor-pointer border-dashed',
              disabled && 'bg-black-50 cursor-not-allowed',
              !disabled &&
                !props.errors &&
                'bg-grey-200 border-grey-400 focus:border-primary',
              className
            )}
            {...getRootProps()}
          >
            {!readOnly && (
              <input
                ref={ref}
                name={props.name}
                id={props.name}
                disabled={disabled}
                autoComplete={autoComplete}
                data-cy-id={props['data-cy-id']}
                className={classNames(
                  'appearance-none',
                  props.labelPosition === 'left' ? 'pt-4 pb-2 px-8' : 'p-8'
                )}
                {...getInputProps()}
              />
            )}
            <div
              className={classNames(
                'flex flex-col items-center',
                loading && 'opacity-0'
              )}
            >
              {previewUrl && preview?.(previewUrl)}
              {content?.(file?.name)}
            </div>
            {loading && (
              <SpinnerIcon height={64} width={64} className="absolute" />
            )}
          </div>
          {enableRemove && file && (
            <div className="absolute top-0 right-0 z-10 m-4">
              <Link
                onClick={() => {
                  setFile(undefined)
                  setPreviewUrl(undefined)
                  onSelectFile?.(undefined)
                }}
                data-cy-id={`dropzone.${props.name}.removeFile`}
              >
                <CrossIcon className="fill-grey-400 h-16 w-16 m-8" />
              </Link>
            </div>
          )}
        </div>
      </ValidationContainer>
    )
  }
)

Dropzone.defaultProps = {
  content: (filename) => (
    <div className="p-10 text-center break-all">
      {filename ?? <Trans id="dropzone.dropFile">Drop or select a file</Trans>}
    </div>
  ),
  preview: (url) => (
    <img
      src={url}
      alt="uploaded file preview"
      className="max-h-full max-w-full p-20"
    />
  ),
  enableRemove: true,
  stateful: true,
}
