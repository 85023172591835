import classNames from 'classnames'
import React, { ChangeEvent, KeyboardEvent, useMemo, useState } from 'react'
import type { TestIdProp } from 'types'
import {
  ValidationContainer,
  ValidationContainerProps,
} from './ValidationContainer'

interface TextAreaProps extends ValidationContainerProps, Partial<TestIdProp> {
  disabled?: boolean
  readOnly?: boolean
  type?: string
  placeholder?: string
  textAreaClassName?: string
  lineNumbers?: boolean
  initialRows?: number
  value?: string
  maxLineLength?: number
  required?: boolean
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      disabled,
      type,
      placeholder,
      textAreaClassName,
      'data-cy-id': testId,
      lineNumbers,
      initialRows,
      readOnly,
      value,
      maxLineLength,
      required,
      ...props
    },
    ref
  ) => {
    const [lines, setLines] = useState(initialRows ?? 10)
    const lineLabels = useMemo(
      () =>
        new Array(lines + 1)
          .fill(true)
          .map((_, i) => i + 1)
          .join('\n'),
      [lines]
    )

    return (
      <ValidationContainer {...props} required={required}>
        <div
          className={classNames(
            'border border-grey-300 rounded w-full text-16 focus:outline-none',
            'flex',
            props.errors && 'border-red-500'
          )}
        >
          {lineNumbers && (
            <textarea
              className={classNames(
                'appearance-none resize-none rounded w-45 text-right overflow-hidden',
                'font-mono text-grey-600 bg-grey-200',
                props.labelPosition === 'left' ? 'pt-4 pb-2 px-8' : 'p-8',
                disabled && 'bg-black-50 cursor-not-allowed',
                textAreaClassName
              )}
              disabled
              readOnly
              value={lineLabels}
              rows={lines}
            />
          )}
          <textarea
            ref={ref}
            className={classNames(
              'appearance-none rounded w-full',
              props.labelPosition === 'left' ? 'pt-4 pb-2 px-8' : 'p-8',
              disabled && 'bg-black-50 cursor-not-allowed',
              !disabled && !props.errors && 'bg-grey-200 border-grey-300',
              readOnly && 'opacity-75',
              lineNumbers && 'resize-none overflow-hidden',
              textAreaClassName
            )}
            id={props.name}
            disabled={disabled}
            placeholder={placeholder}
            name={props.name}
            data-cy-id={testId ?? props.name}
            onKeyPress={
              maxLineLength !== undefined
                ? (e: KeyboardEvent<HTMLTextAreaElement>) => {
                    const lines = e.currentTarget.value.split('\n')
                    if (!lines.every((line) => line.length < maxLineLength)) {
                      e.preventDefault()
                    }
                  }
                : undefined
            }
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
              setLines(e.target.value.split('\n').length)
            }}
            rows={lineNumbers ? lines : undefined}
            readOnly={readOnly}
            value={value}
          />
        </div>
      </ValidationContainer>
    )
  }
)

TextArea.defaultProps = {
  labelPosition: 'top',
}

export default TextArea
