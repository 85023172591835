const routes = require('next-routes')

module.exports = routes()
  .add('index', '/', 'landing')
  .add('index contact-form', '/contact-us', 'landing')
  .add('login')
  .add('register')
  .add('profile')
  .add('profile change-picture', '/profile/change-picture', 'profile')
  .add('join token', '/join/:token', 'join')
  .add('news', '/news', 'news/news')
  .add('news article', '/news/:slug', 'news/article')
  .add('faq')
  .add('developers')

  // documents
  // .add('documents', '/documents', 'documents/documents')
  .add(
    'privacy_policy',
    '/documents/privacy-policy',
    'documents/privacy-policy'
  )
  .add('terms_of_use', '/documents/terms-of-use', 'documents/terms-of-use')

  // docs
  .add('api_docs', '/docs/api/:slug', 'api_docs')
  .add('docs')
  .add('docs slug', '/docs/:slug', 'docs')

  // dashboard
  .add('dashboard')
  .add('dashboard create-app', '/dashboard/create-app', 'dashboard')
  .add('dashboard create-org', '/dashboard/create-organization', 'dashboard')

  // support
  .add('support', '/support', 'support/support')
  .add('support create-ticket', '/support/create-ticket', 'support/support')
  .add('support ticket detail', '/support/:key', 'support/ticket')

  // apps
  .add('app overview', '/apps/:slug', 'apps/overview')
  .add('app sla doc', '/apps/:slug/sla-doc', 'apps/overview')
  .add('app config', '/apps/:slug/config', 'apps/config')
  .add(
    'app config create-org',
    '/apps/:slug/config/create-organization',
    'apps/config'
  )
  .add('app sandbox', '/apps/:slug/sandbox', 'apps/sandbox')
  .add(
    'app sandbox credentials',
    '/apps/:slug/sandbox/credentials',
    'apps/sandbox'
  )
  .add('app sandbox config', '/apps/:slug/sandbox/config', 'apps/sandbox')
  .add('app sandbox help', '/apps/:slug/sandbox/help', 'apps/sandbox')
  .add('app production', '/apps/:slug/production', 'apps/production')
  .add(
    'app production credentials',
    '/apps/:slug/production/credentials',
    'apps/production'
  )
  .add(
    'app production config',
    '/apps/:slug/production/config',
    'apps/production'
  )
  .add('app production help', '/apps/:slug/production/help', 'apps/production')

  .add('app legal', '/apps/:slug/legal', 'apps/legal')
  .add('app financials', '/apps/:slug/financials', 'apps/financials')
  .add('app users', '/apps/:slug/users', 'apps/users')
  .add('app users invite', '/apps/:slug/users/invite', 'apps/users')
  .add('app users detail', '/apps/:slug/users/detail/:userId', 'apps/users')
  .add(
    'app users transfer owner',
    '/apps/:slug/users/transfer-owner/:userId',
    'apps/users'
  )
  .add('app events', '/apps/:slug/events', 'apps/events')
  .add('app product', '/apps/:slug/products', 'apps/products/active')
  .add(
    'app product update',
    '/apps/:slug/products/update',
    'apps/products/update'
  )

  // banks
  .add('bank overview', '/banks/:slug', 'banks/overview')
  .add('bank config', '/banks/:slug/config', 'banks/config')
  .add(
    'bank environment create',
    '/banks/:slug/environments/create',
    'banks/environment'
  )
  .add(
    'bank environment',
    '/banks/:slug/environments/:envSlug',
    'banks/environment'
  )
  .add(
    'bank environment credentials',
    '/banks/:slug/environments/:envSlug/credentials',
    'banks/environment'
  )
  .add(
    'bank environment config',
    '/banks/:slug/environments/:envSlug/config',
    'banks/environment'
  )
  .add(
    'bank environment apps',
    '/banks/:slug/environments/:envSlug/apps',
    'banks/environment'
  )
  .add(
    'bank environment services',
    '/banks/:slug/environments/:envSlug/services',
    'banks/environment'
  )
  .add(
    'bank environment app credentials',
    '/banks/:slug/environments/:envSlug/apps/:appSlug',
    'banks/connectedAppDetail'
  )
  .add('bank legal', '/banks/:slug/legal', 'banks/legal')
  .add('bank financials', '/banks/:slug/financials', 'banks/financials')
  .add('bank users', '/banks/:slug/users', 'banks/users')
  .add('bank users invite', '/banks/:slug/users/invite', 'banks/users')
  .add('bank users detail', '/banks/:slug/users/detail/:userId', 'banks/users')
  .add('bank events', '/banks/:slug/events', 'banks/events')
  .add('bank reports', '/banks/:slug/reports', 'banks/reports')

  // organizations
  .add(
    'organization overview',
    '/organizations/:slug',
    'organizations/overview'
  )
  .add(
    'organization apps',
    '/organizations/:slug/apps',
    'organizations/connectedApps'
  )
  .add(
    'organization legal',
    '/organizations/:slug/legal',
    'organizations/legal'
  )
  .add(
    'organization users',
    '/organizations/:slug/users',
    'organizations/users'
  )
  .add(
    'organization users invite',
    '/organizations/:slug/users/invite',
    'organizations/users'
  )
  .add(
    'organization users detail',
    '/organizations/:slug/users/detail/:userId',
    'organizations/users'
  )
  .add(
    'organization reports',
    '/organizations/:slug/reports',
    'organizations/reports'
  )
  .add(
    'organization sla doc',
    '/organizations/:slug/sla-doc',
    'organizations/overview'
  )

  // notification event detail
  .add('event', '/event/:eventId', 'event')
