import { t, Trans } from '@lingui/macro'
import Axios, { AxiosError } from 'axios'
import { Button, Dropzone, FilePreview, TextArea } from 'components'
import { useToasts } from 'containers/Toast'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { CreateCommentError, CreateCommentFormData, ToastKind } from 'types'
import { useAsyncCallback } from 'utils/handleError'
import { to_jira } from 'utils/jira2md'
import { Router } from 'utils/router'

type CreateCommentFormProps = {
  issueKey: string
}

type CreateCommentFormInputs = Omit<
  CreateCommentFormData,
  'attachments' | 'issueKey'
>

export const CreateCommentForm: React.FC<CreateCommentFormProps> = ({
  issueKey,
}) => {
  const { register, formState, handleSubmit, reset, errors } =
    useForm<CreateCommentFormInputs>()
  const [files, setFiles] = useState<File[]>([])

  const { addToast } = useToasts()
  const handleCreateComment = useAsyncCallback(
    handleSubmit(async (data) => {
      try {
        const formData = new FormData()
        formData.append('issueKey', issueKey)
        formData.append('body', data.body)
        files.forEach((file) => formData.append('attachments', file))

        try {
          formData.set('body', to_jira(formData.get('body')))
          await Axios.post('/api/tickets/comment', formData)
        } catch (ex: any) {
          if (ex.isAxiosError) {
            const err: AxiosError<CreateCommentError> = ex

            if (err.response?.data.code === 'ATTACHMENT_FILENAME_TAKEN') {
              addToast({
                kind: ToastKind.Error,
                content: t`support.createComment.attachmentFilenameTaken`,
              })
              return
            }
          }

          throw ex
        }

        addToast({
          kind: ToastKind.Success,
          content: t`support.createComment.commentCreated`,
        })
        reset()
        setFiles([])
        await Router.pushRoute(`/support/${issueKey}`, { scroll: false })
      } catch (ex) {
        addToast({
          kind: ToastKind.Error,
          content: t`error.somethingWentWrong.text`,
        })
      }
    }),
    [addToast, issueKey]
  )

  return (
    <form onSubmit={handleCreateComment}>
      <TextArea
        name="body"
        ref={register({
          required: t`form.error.required`,
        })}
        required
        errors={errors.body}
        placeholder={t`support.createComment.placeholder`}
        data-cy-id="support.createComment"
      />

      <div className="flex flex-col sm:flex-row items-end mt-10 mb-16">
        <Dropzone
          data-cy-id="support.createComment.attachments"
          label={
            <Trans id="support.createComment.attachments">Attachments</Trans>
          }
          onSelectFile={(file) => file && setFiles((files) => [...files, file])}
          stateful={false}
          className="h-40"
        />
        <div className="w-40 h-12" />
        <div className="flex-shrink-0">
          <Button
            data-cy-id="support.createComment.submit"
            loading={formState.isSubmitting}
            isFormSubmit
          >
            <Trans id="support.createComment.submit">Post comment</Trans>
          </Button>
        </div>
      </div>

      <div className="flex flex-wrap space-x-10">
        {files.map((file) => (
          <FilePreview
            key={file.name}
            file={file}
            onRemove={() =>
              setFiles((files) => files.filter((f) => f !== file))
            }
          />
        ))}
      </div>
    </form>
  )
}
