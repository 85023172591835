import classNames from 'classnames'
import React from 'react'
import { CreateTicketFormData } from 'types'

const priorityColors: Record<CreateTicketFormData['priority'], string> = {
  critical: 'bg-utility-red text-white-100 border-utility-red',
  high: 'bg-grey-200 text-utility-red border-grey-400',
  medium: 'bg-grey-200 text-utility-yellow border-grey-400',
  low: 'bg-grey-200 text-utility-green border-grey-400',
  lowest: 'bg-grey-200 text-grey-600 border-grey-400',
}

type TicketBadgeProps = {
  priority?: CreateTicketFormData['priority']
}

export const TicketBadge: React.FC<TicketBadgeProps> = ({
  priority,
  children,
}) => (
  <div
    className={classNames(
      'inline rounded-16 px-10 border break-normal',
      priority
        ? priorityColors[priority]
        : 'bg-grey-200 text-grey-800 border-grey-400'
    )}
  >
    {children}
  </div>
)
