import { Trans } from '@lingui/macro'
import { Button } from 'components'
import { useAppPermissions } from 'containers'
import React from 'react'
import { AppEnvironmentState, AppEnvironmentType } from 'types'
import { AppDangerZone } from './AppDangerZone'
import { AppProductionChecklist } from './AppProductionChecklist'
import { SandboxNotificationZone } from './SandboxNotificationZone'

type AppCredentialsStateProps = {
  appSlug: string
  envId: string
  envSlug: string
  envState: AppEnvironmentState
  envType: AppEnvironmentType
}

export const AppCredentialsState: React.FC<AppCredentialsStateProps> = ({
  appSlug,
  envId,
  envSlug,
  envState,
  envType,
}) => {
  const hasPermission = useAppPermissions()
  const canConfigure =
    envType === AppEnvironmentType.Production
      ? hasPermission('PROD:W')
      : hasPermission('SBX:W')
  const canCreateSandboxNotification =
    envType === AppEnvironmentType.Sandbox &&
    hasPermission('SBX:W') &&
    (envState === AppEnvironmentState.Activated ||
      envState === AppEnvironmentState.Unsynchronized)

  return (
    <>
      {envState === AppEnvironmentState.DisabledRegistration && (
        <AppProductionChecklist appSlug={appSlug} />
      )}

      {envState === AppEnvironmentState.ReadyToRegister && (
        <>
          <p className="text-grey-900">
            <Trans id="app.environment.credentials.readyToRegister">
              Please configure your app to generate credentials.
            </Trans>
          </p>
          <div className="flex mt-20">
            <Button
              to={`/apps/${appSlug}/${envSlug}/config`}
              data-cy-id="app.environment.credentials.configureButton"
              disabled={!canConfigure}
              tooltip={
                !canConfigure && (
                  <Trans id="tooltip.missingWritePermission">
                    You don&apos;t have permission to do this.
                  </Trans>
                )
              }
            >
              <Trans id="app.environment.credentials.configureButton">
                Go to settings
              </Trans>
            </Button>
          </div>
        </>
      )}

      {canCreateSandboxNotification && (
        <SandboxNotificationZone slug={appSlug} />
      )}

      {canConfigure && (
        <AppDangerZone
          appSlug={appSlug}
          envId={envId}
          envState={envState}
          envType={envType}
        />
      )}
    </>
  )
}
