import { format } from 'date-fns-tz'
import { cs, enUS as en } from 'date-fns/locale'
import getConfig from 'next/config'
import { LocaleType } from './withLang'

export const patterns = {
  url: /^https?:\/\/([a-zA-Z0-9@:%_\+~\-#=]{1,256}\.)*([a-zA-Z0-9@:%_\-\+~#=]{2,256})([-a-zA-Z0-9@:%_\+.~#?&/=]*)/,
  secureUrl:
    /^https:\/\/([a-zA-Z0-9@:%_\+~\-#=]{1,256}\.)*([a-zA-Z0-9@:%_\-\+~#=]{2,256})([-a-zA-Z0-9@:%_\+.~#?&/=]*)/,
  secureUrlOrLocalhost:
    /(^https:\/\/([a-zA-Z0-9@:%_\+~\-#=]{1,256}\.)*([a-zA-Z0-9@:%_\-\+~#=]{2,256})([-a-zA-Z0-9@:%_\+.~#?&\/=]*))|((^http:\/\/localhost|^http:\/\/127.0.0.1)([-a-zA-Z0-9@:%_\+.~#?&\/=]*))/,
  anySchemeUri: /[a-z0-9+.-]+:[-a-zA-Z0-9@:%_\+.~#?&\/=]+/i,
  notHttpUrl: /^(?!http:).*/,
  httpsUrlOrLocalhost:
    /^(?!http:).*|^http:\/\/localhost.*|^http:\/\/127.0.0.1.*/,

  // see http://emailregex.com/
  email:
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,

  emails: /([a-zA-Z0-9+._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/g,
  uuid: /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
  phone: /^\+?\d+/,

  // entity name
  name: /^([ \u00c0-\u01ffa-zA-Z0-9'\-._/()*&+])+$/,
  orgName: /^([ \u00c0-\u01ffa-zA-Z0-9'\-._/,;()*&+])+$/,
  slug: /^([0-9a-zA-Z\-_])+$/,
}

export const uriMaxLength = 255

export const insertStylesBasedOnStatusCode = (
  statusCode: number,
  changeBackground?: boolean
) => {
  if (statusCode >= 400) {
    return changeBackground
      ? 'bg-utility-error'
      : 'border-utility-error text-utility-error'
  } else if (statusCode >= 200 && statusCode < 300) {
    return changeBackground
      ? 'bg-utility-success'
      : 'border-utility-success text-utility-success'
  } else if (statusCode >= 300 || (statusCode < 200 && statusCode >= 100)) {
    return changeBackground ? 'bg-primary' : 'border-primary text-primary'
  }
}

export function makeFullName(
  firstName?: string | null,
  lastName?: string | null
) {
  return [firstName, lastName].filter(Boolean).join(' ')
}

const locales = { cs, en }

export function formatDateWithLocale(
  date: string | number | Date,
  formatString: string,
  locale: LocaleType
) {
  // treat input date as UTC
  const d = new Date(date)
  const utcDate = Date.UTC(
    d.getFullYear(),
    d.getMonth(),
    d.getDate(),
    d.getHours(),
    d.getMinutes(),
    d.getSeconds()
  )

  // use Prague timezone on the server and browser timezone on the client
  const timeZone = typeof window === 'undefined' ? 'Europe/Prague' : undefined
  return format(utcDate, formatString, {
    locale: locales[locale],
    timeZone,
  })
}

export function extractEmails(str: string) {
  return str.match(patterns.emails) ?? []
}

const from =
  'ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆĞÍÌÎÏİŇÑÓÖÒÔÕØŘŔŠŞŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇğíìîïıňñóöòôõøðřŕšşťúůüùûýÿžþÞĐđßÆa·/_,:;'
const to =
  'AAAAAACCCDEEEEEEEEGIIIIINNOOOOOORRSSTUUUUUYYZaaaaaacccdeeeeeeeegiiiiinnooooooorrsstuuuuuyyzbBDdBAa------'

export function slugify(str: string) {
  let slug = str.replace(/^\s+|\s+$/g, '')
  slug = slug.toLowerCase()

  for (let i = 0, l = from.length; i < l; i++) {
    slug = slug.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
  }

  return slug
    .replace(/[^a-z0-9 -]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-')
}

/**
 * Returns object with properties of changed object that are different from original object
 *
 * @param changed
 * @param original
 */
export function getObjectDiff(changed?: any, original?: any) {
  const obj: Record<string, unknown> = {}
  Object.entries(changed ?? {}).forEach(([key, value]) => {
    if (original?.[key] !== undefined && value !== original[key]) {
      obj[key] = value
    }
  })
  return obj
}

/**
 * Returns base 16 hash of provided string. Not for use in security-related applications
 *
 * @param string string to be hashed
 */
export function hashCode(str: string | null | undefined) {
  if (!str) {
    return ''
  }

  return str
    .split('')
    .reduce((a, b) => ((a << 5) - a + b.charCodeAt(0)) | 0, 0)
    .toString(16)
}

const { FILESERVICE_URL } = getConfig().publicRuntimeConfig

export const composeDownloadUrl = (fileId?: string | null) =>
  fileId ? `${FILESERVICE_URL}/api/v1/files/${fileId}/download` : '#'

export function formatCurrency(amount: number) {
  return new Intl.NumberFormat('cs-CZ', {
    style: 'currency',
    currency: 'CZK',
  }).format(amount)
}

export function formatPercentage(amount: number) {
  return new Intl.NumberFormat('cs-CZ', {
    style: 'percent',
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  }).format(amount)
}

export function formatNumber(amount: number) {
  return new Intl.NumberFormat('cs-CZ', {
    style: 'decimal',
  }).format(amount)
}
