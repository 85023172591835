import { t, Trans } from '@lingui/macro'
import classNames from 'classnames'
import { Clickable, Link, LinkProps } from 'components'
import { Notifications, UserBox } from 'containers'
import CloseIcon from 'icons/close.svg'
import MenuIcon from 'icons/hamburger.svg'
import InfoIcon from 'icons/info.svg'
import Logo from 'icons/Logo.svg'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useFeature } from 'utils/features'
import { useDimensions } from 'utils/hooks'
import { screens } from 'utils/theme'
import { useIsBank, useIsLoggedIn } from 'utils/withAuth'
import { useInfoBanner } from 'utils/withInfoBanner'
import { LOCALES, setLocale, useLocale } from 'utils/withLang'

export const HEADER_HEIGHT = 72

const mobileMenuBreakpoint = +screens.lg.replace('px', '')

interface PublicLinksProps {
  kind: LinkProps['kind']
}

export const PublicLinks: React.FC<PublicLinksProps> = ({ kind }) => {
  const news = useFeature('news')

  return (
    <>
      <Link
        to="/developers"
        kind={kind}
        data-cy-id="header.developersDropdown.startDeveloping"
      >
        <Trans id="header.developersDropdown.startDeveloping">
          Start developing now
        </Trans>
      </Link>

      <Link
        to="/docs"
        kind={kind}
        data-cy-id="header.developersDropdown.documentation"
      >
        <Trans id="header.developersDropdown.documentation">
          Documentation
        </Trans>
      </Link>

      {news && (
        <Link to="/news" kind={kind} data-cy-id="header.links.news">
          <Trans id="header.links.news">News</Trans>
        </Link>
      )}

      <Link to="/faq" kind={kind} data-cy-id="header.links.faq">
        <Trans id="header.links.faq">FAQ</Trans>
      </Link>
    </>
  )
}

const LoggedInLinks: React.FC = () => {
  const isBank = useIsBank()
  const showNews = useFeature('news')
  const showSupport = useFeature('support')

  return (
    <>
      <Link to="/dashboard" kind="navbar" data-cy-id="header.links.dashboard">
        <Trans id="header.links.dashboard">Dashboard</Trans>
      </Link>

      <Link to="/docs" kind="navbar" data-cy-id="header.links.docs">
        <Trans id="header.links.docs">Documentation</Trans>
      </Link>

      {showNews && (
        <Link to="/news" kind="navbar" data-cy-id="header.links.news">
          <Trans id="header.links.news">News</Trans>
        </Link>
      )}

      <Link to="/faq" kind="navbar" data-cy-id="header.links.faq">
        <Trans id="header.links.faq">FAQ</Trans>
      </Link>

      {showSupport && (
        <Link to="/support" kind="navbar" data-cy-id="header.links.support">
          <Trans id="header.links.support">Helpdesk</Trans>
        </Link>
      )}
    </>
  )
}

t`header.languageSwitcher.cs`
t`header.languageSwitcher.en`

const LanguageSwitcher: React.FC = () => {
  const currentLocale = useLocale()

  return (
    <p className="text-primary-darker">
      {LOCALES.filter((locale) => locale !== currentLocale).map((locale) => (
        <Fragment key={locale}>
          <Link
            data-cy-id={`header.languageSwitcher.${locale}`}
            onClick={
              locale !== currentLocale
                ? () => {
                    setLocale(locale)
                    window.location.reload()
                  }
                : undefined
            }
            kind="plain"
            className="uppercase"
            title={t({ id: `header.languageSwitcher.${locale}` })}
          >
            {locale}
          </Link>
        </Fragment>
      ))}
    </p>
  )
}

export const Header: React.FC = () => {
  const isLoggedIn = useIsLoggedIn()
  const [open, setOpen] = useState(false)
  const toggleMenu = useCallback(() => setOpen((state) => !state), [])

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0]
    if (open) {
      body?.classList.add('overflow-hidden')
    } else {
      body?.classList.remove('overflow-hidden')
    }
  }, [open])

  const { width, ref } = useDimensions()

  useEffect(() => {
    if ((width ?? 0) > mobileMenuBreakpoint) {
      setOpen(false)
    }
  }, [width])

  const { bannerHidden, toggleBanner } = useInfoBanner()

  return (
    <>
      <header
        className={classNames(
          'border-b bg-white-100 border-grey-300 font-medium text-14',
          open && 'fixed top-0 w-full z-20'
        )}
        ref={ref}
      >
        <nav
          className="flex items-center container mx-auto px-20"
          style={{ height: HEADER_HEIGHT + 'px' }}
        >
          <Link to="/" className="lg:mr-40" data-cy-id="header.links.home">
            <Logo
              title={<Trans id="common.bankId">Bank iD</Trans>}
              className="fill-black-900"
            />
          </Link>

          <div className="h-full hidden lg:flex text-grey-800">
            {isLoggedIn ? <LoggedInLinks /> : <PublicLinks kind="navbar" />}
          </div>
          <div className="flex-grow" />

          {bannerHidden && (
            <div className="hidden lg:block mr-20 mt-2">
              <Clickable onClick={toggleBanner}>
                <InfoIcon
                  className="w-18 h-18 fill-grey-600"
                  title={t`header.showInfoBanner`}
                />
              </Clickable>
            </div>
          )}

          <div className="flex items-center">
            <div className="hidden lg:block">
              <LanguageSwitcher />
            </div>
            {isLoggedIn && (
              <div className="ml-20">
                <Notifications />
              </div>
            )}
            <div className="hidden lg:flex items-center ml-20">
              <UserBox />
            </div>
          </div>

          <Clickable className="lg:hidden" onClick={toggleMenu}>
            <div className="w-45 h-30 flex justify-end items-center">
              {open ? (
                <CloseIcon
                  className="w-16 h-16 fill-grey-800"
                  title={<Trans id="header.closeMenu">Close menu</Trans>}
                />
              ) : (
                <MenuIcon
                  className="w-22 h-22 fill-grey-800"
                  title={<Trans id="header.openMenu">Open menu</Trans>}
                />
              )}
            </div>
          </Clickable>
        </nav>
      </header>

      {open && (
        <nav
          className="fixed z-30 w-full h-full bg-white-100 flex flex-col justify-start"
          style={{ top: HEADER_HEIGHT + 1 + 'px' }}
        >
          <div className="container mx-auto">
            <div>
              {isLoggedIn ? <LoggedInLinks /> : <PublicLinks kind="navbar" />}
            </div>
            <div
              className="absolute ml-20 mb-30"
              style={{ bottom: HEADER_HEIGHT + 1 + 'px' }}
            >
              <div className="flex flex-col">
                <UserBox />
              </div>
              <div className="pt-24">
                <LanguageSwitcher />
              </div>
            </div>
            <Clickable
              onClick={toggleMenu}
              className="mb-30"
              style={{
                position: 'absolute',
                right: '20px',
                bottom: HEADER_HEIGHT + 1 + 'px',
              }}
            >
              <Trans id="header.menu.close">Close</Trans>
            </Clickable>
          </div>
        </nav>
      )}
    </>
  )
}
