import { t, Trans } from '@lingui/macro'
import cn from 'classnames'
import {
  Breadcrumbs,
  Dropdown,
  DropdownLink,
  Error404,
  Link,
  Logo,
} from 'components'
import { Layout, QueryLoader } from 'containers'
import { AppEnvironmentStatusLight } from 'containers/AppEnvironmentStatus'
import OverviewIcon from 'icons/bar-chart.svg'
import BoxIcon from 'icons/box.svg'
import Chevron from 'icons/chevron.svg'
import SettingsIcon from 'icons/settings.svg'
import SupportIcon from 'icons/support.svg'
import UsersIcon from 'icons/users.svg'
import { useRouter } from 'next/router'
import React from 'react'
import {
  AppMetaQuery,
  useAppMetaQuery,
  useAppsQuery,
  useCurrentUserQuery,
  useUserInAppMetaQuery,
} from 'types'
import { useFeature } from 'utils/features'
import { useIsLoggedIn } from 'utils/withAuth'
import { useAppPermissions, UserInAppContext } from './Can'
import { Sidebar, SidebarSection } from './Sidebar'

interface AppDetailSidebarProps {
  slug: string
}

const AppDetailSidebar: React.FC<AppDetailSidebarProps> = ({ slug }) => {
  const location = useRouter()?.asPath
  const hasPermissions = useAppPermissions()
  const appQuery = useAppMetaQuery({
    variables: { slug },
  })
  const app = appQuery.data?.appBySlug

  const enableProduction = useFeature('app_production')
  const enableProducts = useFeature('app_products')
  const enableSupport = useFeature('support')

  return (
    <Sidebar>
      <SidebarSection
        heading={<Trans id="app.sidebar.application">Application</Trans>}
      >
        <Link
          to={`/apps/${slug}`}
          kind="sidebar"
          data-cy-id="app.sidebar.overview"
        >
          <OverviewIcon className="inline mr-16" />
          <Trans id="app.sidebar.overview">Overview</Trans>
        </Link>
      </SidebarSection>

      <SidebarSection
        heading={<Trans id="app.sidebar.environments">Environments</Trans>}
      >
        <Link
          to={`/apps/${slug}/sandbox/credentials`}
          kind="sidebar"
          active={/\/sandbox\//.test(location)}
          disabled={!hasPermissions('SBX:R')}
          tooltip={
            !hasPermissions('SBX:R') && (
              <Trans id="tooltip.missingReadPermission">
                You don&apos;t have permission to see this.
              </Trans>
            )
          }
          tooltipId="app.missingPermission.SBX:R"
          data-cy-id="app.sidebar.sandbox"
        >
          <AppEnvironmentStatusLight
            state={app?.sandboxState}
            className="inline-block mr-16"
          />
          <Trans id="app.sidebar.sandbox">Sandbox</Trans>
        </Link>
        {enableProduction && (
          <Link
            to={`/apps/${slug}/production/credentials`}
            kind="sidebar"
            active={/\/production\//.test(location)}
            disabled={!hasPermissions('PROD:R')}
            tooltip={
              !hasPermissions('PROD:R') && (
                <Trans id="tooltip.missingReadPermission">
                  You don&apos;t have permission to see this.
                </Trans>
              )
            }
            tooltipId="app.missingPermission.PROD:R"
            data-cy-id="app.sidebar.production"
          >
            <AppEnvironmentStatusLight
              state={app?.productionState}
              className="inline-block mr-16"
            />
            <Trans id="app.sidebar.production">Production</Trans>
          </Link>
        )}
      </SidebarSection>

      <SidebarSection
        heading={<Trans id="app.sidebar.appManagement">App Management</Trans>}
      >
        <Link
          to={`/apps/${slug}/config`}
          kind="sidebar"
          disabled={!hasPermissions('APL:R')}
          tooltip={
            !hasPermissions('APL:R') && (
              <Trans id="tooltip.missingWritePermission" />
            )
          }
          data-cy-id="app.sidebar.config"
        >
          <SettingsIcon className="inline mr-12 flex-shrink-0" />
          <Trans id="app.sidebar.config">App Settings</Trans>
        </Link>

        {/* {enableProducts && (
          <Link
            to={`/apps/${slug}/products`}
            kind="sidebar"
            disabled={!hasPermissions('PRD:R')}
            tooltip={
              !hasPermissions('PRD:R') && (
                <Trans id="tooltip.missingReadPermission">
                  You don&apos;t have permission to see this.
                </Trans>
              )
            }
            tooltipId="app.missingPermission.PRD:R"
            data-cy-id="app.sidebar.products"
          >
            <BoxIcon className="inline mr-12" />
            <Trans id="app.sidebar.products">Products</Trans>
          </Link>
        )} */}

        <Link
          to={`/apps/${slug}/users`}
          kind="sidebar"
          disabled={!hasPermissions('USR:R')}
          tooltip={
            !hasPermissions('USR:R') && (
              <Trans id="tooltip.missingReadPermission">
                You don&apos;t have permission to see this.
              </Trans>
            )
          }
          tooltipId="app.missingPermission.USR:R"
          data-cy-id="app.sidebar.users"
        >
          <UsersIcon className="inline mr-12" />
          <Trans id="app.sidebar.users">Users</Trans>
        </Link>

        {enableSupport && (
          <Link to="/support" kind="sidebar" data-cy-id="app.sidebar.support">
            <SupportIcon className="inline mr-12" />
            <Trans id="app.sidebar.support">Helpdesk</Trans>
          </Link>
        )}
      </SidebarSection>
    </Sidebar>
  )
}

const AppDropdownMenu: React.FC = () => {
  const location = useRouter()?.asPath

  const appsQuery = useAppsQuery()
  const apps = appsQuery.data?.currentUser?.apps?.edges

  return (
    <ul className="bg-white-100 shadow rounded-lg py-6 mt-10 relative z-10 max-h-half-screen overflow-y-auto">
      <QueryLoader queries={[appsQuery]}>
        {apps?.map(({ node: { app } }) => (
          <DropdownLink
            to={location.replace(/^\/apps\/[^/]+/, `/apps/${app.slug}`)}
            key={app.slug}
          >
            <Logo media={app.logo} size={24} className="mr-6 inline-block" />
            {app.name}
          </DropdownLink>
        ))}
      </QueryLoader>
    </ul>
  )
}

interface AppDropdownProps {
  selectedApp: NonNullable<AppMetaQuery['appBySlug']>
}

const AppDropdown: React.FC<AppDropdownProps> = ({ selectedApp }) => (
  <Dropdown
    display={
      <div className="border border-grey-400 rounded-8 flex items-center cursor-pointer p-6 max-w-230">
        <Logo
          media={selectedApp.logo}
          size={24}
          className="mr-6 inline-block"
        />
        <span className="flex-grow text-grey-900 truncate">
          {selectedApp.name}
        </span>
        <Chevron className="inline fill-grey-600" />
      </div>
    }
    displayClassName="inline-block w-230"
    data-cy-id="apps.selectAppDropdown"
  >
    <AppDropdownMenu />
  </Dropdown>
)

interface AppDetailProps {
  slug: string
  selectedApp: NonNullable<AppMetaQuery['appBySlug']>
}

export const AppDetail: React.FC<AppDetailProps> = ({
  slug,
  selectedApp,
  children,
}) => {
  const userInAppQuery = useUserInAppMetaQuery({
    variables: { slug },
  })
  const userInApp = userInAppQuery.data?.currentUserInApp
  const appsQuery = useAppsQuery()
  const apps = appsQuery.data?.currentUser?.apps
    ? appsQuery.data?.currentUser?.apps?.edges
    : []

  return (
    <QueryLoader queries={[userInAppQuery]}>
      <UserInAppContext.Provider value={userInApp}>
        <div className="mb-32 px-24 sm:px-0 flex flex-col sm:flex-row sm:items-end sm:space-x-20 lg:space-x-40">
          {apps.length > 1 ? (
            <div className="mb-24 sm:mb-0">
              <div className="text-10 uppercase tracking-widest text-grey-600 font-semibold mb-8">
                <Trans id="app.appSelector.currentApp">
                  Current application
                </Trans>
              </div>
              <AppDropdown selectedApp={selectedApp} />
            </div>
          ) : null}
          <Breadcrumbs
            path={[
              {
                text: <Trans id="dashboard.title">Dashboard</Trans>,
                path: '/dashboard',
              },
              {
                text: selectedApp.name,
                path: `/apps/${slug}`,
              },
            ]}
            className="mb-4"
          />
        </div>
        <div className="bg-white-100 sm:rounded-16 shadow-wider flex flex-col sm:flex-row min-h-1000">
          <AppDetailSidebar slug={slug} />
          <div
            className={cn('px-20 pb-20 lg:px-40 lg:pb-40 w-full overflow-auto')}
          >
            {children}
          </div>
        </div>
      </UserInAppContext.Provider>
    </QueryLoader>
  )
}

type AppContextType = NonNullable<AppMetaQuery['appBySlug']>
export const AppContext = React.createContext<AppContextType>(null!)

interface AppDetailLayoutProps {
  slug: string
}

export const AppDetailLayout: React.FC<AppDetailLayoutProps> = ({
  slug,
  children,
}) => {
  const isLoggedIn = useIsLoggedIn()
  const currentUserQuery = useCurrentUserQuery({ skip: !isLoggedIn })

  const appQuery = useAppMetaQuery({
    variables: { slug },
    skip: !isLoggedIn,
  })
  const app = appQuery.data?.appBySlug

  return (
    <Layout title={t`app.title`} requireLogin background="grey">
      <QueryLoader queries={[appQuery, currentUserQuery]}>
        {!app ? (
          <Error404 />
        ) : (
          <div className="pt-32 pb-50 mx-auto container sm:px-20">
            <AppContext.Provider value={app!}>
              <AppDetail slug={slug} selectedApp={app}>
                {children}
              </AppDetail>
            </AppContext.Provider>
          </div>
        )}
      </QueryLoader>
    </Layout>
  )
}
