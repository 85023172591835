import React from 'react'
import { Clickable } from 'components'
import css from './Table.module.css'
import { TableCell } from './TableCell'
import { TableColumn } from './common'
import classNames from 'classnames'

interface TableRowProps {
  columns: TableColumn<any>[]
  rowData: any
  to?: string
  alignItems: 'center' | 'start'
  className?: string
}

export const TableRow: React.FC<TableRowProps> = (props) => {
  const cells = props.columns.map((c) => (
    <TableCell key={c.key} rowData={props.rowData} column={c} />
  ))

  if (props.to) {
    return (
      <Clickable
        to={props.to}
        className={classNames(
          css.row,
          props.alignItems === 'center' && 'items-center',
          props.className
        )}
        role="row"
      >
        {cells}
      </Clickable>
    )
  } else {
    return (
      <div
        className={classNames(
          css.row,
          props.alignItems === 'center' && 'items-center',
          props.className
        )}
        role="row"
      >
        {cells}
      </div>
    )
  }
}
