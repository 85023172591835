import { t } from '@lingui/macro'
import { Clickable, Markdown } from 'components'
import Cross from 'icons/close.svg'
import React from 'react'

interface InfoBannerProps {
  onHide: () => void
  content: string | null | undefined
}

export const InfoBanner: React.FC<InfoBannerProps> = ({ onHide, content }) => (
  <div className="w-full py-8 bg-utility-yellow shadow" id="infoBanner">
    <div className="container px-20 mx-auto flex justify-between items-start">
      <Markdown className="text-14 leading-20">{content}</Markdown>
      <Clickable data-cy-id="infoBanner.hide" onClick={onHide}>
        <Cross
          className="fill-black-800 w-14 h-14 mt-4 ml-10"
          title={t`infoBanner.hide`}
        />
      </Clickable>
    </div>
  </div>
)
