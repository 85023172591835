import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { NextPage, NextPageContext } from 'next'
import App from 'next/app'
import getConfig from 'next/config'
import { parseCookies } from 'nookies'
import React from 'react'
import fragmentTypes from 'types/fragmentTypes.json'
import { CustomNextPageContext } from 'utils/withAuth'
import { refreshTokens } from './auth'
import { handleError } from './handleError'
import { Router } from './router'
import { captureException } from './sentry'

const initialApolloState = {
  toasts: [],
}

function createApolloClient(
  initialState: NormalizedCacheObject,
  ctx: CustomNextPageContext['ctx']
) {
  const state = {
    ...initialState,
    ROOT_QUERY: {
      ...initialApolloState,
      ...initialState.ROOT_QUERY,
    },
  }

  const cache = new InMemoryCache({
    possibleTypes: fragmentTypes.possibleTypes,
  }).restore(state)

  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, response }) => {
      if (graphQLErrors) {
        const { refresh_token_exp } = parseCookies(ctx)

        graphQLErrors.forEach((graphQLError) => {
          if (
            graphQLError?.extensions?.errorCode === 'USER_NOT_PRESENT_IN_JWT' &&
            !refresh_token_exp &&
            typeof window !== 'undefined'
          ) {
            Router.replaceRoute('/api/login')
            return
          }

          return handleError(graphQLError, {
            apolloClient: cache,
            operation,
          } as any)
        })
      }

      if (networkError) {
        handleError(networkError, { apolloClient: cache, operation } as any)
      }
    }
  )

  const backendUri = getConfig().publicRuntimeConfig.BACKEND_URL + '/graphql'
  const backendLink = createHttpLink({
    uri: backendUri, // Server URL (must be absolute)
    fetch,
  })

  const cmsLink = createHttpLink({
    uri: getConfig().publicRuntimeConfig.CMS_URL,
    fetch,
  })

  const authLink = setContext(async (request, { endpoint, headers }) => {
    const { access_token, refresh_token, refresh_token_exp } = parseCookies(ctx)

    let token = access_token || ctx?.res?.accessToken
    if (!token && refresh_token_exp) {
      // refresh AT if RT is available
      try {
        token = (await refreshTokens(ctx, refresh_token))?.accessToken?.token
      } catch (ex) {}
      // if RT is missing, error link will redirect user to login
    }

    const path =
      typeof window !== 'undefined'
        ? window.location.pathname
        : ctx.req?.url?.split('?')[0]
    const isCms = endpoint === 'cms'

    return {
      headers: {
        ...headers,
        ...(!isCms && token && { authorization: `Bearer ${token}` }),
        ...(!isCms && path && { 'X-PATH': path }),
      },
    }
  })

  // The `ctx` (NextPageContext) will only be present on the server.
  // use it to extract auth headers (ctx.req) or similar.
  return new ApolloClient({
    connectToDevTools:
      typeof window !== 'undefined' &&
      (process.env.NODE_ENV !== 'production' ||
        getConfig().publicRuntimeConfig.ENVIRONMENT === 'dev'),
    ssrMode: Boolean(ctx),
    link: ApolloLink.from([
      errorLink,
      authLink,
      ApolloLink.split(
        (op) => op.getContext().endpoint === 'cms',
        cmsLink,
        backendLink
      ),
    ]),
    cache: new InMemoryCache({
      possibleTypes: fragmentTypes.possibleTypes,
      typePolicies: {
        // merge pagination results of connected apps in bankEnvironment
        BankEnvironment: {
          fields: {
            appEnvironments: {
              keyArgs: false,
              merge(existing, incoming, { args }) {
                if (!incoming) return existing
                if (!existing) return incoming

                // we return new data (incoming) when asking for the 1st page
                // to prevent duplication when navigating away and back
                if (args?.pagination.pageNumber === 1) {
                  return incoming
                }

                const { nodes, ...pageInfo } = incoming
                const result = {
                  ...pageInfo,
                  nodes: [...existing.nodes, ...nodes],
                }
                return result
              },
            },
          },
        },
      },
    }).restore(state),
  })
}

// On the client, we store the Apollo Client in the following variable.
// This prevents the client from reinitializing between page transitions.
let globalApolloClient: ApolloClient<NormalizedCacheObject> | null = null

/**
 * Always creates a new apollo client on the server
 * Creates or reuses apollo client in the browser.
 * @param  {NormalizedCacheObject} initialState
 * @param  {NextPageContext} ctx
 */
const initApolloClient = (
  initialState: NormalizedCacheObject,
  ctx?: CustomNextPageContext['ctx']
) => {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (typeof window === 'undefined') {
    return createApolloClient(initialState, ctx!)
  }

  // Reuse client on the client-side
  if (!globalApolloClient) {
    globalApolloClient = createApolloClient(initialState, ctx!)
  }

  return globalApolloClient
}

/**
 * Installs the Apollo Client on NextPageContext
 * or NextAppContext. Useful if you want to use apolloClient
 * inside getStaticProps, getStaticPaths or getServerSideProps
 * @param {NextPageContext | NextAppContext} ctx
 */
export const initOnContext = (ctx: NextPageContext | any) => {
  const inAppContext = Boolean(ctx.ctx)

  // We consider installing `withApollo({ ssr: true })` on global App level
  // as antipattern since it disables project wide Automatic Static Optimization.
  // if (process.env.NODE_ENV === 'development') {
  //   if (inAppContext) {
  //     console.warn(
  //       'Warning: You have opted-out of Automatic Static Optimization due to `withApollo` in `pages/_app`.\n' +
  //         'Read more: https://err.sh/next.js/opt-out-auto-static-optimization\n'
  //     )
  //   }
  // }

  // Initialize ApolloClient if not already done
  const apolloClient =
    ctx.apolloClient ||
    initApolloClient(ctx.apolloState || {}, inAppContext ? ctx.ctx : ctx)

  // We send the Apollo Client as a prop to the component to avoid calling initApollo() twice in the server.
  // Otherwise, the component would have to call initApollo() again but this
  // time without the context. Once that happens, the following code will make sure we send
  // the prop as `null` to the browser.
  apolloClient.toJSON = () => null

  // Add apolloClient to NextPageContext & NextAppContext.
  // This allows us to consume the apolloClient inside our
  // custom `getInitialProps({ apolloClient })`.
  ctx.apolloClient = apolloClient
  if (inAppContext) {
    ctx.ctx.apolloClient = apolloClient
  }

  return ctx
}

type TApolloClient = ApolloClient<NormalizedCacheObject>

type InitialProps = {
  apolloClient: TApolloClient
  apolloState: any
} & Record<string, any>

/**
 * Creates a withApollo HOC
 * that provides the apolloContext
 * to a next.js Page or AppTree.
 * @param  {Object} withApolloOptions
 * @param  {Boolean} [withApolloOptions.ssr=false]
 * @returns {(PageComponent: ReactNode) => ReactNode}
 */
export const withApollo =
  ({ ssr = false } = {}) =>
  (PageComponent: NextPage) => {
    const WithApollo = ({
      apolloClient,
      apolloState,
      ...pageProps
    }: InitialProps) => {
      let client
      if (apolloClient) {
        // Happens on: getDataFromTree & next.js ssr
        client = apolloClient
      } else {
        // Happens on: next.js csr
        client = initApolloClient(apolloState, undefined)
      }

      return (
        <ApolloProvider client={client}>
          <PageComponent {...pageProps} />
        </ApolloProvider>
      )
    }

    // Set the correct displayName in development
    if (process.env.NODE_ENV !== 'production') {
      const displayName =
        PageComponent.displayName || PageComponent.name || 'Component'
      WithApollo.displayName = `withApollo(${displayName})`
    }

    if (ssr || PageComponent.getInitialProps) {
      WithApollo.getInitialProps = async (ctx: any) => {
        const inAppContext = Boolean(ctx)
        const { apolloClient } = initOnContext(ctx)

        // Run wrapped getInitialProps methods
        let pageProps = {}
        if (PageComponent.getInitialProps) {
          pageProps = await PageComponent.getInitialProps(ctx)
        } else if (inAppContext) {
          pageProps = await App.getInitialProps(ctx)
        }

        // Only on the server:
        if (typeof window === 'undefined') {
          const { AppTree } = ctx
          // When redirecting, the response is finished.
          // No point in continuing to render
          if (ctx.res?.finished) {
            return pageProps
          }

          // Only if dataFromTree is enabled
          if (ssr && AppTree) {
            try {
              // Import `@apollo/client/react/ssr` dynamically.
              // We don't want to have this in our client bundle.
              const { getDataFromTree } = await import(
                '@apollo/client/react/ssr'
              )

              // Since AppComponents and PageComponents have different context types
              // we need to modify their props a little.
              let props
              if (inAppContext) {
                props = { ...pageProps, apolloClient }
              } else {
                props = { pageProps: { ...pageProps, apolloClient } }
              }

              // Take the Next.js AppTree, determine which queries are needed to render,
              // and fetch them. This method can be pretty slow since it renders
              // your entire AppTree once for every query. Check out apollo fragments
              // if you want to reduce the number of rerenders.
              // https://www.apollographql.com/docs/react/data/fragments/
              await getDataFromTree(<AppTree {...props} />)
            } catch (error) {
              // Prevent Apollo Client GraphQL errors from crashing SSR.
              // Handle them in components via the data.error prop:
              // https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error
              captureException('Error while running `getDataFromTree`', {
                errorInfo: error,
              })
            }
          }
        }

        return {
          ...pageProps,
          // Extract query data from the Apollo store
          apolloState: apolloClient.cache.extract(),
          // Provide the client for ssr. As soon as this payload
          // gets JSON.stringified it will remove itself.
          apolloClient: ctx.apolloClient,
        }
      }
    }

    return WithApollo
  }
