import { useApolloClient } from '@apollo/client'
import { t, Trans } from '@lingui/macro'
import axios, { AxiosError, AxiosResponse } from 'axios'
import {
  Button,
  Checkbox,
  Input,
  Link,
  Select,
  TextArea,
  TooltipWithIndicator,
} from 'components'
import { useToasts } from 'containers/Toast'
import { CreateOrgFormData } from 'pages/api/create-organization'
import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { OrgExistsDocument, OrgExistsQuery, ToastKind } from 'types'
import { useAsyncCallback } from 'utils/handleError'
import { Router } from 'utils/router'
import { patterns } from 'utils/utils'

const maxNoteLength = 250

type ContractSigningMethod = {
  physical: string
  online: string
  unspecified: string
}

const labels: ContractSigningMethod = {
  physical: t`myOrgs.createOrg.contractSigningMethod.PHYSICAL`,
  online: t`myOrgs.createOrg.contractSigningMethod.ONLINE`,
  unspecified: t`myOrgs.createOrg.contractSigningMethod.UNSPECIFIED`,
}

const options = Object.entries(labels).map(([value, label]) => ({
  value,
  label,
}))

const contractRoles = ['technical', 'legal', 'financial', 'signature']

type CreateOrgFormInputs = CreateOrgFormData & {
  // global errors placeholder field
  global: never
}

type CreateOrgResponse = {
  data: { key: string }
}

export const CreateOrgForm: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState,
    errors,
    setError,
    clearErrors,
    watch,
    setValue,
  } = useForm<CreateOrgFormInputs>({ mode: 'onBlur' })

  const { addToast } = useToasts()

  const createOrg = useAsyncCallback(
    handleSubmit(async (data) => {
      // check if there is at least one of each role checked
      const roles: string[] = data.contacts
        .flatMap((contact) => contact.contractRoles)
        .filter(Boolean)
      if (!contractRoles.every((role) => roles.includes(role))) {
        setError('global', {
          message: t`myOrgs.createOrg.missingRolesError`,
        })
        return
      }

      // check if every contact has at least one role
      if (
        !data.contacts.every((contact, idx) => {
          if (contact.contractRoles.filter(Boolean).length === 0) {
            setError(`contacts[${idx}].contractRoles`, {
              message: t`myOrgs.createOrg.noRolesError`,
            })
            return false
          }
          return true
        })
      ) {
        return
      }

      const sanitized = {
        ...data,
        contacts: data.contacts.map((contact) => ({
          ...contact,
          contractRoles: contact.contractRoles.filter(Boolean),
        })),
      }

      try {
        const res = await axios.post<
          CreateOrgFormData,
          AxiosResponse<CreateOrgResponse>
        >('/api/create-organization', sanitized)
        addToast({
          kind: ToastKind.Success,
          content: t`myOrgs.createOrg.formSubmitted`,
        })
        Router.pushRoute(`/support/${res.data.data.key}`)
      } catch (ex: any) {
        if (ex.isAxiosError) {
          const err: AxiosError = ex
          if (err.response?.status === 401) {
            addToast({
              kind: ToastKind.Error,
              content: t`error.unauthorized.text`,
            })
            return
          }
        }

        addToast({
          kind: ToastKind.Error,
          content: t`error.somethingWentWrong.text`,
        })
      }
    }),
    [handleSubmit, setError]
  )

  const [contactsNumber, setContactsNumber] = useState(1)

  const apollo = useApolloClient()
  const checkOrgExists = useCallback(
    async (identificationNumber: string) => {
      const exists = await apollo.query<OrgExistsQuery>({
        query: OrgExistsDocument,
        variables: { identificationNumber },
      })
      return exists.data.organisationExists
    },
    [apollo]
  )

  const ico = watch('identificationNumber', '__none')

  const getOrgData = async () => {
    try {
      const data = await axios.get<any>(
        `https://ares.gov.cz/ekonomicke-subjekty-v-be/rest/ekonomicke-subjekty/${ico.padStart(
          8,
          '0'
        )}`
      )
      if (data) {
        clearErrors()
        setValue('identificationNumber', data.data.ico)
        setValue('vatIdentificationNumber', data.data.dic)
        setValue('name', data.data.obchodniJmeno)
        setValue('street', data.data.sidlo.nazevUlice)
        setValue('number', data.data.sidlo.cisloOrientacni)
        setValue('city', data.data.sidlo.nazevObce)
        setValue('zipCode', data.data.sidlo.psc)
      }
      return data.data
    } catch (ex: any) {
      if (ex.isAxiosError) {
        const err: AxiosError = ex
        if (err.response?.status === 404) {
          addToast({
            kind: ToastKind.Error,
            content: t`form.error.orgIcoNotFound`,
          })
          return
        } else {
          addToast({
            kind: ToastKind.Error,
            content: t`error.dataLoadingError`,
          })
          return
        }
      }
    }
  }

  return (
    <form onSubmit={createOrg}>
      <div className="flex">
        <div className="flex-1">
          <Input
            name="identificationNumber"
            label={
              <Trans id="myOrgs.createOrg.identificationNumber">
                Identification Number
              </Trans>
            }
            errors={errors.identificationNumber}
            ref={register({
              required: t`form.error.required`,
              validate: async (value) => {
                const exists = await checkOrgExists(value)
                return exists ? t`myOrgs.createOrg.exists` : undefined
              },
            })}
            required
            data-cy-id="myOrgs.createOrg.identificationNumber"
          />
        </div>
        <div className="w-24" />
        <div className="flex-1">
          <Input
            name="vatIdentificationNumber"
            label={
              <Trans id="myOrgs.createOrg.vatIdentificationNumber">
                VAT Identification Number
              </Trans>
            }
            errors={errors.vatIdentificationNumber}
            ref={register}
            data-cy-id="myOrgs.createOrg.vatIdentificationNumber"
          />
        </div>
      </div>
      <div className="my-10 mb-24 flex justify-start">
        <Link
          kind="text"
          onClick={() => getOrgData()}
          data-cy-id="myOrgs.createOrg.getDataFromAres"
          loading={formState.isSubmitting}
          disabled={ico.length > 8 || ico.length < 2}
          tooltip={
            ico.length > 8 || ico.length < 2
              ? t`myOrgs.createOrg.getData.requiredIdentificationNumber`
              : ''
          }
        >
          <Trans id="myOrgs.createOrg.getData">
            Get organization data from ARES
          </Trans>
        </Link>
      </div>

      <Select
        name="contractSigningMethod"
        ref={register}
        data-cy-id="myOrgs.createOrg.contractSigningMethod"
        options={options}
        defaultValue="unspecified"
        label={
          <Trans id="myOrgs.createOrg.contractSigningMethod">
            Contract Signing Method
          </Trans>
        }
        labelPosition="top"
        className="mb-24"
        fullWidth
      />

      <Input
        name="accountNumber"
        label={
          <Trans id="myOrgs.createOrg.accountNumber">Bank Account Number</Trans>
        }
        errors={errors.accountNumber}
        ref={register}
        data-cy-id="myOrgs.createOrg.accountNumber"
        className="mb-24"
      />

      <h3 className="heading-h3 mb-10">
        <Trans id="myOrgs.createOrg.address">Organization address</Trans>
      </h3>
      <Input
        name="name"
        label={<Trans id="myOrgs.createOrg.name">Organization Name</Trans>}
        errors={errors.name}
        ref={register({
          required: t`form.error.required`,
          pattern: {
            message: t`form.error.orgName`,
            value: patterns.orgName,
          },
        })}
        required
        data-cy-id="myOrgs.createOrg.name"
      />
      <div className="h-24" />

      <div className="flex mb-24">
        <div className="flex-grow">
          <Input
            name="street"
            label={<Trans id="myOrgs.createOrg.street">Street</Trans>}
            errors={errors.street}
            ref={register({
              required: t`form.error.required`,
            })}
            required
            data-cy-id="myOrgs.createOrg.street"
          />
        </div>
        <div className="w-24" />
        <div className="w-125">
          <Input
            name="number"
            label={<Trans id="myOrgs.createOrg.number">Number</Trans>}
            errors={errors.number}
            ref={register({
              required: t`form.error.required`,
            })}
            required
            data-cy-id="myOrgs.createOrg.number"
            fullWidth={false}
            className="w-125"
            labelWidthClassName=""
          />
        </div>
      </div>

      <div className="flex mb-24">
        <div className="flex-grow">
          <Input
            name="city"
            label={<Trans id="myOrgs.createOrg.city">City</Trans>}
            errors={errors.city}
            ref={register({
              required: t`form.error.required`,
            })}
            required
            data-cy-id="myOrgs.createOrg.city"
          />
        </div>
        <div className="w-24" />
        <div className="w-125">
          <Input
            name="zipCode"
            label={<Trans id="myOrgs.createOrg.zipCode">Zip Code</Trans>}
            errors={errors.zipCode}
            ref={register({
              required: t`form.error.required`,
            })}
            required
            data-cy-id="myOrgs.createOrg.zipCode"
            fullWidth={false}
            className="w-125"
            labelWidthClassName=""
          />
        </div>
      </div>

      <h3 className="heading-h3 mb-10">
        <Trans id="myOrgs.createOrg.contacts">Contact persons</Trans>
      </h3>
      <p className="text-grey-800 text-14 leading-20 mb-24">
        <Trans id="myOrgs.createOrg.contacts.contractInfo">
          Contact persons will have contractually assigned permissions to the
          organization.
        </Trans>
      </p>

      {[...Array(contactsNumber).keys()].map((idx) => (
        <React.Fragment key={idx}>
          <div className="flex justify-end">
            {idx > 0 && idx + 1 === contactsNumber && (
              <Link
                kind="danger"
                onClick={() => setContactsNumber((n) => n - 1)}
                className="mt-24"
                data-cy-id="myOrgs.createOrg.removeContactPerson"
              >
                <Trans id="myOrgs.createOrg.removeContactPerson">
                  Remove this contact person
                </Trans>
              </Link>
            )}
          </div>

          <div className="flex mb-24">
            <div className="flex-1">
              <Input
                name={`contacts[${idx}].firstName`}
                label={
                  <Trans id="myOrgs.createOrg.firstName">First name</Trans>
                }
                errors={errors.contacts?.[idx]?.firstName}
                ref={register({
                  required: t`form.error.required`,
                })}
                required
                data-cy-id="myOrgs.createOrg.firstName"
              />
            </div>
            <div className="w-24" />
            <div className="flex-1">
              <Input
                name={`contacts[${idx}].lastName`}
                label={<Trans id="myOrgs.createOrg.lastName">Last name</Trans>}
                errors={errors.contacts?.[idx]?.lastName}
                ref={register({
                  required: t`form.error.required`,
                })}
                required
                data-cy-id="myOrgs.createOrg.lastName"
              />
            </div>
          </div>

          <div className="flex mb-24">
            <div className="flex-1">
              <Input
                name={`contacts[${idx}].email`}
                label={
                  <Trans id="myOrgs.createOrg.contacts.email">E-mail</Trans>
                }
                errors={errors.contacts?.[idx]?.email}
                ref={register({
                  required: t`form.error.required`,
                  pattern: {
                    message: t`form.error.patternEmail`,
                    value: patterns.email,
                  },
                })}
                required
                data-cy-id="myOrgs.createOrg.contacts.email"
              />
            </div>
            <div className="w-24" />
            <div className="flex-1">
              <Input
                name={`contacts[${idx}].phone`}
                label={
                  <Trans id="myOrgs.createOrg.contacts.phone">Phone</Trans>
                }
                errors={errors.contacts?.[idx]?.phone}
                ref={register({
                  required: t`form.error.required`,
                  pattern: {
                    message: t`form.error.patternPhone`,
                    value: patterns.phone,
                  },
                })}
                required
                data-cy-id="myOrgs.createOrg.contacts.phone"
              />
            </div>
          </div>

          <p className="text-14 text-grey-900 mb-14">
            <Trans id="myOrgs.createOrg.contractRole">Responsibilities</Trans>
          </p>
          <div className="flex justify-center space-x-20 mb-24 text-grey-900 text-14">
            <div className="flex items-center">
              <Checkbox
                name={`contacts[${idx}].contractRoles.0`}
                value="technical"
                ref={register}
                label={
                  <Trans id="myOrgs.createOrg.contractRole.technical">
                    Technical
                  </Trans>
                }
                onChange={() => clearErrors()}
              />
              <TooltipWithIndicator
                kind="dark"
                displayTip={
                  <Trans id="myOrgs.createOrg.contractRole.technical.tooltip">
                    Can connect apps to organization and manage organization
                    users. Will be contacted in case of a planned outage.
                  </Trans>
                }
                id="myOrgs.createOrg.contractRole.technical.tooltip"
                className="-mt-4"
              />
            </div>
            <div className="flex items-center">
              <Checkbox
                name={`contacts[${idx}].contractRoles.1`}
                value="legal"
                ref={register}
                label={
                  <Trans id="myOrgs.createOrg.contractRole.legal">Legal</Trans>
                }
                onChange={() => clearErrors()}
              />
              <TooltipWithIndicator
                kind="dark"
                displayTip={
                  <Trans id="myOrgs.createOrg.contractRole.legal.tooltip">
                    Can see contract information and will be contacted with
                    regard to contract signing.
                  </Trans>
                }
                id="myOrgs.createOrg.contractRole.legal.tooltip"
                className="-mt-4"
              />
            </div>
            <div className="flex items-center">
              <Checkbox
                name={`contacts[${idx}].contractRoles.2`}
                value="financial"
                ref={register}
                label={
                  <Trans id="myOrgs.createOrg.contractRole.financial">
                    Financial
                  </Trans>
                }
                onChange={() => clearErrors()}
              />
              <TooltipWithIndicator
                kind="dark"
                displayTip={
                  <Trans id="myOrgs.createOrg.contractRole.financial.tooltip">
                    Can see invoices and reports.
                  </Trans>
                }
                id="myOrgs.createOrg.contractRole.financial.tooltip"
                className="-mt-4"
              />
            </div>
            <div className="flex items-center">
              <Checkbox
                name={`contacts[${idx}].contractRoles.3`}
                value="signature"
                ref={register}
                label={
                  <Trans id="myOrgs.createOrg.contractRole.signature">
                    Signature
                  </Trans>
                }
                onChange={() => clearErrors()}
              />
              <TooltipWithIndicator
                kind="dark"
                displayTip={
                  <Trans id="myOrgs.createOrg.contractRole.signature.tooltip">
                    This person is authorized to sign contracts on behalf of the
                    organization.
                  </Trans>
                }
                id="myOrgs.createOrg.contractRole.signature.tooltip"
                className="-mt-4"
              />
            </div>
          </div>
          {errors.contacts?.[idx]?.contractRoles && (
            <p className="text-utility-error text-14 leading-20 italic mb-24">
              <Trans
                id={(errors.contacts?.[idx]?.contractRoles as any).message}
              ></Trans>
            </p>
          )}
        </React.Fragment>
      ))}

      <Link
        kind="text"
        onClick={() => setContactsNumber((n) => n + 1)}
        data-cy-id="myOrgs.createOrg.addContactPerson"
      >
        <Trans id="myOrgs.createOrg.addContactPerson">
          + Add contact person
        </Trans>
      </Link>
      <div className="mb-24" />

      <TextArea
        className="my-16"
        textAreaClassName="font-mono text-14 leading-22"
        name="note"
        ref={register({
          validate: (value) =>
            value.length > maxNoteLength
              ? t`myOrgs.createOrg.note.tooLong`
              : undefined,
        })}
        errors={errors.note}
        placeholder={t`myOrgs.createOrg.note.placeholder`}
        data-cy-id="myOrgs.createOrg.note"
        label={<Trans id="myOrgs.createOrg.note">Additional notes</Trans>}
      />

      {errors.global?.message && (
        <p className="text-utility-error text-14 leading-20 italic mb-24">
          <Trans id={errors.global.message} />
        </p>
      )}

      <div className="mt-32 flex justify-start">
        <Button
          loading={formState.isSubmitting}
          isFormSubmit
          data-cy-id="myOrgs.createOrg.createButton"
        >
          <Trans id="myOrgs.createOrg.createButton">Send request</Trans>
        </Button>
      </div>

      <p className="mt-16 text-14 text-grey-800">
        <Trans id="myOrgs.createOrg.afterSubmitInstructions">
          We will contact you within two workdays of submitting your request.
        </Trans>
      </p>
    </form>
  )
}
