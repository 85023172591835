import { t } from '@lingui/macro'
import { ToastComponent } from 'containers'
import WarningIcon from 'icons/warning.svg'
import React from 'react'

export type AppConfigErrors =
  | 'APP_ENVIRONMENT_UNSUCCESSFUL_REGISTRATION'
  | 'APP_NAME_ALREADY_EXISTS'
  | 'URI_NOT_VALID'
  | 'GENERIC_ERROR'
  | 'SECTOR_IDENTIFIER_URI_DOES_NOT_MATCH_REDIRECT_URIS'
  | 'SECTOR_IDENTIFIER_URI_INVALID'
  | 'INTERNAL_SERVER_ERROR'
  | 'JWKS_URI_INVALID'
  | 'JWKS_PARSE_ERROR'
  | 'JWKS_ALG_MANDATORY_PARAM_NOT_FOUND'
  | 'JWKS_KID_NOT_FOUND'
  | 'JWKS_USE_NOT_FOUND'
  | null

type AppConfigErrorProps = {
  formError: AppConfigErrors
}

export const AppConfigError: React.FC<AppConfigErrorProps> = ({
  formError,
}) => {
  if (!formError) {
    return null
  }

  const textString =
    formError === 'GENERIC_ERROR' || formError === 'INTERNAL_SERVER_ERROR'
      ? t`error.somethingWentWrong.text`
      : t({ id: `app.environment.config.error.${formError}` })

  return (
    <div className="mt-20">
      <ToastComponent className="bg-utility-error text-white-100" title={false}>
        <p className="text-16">
          <WarningIcon className="inline w-18 h-18 mr-10 opacity-75 fill-current" />
          {textString}
        </p>
      </ToastComponent>
    </div>
  )
}
