import { t, Trans } from '@lingui/macro'
import { Select, SelectProps } from 'components'
import React, { forwardRef } from 'react'
import { ScopeLevel } from 'types'

type AuthMethodLabels = {
  [key in ScopeLevel]: string
}

const labels: AuthMethodLabels = {
  OPTIONAL: t`app.environments.config.scopes.optional`,
  REQUIRED: t`app.environments.config.scopes.required`,
  UNUSED: t`app.environments.config.scopes.unused`,
}

const options = Object.entries(labels).map(([value, label]) => ({
  value,
  label,
}))

export const RefreshTokenSelect = forwardRef<
  HTMLSelectElement,
  Omit<SelectProps, 'name' | 'options'>
>((props, ref) => (
  <Select
    ref={ref}
    rules={{ required: true }}
    className="my-16"
    options={options}
    name="refreshTokenEnabled"
    label={
      <Trans id="app.environment.config.refreshTokenEnabled">
        Refresh token
      </Trans>
    }
    labelTooltip={t`app.environment.config.refreshTokenEnabled.tooltip`}
    {...props}
  />
))
