import React, { useEffect } from 'react'
import { Router } from 'utils/router'
import { api as authApi, useIsLoggedIn } from 'utils/withAuth'
import { useLocale } from 'utils/withLang'

interface LoginRedirectProps {
  kind: 'login' | 'register'
}

export const LoginRedirect: React.FC<LoginRedirectProps> = ({ kind }) => {
  const isLoggedIn = useIsLoggedIn()
  const locale = useLocale()

  useEffect(() => {
    const authUrl =
      kind === 'register' ? authApi.registerUrl(locale) : '/api/login'

    const handleLoggedInRedirect = () => {
      Router.replaceRoute('/dashboard')
    }

    if (isLoggedIn) {
      handleLoggedInRedirect()
    } else {
      Router.replaceRoute(authUrl)
    }
  }, [isLoggedIn, locale, kind])

  return null
}
