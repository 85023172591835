import { t, Trans } from '@lingui/macro'
import { Button, ConfirmationModal } from 'components'
import { PermissionKind, useToasts } from 'containers'
import React from 'react'
import {
  ToastKind,
  useRemoveUserMutation,
  useUsersInAppQuery,
  useUsersInBankQuery,
  useUsersInOrgQuery,
} from 'types'
import { useAsyncCallback } from 'utils/handleError'
import { Router } from 'utils/router'

type RemoveUserButtonProps = {
  type: PermissionKind
  slug: string
  userId: string
  isOwner?: boolean
  isSelf: boolean
  isContract?: boolean
}

export const RemoveUserButton: React.FC<RemoveUserButtonProps> = ({
  type,
  slug,
  userId,
  isOwner,
  isSelf,
  isContract,
}) => {
  const [removeUser] = useRemoveUserMutation({
    variables: {
      id: userId,
      app: type === 'app',
      bank: type === 'bank',
      org: type === 'organization',
    },
  })

  const { refetch: refetchUsersInApp } = useUsersInAppQuery({
    variables: { slug },
    skip: type !== 'app',
  })
  const { refetch: refetchUsersInBank } = useUsersInBankQuery({
    variables: { slug },
    skip: type !== 'bank',
  })
  const { refetch: refetchUsersInOrg } = useUsersInOrgQuery({
    variables: { slug },
    skip: type !== 'organization',
  })

  const { addToast } = useToasts()

  const handleRemoveUser = useAsyncCallback(async () => {
    try {
      await removeUser()
      type === 'app' && (await refetchUsersInApp())
      type === 'bank' && (await refetchUsersInBank())
      type === 'organization' && (await refetchUsersInOrg())
      Router.pushRoute(`/${type}s/${slug}/users`)
      addToast({
        kind: ToastKind.Success,
        content: t`users.userDetail.removeUser.toastSuccess`,
      })
    } catch (_) {
      addToast({
        kind: ToastKind.Error,
        content: t`users.userDetail.removeUser.toastError`,
      })
    }
  }, [
    removeUser,
    refetchUsersInApp,
    refetchUsersInBank,
    refetchUsersInOrg,
    addToast,
  ])

  return (
    <ConfirmationModal
      onConfirm={handleRemoveUser}
      text={
        <Trans id="users.userDetail.confirmRemoveUser">
          Are you sure you want to remove this user?
        </Trans>
      }
    >
      {(openConfirmation) => (
        <Button
          kind="danger-transparent"
          onClick={openConfirmation}
          data-cy-id="users.userDetail.removeUser"
          disabled={isOwner || isSelf || isContract}
          tooltip={
            (isOwner && (
              <Trans id="users.userDetail.cannotRemoveOwner">
                Cannot remove application owner. The user must transfer
                ownership to someone else before they can be removed.
              </Trans>
            )) ||
            (isSelf && (
              <Trans id="users.userDetail.cannotRemoveSelf">
                You cannot remove yourself.
              </Trans>
            )) ||
            (isContract && (
              <Trans id="users.userDetail.cannotRemoveContractUser">
                This user cannot be removed, because they are a contractually
                defined responsible person.
              </Trans>
            ))
          }
        >
          <Trans id="users.userDetail.removeUser">Remove user</Trans>
        </Button>
      )}
    </ConfirmationModal>
  )
}
