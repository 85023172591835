import { Trans } from '@lingui/macro'
import classNames from 'classnames'
import { TooltipWithIndicator } from 'components'
import React, { ReactNode } from 'react'
import { FieldErrors, RegisterOptions } from 'react-hook-form'

export interface ValidationContainerProps {
  name?: string
  label?: ReactNode | string
  labelWidthClassName?: string
  labelPosition?: 'top' | 'left'
  labelTooltip?: string | boolean
  errors?: FieldErrors
  className?: string
  type?: string
  onBlur?: () => void
  checked?: boolean
  description?: ReactNode | string
  descriptionPosition?: 'bottom' | 'right'
  rules?: RegisterOptions // for custom controlled components
  fullWidth?: boolean
  required?: boolean
}

export const ValidationContainer: React.FC<ValidationContainerProps> = ({
  label,
  labelPosition,
  labelTooltip,
  labelWidthClassName,
  errors,
  name,
  children,
  className,
  description,
  descriptionPosition,
  type,
  fullWidth,
  required = false,
}) => {
  return (
    <div
      className={classNames(
        'flex',
        fullWidth && 'w-full',
        label && ' justify-between',
        labelPosition === 'left' && type === 'checkbox' && 'items-center',
        type !== 'checkbox' &&
          (labelPosition === 'left'
            ? 'flex-col items-start lg:flex-row lg:items-center'
            : 'flex-col items-start'),
        className
      )}
    >
      {label && (
        <label
          className={classNames(
            'text-14 text-grey-900 cursor-pointer leading-tight',
            labelPosition === 'left' && 'break-words mr-10',
            labelWidthClassName ?? 'lg:min-w-230',
            labelPosition === 'left' &&
              (type === 'checkbox' ? 'mt-4' : 'lg:mt-4 mb-4 lg:mb-0'),
            labelPosition !== 'left' && 'mb-4'
          )}
          htmlFor={name}
        >
          <>
            {label}
            {required && <span style={{ color: 'red' }}> *</span>}
            {typeof labelTooltip === 'string' && (
              <TooltipWithIndicator
                id={labelTooltip}
                kind="dark"
                className="inline"
                displayTip={<Trans id={labelTooltip} />}
              />
            )}
          </>
        </label>
      )}
      <div
        className={classNames(
          labelPosition === 'left' &&
            type === 'checkbox' &&
            'md:w-1/2 lg:w-full',
          fullWidth && 'w-full'
        )}
      >
        <div
          className={classNames(
            'flex flex-col lg:flex-row lg:items-center relative',
            fullWidth && 'w-full'
          )}
        >
          {children}
          {description && descriptionPosition === 'right' && (
            <span className="my-4 lg:mx-16 lg:my-0 text-grey-800">
              {description}
            </span>
          )}
        </div>
        {errors?.message && (
          <p className="text-utility-error text-14 leading-20 italic py-6">
            <Trans id={errors.message} />
          </p>
        )}
      </div>
      {description && descriptionPosition === 'bottom' && (
        <p className="text-grey-600 text-14 leading-18 mt-10">{description}</p>
      )}
    </div>
  )
}

ValidationContainer.defaultProps = {
  fullWidth: true,
}
