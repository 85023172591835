import { RewriteFrames } from '@sentry/integrations'
import * as Sentry from '@sentry/node'
import jwtDecode from 'jwt-decode'
import omit from 'lodash/omit'
import getConfig from 'next/config'
import { parseCookies } from 'nookies'

const env = getConfig().publicRuntimeConfig.ENVIRONMENT
const scrub = env === 'staging' || env === 'production'

export const init = (release, dsn, environment) => {
  if (dsn) {
    const integrations = []
    // Rewrite Error.stack to use relative paths, so that source maps
    // starting with ~/ map to files in Error.stack
    integrations.push(
      new RewriteFrames({
        iteratee: (frame) => {
          // make frame paths relative to root
          frame.filename = frame.filename.replace(/(.*?)(\.next|_next)/g, '~')
          return frame
        },
      })
    )

    Sentry.init({
      integrations,
      dsn,
      release,
      environment,
    })
  }
}

export const captureException = (err, ctx = {}, opts = {}) => {
  // make Axios errors nicer & scrub sensitive info
  if (err && err.isAxiosError) {
    if (
      scrub &&
      (err.config.headers.authorization || err.config.headers.Authorization)
    ) {
      delete err.config.headers.authorization
      delete err.config.headers.Authorization
      err.config.headers.authorization = '* redacted *'
    }

    ctx.errorInfo = {
      request: {
        method: err.config.method,
        baseUrl: err.config.baseUrl,
        url: err.config.url,
        headers: err.config.headers,
        data: err.config.data,
      },
      response: {
        status: err.response?.status,
        statusText: err.response?.statusText,
        headers: err.response?.headers,
        data: err.response?.data,
      },
    }

    err = new Error(err.message)
  }

  // extract GraphQLError info
  const graphQlInfo = {
    locations: err.locations,
    path: err.path,
    ...err.extensions,
    ...(ctx && ctx.operation
      ? {
          operationName: ctx.operation.operationName,
          variables: ctx.operation.variables,
        }
      : {}),
  }

  if (!scrub) {
    console.groupCollapsed(err.message)
    if (ctx.errorInfo) {
      console.log(JSON.stringify(ctx.errorInfo, null, 2))
    }
    if (err.locations || err.path || err.extensions || ctx.operation) {
      console.log(JSON.stringify(graphQlInfo, null, 2))
    }
    console.groupEnd()
  }

  Sentry.withScope((scope) => {
    if (err.message) {
      // De-duplication currently doesn't work correctly for SSR / browser errors
      // so we force deduplication by error message if it is present
      scope.setFingerprint([err.message])
    }

    if (ctx.errorInfo) {
      scope.setContext('errorInfo', ctx.errorInfo)
    }

    scope.setTag('ssr', typeof window === 'undefined')
    scope.setContext('graphql', graphQlInfo)

    const cookies = parseCookies(ctx)
    if (cookies) {
      const sanitized = omit(cookies, [
        'access_token',
        'id_token',
        'refresh_token',
      ])
      scope.setContext('cookies', sanitized)

      if (cookies.id_token) {
        const { email, given_name, family_name, sub } = jwtDecode(
          cookies.id_token
        )

        scope.setUser({
          id: `sub:${sub}`,
          email: scrub ? undefined : email,
          username: scrub ? undefined : `${given_name} ${family_name}`,
        })
      }
    }

    // TODO use modified flags
    if (typeof window !== 'undefined') {
      scope.setContext(
        'features',
        Object.fromEntries(
          window.FEATURES.map((flag) => [flag.name, flag.defaultValue])
        )
      )
    }

    // capture traceId from GraphQL requests
    const context = ctx && ctx.operation && ctx.operation.getContext()
    const headers = context && context.response && context.response.headers
    const traceId = headers && headers.get('traceId')
    if (traceId) {
      scope.setTag('traceId', traceId)
    }

    // TODO file service traceId

    if (err instanceof Error) {
      Sentry.setExtra('errorData', ctx.errorInfo?.response?.data ?? '')
      Sentry.captureException(err)
      return
    }

    Sentry.captureException(err.message || 'Unknown error')

    if (opts && opts.flush) {
      Sentry.flush(2000)
    }
  })
}
