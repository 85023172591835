import { Trans } from '@lingui/macro'
import { Link } from 'components'
import { QueryLoader } from 'containers'
import React from 'react'
import { AppMetaQuery, useUserInOrgQuery } from 'types'

type ConnectedOrgLinkProps = {
  org: NonNullable<NonNullable<AppMetaQuery['appBySlug']>['organisation']>
  userId?: string
}

export const ConnectedOrgLink: React.FC<ConnectedOrgLinkProps> = ({
  org,
  userId,
}) => {
  const userInOrgQuery = useUserInOrgQuery({
    variables: { slug: org.slug, userId: userId! }, // skipped if userId is undefined
    skip: !userId,
  })
  const userInOrg = userInOrgQuery.data?.userInOrganisation

  return (
    <p>
      <Trans id="app.config.org.connected">
        This app is connected to organization
      </Trans>
      &nbsp;
      <QueryLoader queries={[userInOrgQuery]} errorComponent={null}>
        <Link
          to={`/organizations/${org.slug}`}
          data-cy-id={`organisation.${org.slug}`}
        >
          {org.name}
        </Link>
      </QueryLoader>
      {!userInOrg && <>{org.name}</>}
    </p>
  )
}
