import { Trans } from '@lingui/react'
import { TicketBadge } from 'containers'
import React from 'react'
import { mapTicketStatusForDisplay, TicketDetailFields } from 'types'

type TicketBadgeProps = {
  status: TicketDetailFields['status']['id']
}

export const TicketStatus: React.FC<TicketBadgeProps> = ({ status }) => (
  <TicketBadge>
    <Trans id={`support.tickets.status.${mapTicketStatusForDisplay(status)}`} />
  </TicketBadge>
)
