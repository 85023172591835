import { t, Trans } from '@lingui/macro'
import { Button, Input } from 'components'
import { QueryLoader, useToasts } from 'containers'
import React from 'react'
import { useForm } from 'react-hook-form'
import { ToastKind, useProfileQuery, useUpdateUserMutation } from 'types'
import { useAsyncCallback } from 'utils/handleError'

type NameAndEmailFormData = {
  firstName: string
  lastName: string
}

export const NameAndEmailForm: React.FC = () => {
  const profileQuery = useProfileQuery()
  const currentUser = profileQuery.data?.currentUser

  const { register, handleSubmit, formState, errors, reset } =
    useForm<NameAndEmailFormData>({
      defaultValues: {
        firstName: currentUser?.firstName ?? '',
        lastName: currentUser?.lastName ?? '',
      },
    })

  const [updateUser] = useUpdateUserMutation()
  const { addToast } = useToasts()

  const submitForm = useAsyncCallback(
    handleSubmit(async (user) => {
      if (!currentUser?.id) {
        throw new Error('Invalid user ID')
      }

      await updateUser({ variables: { userId: currentUser.id, user } })
      addToast({
        kind: ToastKind.Success,
        content: t`profile.name.updateSuccessful`,
      })
      const res = await profileQuery.refetch()
      reset({
        firstName: res.data.currentUser?.firstName ?? '',
        lastName: res.data.currentUser?.lastName ?? '',
      })
    }),
    [currentUser, updateUser, addToast, profileQuery]
  )

  return (
    <QueryLoader queries={[profileQuery]}>
      <form onSubmit={submitForm}>
        <div className="sm:flex items-start mb-20">
          <Input
            name="firstName"
            label={<Trans id="profile.firstName">First name</Trans>}
            className="w-full"
            ref={register({
              required: t`form.error.required`,
            })}
            errors={errors.firstName}
            data-cy-id="profile.firstName"
          />
          <div className="w-40 h-20" />
          <Input
            name="lastName"
            label={<Trans id="profile.lastName">Last name</Trans>}
            className="w-full"
            ref={register({
              required: t`form.error.required`,
            })}
            errors={errors.lastName}
            data-cy-id="profile.lastName"
          />
        </div>

        <Input
          name="email"
          label={<Trans id="profile.email">E-mail</Trans>}
          readOnly
          value={currentUser?.email}
          className="mb-6"
          data-cy-id="profile.email"
        />
        <p className="text-grey-600 mb-20 text-14">
          <Trans id="profile.emailCannotBeChanged">
            E-mail cannot be changed.
          </Trans>
        </p>

        <Button
          loading={formState.isSubmitting}
          disabled={!formState.isDirty}
          isFormSubmit
          data-cy-id="profile.nameAndEmail.saveChanges"
        >
          <Trans id="profile.saveChanges">Save changes</Trans>
        </Button>
      </form>
    </QueryLoader>
  )
}
