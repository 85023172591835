import { Trans } from '@lingui/macro'
import { Code, Tooltip } from 'components'
import { PermissionKind } from 'containers'
import React from 'react'

interface PermissionTagProps {
  children: string
  type: PermissionKind
}

export const PermissionTag: React.FC<PermissionTagProps> = ({
  children: permission,
  type,
}) => (
  <Tooltip
    id={permission}
    kind="dark"
    displayTip={
      <>
        <span className="block">
          <Trans id={`users.permissions.${type}.${permission}.title`} />
        </span>
        <Trans id={`users.permissions.${type}.${permission}.text`} />
      </>
    }
  >
    <Code className="block mr-12 mt-10">{permission}</Code>
  </Tooltip>
)
