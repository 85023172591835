import { t } from '@lingui/macro'
import classNames from 'classnames'
import { Link } from 'components'
import Cross from 'icons/close.svg'
import React from 'react'
import ReactModal from 'react-modal'
import type { TestIdProp } from 'types'
import css from './Modal.module.css'

export interface ModalProps extends TestIdProp {
  title: React.ReactNode
  isOpen?: boolean
  maxWidth?: number | string
  showCloseButton?: boolean
  shouldCloseOnOverlayClick?: boolean

  onClose?: () => unknown
  onAfterOpen?: () => unknown
  alertOnClose?: () => unknown
}

export const Modal: React.FC<ModalProps> = (props) => (
  <ReactModal
    isOpen={props.isOpen ?? false}
    overlayClassName={{
      base: css.overlay,
      afterOpen: css.afterOpen,
      beforeClose: css.beforeClose,
    }}
    closeTimeoutMS={200}
    onAfterOpen={props.onAfterOpen}
    className={css.content}
    htmlOpenClassName={css.htmlOpen}
    onRequestClose={props.alertOnClose ? props.alertOnClose : props.onClose}
    appElement={
      (typeof document !== 'undefined' && document.getElementById('__next')) ||
      undefined
    }
    shouldCloseOnOverlayClick={props.shouldCloseOnOverlayClick}
  >
    <div
      className="bg-white-100 sm:rounded-16 shadow min-w-0"
      style={{ width: props.maxWidth }}
    >
      <div
        className={classNames(
          'px-32 pt-22 pb-18 border-b border-grey-300 text-21 font-semibold flex font-display text-primary-darker',
          props.title ? 'justify-between' : 'justify-end'
        )}
      >
        {props.title}
        {props.showCloseButton && (
          <Link
            onClick={props.onClose}
            data-cy-id={`${props['data-cy-id']}-close`}
          >
            <Cross
              title={t`modal.close.alt`}
              className="fill-grey-600"
              width={15}
              height={15}
            />
          </Link>
        )}
      </div>
      <div className="px-32 pb-32 pt-26">{props.children}</div>
    </div>
  </ReactModal>
)

Modal.defaultProps = {
  maxWidth: 400,
  showCloseButton: true,
}
