import cn from 'classnames'
import { find } from 'lodash'
import React from 'react'
import { TableColumn } from './common'
import { TableHeaderRow } from './TableHeaderRow'
import { TableRow } from './TableRow'

interface TableProps {
  columns: TableColumn<any>[]
  data: any[]
  inCard?: boolean
  rowTo?: (row: any) => string | undefined
  minWidth?: number
  className?: string
  alignRows?: 'center' | 'start'
  headerRow?: boolean
  rowClassName?: (row: any) => boolean | string | undefined
}

export const Table: React.FC<TableProps> = (props) => {
  const containsHeadings =
    props.headerRow && !!find(props.columns, (x) => x.title)

  return (
    <div role="table" className={cn('overflow-x-auto', props.className)}>
      <div style={{ minWidth: props.minWidth }}>
        {containsHeadings && <TableHeaderRow columns={props.columns} />}
        {props.data.map((x) => (
          <TableRow
            key={x.id}
            rowData={x}
            columns={props.columns}
            to={props.rowTo?.(x)}
            alignItems={props.alignRows!}
            className={cn(props.rowClassName?.(x))}
          />
        ))}
      </div>
    </div>
  )
}

Table.defaultProps = {
  alignRows: 'center',
  headerRow: true,
}
