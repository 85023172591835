import classNames from 'classnames'
import { Trans } from '@lingui/macro'
import { Dropzone, DropzoneProps, Logo } from 'components'
import React from 'react'
import { MediaFragmentWithFsId } from 'utils/url'

type LogoDropzoneProps = DropzoneProps & {
  currentLogo?: MediaFragmentWithFsId | null
}

export const LogoDropzone: React.FC<LogoDropzoneProps> = ({
  currentLogo,
  ...props
}) => (
  <Dropzone
    className={classNames(
      'max-h-300 mt-10',
      props.labelPosition === 'left' && 'max-w-250'
    )}
    description={
      <p className="text-grey-600 text-14 leading-18">
        <Trans id="dropzone.logoRequirements">
          The logo must be a square image at least 1024 by 1024 px in the PNG
          format, maximum 10 MB.
        </Trans>
      </p>
    }
    descriptionPosition="bottom"
    content={(filename) => (
      <div className="text-center flex flex-col items-center break-all px-10 text-14">
        {filename ?? (
          <>
            {<Logo media={currentLogo} className="m-10" />}
            <Trans id="dropzone.dropFile">Drop or select a file</Trans>
          </>
        )}
      </div>
    )}
    preview={(url) => <img width={64} alt="" src={url} className="m-10" />}
    imageOnly
    mimeTypes={['image/png']}
    enableRemove={false}
    {...props}
  />
)
