import { useEffect, useState } from 'react'

export function useLoader<TData, TError>(loader: () => Promise<TData>) {
  const [data, setData] = useState<TData | undefined>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<TError>()

  useEffect(() => {
    loader()
      .then((data) => setData(data))
      .catch((ex) => setError(ex))
      .finally(() => setLoading(false))
  }, [loader])

  return { data, loading, error }
}
