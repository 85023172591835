import { t, Trans } from '@lingui/macro'
import { Button, Select } from 'components'
import { QueryLoader } from 'containers'
import { uniqBy } from 'lodash'
import { TicketFilter } from 'pages/support/support'
import React, { useMemo } from 'react'
import { TicketMeta, useUserAppsOrgsQuery } from 'types'

const NONE = '__none'

const noAppFilter = {
  label: t`support.tickets.filter.app`,
  value: NONE,
}

const noOrgFilter = {
  label: t`support.tickets.filter.org`,
  value: NONE,
}

type TicketListToolbarProps = {
  data?: TicketMeta[]
  selectFilter: (filter: TicketFilter) => void
}

export const TicketListToolbar: React.FC<TicketListToolbarProps> = ({
  data,
  selectFilter,
}) => {
  const appsAndOrgs = useUserAppsOrgsQuery()

  const appsOptions = useMemo(() => {
    const apps =
      data
        ?.filter((obj) => !!obj.fields.customfield_10075)
        .map((ticket) => ({
          label:
            appsAndOrgs.data?.currentUser?.apps?.edges.find(
              ({ node }) => node.app.id === ticket.fields.customfield_10075
            )?.node.app.name ?? ticket.fields.customfield_10075,
          value: ticket.fields.customfield_10075,
        })) ?? []
    return [noAppFilter, ...uniqBy(apps, (app) => app.value)]
  }, [data, appsAndOrgs.data?.currentUser?.apps?.edges])

  const orgsOptions = useMemo(() => {
    const orgs =
      data
        ?.filter((obj) => !!obj.fields.customfield_10073)
        .map((ticket) => ({
          label:
            appsAndOrgs.data?.currentUser?.organisations.find(
              ({ organisation }) =>
                organisation.id === ticket.fields.customfield_10073
            )?.organisation.name ?? ticket.fields.customfield_10073,
          value: ticket.fields.customfield_10073,
        })) ?? []
    return [noOrgFilter, ...uniqBy(orgs, (org) => org.value)]
  }, [data, appsAndOrgs.data?.currentUser?.organisations])

  return (
    <div className="flex flex-col lg:flex-row lg:items-center max-w-900">
      <p className="text-grey-800 mb-12 lg:mb-0">
        <Trans id="support.tickets.filter">Filter</Trans>
      </p>
      <div className="w-60" />
      <QueryLoader
        queries={[appsAndOrgs]}
        loaderComponent={null}
        errorComponent={null}
      >
        <Select
          name="app"
          data-cy-id="support.tickets.filter.app"
          options={appsOptions}
          disabled={appsOptions.length === 1}
          onChange={(app) =>
            selectFilter({ app: app === NONE ? undefined : app })
          }
          fullWidth
          className="text-grey-600"
        />
        <div className="w-60 h-16" />
        <Select
          name="org"
          data-cy-id="support.tickets.filter.org"
          options={orgsOptions}
          disabled={orgsOptions.length === 1}
          onChange={(org) =>
            selectFilter({ org: org === NONE ? undefined : org })
          }
          fullWidth
          className="text-grey-600"
        />
        <div className="w-60 h-16" />
      </QueryLoader>
      <Button
        data-cy-id="support.createTicket"
        to="/support/create-ticket"
        className="self-end w-120 flex-shrink-0"
      >
        <Trans id="support.createTicket">New Ticket</Trans>
      </Button>
    </div>
  )
}
