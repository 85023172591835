import { t } from '@lingui/macro'
import { Link } from 'components/Clickable'
import Cross from 'icons/close.svg'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import ReactModal from 'react-modal'
import { useKeys } from 'utils/hooks'
import css from './Modal.module.css'

interface LightBoxContextType {
  setImage: (url: string) => void
  setAlt: (alt?: string) => void
}

const LightBoxContext = createContext<LightBoxContextType | null>(null)

export function useLightBox() {
  const { setImage, setAlt } = useContext(LightBoxContext) ?? {}

  if (process.env.NODE_ENV !== 'production' && !setImage) {
    throw new Error(
      'LightBoxContext is null. Ensure there is a LightBoxProvider up in the component tree.'
    )
  }

  const open = useCallback(
    (url?: string, alt?: string) => () => {
      if (!url) {
        return
      }

      setImage?.(url)
      setAlt?.(alt)
    },
    [setImage, setAlt]
  )
  const [ref, setRef] = useState<HTMLImageElement | null>(null)
  const [action, setAction] = useState<typeof open | undefined>(() => open)

  useEffect(() => {
    const checkSize = () => {
      const width = ref?.width ?? 0
      const naturalWidth = ref?.naturalWidth ?? 0

      // disable preview if image is larger on page than its actual size
      if (naturalWidth < width) {
        setAction(undefined)
      }
    }

    // check size if image loaded before registering handler
    if (ref?.complete) {
      checkSize()
      return
    }

    ref?.addEventListener('load', checkSize)
    return () => ref?.removeEventListener('load', checkSize)
  }, [ref])

  return [action, setRef] as const
}

export const LightBoxProvider: React.FC = ({ children }) => {
  const [image, setImage] = useState<string | undefined>()
  const [alt, setAlt] = useState<string | undefined>()
  const handleClose = useCallback(() => {
    setImage(undefined)
    setAlt(undefined)
  }, [setImage, setAlt])
  const handleKeyUp = useKeys(['Enter', ' ', 'Escape'])(handleClose)

  return (
    <LightBoxContext.Provider value={{ setImage, setAlt }}>
      {children}
      <ReactModal
        isOpen={!!image}
        overlayClassName={{
          base: css.overlay,
          afterOpen: css.afterOpen,
          beforeClose: css.beforeClose,
        }}
        closeTimeoutMS={200}
        className={css.lightbox}
        htmlOpenClassName={css.htmlOpen}
        onRequestClose={handleClose}
        appElement={
          (typeof document !== 'undefined' &&
            document.getElementById('__next')) ||
          undefined
        }
      >
        <Link
          onClick={handleClose}
          data-cy-id="lightbox.close"
          className="absolute top-0 right-0 m-30 p-15 bg-black-100 rounded"
        >
          <Cross
            title={t`lightbox.close.alt`}
            className="fill-grey-display"
            width={25}
            height={25}
          />
        </Link>
        {/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */}
        <img
          src={image}
          alt={alt}
          className="h-full max-w-full flex-1 object-contain"
          onClick={handleClose}
          onKeyUp={handleKeyUp}
          role="button"
          tabIndex={0}
        />
        {/* eslint-enable jsx-a11y/no-noninteractive-element-to-interactive-role */}
      </ReactModal>
    </LightBoxContext.Provider>
  )
}
