import { Trans } from '@lingui/macro'
import classNames from 'classnames'
import Spinner from 'icons/spinner.svg'
import type { AriaAttributes, CSSProperties } from 'react'
import React, { useCallback, useState } from 'react'
import type { TestIdProp } from 'types'
import { UrlObject } from 'url'
import { useKeys } from 'utils/hooks'
import routes from '../../../routes'

const { Link } = routes

export interface ClickableProps extends Partial<TestIdProp> {
  /**
   * Internal link
   */
  to?: string | UrlObject

  /**
   * External link
   */
  href?: string

  ariaLabelledBy?: string
  ariaCurrent?: AriaAttributes['aria-current']
  role?: string
  onClick?: () => void
  onMouseDown?: () => unknown
  isFormSubmit?: boolean
  openInNewTab?: boolean
  className?: string
  disabled?: boolean
  download?: boolean
  loading?: boolean
  title?: string
  as?: string
  params?: Record<string, any>
  scroll?: boolean
  replace?: boolean
  style?: CSSProperties
  fullWidth?: boolean
}

interface Props extends ClickableProps {
  loadingClassName?: string
  disabledClassName?: string
  loadingColor?: string
}

export const Clickable: React.FC<Props> = (props) => {
  const [loadingState, setLoadingState] = useState(false)

  const clickHandler = (
    e: React.MouseEvent<any>,
    fn?: (e: React.MouseEvent<any>) => unknown
  ) => {
    if (fn) {
      const res: any = fn(e)

      if (typeof res?.finally === 'function') {
        setLoadingState(true)
        res.finally(() => setLoadingState(false))
      }

      e.preventDefault()

      return res
    } else {
      return undefined
    }
  }

  const onClick = useCallback(
    (e) => clickHandler(e, props.onClick),
    [props.onClick]
  )
  const onMouseDown = useCallback(
    (e) => clickHandler(e, props.onMouseDown),
    [props.onMouseDown]
  )

  const loading = props.loading || loadingState
  const disabled = props.disabled || loading

  const className = classNames(
    props.disabled && 'opacity-50 cursor-not-allowed',
    props.disabled && props.disabledClassName,
    loading && 'opacity-75 loading cursor-wait',
    loading && props.loadingClassName,
    props.fullWidth && 'w-full',
    props.className
  )

  const makeKeyboardHandler = useKeys([' ', 'Enter'])
  const onActivate = props.onClick
    ? makeKeyboardHandler(props.onClick)
    : undefined

  if (!disabled && props.to) {
    return (
      /* eslint-disable jsx-a11y/anchor-is-valid */
      /* eslint-disable react/jsx-no-target-blank */
      <Link route={props.to} scroll={props.scroll} replace={props.replace}>
        <a
          className={className}
          style={props.style}
          target={props.openInNewTab ? '_blank' : undefined}
          rel={props.openInNewTab ? 'noreferrer' : undefined}
          role={props.role}
          title={props.title}
          onClick={props.onClick}
          onKeyUp={onActivate}
          download={props.download}
          data-cy-id={props['data-cy-id']}
          aria-labelledby={props.ariaLabelledBy}
          aria-current={props.ariaCurrent}
        >
          {props.children}
        </a>
      </Link>
      /* eslint-enable react/jsx-no-target-blank */
      /* eslint-enable jsx-a11y/anchor-is-valid */
    )
  } else if (!disabled && props.href) {
    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a
        href={props.href}
        onClick={props.onClick}
        className={className}
        style={props.style}
        target={props.openInNewTab ? '_blank' : undefined}
        rel={props.openInNewTab ? 'noreferrer' : undefined}
        role={props.role}
        title={props.title}
        download={props.download}
        data-cy-id={props['data-cy-id']}
        aria-labelledby={props.ariaLabelledBy}
        aria-current={props.ariaCurrent}
      >
        {props.children}
      </a>
    )
  } else {
    const doesNothing =
      !props.onClick && !props.onMouseDown && !props.isFormSubmit

    return (
      <button
        type={props.isFormSubmit ? 'submit' : 'button'}
        className={classNames(
          'outline-none text-left',
          props.fullWidth && 'w-full',
          doesNothing && 'cursor-default'
        )}
        style={props.style}
        onClick={disabled ? undefined : onClick}
        onMouseDown={disabled ? undefined : onMouseDown}
        disabled={disabled}
        role={props.role}
        title={props.title}
        data-cy-id={props['data-cy-id']}
        data-cy-loading={props.loading}
        aria-labelledby={props.ariaLabelledBy}
        aria-current={props.ariaCurrent}
      >
        <div tabIndex={-1} className={classNames('outline-none', className)}>
          {loading ? (
            <div className="relative">
              <div className="opacity-0">{props.children}</div>
              <div className="w-full absolute h-full top-0 flex justify-center items-center">
                <Spinner
                  height={26}
                  width={26}
                  title={<Trans id="general.loading">Loading</Trans>}
                  className="block stroke-current"
                />
              </div>
            </div>
          ) : (
            props.children
          )}
        </div>
      </button>
    )
  }
}
