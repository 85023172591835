import React from 'react'
import classNames from 'classnames'

interface CodeProps {
  className?: string
}

export const Code: React.FC<CodeProps> = ({ className, children }) => (
  <span
    className={classNames(
      'inline-block break-normal font-mono text-14 bg-grey-200 text-grey-900 p-4 border border-grey-300 rounded-sm',
      className
    )}
  >
    {children}
  </span>
)
