import getConfig from 'next/config'
import { setCookie as setNookie } from 'nookies'

const env = getConfig().publicRuntimeConfig.ENVIRONMENT

export const setCookie: typeof setNookie = function (
  ctx,
  name,
  value,
  options = {}
) {
  return setNookie(ctx, name, value, {
    ...options,

    // set SameSite to Lax in order to remain logged in when following
    // a link to dev portal from 3rd party sites
    sameSite: 'lax',

    // set all cookies for all paths
    path: '/',

    // set all cookies to secure when deployed
    secure: env !== 'local',
  })
}
