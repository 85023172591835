import classNames from 'classnames'
import React, { forwardRef } from 'react'
import {
  getImageUrl,
  ImageOptions,
  MediaFragmentWithFsId,
  resizeImage,
} from 'utils/url'

export interface ImageProps {
  src?: string
  id?: string
  media?: MediaFragmentWithFsId | null
  alt?: string
  className?: string
  size?: number
  opts?: ImageOptions
}

const getImageParams = (
  opts?: ImageOptions,
  size?: number,
  source?: string
) => {
  let sourceWidth, sourceHeight
  if (source) {
    try {
      const parsedUrl = new URL(source ?? '')
      const params = new URLSearchParams(parsedUrl.search)
      sourceWidth = params.get('width')
      sourceHeight = params.get('height')
    } catch (_) {}
  }

  const width = sourceWidth ?? opts?.width ?? size
  const height = sourceHeight ?? opts?.height ?? size

  const options = {
    width: width ? +width : undefined,
    height: height ? +height : undefined,
    resizingType: opts?.resizingType ?? 'fit',
  }

  const highDpiOptions = {
    width: width ? +width * 2 : undefined,
    height: height ? +height * 2 : undefined,
    resizingType: opts?.resizingType ?? 'fit',
  }

  return { width, height, options, highDpiOptions }
}

/**
 * Displays an image based on a media fragment (mostly from backend)
 * or from a source URL (images from CMS).
 *
 * Image dimensions are determined in the following order:
 * - width or height in source URL
 * - props.opts.width or props.opts.height with fallback on props.size
 */
export const Image = forwardRef<HTMLImageElement, ImageProps>(
  ({ src, id, media, alt, className, size, opts }, ref) => {
    const { width, height, options, highDpiOptions } = getImageParams(
      opts,
      size,
      src
    )
    const url = src
      ? resizeImage(src, options)
      : getImageUrl(media?.file?.id ?? id, options)

    const highDpiUrl = src
      ? resizeImage(src, highDpiOptions)
      : getImageUrl(media?.file?.id ?? id, highDpiOptions)

    return (
      <img
        srcSet={`${url}, ${highDpiUrl} 2x`}
        src={url}
        alt={alt ?? ''}
        className={classNames(
          'flex-shrink-0 overflow-hidden truncate text-10',
          className
        )}
        width={width}
        height={height}
        ref={ref}
      />
    )
  }
)
