import { t, Trans } from '@lingui/macro'
import Axios from 'axios'
import { Button, Checkbox, Dropzone, Input, Link, TextArea } from 'components'
import { ContactFormData } from 'pages/api/contact'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { ToastKind, useCurrentUserQuery } from 'types'
import { useAsyncCallback } from 'utils/handleError'
import { makeFullName, patterns } from 'utils/utils'
import { useIsLoggedIn } from 'utils/withAuth'
import { useToasts } from './Toast'

export interface ContactFormProps {
  source?: string
  onSubmit?: () => void
}

export const ContactForm: React.FC<ContactFormProps> = ({
  source,
  onSubmit,
}) => {
  const isLoggedIn = useIsLoggedIn()
  const currentUserQuery = useCurrentUserQuery({ skip: !isLoggedIn })
  const user = currentUserQuery.data?.currentUser

  const { register, setValue, errors, handleSubmit, formState, reset } =
    useForm<ContactFormData>()

  useEffect(() => {
    register({ name: 'attachment' })
  }, [register])

  const { addToast } = useToasts()

  const handleSubmitForm = useAsyncCallback(
    handleSubmit(async (data) => {
      const formData = new FormData()
      Object.entries(data).forEach(([key, value]) =>
        formData.append(key, value as any)
      )
      source && formData.append('source', source)
      user?.id && formData.append('userId', user.id)

      try {
        await Axios.post('/api/contact', formData)
        addToast({
          kind: ToastKind.Success,
          content: t`contactUs.formSubmitted`,
        })
        reset()
        onSubmit?.()
      } catch (ex) {
        addToast({
          kind: ToastKind.Error,
          content: t`error.somethingWentWrong.text`,
        })
      }
    }),
    [handleSubmit]
  )

  return (
    <form className="flex flex-col items-start" onSubmit={handleSubmitForm}>
      <Input
        name="name"
        defaultValue={makeFullName(user?.firstName, user?.lastName)}
        errors={errors.name}
        ref={register({
          required: t`form.error.required`,
        })}
        required
        data-cy-id="contactUs.form.name"
        label={<Trans id="contactUs.form.name">Name</Trans>}
      />
      <div className="mb-24" />

      <Input
        name="email"
        defaultValue={user?.email}
        errors={errors.email}
        ref={register({
          required: t`form.error.required`,
          pattern: {
            value: patterns.email,
            message: t`form.error.patternEmail`,
          },
        })}
        required
        data-cy-id="contactUs.form.email"
        label={<Trans id="contactUs.form.email">E-mail</Trans>}
      />
      <div className="mb-24" />

      <Input
        name="phone"
        errors={errors.phone}
        ref={register({
          required: t`form.error.required`,
          pattern: {
            value: patterns.phone,
            message: t`form.error.patternPhone`,
          },
        })}
        required
        data-cy-id="contactUs.form.phone"
        label={<Trans id="contactUs.form.phone">Phone</Trans>}
      />
      <div className="mb-24" />

      <Input
        name="company"
        errors={errors.company}
        ref={register}
        data-cy-id="contactUs.form.company"
        label={<Trans id="contactUs.form.company">Company</Trans>}
      />
      <div className="mb-24" />

      <Input
        name="subject"
        errors={errors.subject}
        ref={register({
          required: t`form.error.required`,
        })}
        required
        data-cy-id="contactUs.form.subject"
        label={<Trans id="contactUs.form.subject">Subject</Trans>}
      />
      <div className="mb-24" />

      <TextArea
        name="content"
        errors={errors.content}
        ref={register({
          required: t`form.error.required`,
        })}
        required
        data-cy-id="contactUs.form.content"
        label={<Trans id="contactUs.form.content">Content</Trans>}
        textAreaClassName="min-h-200"
      />
      <div className="mb-24" />

      <Dropzone
        label={<Trans id="contactUs.form.attachment">Attachment</Trans>}
        errors={errors.attachment}
        onSelectFile={(file) =>
          setValue('attachment', file, { shouldDirty: true })
        }
        data-cy-id="contactUs.form.attachment"
        mimeTypes={[
          'text/*',
          'image/png',
          'image/jpg',
          'image/jpeg',
          'application/pdf',
        ]}
      />
      <div className="mb-12" />

      <div className="flex items-center">
        <Checkbox
          name="consent"
          ref={register({
            required: t`form.error.required`,
          })}
          label={
            <Trans id="contactUs.form.consent">
              I consent to the Bank iD{' '}
              <Link
                href="/documents/privacy-policy"
                data-cy-id="contactUs.form.consent"
              >
                privacy policy
              </Link>
            </Trans>
          }
        />
      </div>
      {errors.consent?.message && (
        <p className="text-utility-error text-14 leading-20 italic py-6">
          <Trans id={errors.consent.message} />
        </p>
      )}
      <div className="mb-24" />

      <Button
        kind="primary"
        data-cy-id="contactUs.form.send"
        loading={formState.isSubmitting}
        isFormSubmit
      >
        <Trans id="contactUs.form.send">Send message</Trans>
      </Button>
    </form>
  )
}
