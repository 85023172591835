import cn from 'classnames'
import React from 'react'

interface TabsContainerProps {
  className?: string
  noBorder?: boolean
  noPadding?: boolean
}

export const TabsContainer: React.FC<TabsContainerProps> = ({
  children,
  className,
  noBorder,
  noPadding,
}) => {
  return (
    <div
      className={cn(
        'flex justify-between items-stretch w-full overflow-x-auto',
        !noBorder && 'border-b border-grey-300 ',
        !noPadding && 'px-25',
        className
      )}
    >
      {children}
    </div>
  )
}
