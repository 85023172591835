import { ApolloClient, useApolloClient } from '@apollo/client'
import { Trans } from '@lingui/macro'
import classNames from 'classnames'
import React, { useEffect, useCallback } from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { v4 } from 'uuid'
import { Clickable } from 'components'
import {
  useToastsQuery,
  ToastsQuery,
  ToastsDocument,
  Toast,
  ToastKind,
} from 'types'
import CloseSvg from 'icons/close.svg'
import css from './Toast.module.css'

interface ToastComponentProps {
  kind?: ToastKind
  onRemove?: () => void
  timeout?: number | null
  title?: boolean
  className?: string
}

export const ToastComponent: React.FC<ToastComponentProps> = ({
  kind,
  onRemove,
  timeout,
  title,
  children,
  className,
}) => {
  useEffect(() => {
    const timer = timeout
      ? setTimeout(() => {
          onRemove?.()
        }, timeout)
      : undefined

    return () => {
      timer && clearTimeout(timer)
    }
  }, [onRemove, timeout])

  return (
    <div
      className={classNames(
        kind === ToastKind.Info && 'bg-white-100 text-black-600',
        kind === ToastKind.Error && 'bg-utility-error text-white-100',
        kind === ToastKind.Warning && 'bg-utility-danger text-black-600',
        kind === ToastKind.Success && 'bg-utility-success text-black-600',
        'px-24 py-16 mb-32 relative flex justify-between rounded-8 pointer-events-auto shadow',
        className
      )}
      data-cy-id="toast"
      data-cy-kind={kind}
    >
      <div>
        <div className="flex items-center">
          {title && (
            <p className="text-16">
              {kind === ToastKind.Error && (
                <Trans id="common.error">Error</Trans>
              )}
              {kind === ToastKind.Warning && (
                <Trans id="common.warning">Warning</Trans>
              )}
              {kind === ToastKind.Success && (
                <Trans id="common.success">Success</Trans>
              )}
            </p>
          )}
        </div>
        <div className="mt-1 text-14">{children}</div>
      </div>
      <Clickable onClick={onRemove} className="p-16 absolute top-0 right-0">
        <CloseSvg
          className={classNames(
            kind === ToastKind.Error ? 'fill-white-100' : 'text-black-600'
          )}
        />
      </Clickable>
    </div>
  )
}

ToastComponent.defaultProps = {
  title: true,
}

export interface ToastOptions {
  timeout?: number
  kind?: ToastKind
  content?: string
}

export function removeToast(id: string, apolloClient: ApolloClient<unknown>) {
  const data = apolloClient.readQuery<ToastsQuery>({ query: ToastsDocument })
  apolloClient.writeQuery({
    query: ToastsDocument,
    data: {
      toasts: data?.toasts.filter((toast) => toast.id !== id),
    },
  })
}

export function addToast(
  toast: ToastOptions,
  apolloClient: ApolloClient<unknown>
) {
  const newToast: Toast = {
    __typename: 'Toast',
    id: v4(),
    timeout: toast.timeout || 2000,
    kind: toast.kind || ToastKind.Info,
    content: toast.content || '',
  }

  const data = apolloClient.readQuery<ToastsQuery>({ query: ToastsDocument })
  apolloClient.writeQuery({
    query: ToastsDocument,
    data: {
      toasts: [...(data?.toasts || []), newToast],
    },
  })

  if (toast.timeout) {
    setTimeout(() => {
      removeToast(newToast.id, apolloClient)
    }, toast.timeout)
  }

  return newToast
}

export function useToasts() {
  let apollo: ApolloClient<unknown> | undefined = undefined

  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    apollo = useApolloClient()
  } catch (_) {}

  if (!apollo) {
    throw new Error(
      'useToasts: No Apollo client was found. Ensure there is an ApolloContext.Provider higher up in the component tree.'
    )
  }

  return {
    addToast: (toast: ToastOptions) => addToast(toast, apollo!),
    removeToast: (id: string) => removeToast(id, apollo!),
  }
}

export function useToast() {
  const apollo = useApolloClient()

  return useCallback(
    (content: string, opts: Omit<ToastOptions, 'content'> = {}) =>
      addToast({ ...opts, content }, apollo),
    [apollo]
  )
}

export const ToastContainer: React.FC = () => {
  let toasts: Toast[] = []
  const { removeToast } = useToasts()

  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const toastsQuery = useToastsQuery()
    toasts = toastsQuery.data?.toasts || []
  } catch (_) {
    return null
  }

  return (
    <div className="fixed top-0 right-0 pt-100 px-50 z-50 pointer-events-none overflow-x-hidden">
      <TransitionGroup className="flex flex-col items-end">
        {toasts.map((toast) => (
          <CSSTransition
            timeout={300}
            classNames={{
              enter: css['toastTransition-enter'],
              enterActive: css['toastTransition-enter-active'],
              exit: css['toastTransition-exit'],
              exitActive: css['toastTransition-exit-active'],
            }}
            key={toast.id}
            unmountOnExit
          >
            <ToastComponent
              kind={toast.kind}
              timeout={toast.timeout}
              onRemove={() => removeToast(toast.id)}
            >
              {toast.content && <Trans id={toast.content} />}
            </ToastComponent>
          </CSSTransition>
        ))}
      </TransitionGroup>
    </div>
  )
}
