import { useApolloClient } from '@apollo/client'
import { NextPage, NextPageContext } from 'next'
import App from 'next/app'
import getConfig from 'next/config'
import { parseCookies } from 'nookies'
import qs from 'qs'
import React, { useContext } from 'react'
import { parseTokens, refreshTokens, saveTokens, Tokens } from './auth'
import { useAsyncCallback } from './handleError'
import { Router } from './router'

type AuthContextType = {
  tokens?: Tokens
}

const AuthContext = React.createContext<AuthContextType>(null!)

export const useAuthContext = () => useContext(AuthContext)
export const useIsLoggedIn = () => !!useAuthContext().tokens?.accessToken
export const useIsBank = () =>
  useContext(
    AuthContext
  ).tokens?.accessToken?.parsed?.realm_access?.roles?.includes('bank')

const baseURL = getConfig().publicRuntimeConfig.BASE_URL

const authServerBaseURL = `${
  getConfig().publicRuntimeConfig.KEYCLOAK_ISSUER
}/protocol/openid-connect`
const client_id = getConfig().publicRuntimeConfig.KEYCLOAK_CLIENT_ID

export const api = {
  logoutUrl: (idToken: string) =>
    `${authServerBaseURL}/logout${qs.stringify(
      { id_token_hint: idToken },
      { addQueryPrefix: true }
    )}`,
  loginUrl: (locale?: string, state?: string, email?: string | null) =>
    `${authServerBaseURL}/auth${qs.stringify(
      {
        redirect_uri: baseURL + '/api/token',
        client_id,
        scope: 'openid profile email',
        response_type: 'code',
        response_mode: 'query',
        ui_locales: locale,
        state,
        email,
      },
      { addQueryPrefix: true }
    )}`,
  registerUrl: (locale?: string, email?: string | null) =>
    `${authServerBaseURL}/registrations${qs.stringify(
      {
        redirect_uri: baseURL + '/api/token',
        client_id,
        scope: 'openid profile email',
        response_type: 'code',
        response_mode: 'query',
        ui_locales: locale,
        email,
      },
      { addQueryPrefix: true }
    )}`,
}

export const useLogout = (noRedirect?: boolean) => {
  const apollo = useApolloClient()
  const { tokens } = useAuthContext()

  return useAsyncCallback(async () => {
    saveTokens(undefined, undefined)

    if (!noRedirect && tokens?.idToken) {
      Router.replaceRoute(api.logoutUrl(tokens.idToken.token))
    } else {
      await apollo.clearStore()
      window.localStorage.clear()
    }
  }, [apollo, tokens, saveTokens])
}

export type CustomNextPageContext = NextPageContext & {
  ctx: NextPageContext & { res: { accessToken: string | undefined } }
}

type WithAuthProps = {
  tokens?: Tokens
}

const withAuth = (PageComponent: NextPage) => {
  const WithAuth = ({ tokens, ...props }: WithAuthProps) => (
    <AuthContext.Provider value={{ tokens }}>
      <PageComponent {...props} />
    </AuthContext.Provider>
  )

  if (PageComponent.getInitialProps) {
    WithAuth.getInitialProps = async (ctx: CustomNextPageContext) => {
      const inAppContext = Boolean(ctx)

      let pageProps = {}
      if (PageComponent.getInitialProps) {
        pageProps = await PageComponent.getInitialProps(ctx)
      } else if (inAppContext) {
        pageProps = await App.getInitialProps(ctx as any)
      }

      const context = typeof window === 'undefined' ? ctx.ctx : undefined
      const cookies = parseCookies(context)
      let tokens = parseTokens(cookies)
      const refreshToken =
        typeof tokens?.refreshToken === 'string'
          ? tokens.refreshToken
          : undefined

      if (!tokens?.accessToken && tokens?.refreshTokenExp) {
        try {
          tokens = await refreshTokens(context, refreshToken)
        } catch (ex) {}

        // when refreshed server side withApollo and _document.tsx can't access it
        // because the client hasn't set it yet to cookies, we store it in accessToken variable
        if (tokens.accessToken && ctx.ctx.res) {
          ctx.ctx.res.accessToken = tokens.accessToken.token
        }
      }

      return {
        ...pageProps,
        tokens,
      }
    }
  }

  return WithAuth
}

export default withAuth
