import { t, Trans } from '@lingui/macro'
import cn from 'classnames'
import {
  Clickable,
  Collapsible,
  Image,
  Input,
  Link,
  Tooltip,
  useLightBox,
  ValidationContainer,
} from 'components'
import { QueryLoader, useToasts } from 'containers'
import qs from 'qs'
import React, { useMemo, useState } from 'react'
import {
  AppCredentialsFragment,
  AppEnvironmentType,
  ToastKind,
  useAppCredentialsQuery,
  useConnectedAppCredentialsQuery,
  useUnblockAppEnvironmentNotificationUriMutation,
} from 'types'
import { getImageUrl } from 'utils/url'
import { v4 } from 'uuid'
import { AppCredentialsState } from './AppCredentialsState'
import RetryIcon from 'icons/retry.svg'
import { useAsyncCallback } from 'utils/handleError'

function generateTryItUri(credentials: AppCredentialsFragment['credentials']) {
  if (!credentials || !credentials.discovery.authorization_endpoint) {
    return undefined
  }

  const query = qs.stringify(
    {
      client_id: credentials?.clientId,
      redirect_uri: credentials.redirect_uris[0],
      scope: credentials.scope.filter((x) => !x.includes('sign.')).join(' '),
      response_type: credentials.response_types
        .map((x) => x.toLocaleLowerCase())
        .filter((x) => x !== 'id_token')
        .join(' '),
      state: 'Bank iD works!',
      nonce: v4(),
      prompt: 'login',
      display: 'page',
      acr_values: credentials.default_acr_values[0]?.toLowerCase(),
    },
    { addQueryPrefix: true }
  )

  return credentials?.discovery.authorization_endpoint + query
}

interface AppCredentialsFieldsProps {
  environment: AppCredentialsFragment
  env: AppEnvironmentType
  activeProducts: {
    slug: string
    name: string
  }[]
  refetch: () => void
}

const AppCredentialsFields: React.FC<AppCredentialsFieldsProps> = ({
  environment,
  env,
  activeProducts,
  refetch,
}) => {
  const credentials = environment.credentials
  const discovery = credentials?.discovery

  const [showPreview, ref] = useLightBox()

  const [showSecret, setShowSecret] = useState(false)
  const tryItUri = useMemo(() => generateTryItUri(credentials), [credentials])

  const { addToast } = useToasts()

  const [unblockNotifUri] = useUnblockAppEnvironmentNotificationUriMutation()
  const handleUnblockNotifUri = useAsyncCallback(async () => {
    if (!environment?.id) {
      throw 'Invalid app environment ID'
    }

    await unblockNotifUri({ variables: { appEnvId: environment?.id } }).then(
      () => {
        addToast({
          kind: ToastKind.Success,
          timeout: 7000,
          content: t`app.environment.credentials.notification_uri.unblockSuccess`,
        })
        refetch()
      }
    )
  }, [unblockNotifUri])

  return (
    <>
      <h3 className="text-18 font-medium mb-16">
        <Trans id="app.environment.credentials.keys">Keys</Trans>
      </h3>

      <Input
        className="my-10"
        labelPosition="left"
        readOnly
        clipboard
        label={
          <Trans id="app.environment.credentials.client_id">Client ID</Trans>
        }
        labelTooltip={t`app.environment.credentials.client_id.tooltip`}
        data-cy-id="app.environment.credentials.client_id"
        value={credentials?.clientId}
      />

      {credentials?.clientSecret && (
        <>
          {showSecret && (
            <Input
              className="my-10"
              labelPosition="left"
              readOnly
              clipboard
              label={
                <Trans id="app.environment.credentials.client_secret">
                  Client secret
                </Trans>
              }
              labelTooltip={t`app.environment.credentials.client_secret.tooltip`}
              data-cy-id="app.environment.credentials.client_secret"
              value={credentials?.clientSecret}
            />
          )}
          <ValidationContainer
            label={
              !showSecret ? (
                <Trans id="app.environment.credentials.client_secret">
                  Client secret
                </Trans>
              ) : (
                <span />
              )
            }
            labelPosition="left"
            labelTooltip={
              !showSecret
                ? t`app.environment.credentials.client_secret.tooltip`
                : undefined
            }
            className="py-10 text-14"
          >
            <Link
              onClick={() => setShowSecret((s) => !s)}
              className="py-10 pl-8"
              data-cy-id="app.environment.credentials.clientSecretButton"
            >
              {showSecret ? (
                <Trans id="app.environment.credentials.hideClientSecretButton">
                  Hide client secret
                </Trans>
              ) : (
                <Trans id="app.environment.credentials.showClientSecretButton">
                  Show client secret
                </Trans>
              )}
            </Link>
          </ValidationContainer>
        </>
      )}

      <h3 className="text-18 font-medium mt-38 my-10">
        <Trans id="app.credentials.credentials.examples">Examples</Trans>
      </h3>

      {tryItUri && (
        <Input
          className="my-10"
          labelPosition="left"
          readOnly
          clipboard
          label={
            <Trans id="app.environment.credentials.try_it">
              Authorization URI
            </Trans>
          }
          data-cy-id="app.environment.credentials.try_it"
          value={tryItUri}
        />
      )}

      {credentials?.discovery && (
        <Collapsible
          className="mt-28"
          defaultCollapsed={true}
          data-cy-id="app.environment.credentials.toggleDiscovery"
          heading={
            <h3 className="text-18 font-medium my-10 mr-8">
              <Trans id="app.environment.credentials.discovery">
                OIDC Discovery
              </Trans>
            </h3>
          }
        >
          <Input
            className="my-10"
            labelPosition="left"
            readOnly
            clipboard
            label={
              <Trans id="app.environment.credentials.issuer">Issuer</Trans>
            }
            labelTooltip={t`app.environment.credentials.issuer.tooltip`}
            data-cy-id="app.environment.credentials.issuer"
            value={discovery?.issuer}
          />

          <Input
            className="my-10"
            labelPosition="left"
            readOnly
            clipboard
            label={
              <Trans id="app.environment.credentials.authorizationEndpoint">
                Authorization endpoint
              </Trans>
            }
            labelTooltip={t`app.environment.credentials.authorizationEndpoint.tooltip`}
            data-cy-id="app.environment.credentials.authorizationEndpoint"
            value={discovery?.authorization_endpoint}
          />

          <Input
            className="my-10"
            labelPosition="left"
            readOnly
            clipboard
            label={
              <Trans id="app.environment.credentials.tokenEndpoint">
                Token endpoint
              </Trans>
            }
            labelTooltip={t`app.environment.credentials.tokenEndpoint.tooltip`}
            data-cy-id="app.environment.credentials.tokenEndpoint"
            value={discovery?.token_endpoint}
          />

          <Input
            className="my-10"
            labelPosition="left"
            readOnly
            clipboard
            label={
              <Trans id="app.environment.credentials.userinfoEndpoint">
                Userinfo endpoint
              </Trans>
            }
            labelTooltip={t`app.environment.credentials.userinfoEndpoint.tooltip`}
            data-cy-id="app.environment.credentials.userinfoEndpoin"
            value={discovery?.userinfo_endpoint}
          />

          <Input
            className="my-10"
            labelPosition="left"
            readOnly
            clipboard
            label={
              <Trans id="app.environment.credentials.profileEndpoint">
                Profile endpoint
              </Trans>
            }
            labelTooltip={t`app.environment.credentials.profileEndpoint.tooltip`}
            data-cy-id="app.environment.credentials.profileEndpoint"
            value={discovery?.profile_endpoint}
          />

          <Input
            className="my-10"
            labelPosition="left"
            readOnly
            clipboard
            label={
              <Trans id="app.environment.credentials.introspectionEndpoint">
                Introspection endpoint
              </Trans>
            }
            labelTooltip={t`app.environment.credentials.introspectionEndpoint.tooltip`}
            data-cy-id="app.environment.credentials.introspectionEndpoint"
            value={discovery?.introspection_endpoint}
          />

          <Input
            className="my-10"
            labelPosition="left"
            readOnly
            clipboard
            label={
              <Trans id="app.environment.credentials.rosEndpoint">
                ROS endpoint
              </Trans>
            }
            labelTooltip={t`app.environment.credentials.rosEndpoint.tooltip`}
            data-cy-id="app.environment.credentials.rosEndpoint"
            value={discovery?.ros_endpoint}
          />

          <Input
            className="my-10"
            labelPosition="left"
            readOnly
            clipboard
            label={
              <Trans id="app.environment.credentials.authorizeEndpoint">
                Authorize (Sign) endpoint
              </Trans>
            }
            labelTooltip={t`app.environment.credentials.authorizeEndpoint.tooltip`}
            data-cy-id="app.environment.credentials.authorizeEndpoint"
            value={discovery?.authorize_endpoint}
          />

          <ValidationContainer
            label={
              <Trans id="app.environment.credentials.requestParameterSupported">
                Request parameter supported
              </Trans>
            }
            labelPosition="left"
            labelTooltip={t`app.environment.credentials.requestParameterSupported.tooltip`}
            className="py-10"
          >
            <div className="font-mono text-14 leading-relaxed text-primary-dark bg-grey-200 rounded border border-grey-300 py-4 px-8">
              {discovery?.request_parameter_supported ? 'true' : 'false'}
            </div>
          </ValidationContainer>

          <ValidationContainer
            label={
              <Trans id="app.environment.credentials.claimsParameterSupported">
                Claims parameter supported
              </Trans>
            }
            labelPosition="left"
            labelTooltip={t`app.environment.credentials.claimsParameterSupported.tooltip`}
            className="py-10"
          >
            <div className="font-mono text-14 leading-relaxed text-primary-dark bg-grey-200 rounded border border-grey-300 py-4 px-8">
              {discovery?.claims_parameter_supported ? 'true' : 'false'}
            </div>
          </ValidationContainer>

          <Input
            className="my-10"
            labelPosition="left"
            readOnly
            clipboard
            label={
              <Trans id="app.environment.credentials.serviceDocumentation">
                Service documentation
              </Trans>
            }
            labelTooltip={t`app.environment.credentials.serviceDocumentation.tooltip`}
            data-cy-id="app.environment.credentials.serviceDocumentation"
            value={discovery?.service_documentation}
          />

          <Input
            className="my-10"
            labelPosition="left"
            readOnly
            clipboard
            label={
              <Trans id="app.environment.credentials.deviceAuthorizationEndpoint">
                Device authorization endpoint
              </Trans>
            }
            labelTooltip={t`app.environment.credentials.deviceAuthorizationEndpoint.tooltip`}
            data-cy-id="app.environment.credentials.deviceAuthorizationEndpoint"
            value={discovery?.device_authorization_endpoint}
          />

          <ValidationContainer
            label={
              <Trans id="app.environment.credentials.claimsSupported">
                Claims supported
              </Trans>
            }
            labelTooltip={t`app.environment.credentials.claimsSupported.tooltip`}
            labelPosition="left"
            className="py-10"
          >
            <div className="flex font-mono text-14 leading-relaxed text-primary-dark flex-wrap">
              {(discovery?.claims_supported?.length
                ? discovery.claims_supported
                : ['empty']
              ).map((claim) => (
                <div
                  className="bg-grey-200 rounded border border-grey-300 py-4 px-8 mr-8 my-4"
                  key={claim}
                >
                  {claim}
                </div>
              ))}
            </div>
          </ValidationContainer>

          <Input
            className="my-10"
            labelPosition="left"
            readOnly
            clipboard
            label={
              <Trans id="app.environment.credentials.endSessionEndpoint">
                End session endpoint
              </Trans>
            }
            labelTooltip={t`app.environment.credentials.endSessionEndpoint.tooltip`}
            data-cy-id="app.environment.credentials.endSessionEndpoint"
            value={discovery?.end_session_endpoint}
          />

          <Input
            className="my-10"
            labelPosition="left"
            readOnly
            clipboard
            label={
              <Trans id="app.environment.credentials.opTosUri">
                OP Terms of Service URI
              </Trans>
            }
            labelTooltip={t`app.environment.credentials.opTosUri.tooltip`}
            data-cy-id="app.environment.credentials.opTosUri"
            value={discovery?.op_tos_uri}
          />

          <Input
            className="my-10"
            labelPosition="left"
            readOnly
            clipboard
            label={
              <Trans id="app.environment.credentials.jwksUri">JWKs URI</Trans>
            }
            labelTooltip={t`app.environment.credentials.jwksUri.tooltip`}
            data-cy-id="app.environment.credentials.jwksUri"
            value={discovery?.jwks_uri}
          />
        </Collapsible>
      )}

      {env === AppEnvironmentType.Production && (
        <>
          <h3 className="text-18 font-medium mt-38 my-10">
            <Trans id="app.credentials.credentials.productConfig">
              Product config
            </Trans>
          </h3>
          <ValidationContainer
            label={
              <Trans id="app.environment.credentials.activeProduct.name">
                Active Product
              </Trans>
            }
            labelPosition="left"
            labelTooltip={t`app.environment.credentials.activeProduct.name.tooltip`}
            className="py-10"
          >
            <div className="font-mono text-14 leading-relaxed text-primary-dark flex">
              {activeProducts?.map((product) => (
                <div
                  className="bg-grey-200 rounded border border-grey-300 py-4 px-8 mr-8"
                  key={product.slug}
                >
                  {product.name}
                </div>
              ))}
            </div>
          </ValidationContainer>
        </>
      )}

      <h3 className="text-18 font-medium mt-38 my-10">
        <Trans id="app.credentials.credentials.environmentConfig">
          Environment config
        </Trans>
      </h3>
      {credentials?.redirect_uris.map((uri, i, arr) => (
        <Input
          className={cn(i === arr.length - 1 ? 'mt-4 mb-10' : 'my-4')}
          containerClassName="justify-end"
          labelPosition="left"
          readOnly
          clipboard
          label={
            i === 0 ? (
              <Trans id="app.environment.credentials.redirect_uris">
                Redirect URIs
              </Trans>
            ) : (
              <span />
            )
          }
          labelTooltip={
            i === 0 && t`app.environment.credentials.redirect_uris.tooltip`
          }
          value={uri}
          key={uri}
          data-cy-id="app.environment.credentials.redirect_uris"
        />
      ))}

      {credentials?.sector_identifier_uri && (
        <Input
          className="my-10"
          labelPosition="left"
          readOnly
          clipboard
          label={
            <Trans id="app.environment.credentials.sector_identifier_uri">
              Sector Identifier URI
            </Trans>
          }
          labelTooltip={t`app.environment.credentials.sector_identifier_uri.tooltip`}
          data-cy-id="app.environment.credentials.sector_identifier_uri"
          value={credentials?.sector_identifier_uri}
        />
      )}

      {!!credentials?.response_types.length && (
        <ValidationContainer
          label={
            <Trans id="app.environment.credentials.response_types">
              Response Types
            </Trans>
          }
          labelPosition="left"
          labelTooltip={t`app.environment.credentials.response_types.tooltip`}
          className="py-10"
        >
          <div className="flex font-mono text-14 leading-relaxed text-primary-dark">
            {credentials?.response_types.map((type) => (
              <div
                className="bg-grey-200 rounded border border-grey-300 py-4 px-8 mr-8"
                key={type}
              >
                {type.toLocaleLowerCase()}
              </div>
            ))}
          </div>
        </ValidationContainer>
      )}

      {!!credentials?.grant_types.length && (
        <ValidationContainer
          label={
            <Trans id="app.environment.credentials.grant_types">
              Grant Types
            </Trans>
          }
          labelPosition="left"
          labelTooltip={t`app.environment.credentials.grant_types.tooltip`}
          className="py-10"
        >
          <div className="flex font-mono text-14 leading-relaxed text-primary-dark">
            {credentials?.grant_types.map((type) => (
              <div
                className="bg-grey-200 rounded border border-grey-300 py-4 px-8 mr-8"
                key={type}
              >
                {type.toLocaleLowerCase()}
              </div>
            ))}
          </div>
        </ValidationContainer>
      )}

      <Input
        containerClassName="py-10"
        labelPosition="left"
        readOnly
        clipboard
        label={
          <Trans id="app.environment.credentials.client_name">
            Client Application Name
          </Trans>
        }
        labelTooltip={t`app.environment.credentials.client_name.tooltip`}
        data-cy-id="app.environment.credentials.client_name"
        value={credentials?.client_name}
      />

      <Input
        containerClassName="py-10"
        labelPosition="left"
        readOnly
        clipboard
        label={
          <Trans id="app.environment.credentials.client_provider_name">
            Client Provider Name
          </Trans>
        }
        labelTooltip={t`app.environment.credentials.client_provider_name.tooltip`}
        data-cy-id="app.environment.credentials.client_provider_name"
        value={credentials?.client_provider_name}
      />

      <ValidationContainer
        label={
          <Trans id="app.environment.config.client_type">Client Type</Trans>
        }
        labelPosition="left"
        labelTooltip={t`app.environment.config.client_type.tooltip`}
        className="py-10"
      >
        <div className="font-mono text-14 leading-relaxed text-primary-dark">
          <div className="bg-grey-200 rounded border border-grey-300 py-4 px-8 mr-8">
            {credentials?.client_type.toLocaleLowerCase()}
          </div>
        </div>
      </ValidationContainer>

      <ValidationContainer
        label={<Trans id="app.environment.credentials.logo_id">Logo</Trans>}
        labelPosition="left"
        labelTooltip={t`app.environment.credentials.logo_id.tooltip`}
        className="my-10"
      >
        <div className="w-32 h-32 rounded bg-grey-200 border border-grey-300">
          {credentials?.logo_id && (
            <Link
              kind="plain"
              onClick={showPreview?.(getImageUrl(credentials.logo_id))}
              data-cy-id="app.environment.credentials.logo.showPreview"
            >
              <Image
                media={credentials.logo_id}
                size={32}
                className="rounded object-cover"
                alt={t`app.environment.credentials.logo_id.alt`}
                ref={ref}
              />
            </Link>
          )}
        </div>
      </ValidationContainer>

      <Input
        className="my-10"
        labelPosition="left"
        readOnly
        clipboard
        label={
          <Trans id="app.environment.credentials.client_uri">Client URI</Trans>
        }
        labelTooltip={t`app.environment.credentials.client_uri.tooltip`}
        data-cy-id="app.environment.credentials.client_uri"
        value={credentials?.client_uri}
      />

      {credentials?.tos_uri && (
        <Input
          className="my-10"
          labelPosition="left"
          readOnly
          clipboard
          label={
            <Trans id="app.environment.credentials.tos_uri">
              Terms of Service URI
            </Trans>
          }
          labelTooltip={t`app.environment.credentials.tos_uri.tooltip`}
          data-cy-id="app.environment.credentials.tos_uri"
          value={credentials?.tos_uri}
        />
      )}

      {credentials?.policy_uri && (
        <Input
          className="my-10"
          labelPosition="left"
          readOnly
          clipboard
          label={
            <Trans id="app.environment.credentials.policy_uri">
              Data Usage Policy URI
            </Trans>
          }
          labelTooltip={t`app.environment.credentials.policy_uri.tooltip`}
          data-cy-id="app.environment.credentials.policy_uri"
          value={credentials?.policy_uri}
        />
      )}

      {credentials?.jwks_uri && (
        <Input
          className="my-10"
          labelPosition="left"
          readOnly
          clipboard
          label={
            <Trans id="app.environment.credentials.jwks_uri">JWKs URI</Trans>
          }
          labelTooltip={t`app.environment.credentials.jwks_uri.tooltip`}
          data-cy-id="app.environment.credentials.jwks_uri"
          value={credentials?.jwks_uri}
        />
      )}

      {credentials?.request_uris &&
        credentials?.request_uris.map((uri, i, arr) => (
          <Input
            className={cn(i === arr.length - 1 ? 'mt-4 mb-10' : 'my-4')}
            containerClassName="justify-end"
            labelPosition="left"
            readOnly
            clipboard
            label={
              i === 0 ? (
                <Trans id="app.environment.credentials.request_uris">
                  Request URIs
                </Trans>
              ) : (
                <span />
              )
            }
            labelTooltip={
              i === 0 && t`app.environment.credentials.request_uris.tooltip`
            }
            value={uri}
            key={uri}
            data-cy-id="app.environment.credentials.request_uris"
          />
        ))}

      <ValidationContainer
        label={
          <Trans id="app.environment.credentials.requiredScope">
            Required scope
          </Trans>
        }
        labelPosition="left"
        labelTooltip={t`app.environment.credentials.requiredScope.tooltip`}
        className="py-10"
      >
        <div className="flex flex-wrap font-mono text-14 leading-relaxed text-primary-dark">
          {(credentials?.required_scope?.length
            ? credentials.required_scope
            : ['empty']
          ).map((scope) => (
            <div
              className="bg-grey-200 rounded border border-grey-300 py-4 px-8 mr-8 mb-10"
              key={scope}
            >
              {scope}
            </div>
          ))}
        </div>
      </ValidationContainer>

      <ValidationContainer
        label={<Trans id="app.environment.credentials.scope">Scope</Trans>}
        labelPosition="left"
        labelTooltip={t`app.environment.credentials.scope.tooltip`}
        className="py-10"
      >
        <div className="flex flex-wrap font-mono text-14 leading-relaxed text-primary-dark">
          {(credentials?.scope?.length ? credentials.scope : ['empty']).map(
            (scope) => (
              <div
                className="bg-grey-200 rounded border border-grey-300 py-4 px-8 mr-8 mb-10"
                key={scope}
              >
                {scope}
              </div>
            )
          )}
        </div>
      </ValidationContainer>

      {credentials?.notification_uri && (
        <div className="flex flex-row items-center">
          <Input
            className="my-10"
            labelPosition="left"
            readOnly
            clipboard
            label={
              <Trans id="app.environment.credentials.notification_uri">
                Notification URI
              </Trans>
            }
            labelTooltip={t`app.environment.credentials.notification_uri.tooltip`}
            data-cy-id="app.environment.credentials.notification_uri"
            value={credentials?.notification_uri}
            errors={
              environment?.isNotificationUriBlacklisted
                ? [t`app.environment.credentials.notification_uri.blocked`]
                : undefined
            }
          />
          {environment?.isNotificationUriBlacklisted && (
            <Tooltip
              kind="dark"
              displayTip={
                <Trans
                  id={'app.environment.credentials.notification_uri.blocked'}
                />
              }
              id="billing.warning"
              className="ml-10"
            >
              <Clickable data-cy-id="" onClick={handleUnblockNotifUri}>
                <RetryIcon />
              </Clickable>
            </Tooltip>
          )}
        </div>
      )}

      <ValidationContainer
        label={
          <Trans id="app.environment.credentials.token_endpoint_auth_method">
            Token Endpoint
            <br />
            Authorization Method
          </Trans>
        }
        labelPosition="left"
        labelTooltip={t`app.environment.credentials.token_endpoint_auth_method.tooltip`}
        className="py-10"
      >
        <div className="font-mono text-14 leading-relaxed text-primary-dark">
          <div className="bg-grey-200 rounded border border-grey-300 py-4 px-8 mr-8">
            {credentials?.token_endpoint_auth_method.toLocaleLowerCase()}
          </div>
        </div>
      </ValidationContainer>

      {!!credentials?.tax_number && (
        <ValidationContainer
          label={
            <Trans id="app.environment.credentials.tax_number">Tax ID</Trans>
          }
          labelPosition="left"
          labelTooltip={t`app.environment.credentials.tax_number.tooltip`}
          className="py-10"
        >
          <div className="font-mono text-14 leading-relaxed text-primary-dark">
            <div
              className="bg-grey-200 rounded border border-grey-300 py-4 px-8 mr-8"
              data-cy-id="tapp.environment.credentials.ax_number"
            >
              {credentials?.tax_number}
            </div>
          </div>
        </ValidationContainer>
      )}

      {credentials?.initiate_login_uri && (
        <Input
          className="my-10"
          labelPosition="left"
          readOnly
          clipboard
          label={
            <Trans id="app.environment.credentials.initiate_login_uri">
              Initiate Login URI
            </Trans>
          }
          labelTooltip={t`app.environment.credentials.initiate_login_uri.tooltip`}
          data-cy-id="app.environment.credentials.initiate_login_uri"
          value={credentials?.initiate_login_uri}
        />
      )}

      {credentials?.backchannel_logout_uri && (
        <Input
          className="my-10"
          labelPosition="left"
          readOnly
          clipboard
          label={
            <Trans id="app.environment.credentials.backchannel_logout_uri">
              Backchannel Logout URI
            </Trans>
          }
          labelTooltip={t`app.environment.credentials.backchannel_logout_uri.tooltip`}
          data-cy-id="app.environment.credentials.backchannel_logout_uri"
          value={credentials?.backchannel_logout_uri}
        />
      )}

      {credentials?.frontchannel_logout_uri && (
        <Input
          className="my-10"
          labelPosition="left"
          readOnly
          clipboard
          label={
            <Trans id="app.environment.credentials.frontchannel_logout_uri">
              Frontchannel Logout URI
            </Trans>
          }
          labelTooltip={t`app.environment.credentials.frontchannel_logout_uri.tooltip`}
          data-cy-id="app.environment.credentials.frontchannel_logout_uri"
          value={credentials?.frontchannel_logout_uri}
        />
      )}

      {credentials?.post_logout_redirect_uris &&
        credentials?.post_logout_redirect_uris.map((uri, i, arr) => (
          <Input
            className={cn(i === arr.length - 1 ? 'mt-4 mb-10' : 'my-4')}
            containerClassName="justify-end"
            labelPosition="left"
            readOnly
            clipboard
            label={
              i === 0 ? (
                <Trans id="app.environment.credentials.post_logout_redirect_uris">
                  Post Logout Redirect URIs
                </Trans>
              ) : (
                <span />
              )
            }
            labelTooltip={
              i === 0 &&
              t`app.environment.credentials.post_logout_redirect_uris.tooltip`
            }
            value={uri}
            key={uri}
            data-cy-id="app.environment.credentials.post_logout_redirect_uris"
          />
        ))}

      {credentials?.require_auth_time && (
        <ValidationContainer
          label={
            <Trans id="app.environment.credentials.require_auth_time">
              Require Auth Time
            </Trans>
          }
          labelPosition="left"
          labelTooltip={t`app.environment.credentials.require_auth_time.tooltip`}
          className="py-10"
        >
          <div className="font-mono text-14 leading-relaxed text-primary-dark">
            <div
              className="bg-grey-200 rounded border border-grey-300 py-4 px-8 mr-8"
              data-cy-id="app.environment.credentials.require_auth_time"
            >
              {credentials?.require_auth_time + ''}
            </div>
          </div>
        </ValidationContainer>
      )}

      {credentials?.code_challenge_method && (
        <ValidationContainer
          label={
            <Trans id="app.environment.credentials.code_challenge_method">
              Code Challenge Method
            </Trans>
          }
          labelPosition="left"
          labelTooltip={t`app.environment.credentials.code_challenge_method.tooltip`}
          className="py-10"
        >
          <div className="font-mono text-14 leading-relaxed text-primary-dark">
            <div
              className="bg-grey-200 rounded border border-grey-300 py-4 px-8 mr-8"
              data-cy-id="app.environment.credentials.code_challenge_method"
            >
              {credentials?.code_challenge_method}
            </div>
          </div>
        </ValidationContainer>
      )}

      {credentials?.profile_signed_response_alg && (
        <ValidationContainer
          label={
            <Trans id="app.environment.credentials.signed_response_alg">
              Signed token response algorithm
            </Trans>
          }
          labelPosition="left"
          labelTooltip={t`app.environment.credentials.signed_response_alg.tooltip`}
          className="py-10"
        >
          <div className="font-mono text-14 leading-relaxed text-primary-dark">
            <div
              className="bg-grey-200 rounded border border-grey-300 py-4 px-8 mr-8"
              data-cy-id="app.environment.credentials.profile_signed_response_alg"
            >
              {credentials?.profile_signed_response_alg}
            </div>
          </div>
        </ValidationContainer>
      )}

      {credentials?.profile_encrypted_response_alg && (
        <ValidationContainer
          label={
            <Trans id="app.environment.credentials.encrypted_response_alg">
              Encrypted token response algorithm
            </Trans>
          }
          labelPosition="left"
          labelTooltip={t`app.environment.credentials.encrypted_response_alg.tooltip`}
          className="py-10"
        >
          <div className="font-mono text-14 leading-relaxed text-primary-dark">
            <div
              className="bg-grey-200 rounded border border-grey-300 py-4 px-8 mr-8"
              data-cy-id="app.environment.credentials.profile_encrypted_response_alg"
            >
              {credentials?.profile_encrypted_response_alg}
            </div>
          </div>
        </ValidationContainer>
      )}

      {credentials?.profile_encrypted_response_enc && (
        <ValidationContainer
          label={
            <Trans id="app.environment.credentials.encrypted_response_enc">
              Encrypted token response encryption
            </Trans>
          }
          labelPosition="left"
          labelTooltip={t`app.environment.credentials.encrypted_response_enc.tooltip`}
          className="py-10"
        >
          <div className="font-mono text-14 leading-relaxed text-primary-dark">
            <div
              className="bg-grey-200 rounded border border-grey-300 py-4 px-8 mr-8"
              data-cy-id="app.environment.credentials.profile_encrypted_response_enc"
            >
              {credentials?.profile_encrypted_response_enc}
            </div>
          </div>
        </ValidationContainer>
      )}
    </>
  )
}

interface AppCredentialsProps {
  slug: string
  bankSlug?: string
  env: AppEnvironmentType
}

export const AppCredentials: React.FC<AppCredentialsProps> = ({
  slug,
  bankSlug,
  env,
}) => {
  // use this query for app users
  const credentialsQuery = useAppCredentialsQuery({
    variables: {
      slug,
      sandbox: env === AppEnvironmentType.Sandbox,
      production: env === AppEnvironmentType.Production,
    },
    skip: !!bankSlug,
  })

  // use this query for bank users
  const connectedAppCredentialsQuery = useConnectedAppCredentialsQuery({
    variables: {
      appSlug: slug,
      bankSlug: bankSlug!, // skipped if undefined
      sandbox: env === AppEnvironmentType.Sandbox,
      production: env === AppEnvironmentType.Production,
    },
    skip: !bankSlug,
  })

  const envSlug =
    env === AppEnvironmentType.Production ? 'production' : 'sandbox'

  const environment = bankSlug
    ? connectedAppCredentialsQuery.data?.appByAppSlugAndBankSlug?.[envSlug]
    : credentialsQuery.data?.appBySlug?.[envSlug]

  const activeProducts = credentialsQuery.data?.appBySlug?.activeProducts ?? []

  return (
    <div className="text-16 form-mono-font">
      <h1
        className="heading-h2 mt-40 mb-16"
        data-cy-id="app.environment.credentials.heading"
      >
        <Trans id="app.environment.credentials.heading">Credentials</Trans>
      </h1>
      <QueryLoader
        queries={[
          !bankSlug && credentialsQuery,
          !!bankSlug && connectedAppCredentialsQuery,
        ]}
      >
        {environment?.credentials && (
          <AppCredentialsFields
            environment={environment}
            env={env}
            activeProducts={activeProducts}
            refetch={credentialsQuery.refetch}
          />
        )}

        {environment && (
          <AppCredentialsState
            appSlug={slug}
            envId={environment.id}
            envSlug={envSlug}
            envState={environment.state}
            envType={env}
          />
        )}
      </QueryLoader>
    </div>
  )
}
