import { useApolloClient } from '@apollo/client'
import { t, Trans } from '@lingui/macro'
import classNames from 'classnames'
import { Link } from 'components'
import AppNotificationIcon from 'icons/app-notification.svg'
import BankNotificationIcon from 'icons/bank-notification.svg'
import BellIcon from 'icons/bell.svg'
import GlobalNotificationIcon from 'icons/global-notification.svg'
import OrgNotificationIcon from 'icons/organisation-notification.svg'
import SupportNotificationIcon from 'icons/support-notification.svg'
import UserNotificationIcon from 'icons/user-notification.svg'
import React, { useEffect, useRef, useState } from 'react'
import {
  EventContext,
  NewNotificationCountFragment,
  NewNotificationCountFragmentDoc,
  NotificationFragment,
  useNewNotificationsQuery,
  useNotificationsQuery,
} from 'types'
import { useOutsideClick } from 'utils/hooks'
import { formatDateWithLocale } from 'utils/utils'
import { useLocale } from 'utils/withLang'
import { QueryLoader } from './QueryLoader'

type EventContextProps = {
  context: EventContext
}

const EventContextIcon: React.FC<EventContextProps> = ({ context }) => (
  <>
    {context === EventContext.App && <AppNotificationIcon />}
    {context === EventContext.Bank && <BankNotificationIcon />}
    {context === EventContext.Organisation && <OrgNotificationIcon />}
    {context === EventContext.UserAccount && <UserNotificationIcon />}
    {context === EventContext.Global && <GlobalNotificationIcon />}
    {context === EventContext.Support && <SupportNotificationIcon />}
  </>
)

// EventContext
t`notifications.event.context.APP`
t`notifications.event.context.BANK`
t`notifications.event.context.ORGANISATION`
t`notifications.event.context.USER_ACCOUNT`
t`notifications.event.context.GLOBAL`
t`notifications.event.context.SUPPORT`

// EventType
t`notifications.event.type.APP_PRODUCTION_DISABLED`
t`notifications.event.type.APP_PRODUCTION_ENABLED`
t`notifications.event.type.APP_PRODUCTION_CONFIG_CHANGED`
t`notifications.event.type.APP_DELETED`
t`notifications.event.type.APP_PERMISSIONS_CHANGED`
t`notifications.event.type.APP_INVITATION_ACCEPTED`
t`notifications.event.type.APP_INVITATION_DENIED`
t`notifications.event.type.APP_USER_JOINED`
t`notifications.event.type.BANK_APP_REGISTRATION_FAILED`
t`notifications.event.type.BANK_DELETED`
t`notifications.event.type.BANK_PERMISSIONS_CHANGED`
t`notifications.event.type.BANK_INVITATION_ACCEPTED`
t`notifications.event.type.BANK_INVITATION_DENIED`
t`notifications.event.type.BANK_USER_JOINED`
t`notifications.event.type.ORG_APP_CONNECTED`
t`notifications.event.type.ORG_NEW_INVOICE`
t`notifications.event.type.ORG_NEW_BUSINESS_REPORT`
t`notifications.event.type.ORG_INVITATION_ACCEPTED`
t`notifications.event.type.ORG_INVITATION_DENIED`
t`notifications.event.type.ORG_DELETED`
t`notifications.event.type.ORG_PERMISSIONS_CHANGED`
t`notifications.event.type.ORG_USER_JOINED`
t`notifications.event.type.BO_APP_GENERATED_PROD_CREDENTIALS`
t`notifications.event.type.BO_APP_REGISTRATION_FAILED`
t`notifications.event.type.USER_PASSWORD_CHANGED`
t`notifications.event.type.USER_ACCOUNT_DELETE`

type NotificationRowProps = {
  notification: NotificationFragment
}

const NotificationRow: React.FC<NotificationRowProps> = ({ notification }) => {
  const locale = useLocale()

  return (
    <Link
      to={`/event/${notification.event.id}`}
      kind="plain"
      className="block hover:bg-grey-100 -mx-24 px-24 py-12"
      data-cy-id={`notification.event.${notification.event.id}`}
    >
      <div className="flex items-center mb-12">
        <EventContextIcon context={notification.event.context} />
        <p className="text-grey-600 uppercase text-10 ml-15">
          <Trans
            id={`notifications.event.context.${notification.event.context}`}
          />
        </p>
      </div>
      <p>
        <Trans id={`notifications.event.type.${notification.event.type}`} />
      </p>
      <p className="text-grey-600 text-12 mt-12">
        {formatDateWithLocale(
          notification.event.happenedAt,
          'dd.MM.yyyy HH:mm',
          locale
        )}
      </p>
    </Link>
  )
}

const NotificationsList: React.FC = () => {
  const notificationsQuery = useNotificationsQuery({
    fetchPolicy: 'network-only',
  })
  const notifications =
    notificationsQuery.data?.currentUser?.notifications ?? []

  const read = notifications?.filter((n) => n.read)
  const unread = notifications?.filter((n) => !n.read)

  return (
    <div className="p-24">
      <QueryLoader queries={[notificationsQuery]}>
        {unread.length > 0 && (
          <>
            <h4 className="heading-h4 border-b border-grey-300 pb-16 mb-12">
              <Trans id="notifications.unread">Unread</Trans>
              <span className="text-grey-600 text-12 font-normal ml-6">
                ({unread.length})
              </span>
            </h4>
            {unread.map((n) => (
              <NotificationRow notification={n} key={n.id} />
            ))}
          </>
        )}
        {unread.length === 0 && read.length > 0 && (
          <p className="text-grey-800">
            <Trans id="notifications.noNewNotifications">
              You don&apos;t have any new notifications.
            </Trans>
          </p>
        )}

        {read.length > 0 && (
          <>
            <h4 className="heading-h4 border-b border-grey-300 pb-16 mt-24 mb-12">
              <Trans id="notifications.read">Read</Trans>
              <span className="text-grey-600 text-12 font-normal ml-6">
                ({read.length})
              </span>
            </h4>
            {read.map((n) => (
              <NotificationRow notification={n} key={n.id} />
            ))}
          </>
        )}

        {unread.length === 0 && read.length === 0 && (
          <p className="text-grey-800">
            <Trans id="notifications.noNotifications">
              You don&apos;t have any notifications.
            </Trans>
          </p>
        )}
      </QueryLoader>
    </div>
  )
}

export const Notifications: React.FC = () => {
  const [open, setOpen] = useState(false)
  const listRef = useRef<HTMLDivElement>(null)
  useOutsideClick(listRef, () => open && setOpen(false))

  const newNotificationsQuery = useNewNotificationsQuery({
    fetchPolicy: 'cache-and-network',
    pollInterval: 60 * 1000,
  })
  const newNotificationCount =
    newNotificationsQuery.data?.currentUser?.newNotificationCount ?? 0
  const user = newNotificationsQuery.data?.currentUser

  // reset new notification count after opening list
  const apollo = useApolloClient()
  useEffect(() => {
    if (open && user) {
      apollo.writeFragment<NewNotificationCountFragment>({
        fragment: NewNotificationCountFragmentDoc,
        id: apollo.cache.identify(user),
        data: { newNotificationCount: 0 },
      })
    }
  }, [open, user, apollo])

  return (
    <div className="sm:relative">
      <div className="relative">
        <button
          className=""
          onClick={() => setOpen((open) => !open)}
          data-cy-id="header.notifications"
        >
          <BellIcon className="fill-grey-600" />
          {newNotificationCount > 0 && (
            <div
              className={classNames(
                'bg-utility-red rounded-full min-w-16 h-16',
                'text-white-100 text-10 text-center leading-16',
                'absolute top-0 right-0 -mt-6 -mr-4 px-2'
              )}
            >
              {newNotificationCount}
            </div>
          )}
        </button>
      </div>
      {open && (
        <div
          className={classNames(
            'sm:w-380 absolute z-20 right-0 left-0 sm:left-auto sm:-mr-20 mt-30',
            'sm:max-h-640 overflow-y-auto overflow-x-hidden',
            'bg-white-100 sm:rounded-8 shadow'
          )}
          ref={listRef}
        >
          <NotificationsList />
          {newNotificationCount > 0 && (
            <div className="mt-20 h-80 w-full sticky bottom-0 left-0 bg-gradient-to-b from-transparent to-white-100" />
          )}
        </div>
      )}
    </div>
  )
}
