import { Trans } from '@lingui/macro'
import { Avatar, Dropdown, DropdownLink, Link } from 'components'
import AvatarPlaceholder from 'icons/avatar.svg'
import CaretDown from 'icons/caret-down.svg'
import LogoutIcon from 'icons/logout.svg'
import React, { useMemo } from 'react'
import { useCurrentUserQuery } from 'types'
import { makeFullName } from 'utils/utils'
import { api as authAPI, useIsLoggedIn, useLogout } from 'utils/withAuth'
import { useLocale } from 'utils/withLang'

const UserDropdownLinks: React.FC = () => {
  const logout = useLogout()

  return (
    <>
      <Link
        to="/profile"
        data-cy-id="header.userBox.links.profile"
        kind="plain"
      >
        <Trans id="header.userBox.links.profile">Profile</Trans>
      </Link>
      <div className="h-24" />
      <Link
        onClick={logout}
        data-cy-id="header.userBox.links.logOut"
        className="text-utility-red"
      >
        <Trans id="header.userBox.links.logOut">Log out</Trans>
      </Link>
    </>
  )
}

type UserDropdownMenuProps = {
  name?: string
}

const UserDropdownMenu: React.FC<UserDropdownMenuProps> = ({ name }) => {
  const logout = useLogout()

  return (
    <ul className="bg-white-100 shadow rounded-lg mt-10 py-6 text-grey-900">
      {name && (
        <p className="px-16 pt-10 pb-12 mb-8 border-b border-grey-300 text-grey-600">
          {name}
        </p>
      )}
      <DropdownLink
        to="/profile"
        data-cy-id="header.userBox.links.profile"
        fullWidth
      >
        <AvatarPlaceholder className="-mt-2 inline fill-grey-900" />{' '}
        <span className="pl-4">
          <Trans id="header.userBox.links.profile">Profile</Trans>
        </span>
      </DropdownLink>
      <DropdownLink
        onClick={logout}
        data-cy-id="header.userBox.links.logOut"
        fullWidth
        className="text-utility-red"
      >
        <LogoutIcon className="-mt-2 inline" />{' '}
        <span className="pl-4">
          <Trans id="header.userBox.links.logOut">Log out</Trans>
        </span>
      </DropdownLink>
    </ul>
  )
}

const UserDropdown: React.FC = () => {
  const userQuery = useCurrentUserQuery()
  const user = userQuery.data?.currentUser
  const userFullName = makeFullName(user?.firstName, user?.lastName)

  return (
    <>
      <div className="hidden lg:block">
        <Dropdown
          display={
            <div className="flex items-center">
              <Avatar media={user?.avatar} size={32} kind="borderless" />
              <CaretDown className="ml-10" />
            </div>
          }
          displayClassName="mt-6"
          dropdownClassName="w-150 mt-18 left-auto z-50"
          data-cy-id="header.userDropdown"
        >
          <UserDropdownMenu name={userFullName} />
        </Dropdown>
      </div>
      <div className="lg:hidden">
        <UserDropdownLinks />
      </div>
    </>
  )
}

export const UserBox: React.FC = () => {
  const locale = useLocale()
  const registerURL = useMemo(() => authAPI.registerUrl(locale), [locale])

  const isLoggedIn = useIsLoggedIn()
  if (isLoggedIn) {
    return <UserDropdown />
  }

  return (
    <>
      <Link to="/api/login" data-cy-id="header.links.logIn">
        <Trans id="header.links.logIn">Log in</Trans>
      </Link>
      <div className="w-24 h-24" />
      <Link href={registerURL} data-cy-id="header.links.register">
        <Trans id="header.links.register">Register</Trans>
      </Link>
    </>
  )
}
