import classNames from 'classnames'
import AvatarPlaceholder from 'icons/avatar.svg'
import React from 'react'
import type { ImageProps } from './Image'
import { Image } from './Image'

type AvatarProps = ImageProps & {
  kind?: 'regular' | 'borderless'
}

export const Avatar: React.FC<AvatarProps> = ({
  className,
  opts,
  kind,
  ...props
}) => {
  if (!props.id && !props.media) {
    return (
      <AvatarPlaceholder
        className={classNames(
          'flex-shrink-0 fill-grey-600 rounded-full object-cover',
          kind !== 'borderless' && 'border border-black-100',
          className
        )}
        width={opts?.width ?? props.size}
        height={opts?.height ?? props.size}
        aria-hidden
      />
    )
  }

  return (
    <Image
      className={classNames(
        'flex-shrink-0 fill-grey-600 rounded-full object-cover',
        kind !== 'borderless' && 'border border-black-100',
        className
      )}
      opts={{ resizingType: 'fill', ...opts }}
      {...props}
    />
  )
}

Avatar.defaultProps = {
  kind: 'regular',
}
