import { t, Trans } from '@lingui/macro'
import classNames from 'classnames'
import { Button, ConfirmationModal, Link } from 'components'
import { useBankPermissions } from 'containers/Layout'
import { useToasts } from 'containers/Toast'
import RetryIcon from 'icons/retry.svg'
import React from 'react'
import {
  AppEnvironmentInBankEnvironmentState,
  ToastKind,
  useRetryAllAppRegistrationMutation,
  useRetryAppRegistrationMutation,
} from 'types'
import { useAsyncCallback } from 'utils/handleError'

interface RetryButtonProps {
  appEnvId: string
  bankEnvId: string
  onDone?: () => Promise<unknown>
}

export const RetryButton: React.FC<RetryButtonProps> = ({
  appEnvId,
  bankEnvId,
  onDone,
}) => {
  const [retryRegistration] = useRetryAppRegistrationMutation({
    variables: { appEnvId, bankEnvId },
  })
  const { addToast } = useToasts()

  const canRetry = useBankPermissions()('APL:T')

  const retry = useAsyncCallback(async () => {
    await retryRegistration()
    await onDone?.()
    addToast({
      kind: ToastKind.Success,
      content: t`bank.environment.connectedApps.retrySuccessful`,
    })
  }, [retryRegistration, addToast])

  return (
    <Link
      kind="text"
      onClick={retry}
      disabled={!canRetry}
      data-cy-id="bank.environment.connectedApps.retry"
    >
      <RetryIcon className="inline-block -mt-4 mr-4" />
      <Trans id="bank.environment.connectedApps.retry">Retry</Trans>
    </Link>
  )
}

interface RetryAllButtonProps {
  bankEnvId: string
  onDone?: () => Promise<unknown>
}

export const RetryAllButton: React.FC<RetryAllButtonProps> = ({
  bankEnvId,
  onDone,
}) => {
  const [retryRegistration] = useRetryAllAppRegistrationMutation({
    variables: { bankEnvId, failedOnly: true },
  })
  const { addToast } = useToasts()

  const canRetry = useBankPermissions()('APL:T')

  const retry = useAsyncCallback(async () => {
    await retryRegistration()
    await onDone?.()
    addToast({
      kind: ToastKind.Success,
      content: t`bank.environment.connectedApps.retrySuccessful`,
    })
  }, [retryRegistration, addToast])

  return (
    <Button
      kind="primary"
      onClick={retry}
      disabled={!canRetry}
      className="h-40 ml-15 flex items-center"
      data-cy-id="bank.environment.connectedApps.retryAll"
    >
      <Trans id="bank.environment.connectedApps.retryAll">Retry all</Trans>
    </Button>
  )
}

interface ReRegisterButtonProps {
  bankEnvId: string
  onDone?: () => Promise<unknown>
}

export const ReRegisterButton: React.FC<ReRegisterButtonProps> = ({
  bankEnvId,
  onDone,
}) => {
  const [retryRegistration] = useRetryAllAppRegistrationMutation({
    variables: { bankEnvId, failedOnly: false },
  })
  const { addToast } = useToasts()

  const canRetry = useBankPermissions()('APL:T')

  const retry = useAsyncCallback(async () => {
    addToast({
      kind: ToastKind.Success,
      content: t`bank.environment.connectedApps.reregisterStarted`,
      timeout: 5000,
    })
    await retryRegistration()
    await onDone?.()
    addToast({
      kind: ToastKind.Success,
      content: t`bank.environment.connectedApps.retrySuccessful`,
    })
  }, [retryRegistration, addToast])

  return (
    <ConfirmationModal
      onConfirm={retry}
      text={
        <Trans id="bank.environment.connectedApps.confirmReregisterApp">
          Are you sure you want to re-register all apps?
        </Trans>
      }
    >
      {(openConfirmation) => (
        <Button
          kind="primary"
          onClick={openConfirmation}
          disabled={!canRetry}
          className="h-40 ml-15 flex items-center"
          data-cy-id="bank.environment.connectedApps.reregister"
        >
          <Trans id="bank.environment.connectedApps.reregister">
            Re-register apps
          </Trans>
        </Button>
      )}
    </ConfirmationModal>
  )
}

t`bank.environment.connectedApps.state.ACTIVE`
t`bank.environment.connectedApps.state.INACTIVE`
t`bank.environment.connectedApps.state.PENDING`
t`bank.environment.connectedApps.state.ERROR`

interface AppStateIndicatorProps {
  state: AppEnvironmentInBankEnvironmentState
}

export const AppStateIndicator: React.FC<AppStateIndicatorProps> = ({
  state,
}) => (
  <span
    className={classNames(
      'mr-10 font-semibold',
      state === AppEnvironmentInBankEnvironmentState.Active &&
        'text-utility-success',
      state === AppEnvironmentInBankEnvironmentState.Error &&
        'text-utility-error'
    )}
  >
    <Trans id={`bank.environment.connectedApps.state.${state}`} />
  </span>
)
