import classNames from 'classnames'
import { Tooltip } from 'components/Tooltip'
import { useRouter } from 'next/router'
import React from 'react'
import { Clickable, ClickableProps } from './Clickable'

export type LinkKind = 'text' | 'navbar' | 'plain' | 'danger' | 'sidebar'

interface CommonLinkProps {
  kind?: LinkKind
  active?: boolean
}

interface LinkPropsWithoutTooltip extends ClickableProps {}

interface LinkPropsWithTooltip extends ClickableProps {
  kind?: LinkKind
  tooltip: React.ReactNode | boolean
  tooltipId: string
}

export type LinkProps = CommonLinkProps &
  (LinkPropsWithoutTooltip | LinkPropsWithTooltip)

const styles = {
  text: 'text-primary',
  navbar:
    'xl:leading-50 h-full xl:mr-36 px-20 xl:px-12 flex items-center py-20 xl:py-0 border-b border-grey-300 xl:border-b-0',
  plain: 'text-left',
  danger: 'text-utility-red',
  sidebar: 'text-white-80 font-medium py-8 flex items-center',
}

export const Link: React.FC<LinkProps> = (props) => {
  const location = useRouter()?.asPath
  const active = props.active ?? props.to === location

  const className = classNames(
    styles[props.kind!],
    props.kind === 'text' && !props.disabled && 'hover:underline',
    props.kind === 'navbar' &&
      active &&
      'xl:border-primary xl:border-b-4 xl:pt-4',
    props.kind === 'sidebar' &&
      active &&
      'bg-white-10 rounded py-6 text-white-100 px-12 -mx-12',
    props.className
  )

  const element = (
    <Clickable {...props} className={className}>
      {props.children}
    </Clickable>
  )

  if ('tooltip' in props && props.tooltip) {
    return (
      <Tooltip
        id={props.tooltipId}
        kind={props.kind === 'sidebar' ? 'primary' : 'dark'}
        displayTip={props.tooltip}
      >
        {element}
      </Tooltip>
    )
  }

  return <>{element}</>
}

Link.defaultProps = {
  kind: 'text',
}
