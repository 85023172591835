import { t } from '@lingui/macro'
import { PermissionKind } from './PermissionsInput'

export type AppPermission =
  | 'APL'
  | 'USR'
  | 'SBX'
  | 'PROD'
  | 'FNC'
  | 'LGL'
  | 'ADT'
  | 'PRD'

export type BankPermission =
  | 'BNK'
  | 'USR'
  | 'FNC'
  | 'ADT'
  | 'DOC'
  | 'ENV'
  | 'OUT'
  | 'APL'
  | 'REP'

export type OrgPermission = 'ORG' | 'USR' | 'FNC' | 'LGL' | 'REP'

t`permissions.app.APL`
t`permissions.app.USR`
t`permissions.app.SBX`
t`permissions.app.PROD`
t`permissions.app.FNC`
t`permissions.app.LGL`
t`permissions.app.ADT`
t`permissions.app.PRD`

t`permissions.bank.BNK`
t`permissions.bank.USR`
t`permissions.bank.FNC`
t`permissions.bank.ADT`
t`permissions.bank.DOC`
t`permissions.bank.ENV`
t`permissions.bank.OUT`
t`permissions.bank.APL`
t`permissions.bank.REP`

t`permissions.organization.ORG`
t`permissions.organization.USR`
t`permissions.organization.FNC`
t`permissions.organization.LGL`
t`permissions.organization.REP`

export type Permission = AppPermission | BankPermission | OrgPermission

export type PermissionLevel = 'N' | 'R' | 'RW' | 'RT' | 'RWS' | 'RS'

type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T
}

type ForbiddenPermissions = {
  app: PartialRecord<Permission, PermissionLevel>
  bank: PartialRecord<Permission, PermissionLevel>
  organization: PartialRecord<Permission, PermissionLevel>
}

const forbiddenPermissions: ForbiddenPermissions = {
  app: {
    APL: 'N',
    ADT: 'RW',
    PRD: 'N',
  },
  bank: {
    BNK: 'N',
    ADT: 'RW',
    APL: 'RW',
    FNC: 'RW',
    DOC: 'RW',
    REP: 'RW',
  },
  organization: {
    ORG: 'N',
    REP: 'RW',
  },
}

export function isPermissionValid(
  type: PermissionKind,
  permission: Permission,
  level: PermissionLevel
) {
  return level !== forbiddenPermissions[type][permission]
}
