import { InfoBanner, QueryLoader } from 'containers'
import { NextPage } from 'next'
import React, { createContext, useCallback, useContext, useState } from 'react'
import { useInfoBannerQuery } from 'types/cms'
import { setCookie } from './cookie'
import { hashCode } from './utils'
import { useLocale } from './withLang'

const cookieName = 'bannerHidden'

interface InfoBannerContext {
  bannerHidden: boolean
  toggleBanner: () => void
}

const InfoBannerCtx = createContext<InfoBannerContext>(null!)

export function useInfoBanner() {
  return useContext(InfoBannerCtx)
}

export function withInfoBanner(
  cookies: Record<string, string>,
  Component: any
) {
  const WithInfoBanner: NextPage = (props) => {
    const locale = useLocale()

    const disclaimerQuery = useInfoBannerQuery({
      variables: { locale },
      context: { endpoint: 'cms' },
    })

    const content = disclaimerQuery.data?.infoBanner?.data?.attributes?.content

    const [dismissedHash, setDismissedHash] = useState(cookies[cookieName])

    const toggle = useCallback(() => {
      const hash = dismissedHash ? '' : hashCode(content)
      setCookie(null, cookieName, hash)
      setDismissedHash(hash)
    }, [content, dismissedHash, setDismissedHash])

    const bannerChanged = dismissedHash !== hashCode(content)

    return (
      <>
        <QueryLoader
          queries={[disclaimerQuery]}
          errorComponent={null}
          loaderComponent={null}
        >
          {content && bannerChanged && (
            <InfoBanner onHide={toggle} content={content} />
          )}
        </QueryLoader>
        <InfoBannerCtx.Provider
          value={{
            bannerHidden: !!content && !bannerChanged,
            toggleBanner: toggle,
          }}
        >
          <Component {...props} />
        </InfoBannerCtx.Provider>
      </>
    )
  }

  return WithInfoBanner
}
