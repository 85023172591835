import classNames from 'classnames'
import React, { useCallback, useRef, useState } from 'react'
import type { TestIdProp } from 'types'
import { useKeys, useOutsideClick } from 'utils/hooks'

interface DropdownProps extends TestIdProp {
  display: React.ReactNode
  displayClassName?: string
  dropdownClassName?: string
}

export const Dropdown: React.FC<DropdownProps> = ({
  display,
  children,
  displayClassName,
  dropdownClassName,
  'data-cy-id': testId,
}) => {
  const [isOpen, setOpen] = useState(false)

  const toggleDropdown = useCallback(
    () => setOpen((current) => !current),
    [setOpen]
  )
  const toggleKeyDownHandler = useKeys(['Enter', ' '])(toggleDropdown)

  const closeDropdown = useCallback(() => setOpen(false), [setOpen])
  const closeKeyDownHandler = useKeys('Escape')(closeDropdown)

  const menuRef = useRef<HTMLDivElement>(null)
  useOutsideClick(menuRef, () => setOpen(false))

  return (
    <div className={classNames('relative h-40 select-none', displayClassName)}>
      <div
        tabIndex={0}
        onClick={toggleDropdown}
        onKeyDown={toggleKeyDownHandler}
        ref={menuRef}
        aria-haspopup="menu"
        data-cy-id={testId}
        role="button"
      >
        {display}
      </div>

      {isOpen && (
        <div
          onKeyDown={closeKeyDownHandler}
          className={classNames(
            'absolute left-0 right-0 z-20',
            dropdownClassName
          )}
          role="menu"
          tabIndex={0}
        >
          {children}
        </div>
      )}
    </div>
  )
}
