import cn from 'classnames'
import React, { ChangeEventHandler, MouseEventHandler } from 'react'
import { Control, Controller } from 'react-hook-form'
import { TestIdProp } from 'types'
import {
  ValidationContainer,
  ValidationContainerProps,
} from './ValidationContainer'

type UncontrolledToggleProps = ValidationContainerProps &
  TestIdProp & {
    className?: string
    fullWidth?: boolean
    defaultChecked?: boolean
    disabled?: boolean
    checked: boolean
    name: string
    onChange?: ChangeEventHandler<HTMLInputElement>
    onBlur?: MouseEventHandler<HTMLInputElement>
    onClick?: MouseEventHandler<HTMLInputElement>
  }

export const UncontrolledToggle: React.FC<UncontrolledToggleProps> = ({
  fullWidth,
  defaultChecked,
  disabled,
  checked,
  rules,
  name,
  onChange,
  onBlur,
  onClick,
  ...props
}) => {
  return (
    <ValidationContainer type="checkbox" fullWidth={fullWidth} {...props}>
      <div
        className={cn(
          'relative w-48 h-26',
          disabled && 'cursor-not-allowed opacity-75'
        )}
      >
        <input
          className={cn(
            'appearance-none h-full rounded-full transition duration-300',
            fullWidth ? 'w-full' : 'min-w-48',
            disabled ? 'cursor-not-allowed' : 'cursor-pointer',
            checked ? 'bg-primary' : 'bg-grey-400'
          )}
          id={name}
          onBlur={onBlur}
          onChange={onChange}
          name={name}
          disabled={disabled}
          type="checkbox"
          checked={checked}
          onClick={!disabled ? onClick : undefined}
          data-cy-id={props['data-cy-id']}
        />
        <div
          className={cn(
            'absolute inset-0 flex items-center justify-between px-3 pointer-events-none',
            !checked && 'flex-row-reverse'
          )}
        >
          <span
            className={cn(
              'text-10 tracking-tighter',
              checked ? 'text-white-100 ml-5' : 'text-grey-800 mr-5'
            )}
          >
            {checked ? 'ON' : 'OFF'}
          </span>
          <div className="w-16 h-16 rounded-full bg-white-100"></div>
        </div>
      </div>
    </ValidationContainer>
  )
}

UncontrolledToggle.defaultProps = {
  labelPosition: 'left',
  fullWidth: true,
}

type ToggleProps = Omit<UncontrolledToggleProps, 'checked'> & {
  control: Control
}

export const Toggle: React.FC<ToggleProps> = ({ control, ...props }) => {
  const { setValue } = control

  return (
    <Controller
      control={control}
      rules={props.rules}
      name={props.name}
      defaultValue={props.defaultChecked || false}
      render={({ onChange: formOnChange, onBlur, value: checked, name }) => (
        <UncontrolledToggle
          onBlur={onBlur}
          onChange={(e) =>
            props.onChange?.(e) ?? formOnChange(e.target.checked)
          }
          onClick={!props.disabled ? () => setValue(name, !checked) : undefined}
          {...props}
          checked={checked}
        />
      )}
    />
  )
}

Toggle.defaultProps = {
  labelPosition: 'left',
}
