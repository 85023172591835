import { t, Trans } from '@lingui/macro'
import { Button, ButtonKind, Link } from 'components'
import { useToasts } from 'containers/Toast'
import React from 'react'
import {
  ToastKind,
  useAppCredentialsQuery,
  useCreateSandboxNotificationMutation,
} from 'types'
import { useAsyncCallback } from 'utils/handleError'
import { useLocale } from 'utils/withLang'

type SandboxNotificationZoneProps = {
  slug: string
  kind?: ButtonKind
}

export const SandboxNotificationZone: React.FC<SandboxNotificationZoneProps> =
  ({ slug, kind }) => {
    const credentialsQuery = useAppCredentialsQuery({
      variables: {
        slug,
        sandbox: true,
        production: false,
      },
    })

    const credentials = credentialsQuery.data?.appBySlug?.sandbox?.credentials
    const notificationsEnabled = !!credentials?.scope.find(
      (scope) => scope === 'notification.claims_updated'
    )

    const [createSandboxNotification] = useCreateSandboxNotificationMutation({
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      variables: { clientId: credentials?.clientId! }, // button disabled unless clientId is defined
    })
    const { addToast } = useToasts()
    const currentLocale = useLocale()
    const handleCreateNotification = useAsyncCallback(async () => {
      const res = await createSandboxNotification()
      if (res.data?.createSandboxNotification) {
        addToast({
          kind: ToastKind.Success,
          content: t`app.environment.credentials.createSandboxNotification.success`,
        })
      } else {
        addToast({
          kind: ToastKind.Error,
          content: t`app.environment.credentials.createSandboxNotification.error`,
        })
      }
    }, [createSandboxNotification, addToast])

    return (
      <>
        <h2 className="heading-h2 mt-60">
          <Trans id="app.environment.credentials.sandboxNotificationZone.title">
            Create sandbox update notification
          </Trans>
        </h2>
        <p className="my-20 text-grey-900">
          <Trans id="app.environment.credentials.sandboxNotificationZone.description">
            Use this button to generate an update notification in sandbox to
            test your update notification integration. A notification will be
            generated for test user JanN. For the notification to be delivered,
            JanN needs to authorize with your application first. You can
            authorize as JanN by opening Authorization URI in credentials above
            in your browser and logging in as JanN. &apos;ll find more
            information in the{' '}
            <Link
              to={
                currentLocale === 'cs'
                  ? '/docs/apis_sep#api-pro-notifikace'
                  : '/docs/apis_sep#notification-api'
              }
              openInNewTab
              data-cy-id="app.environment.credentials.openDocs"
            >
              update notifications documentation.
            </Link>
          </Trans>
        </p>
        <div className="flex">
          <Button
            kind={kind}
            data-cy-id="app.environment.credentials.createSandboxNotificationButton"
            onClick={handleCreateNotification}
            disabled={!credentials?.clientId || !notificationsEnabled}
            tooltip={
              !notificationsEnabled && (
                <Trans id="app.environment.credentials.createSandboxNotification.notificationsDisabled">
                  Update notification are disabled for this app. To enable
                  update notifications, set scope notification.claims_updated to
                  Optional or Required.
                </Trans>
              )
            }
          >
            <Trans id="app.environment.credentials.createSandboxNotificationButton">
              Create sandbox notification
            </Trans>
          </Button>
        </div>
      </>
    )
  }
