import { t } from '@lingui/macro'
import { Ticket } from 'data/ServiceDeskClient'

export const productionServiceDeskKey = 'EXT'
export const demoServiceDeskKey = 'DEMO'

export function getServiceDeskKey(isProduction: boolean) {
  return isProduction ? productionServiceDeskKey : demoServiceDeskKey
}

export const issueType: Record<CreateTicketFormData['type'], string> = {
  support: '10033',
  incident: '10034',
}

export const priority: Record<CreateTicketFormData['priority'], string> = {
  critical: '1',
  high: '2',
  medium: '3',
  low: '4',
  lowest: '5',
}

t`support.tickets.status.new`
t`support.tickets.status.in_progress`
t`support.tickets.status.reopened`
t`support.tickets.status.completed`
t`support.tickets.status.closed`
t`support.tickets.status.waiting_for_reporter`

export const ticketStatus: Record<string, string> = {
  '1': 'new',
  '3': 'in_progress',
  '4': 'reopened',
  '5': 'completed',
  '6': 'closed',
  '10028': 'waiting_for_reporter',
  '10070': 'waiting_for_deployment',
  '10071': 'waiting_for_idp',
  '10030': 'completed',
}

export function mapTicketStatusForDisplay(statusId: string): string {
  if (statusId === '10070' || statusId === '10071') {
    return ticketStatus['3'] // show Waiting for IDP & deployment as In progress
  }
  return ticketStatus[statusId]
}

export const canCloseStates = ['1', '3', '4', '10028', '10070', '10071']
export const canReopenStates = ['5', '10030']

export const reopenTransitionId = '771'
export const resolveTransitionId = '761'

/**
 * Ticket data in ticket list
 */
export type TicketMetaFields = {
  summary: string
  description: string
  customfield_10073: string // org ID
  customfield_10075: string // app ID
  status: {
    id: string
  }
  priority: {
    id: string
  }
  reporter: {
    emailAddress: string
    displayName: string
  }
  issuetype: {
    id: string
  }
  created: string
  attachment: TicketAttachment[]
}

// list of fields to request from Service Desk
const ticketMetaFieldsList: Record<keyof TicketMetaFields, unknown> = {
  summary: '',
  description: '',
  customfield_10073: '',
  customfield_10075: '',
  status: '',
  priority: '',
  reporter: '',
  issuetype: '',
  created: '',
  attachment: '',
}

export const ticketMetaFields = Object.keys(ticketMetaFieldsList).join(',')

export type TicketMeta = Ticket<TicketMetaFields>

/**
 * Ticket data on ticket detail page
 */
export type TicketDetailFields = {
  summary: string
  description: string
  customfield_10073: string // org ID
  customfield_10074: string // app slug
  status: {
    id: string
  }
  priority: {
    id: string
  }
  reporter: {
    emailAddress: string
    displayName: string
  }
  issuetype: {
    id: string
  }
  created: string
  attachment: TicketAttachment[]
  comment: TicketComments
}

export type TicketAttachment = {
  id: string
  filename: string
  created: string
  mimeType: string
  content: string
  thumbnail: string
}

type TicketComments = {
  comments: TicketCommentWithMetadata[]
  maxResults: 0
  startAt: 0
  total: 0
}

export type TicketComment = {
  id: string
  body: string
  created: string
  updated: string
  jsdPublic: boolean
  author: {
    displayName?: string
    emailAddress?: string
  }
}

export type TicketCommentParsedMetadata = {
  author: {
    displayName?: string
    email?: string
  }
  attachments: string[]
}

export type TicketCommentMetadata = {
  author: {
    displayName?: string
    email?: string
  }
  attachments: TicketAttachment[]
}

export type TicketCommentWithMetadata = TicketComment & TicketCommentMetadata

// list of fields to request from Service Desk
const ticketDetailFieldsList: Record<keyof TicketDetailFields, unknown> = {
  summary: '',
  description: '',
  customfield_10073: '',
  customfield_10074: '',
  status: '',
  priority: '',
  reporter: '',
  issuetype: '',
  created: '',
  attachment: '',
  comment: '',
}

export const ticketDetailFields = Object.keys(ticketDetailFieldsList).join(',')

export type TicketDetail = Ticket<TicketDetailFields>

export type CreateTicketFormData = {
  type: 'support' | 'incident'
  priority: 'critical' | 'high' | 'medium' | 'low' | 'lowest'
  title: string
  description: string
  appSlug?: string
  appId?: string
  appName?: string
  orgIdNumber?: string
  orgName?: string
  orgId?: string
  attachments?: Express.Multer.File[]
  environment: 'sandbox' | 'production' | ''
  traceId?: string
  request?: string
  response?: string
  issueDate?: string
  issueTime?: string
  labels?: string[]
}

export type CreateCommentFormData = {
  issueKey: string
  body: string
  attachments?: Express.Multer.File[]
}

export type CreateCommentError = {
  code: 'ATTACHMENT_FILENAME_TAKEN'
}

export enum TicketRequestTypeDev {
  ProdTechnicalSupport = '129',
  IncidentInProdEnvironment = '127',
  OrganisationRegistration = '131',
  WebFormRequest = '132',
}

export enum TicketRequestTypeProd {
  ProdTechnicalSupport = '55',
  IncidentInProdEnvironment = '72',
  OrganisationRegistration = '119',
  WebFormRequest = '120',
}

export const getTicketRequestType = (isProduction: boolean) =>
  isProduction ? TicketRequestTypeProd : TicketRequestTypeDev
