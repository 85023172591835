import getConfig from 'next/config'
import { captureException } from './sentry'

const { FILESERVICE_URL } = getConfig().publicRuntimeConfig

export interface ImageOptions {
  width?: number
  height?: number
  resizingType?: 'auto' | 'fill' | 'fit'
}

/**
 * Adds width and height query params to a FileService image URL
 *
 * @param url   FileService image URL
 * @param opts  Desired image size
 */
export function resizeImage(url?: string, opts: ImageOptions = {}) {
  try {
    const parsedUrl = new URL(url ?? '')
    const params = new URLSearchParams(parsedUrl.search)

    for (const [key, value] of Object.entries(opts)) {
      if (value !== undefined && !params.has(key)) {
        params.append(key, value)
      }
    }
    parsedUrl.search = params.toString()

    return parsedUrl.toString()
  } catch (_) {
    captureException(`Could not parse image URL: ${url}`)
    return url
  }
}

export interface MediaFragmentWithFsId {
  file?: {
    id?: string | null
  }
}

/**
 * Constructs a FileService image URL from fileId and image size params
 *
 * @param fileId  FileService file ID
 * @param opts    Desired image size
 */
export function getImageUrl(
  file?: string | MediaFragmentWithFsId | null,
  opts: ImageOptions = {}
) {
  const fileId = typeof file === 'string' ? file : file?.file?.id
  const url = `${FILESERVICE_URL}/api/v1/files/${fileId}/image`

  return resizeImage(url, opts)
}
