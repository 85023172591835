import { t, Trans } from '@lingui/macro'
import classNames from 'classnames'
import { Clickable } from 'components'
import { Layout } from 'containers'
import ExpandIcon from 'icons/expand.svg'
import ShrinkIcon from 'icons/shrink.svg'
import { useGlobalState } from 'pages/_app'
import React from 'react'
import { useDimensions } from 'utils/hooks'
import { screens } from 'utils/theme'

const expandBreakpoint = +screens.xl.replace('px', '')

export const DocsContainer: React.FC = ({ children }) => {
  const {
    state: { docsExpanded },
    setGlobalState,
  } = useGlobalState()
  const { width, ref } = useDimensions<HTMLDivElement>()

  return (
    <Layout title={t`docs.title`}>
      <div ref={ref}>
        {(width ?? 0) > expandBreakpoint && (
          <div className="fixed right-0 mt-30">
            <Clickable
              onClick={() =>
                setGlobalState((state) => ({
                  ...state,
                  docsExpanded: !state.docsExpanded,
                }))
              }
              className="px-12 py-6 bg-grey-300 rounded-l w-150"
              aria-hidden
            >
              {docsExpanded ? (
                <>
                  <ShrinkIcon
                    className="inline fill-black-800 mr-6"
                    width={20}
                  />
                  <Trans id="docs.shrinkLayout">Shrink docs</Trans>
                </>
              ) : (
                <>
                  <ExpandIcon
                    className="inline fill-black-800 mr-6"
                    width={20}
                  />
                  <Trans id="docs.expandLayout">Expand docs</Trans>
                </>
              )}
            </Clickable>
          </div>
        )}

        <div
          className={classNames(
            'py-32 mx-auto flex flex-col items-start',
            docsExpanded ? 'md:px-32' : 'container px-20'
          )}
        >
          {children}
        </div>
      </div>
    </Layout>
  )
}
