import classNames from 'classnames'
import React from 'react'

type BadgeProps = {
  kind: 'success' | 'warning' | 'error' | 'n/a'
}

export const Badge: React.FC<BadgeProps> = ({ kind, children }) => (
  <div
    className={classNames(
      'px-16 pt-4 pb-3 rounded-full inline-block border border-black-100',
      kind === 'success' && 'text-white-100 bg-utility-success',
      kind === 'warning' && 'text-grey-900 bg-utility-danger',
      kind === 'error' && 'text-white-100 bg-utility-error',
      kind === 'n/a' && 'text-grey-800 bg-grey-200'
    )}
  >
    {children}
  </div>
)
