import React, { useEffect, useMemo } from 'react'
import { FileThumbnail } from './FileThumbnail'

type FilePreviewProps = {
  file: File
  onRemove: () => void
}

export const FilePreview: React.FC<FilePreviewProps> = ({ file, onRemove }) => {
  const previewUrl = useMemo(() => {
    if (file.type.includes('image')) {
      return URL.createObjectURL(file)
    }
    return undefined
  }, [file])

  useEffect(
    () => () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl)
      }
    },
    [previewUrl]
  )

  return (
    <FileThumbnail url={previewUrl} filename={file.name} onRemove={onRemove} />
  )
}
