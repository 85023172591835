import Axios, { AxiosResponse } from 'axios'
import getConfig from 'next/config'
import { parseCookies } from 'nookies'
import {
  parseTokens,
  refreshTokens,
  Tokens,
  validateAccessToken,
} from 'utils/auth'

/**
 * File category specifies the context in which the uploaded file can be used
 *
 * https://git.bankid.cz/dev-portal/schemas/-/blob/358b0c81db9ec81c0047b7d2e4339e21857342c8/fileservice/fileservice.yaml
 */
export type FileCategory = 'AVATAR' | 'LOGO' | 'CMS' | 'BO' | 'UNKNOWN'

export interface FileInfo {
  fileId: string
  size: number
  mimeType: string

  name?: string
  width?: number
  height?: number
  s3Uri?: string
}

const { FILESERVICE_URL, BACKEND_URL } = getConfig().publicRuntimeConfig

const axios = Axios.create({
  baseURL: `${FILESERVICE_URL}/api/v1`,
})

const axiosBackend = Axios.create({
  baseURL: `${BACKEND_URL}/api/v1`,
})

const FileServiceClient = {
  uploadFile: async (file: File, fileCategory?: FileCategory) => {
    const cookies = parseCookies()
    let tokens: Tokens | undefined = parseTokens(cookies)

    if (!validateAccessToken(tokens?.accessToken)) {
      tokens = await refreshTokens()
    }

    const formData = new FormData()
    formData.set('file', file)
    return axios
      .post<FormData, AxiosResponse<FileInfo>>('/files', formData, {
        headers: {
          Authorization: `Bearer ${tokens?.accessToken?.token}`,
        },
        params: {
          ...(fileCategory ? { fileCategory } : {}),
        },
      })
      .catch((ex) => {
        // If refresh and access tokens are expired, return USER_NOT_PRESENT_IN_JWT
        // TODO remove this once File Service returns proper status code
        if (ex.response.status === 401 && !tokens?.refreshTokenExp) {
          ex.response.data = { code: 'USER_NOT_PRESENT_IN_JWT' }
        }

        throw ex
      })
  },
  downloadFile: async (
    fileId: string,
    type: string,
    fileOwnerId: string,
    fileName: string
  ) => {
    const cookies = parseCookies()
    let tokens: Tokens | undefined = parseTokens(cookies)

    if (!validateAccessToken(tokens?.accessToken)) {
      tokens = await refreshTokens()
    }

    try {
      await axiosBackend
        .get(`/files`, {
          headers: {
            Authorization: `Bearer ${tokens?.accessToken?.token}`,
          },
          params: {
            fileId,
            type,
            fileOwnerId,
          },
          responseType: 'blob',
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName) // Set the file name and extension
          document.body.appendChild(link)
          link.click()
          link.parentNode?.removeChild(link) // Clean up
        })
    } catch (ex: any) {
      // Handle errors
      if (
        ex.response &&
        ex.response.status === 401 &&
        !tokens?.refreshTokenExp
      ) {
        ex.response.data = { code: 'USER_NOT_PRESENT_IN_JWT' }
      }
      throw ex
    }
  },
}

export default FileServiceClient
