import { Trans } from '@lingui/macro'
import React from 'react'
import { useIsBank, useIsLoggedIn } from 'utils/withAuth'
import { LoginRedirect } from './LoginRedirect'

interface AuthorizedProps {
  fallback?: React.ReactNode
  requireLogin?: boolean
  requireBank?: boolean
}

export const BankID401 = () => {
  return (
    <div className="text-center py-80">
      <p className="text-30 heading mt-30 mb-24">
        <Trans id="error401.message">
          You do not have permission for this action
        </Trans>
      </p>
    </div>
  )
}

export const Authorized: React.FC<AuthorizedProps> = (props) => {
  const isLoggedIn = useIsLoggedIn()
  const isBank = useIsBank()

  if ((props.requireBank || props.requireLogin) && !isLoggedIn) {
    return props.fallback ? (
      <>{props.fallback}</>
    ) : (
      <div className="flex py-100 items-center justify-center">
        <div className="max-w-400">
          <LoginRedirect kind="login" />
        </div>
      </div>
    )
  }

  if (props.requireBank && !isBank) {
    return props.fallback ? <>{props.fallback}</> : <BankID401 />
  }

  return <>{props.children}</>
}
