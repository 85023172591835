import { t, Trans } from '@lingui/macro'
import classNames from 'classnames'
import { Button, Input, Link } from 'components'
import { LogoDropzone, useToasts, useUpdateAbility } from 'containers'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { ToastKind, useAppsQuery, useCreateAppMutation } from 'types'
import { useAsyncCallback } from 'utils/handleError'
import { useFileUpload, useUploadFormErrorHandler } from 'utils/hooks'
import { Router } from 'utils/router'
import { patterns } from 'utils/utils'

interface NewAppCardProps {
  first: boolean
}

export const NewAppCard: React.FC<NewAppCardProps> = ({ first }) => (
  <Link
    to="/dashboard/create-app"
    scroll={false}
    kind="plain"
    className={classNames(
      'block w-full sm:w-380 h-360 sm:mr-24 mb-24',
      'border border-dashed border-grey-600 rounded-16',
      'flex flex-col justify-center items-center',
      'hover:shadow-inner-thin'
    )}
    data-cy-id="myApps.createAppButton"
  >
    <p className="mb-24 text-21 text-grey-600 font-semibold tracking-wide">
      {first ? (
        <Trans id="myApps.createFirstApp">Create your first app</Trans>
      ) : (
        <Trans id="myApps.createAnotherApp">Create another app</Trans>
      )}
    </p>
    <Button
      data-cy-id="myApps.createAppCard.createButton"
      className="cursor-pointer"
    >
      <Trans id="myApps.createAppButton">Create app</Trans>
    </Button>
  </Link>
)

interface CreateAppFormInputs {
  name: string
  file?: File
}

interface CreateAppFormProps {
  onClose: () => void
}

export const CreateAppForm: React.FC<CreateAppFormProps> = ({ onClose }) => {
  const {
    register,
    handleSubmit,
    formState,
    errors,
    setValue,
    setError,
    clearErrors,
  } = useForm<CreateAppFormInputs>()

  const { refetch: refetchApps } = useAppsQuery()

  useEffect(() => {
    register({ name: 'file' })
    document.getElementById('name')?.focus()
  }, [register])

  const handleUploadError = useUploadFormErrorHandler(
    'file',
    setError,
    clearErrors
  )
  const [, uploadFile] = useFileUpload(undefined, handleUploadError)

  const [createAppMutation] = useCreateAppMutation()
  const { addToast } = useToasts()
  const updateAbility = useUpdateAbility()

  const createApp = useAsyncCallback(
    handleSubmit(async ({ name, file }) => {
      const fileInfo = file ? await uploadFile(file, 'LOGO') : undefined
      if (file && !fileInfo) {
        return
      }

      const app = await createAppMutation({
        variables: { app: { name, logoFileId: fileInfo?.fileId } },
      })
      addToast({
        kind: ToastKind.Success,
        content: t`myApps.createApp.createdSuccessfully`,
      })
      await refetchApps()
      await updateAbility()
      onClose()
      Router.pushRoute(`/apps/${app.data?.createApp?.slug}`)
    }),
    [createAppMutation]
  )

  return (
    <form onSubmit={createApp}>
      <Input
        name="name"
        placeholder={t`myApps.createApp.placeholder`}
        label={<Trans id="myApps.createApp.appName">App name</Trans>}
        errors={errors.name}
        ref={register({
          required: t`form.error.required`,
          pattern: {
            message: t`form.error.name`,
            value: patterns.name,
          },
        })}
        data-cy-id="myApps.createApp.appName"
        required
      />
      <div className="mt-24" />
      <LogoDropzone
        name="logoFileId"
        label={<Trans id="myApps.createApp.appLogo">App logo</Trans>}
        errors={errors.file}
        onSelectFile={(file) => setValue('file', file)}
        data-cy-id="myApps.createApp.appLogoDropzone"
      />
      <p className="text-grey-600 text-14 leading-18">
        <Trans id="myApps.createApp.uploadLogoLater">
          You can upload a logo later. The logo must be a square image at least
          1024 by 1024 px in the PNG format, maximum 10 MB.
        </Trans>
      </p>
      <div className="flex justify-end mt-30">
        <Button
          loading={formState.isSubmitting}
          isFormSubmit
          data-cy-id="myApps.createApp.createButton"
        >
          <Trans id="myApps.createApp.createButton">Create app</Trans>
        </Button>
      </div>
    </form>
  )
}
