import React, { ReactNode } from 'react'
import classNames from 'classnames'

interface SectionContainerProps {
  background?: 'white' | 'grey'
  backgroundImage?: ReactNode
  border?: 'bottom' | 'none'
  className?: string
}

export const Section: React.FC<SectionContainerProps> = ({
  background,
  backgroundImage,
  border,
  className,
  children,
}) => (
  <section
    className={classNames(
      className,
      'relative overflow-hidden',
      background === 'grey' && 'bg-grey-200',
      border === 'bottom' && 'border-b border-grey-300'
    )}
  >
    {backgroundImage}
    <div className="relative z-10 px-20 container mx-auto">{children}</div>
  </section>
)

Section.defaultProps = {
  background: 'white',
  border: 'bottom',
}
