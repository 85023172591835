import { t, Trans } from '@lingui/macro'
import { Button, Modal, PermissionTag } from 'components'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import {
  InvitationState,
  PendingInvitationsQuery,
  ToastKind,
  useAcceptInvitationMutation,
  useAppsQuery,
  useBanksMetaQuery,
  useBanksQuery,
  useDeclineInvitationMutation,
  useOrganizationsQuery,
  usePendingInvitationsQuery,
  useSeenInvitationsMutation,
} from 'types'
import { refreshTokens } from 'utils/auth'
import { useAsyncCallback } from 'utils/handleError'
import { Router } from 'utils/router'
import { useIsLoggedIn } from 'utils/withAuth'
import { useUpdateAbility } from './Layout'
import { QueryLoader } from './QueryLoader'
import { useToasts } from './Toast'

export const InvitationList: React.FC = () => {
  const [open, setOpen] = useState(false)

  const invitationsQuery = usePendingInvitationsQuery({
    skip: !useIsLoggedIn(),
  })
  const invitations = invitationsQuery.data?.currentUser?.pendingInvitations
  const expiredInvitations = invitations?.filter(
    (i) => i.state === InvitationState.Expired
  )
  const pendingInvitations = invitations?.filter(
    (i) => i.state === InvitationState.Pending
  )
  const remindedInvitations = invitations?.filter(
    (i) => i.state === InvitationState.Reminded
  )

  const isClosable =
    pendingInvitations &&
    pendingInvitations?.length === 0 &&
    remindedInvitations &&
    remindedInvitations?.length === 0

  const [seen] = useSeenInvitationsMutation({
    variables: {
      invitationIds: expiredInvitations
        ? expiredInvitations.map((invite) => invite.id)
        : [],
    },
  })
  const { refetch: refetchInvitations } = usePendingInvitationsQuery()

  const handleClose = useCallback(async () => {
    if (expiredInvitations && expiredInvitations.length > 0) {
      await seen()
      await refetchInvitations()
    }
    const currentRoute = Router?.router?.asPath
    setOpen(false)
    // We need withAuth HOC component to run so user context and useIsBank in Header component is updated,
    // so we call Router with the current path which results in re-rendering the whole page including HOC and getInitialProps
    Router.pushRoute(currentRoute)
  }, [setOpen])

  useEffect(() => {
    if (invitations && invitations.length > 0) {
      setOpen(true)
    }
  }, [invitations, setOpen])

  if (!invitations || invitations.length === 0) return null

  return (
    <QueryLoader
      queries={[invitationsQuery]}
      errorComponent={null}
      loaderComponent={null}
    >
      <Modal
        title={<Trans id="profile.invitations">Invitations</Trans>}
        isOpen={open}
        maxWidth={900}
        onClose={handleClose}
        data-cy-id="profile.invitations.modal"
        showCloseButton={isClosable}
        shouldCloseOnOverlayClick={isClosable}
      >
        {invitations?.map((row, i) => (
          <Fragment key={row.id}>
            <InvitationRow invitation={row} />
            {i < invitations.length - 1 && (
              <div className="border-b border-grey-300 pb-24 mb-24" />
            )}
          </Fragment>
        ))}
      </Modal>
    </QueryLoader>
  )
}

interface InvitationRowProps {
  invitation: NonNullable<
    PendingInvitationsQuery['currentUser']
  >['pendingInvitations'][0]
}

const InvitationRow: React.FC<InvitationRowProps> = ({ invitation }) => {
  const [state, setState] = useState<InvitationState>(invitation.state)

  const { addToast } = useToasts()
  const { refetch: refetchApps } = useAppsQuery()
  const { refetch: refetchBanks } = useBanksQuery()
  const { refetch: refetchBanksMetaQuery } = useBanksMetaQuery()
  const { refetch: refetchOrgs } = useOrganizationsQuery()
  const { refetch: refetchInvitations } = usePendingInvitationsQuery()
  const updateAbility = useUpdateAbility()

  const [accept, { loading: accepting }] = useAcceptInvitationMutation({
    variables: { id: invitation.id },
  })
  const handleAccept = useAsyncCallback(async () => {
    await accept()
    setState(InvitationState.Accepted)
    await refreshTokens()
    await updateAbility()

    addToast({
      kind: ToastKind.Success,
      content: t`profile.invitation.toast.accepted`,
    })

    if (invitation.__typename === 'AppInvitation') {
      await refetchApps()
    }

    if (invitation.__typename === 'BankInvitation') {
      await refetchBanksMetaQuery()
      await refetchBanks()
    }

    if (invitation.__typename === 'OrganisationInvitation') {
      await refetchOrgs()
    }

    await refetchInvitations()
  }, [
    accept,
    setState,
    invitation.__typename,
    refetchApps,
    refetchBanks,
    refetchOrgs,
  ])

  const [decline, { loading: declining }] = useDeclineInvitationMutation({
    variables: { id: invitation.id },
  })
  const handleDecline = useAsyncCallback(async () => {
    await decline()
    setState(InvitationState.Declined)
    addToast({
      kind: ToastKind.Info,
      content: t`profile.invitation.toast.declined`,
    })
    await refetchInvitations()
  }, [decline, setState])

  return (
    <div className="lg:flex">
      <div className="flex-2 lg:mr-30">
        {invitation.__typename === 'AppInvitation' && (
          <>
            <Trans id="profile.invitation.app">
              <span className="font-semibold">
                {invitation.publicMetadata?.authorName}
              </span>{' '}
              invited you to join{' '}
              <span className="font-semibold">{invitation.app.name}</span>{' '}
              application with the following permissions
            </Trans>
            <div className="flex flex-wrap items-start">
              {invitation.recipient.appPermissions.map((permission) => (
                <PermissionTag type="app" key={permission}>
                  {permission}
                </PermissionTag>
              ))}
            </div>
          </>
        )}
        {invitation.__typename === 'BankInvitation' && (
          <>
            <Trans id="profile.invitation.bank">
              <span className="font-semibold">
                {invitation.publicMetadata?.authorName}
              </span>{' '}
              invited you to join{' '}
              <span className="font-semibold">{invitation.bank.name}</span> bank
              with the following permissions
            </Trans>
            <div className="flex flex-wrap items-start">
              {invitation.recipient.bankPermissions.map((permission) => (
                <PermissionTag type="bank" key={permission}>
                  {permission}
                </PermissionTag>
              ))}
            </div>
          </>
        )}
        {invitation.__typename === 'OrganisationInvitation' && (
          <>
            <Trans id="profile.invitation.organization">
              <span className="font-semibold">
                {invitation.publicMetadata?.authorName}
              </span>{' '}
              invited you to join{' '}
              <span className="font-semibold">
                {invitation.organisation.name}
              </span>{' '}
              organization with the following permissions
            </Trans>
            <div className="flex flex-wrap items-start">
              {invitation.recipient.organisationPermissions.map(
                (permission) => (
                  <PermissionTag type="organization" key={permission}>
                    {permission}
                  </PermissionTag>
                )
              )}
            </div>
          </>
        )}
      </div>
      <div className="flex items-start justify-end mt-16 lg:mt-0 w-350">
        {(state === InvitationState.Pending ||
          state === InvitationState.Reminded) && (
          <>
            <Button
              kind="danger-transparent"
              onClick={handleDecline}
              loading={declining}
              data-cy-id="profile.invitation.decline"
            >
              <Trans id="profile.invitation.decline">Decline</Trans>
            </Button>
            <div className="w-16" />
            <Button
              className="truncate"
              onClick={handleAccept}
              loading={accepting}
              data-cy-id="profile.invitation.accept"
            >
              <Trans id="profile.invitation.accept">Accept invitation</Trans>
            </Button>
          </>
        )}
        {state === InvitationState.Expired && (
          <div className="flex flex-row items-center">
            <span className="text-utility-error font-semibold">
              <Trans id="profile.invitation.expired">
                The invitation has expired.
              </Trans>
            </span>
          </div>
        )}
        {state === InvitationState.Accepted && (
          <span className="text-utility-success font-semibold">
            <Trans id="profile.invitation.accepted">Invitation accepted</Trans>
          </span>
        )}
        {state === InvitationState.Declined && (
          <span className="font-semibold">
            <Trans id="profile.invitation.declined">Invitation declined</Trans>
          </span>
        )}
      </div>
    </div>
  )
}
