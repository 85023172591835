import { Trans } from '@lingui/macro'
import classNames from 'classnames'
import { Spinner, Table } from 'components'
import { TableColumn } from 'components/Table/common'
import { TicketBadge, TicketStatus } from 'containers'
import { format } from 'date-fns'
import { invert } from 'lodash'
import { TicketFilter } from 'pages/support/support'
import React, { useMemo } from 'react'
import { CreateTicketFormData, issueType, priority, TicketMeta } from 'types'

const priorities = invert(priority) as Record<
  string,
  CreateTicketFormData['priority']
>

const issueTypes = invert(issueType)

const TicketListColumns: TableColumn<TicketMeta>[] = [
  {
    key: 'name',
    title: (
      <span className="ml-16">
        <Trans id="support.tickets.ticket">Ticket</Trans>
      </span>
    ),
    width: 560,
    render: (row) => (
      <p className="ml-16 box-decoration-clone truncate">
        {row.fields.summary}
      </p>
    ),
  },
  {
    key: 'created',
    title: <Trans id="support.tickets.created">Created</Trans>,
    width: 230,
    render: (row) => (
      <div className={classNames('font-mono text-13')}>
        {format(new Date(row.fields.created), 'dd.MM.yyyy | HH:mm')}
      </div>
    ),
  },
  {
    key: 'status',
    title: <Trans id="support.tickets.status">Status</Trans>,
    width: 210,
    render: (row) => <TicketStatus status={row.fields.status.id} />,
  },
  {
    key: 'type',
    title: <Trans id="support.tickets.type">Type</Trans>,
    width: 120,
    render: (row) => (
      <>
        {row.fields.issuetype.id && issueTypes[row.fields.issuetype.id] && (
          <Trans
            id={`support.tickets.type.${issueTypes[row.fields.issuetype.id]}`}
          />
        )}
      </>
    ),
  },
  {
    key: 'priority',
    title: (
      <span className="mr-16">
        <Trans id="support.tickets.priority">Priority</Trans>
      </span>
    ),
    width: 150,
    render: (row) => (
      <span className="mr-16">
        <TicketBadge priority={priorities[row.fields.priority.id]}>
          <Trans
            id={`support.tickets.priority.${
              priorities[row.fields.priority.id]
            }`}
          />
        </TicketBadge>
      </span>
    ),
  },
]

type TicketListProps = {
  data?: TicketMeta[]
  loading: boolean
  error: boolean
  filter: TicketFilter
}

export const TicketList: React.FC<TicketListProps> = ({
  data,
  loading,
  error,
  filter,
}) => {
  const filtered = useMemo(
    () =>
      data?.filter(
        (obj) =>
          (!filter.app || obj.fields.customfield_10075 === filter.app) &&
          (!filter.org || obj.fields.customfield_10073 === filter.org)
      ) ?? [],
    [filter, data]
  )

  return (
    <div className="text-primary-darker">
      <Table
        columns={TicketListColumns}
        data={filtered}
        minWidth={1100}
        rowTo={(row) => `/support/${row.key}`}
      />
      {loading && <Spinner />}
      {error && (
        <p className="text-center text-grey-900 mt-30">
          <Trans id="support.tickets.errorLoadingTickets">
            There was an error loading tickets. Please try reloading the page.
          </Trans>
        </p>
      )}
      {!error && !loading && filtered?.length === 0 && (
        <p className="text-center text-grey-900 mt-30">
          <Trans id="support.tickets.noTickets">
            There are no tickets to show.
          </Trans>
        </p>
      )}
    </div>
  )
}
