import { Trans } from '@lingui/macro'
import { Link } from 'components'
import { QueryLoader, TicketBadge, TicketStatus } from 'containers'
import { format } from 'date-fns'
import { invert } from 'lodash'
import React from 'react'
import {
  canCloseStates,
  canReopenStates,
  CreateTicketFormData,
  issueType,
  priority,
  TicketCommentWithMetadata,
  TicketDetail,
  useAppBySlugQuery,
  useOrganizationsQuery,
  useOrgByIdQuery,
} from 'types'
import { AttachmentThumbnail } from './AttachmentThumbnail'
import { CreateCommentForm } from './CreateCommentForm'
import { JiraFormatter } from './JiraFormatter'
import {
  ReopenTicketButton,
  ResolveTicketButton,
} from './TicketTransitionButtons'

type CommentProps = {
  comment: TicketCommentWithMetadata
}

const Comment: React.FC<CommentProps> = ({ comment }) => (
  <div>
    <div className="flex flex-col sm:flex-row justify-between text-14 mb-16">
      <p className="font-semibold mb-8 sm:mb-0">
        {comment.author.email ? (
          <Link
            href={`mailto:${comment.author.email}`}
            kind="plain"
            data-cy-id={`comment.author.name.${comment.author.email}`}
          >
            {comment.author.displayName}
          </Link>
        ) : (
          <>{comment.author.displayName}</>
        )}
      </p>
      <p className="text-grey-800">
        {format(new Date(comment.created), 'dd.MM.yyyy HH:mm')}
      </p>
    </div>
    <p className="mb-16 whitespace-pre-wrap break-words">
      <JiraFormatter content={comment.body} />
    </p>
    {comment.attachments.map((attachment) => (
      <AttachmentThumbnail
        key={attachment.id}
        kind="comment"
        attachment={attachment}
      />
    ))}
  </div>
)

type AppNameFieldProps = {
  appSlug: string
}

const AppNameField: React.FC<AppNameFieldProps> = ({ appSlug }) => {
  const appQuery = useAppBySlugQuery({ variables: { slug: appSlug } })
  const appName = appQuery.data?.appBySlug?.name

  return (
    <QueryLoader
      queries={[appQuery]}
      loaderComponent={null}
      errorComponent={null}
    >
      <div className="flex mb-16">
        <div className="flex-1 text-grey-800">
          <Trans id="support.ticket.sidebar.application">Application</Trans>
        </div>
        <div className="flex-2">
          <Link
            to={`/apps/${appSlug}`}
            data-cy-id={`support.ticket.sidebar.application.${appSlug}`}
          >
            {appName}
          </Link>
        </div>
      </div>
    </QueryLoader>
  )
}

type OrgNameFieldProps = {
  orgId: string
}

const OrgNameField: React.FC<OrgNameFieldProps> = ({ orgId }) => {
  const orgQuery = useOrgByIdQuery({ variables: { orgId } })
  const org = orgQuery.data?.organisation

  const orgsQuery = useOrganizationsQuery()
  const hasOrg =
    orgsQuery.data?.currentUser?.organisations.find(
      (org) => org.organisation.id === orgId
    ) !== undefined

  return (
    <QueryLoader
      queries={[orgQuery]}
      loaderComponent={null}
      errorComponent={null}
    >
      <div className="flex mb-16">
        <div className="flex-1 truncate text-grey-800">
          <Trans id="support.ticket.sidebar.organization">Organization</Trans>
        </div>
        <div className="flex-2">
          {hasOrg ? (
            <Link
              to={`/organizations/${org?.slug}`}
              data-cy-id={`support.ticket.sidebar.application.${org?.slug}`}
            >
              {org?.name}
            </Link>
          ) : (
            org?.name
          )}
        </div>
      </div>
    </QueryLoader>
  )
}

const priorities = invert(priority) as Record<
  string,
  CreateTicketFormData['priority']
>

const issueTypes = invert(issueType)

type SidebarProps = {
  ticket: TicketDetail
}

const Sidebar: React.FC<SidebarProps> = ({ ticket }) => (
  <div>
    {canCloseStates.includes(ticket.fields.status.id) && (
      <ResolveTicketButton issueKey={ticket.key} />
    )}
    {canReopenStates.includes(ticket.fields.status.id) && (
      <ReopenTicketButton issueKey={ticket.key} />
    )}

    <div className="flex mb-16">
      <div className="flex-1 text-grey-800">
        <Trans id="support.ticket.sidebar.priority">Priority</Trans>
      </div>
      <div className="flex-2">
        <TicketBadge priority={priorities[ticket.fields.priority.id]}>
          <Trans
            id={`support.tickets.priority.${
              priorities[ticket.fields.priority.id]
            }`}
          />
        </TicketBadge>
      </div>
    </div>
    <div className="flex mb-16">
      <div className="flex-1 text-grey-800">
        <Trans id="support.ticket.sidebar.type">Type</Trans>
      </div>
      <div className="flex-2">
        {ticket.fields.issuetype.id &&
          issueTypes[ticket.fields.issuetype.id] && (
            <Trans
              id={`support.tickets.type.${
                issueTypes[ticket.fields.issuetype.id]
              }`}
            />
          )}
      </div>
    </div>
    <div className="flex mb-16">
      <div className="flex-1 text-grey-800">
        <Trans id="support.ticket.sidebar.reporter">Reporter</Trans>
      </div>
      <div className="flex-2 truncate">
        {ticket.fields.reporter.displayName}
      </div>
    </div>
    <div className="flex mb-16">
      <div className="flex-1 text-grey-800">
        <Trans id="support.ticket.sidebar.created">Created</Trans>
      </div>
      <div className="flex-2">
        {format(new Date(ticket.fields.created), 'dd.MM.yyyy HH:mm')}
      </div>
    </div>
    {ticket.fields.customfield_10074 && (
      <AppNameField appSlug={ticket.fields.customfield_10074} />
    )}
    {ticket.fields.customfield_10073 && (
      <OrgNameField orgId={ticket.fields.customfield_10073} />
    )}
    <div className="flex mb-16">
      <div className="flex-1 text-grey-800">
        <Trans id="support.ticket.sidebar.status">Status</Trans>
      </div>
      <div className="flex-2">
        <TicketStatus status={ticket.fields.status.id} />
      </div>
    </div>
  </div>
)

type TicketProps = {
  ticket: TicketDetail
}

export const Ticket: React.FC<TicketProps> = ({ ticket }) => (
  <>
    <div className="flex-2 px-40 max-w-full lg:w-70">
      <h1 className="heading-h1 mb-32 break-words">{ticket.fields.summary}</h1>
      <div className="flex-1 mb-40 lg:hidden">
        <Sidebar ticket={ticket} />
      </div>
      <p className="mb-32 whitespace-pre-wrap break-words">
        <JiraFormatter content={ticket.fields.description} />
      </p>

      {ticket.fields.attachment.length > 0 && (
        <>
          <h3 className="font-medium mb-6">
            <Trans id="support.ticket.attachments">Attachments</Trans>
          </h3>
          <div className="flex flex-wrap -mx-12 mb-40">
            {ticket.fields.attachment.map((attachment) => (
              <AttachmentThumbnail
                key={attachment.id}
                attachment={attachment}
              />
            ))}
          </div>
        </>
      )}

      {ticket.fields.comment.comments.map((comment, i) => (
        <React.Fragment key={comment.id}>
          <Comment comment={comment} />
          {i < ticket.fields.comment.comments.length - 1 && (
            <hr className="mb-16" />
          )}
        </React.Fragment>
      ))}
      <div className="mb-40" />
      <CreateCommentForm issueKey={ticket.key} />
    </div>
    <div className="-my-48 border-r border-grey-300" />
    <div className="flex-1 px-40 hidden lg:block">
      <Sidebar ticket={ticket} />
    </div>
  </>
)
