import { Trans } from '@lingui/macro'
import classNames from 'classnames'
import { Button, ButtonKind } from 'components/Clickable'
import React, { useCallback, useState } from 'react'
import { Modal } from './Modal'

type ConfirmationModalProps = {
  title?: React.ReactNode
  text?: React.ReactNode
  kind?: ButtonKind
  children: (open: () => void) => React.ReactNode
  onConfirm: () => Promise<void>
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  title,
  text,
  kind,
  children,
  onConfirm,
}) => {
  const [open, setOpen] = useState(false)
  const handleToggle = useCallback(() => setOpen((open) => !open), [setOpen])
  const handleConfirm = useCallback(() => {
    onConfirm()
    handleToggle()
  }, [onConfirm, handleToggle])

  return (
    <>
      {children(handleToggle)}
      <Modal
        title={title}
        data-cy-id="common.confirmationModal"
        isOpen={open}
        onClose={handleToggle}
      >
        {text}
        <div className={classNames('flex justify-center', text && 'mt-20')}>
          <Button
            data-cy-id="common.confirmationModal.confirm"
            kind={kind}
            onClick={handleConfirm}
            className="mr-16"
          >
            <Trans id="common.confirmationModal.confirm">Confirm</Trans>
          </Button>
          <Button
            data-cy-id="common.confirmationModal.cancel"
            kind="secondary"
            onClick={handleToggle}
          >
            <Trans id="common.confirmationModal.cancel">Cancel</Trans>
          </Button>
        </div>
      </Modal>
    </>
  )
}

ConfirmationModal.defaultProps = {
  title: <Trans id="common.confirmationModal.title">Are you sure?</Trans>,
}
