import { t, Trans } from '@lingui/macro'
import { Button, ButtonKind, ConfirmationModal } from 'components'
import { QueryLoader } from 'containers/QueryLoader'
import { useToasts } from 'containers/Toast'
import React from 'react'
import {
  AppEnvironmentState,
  AppEnvironmentType,
  ToastKind,
  useActivateAppEnvMutation,
  useAppCredentialsQuery,
  useAppMetaQuery,
  useAppProductionQuery,
  useDeactivateAppEnvMutation,
  useGenerateClientSecretMutation,
} from 'types'
import { useAsyncCallback } from 'utils/handleError'

type ActivateButtonProps = {
  envId: string
  appSlug: string
  kind?: ButtonKind
  className?: string
}

export const ActivateAppEnvButton: React.FC<ActivateButtonProps> = ({
  envId,
  appSlug,
  kind,
  className,
}) => {
  const { refetch: refetchAppMeta } = useAppMetaQuery({
    variables: { slug: appSlug },
  })
  const { refetch: refetchAppProduction } = useAppProductionQuery({
    variables: { slug: appSlug },
  })

  const [activateAppEnv] = useActivateAppEnvMutation({ variables: { envId } })
  const handleActivate = useAsyncCallback(async () => {
    await activateAppEnv()
    await refetchAppMeta()
    await refetchAppProduction()
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [activateAppEnv])

  return (
    <Button
      kind={kind}
      data-cy-id="app.environment.credentials.activateButton"
      onClick={handleActivate}
      className={className}
    >
      <Trans id="app.environment.credentials.activateButton">
        Reactivate production environment
      </Trans>
    </Button>
  )
}

type DeactivateButtonProps = {
  envId: string
  appSlug: string
}

const DeactivateAppEnvButton: React.FC<DeactivateButtonProps> = ({
  envId,
  appSlug,
}) => {
  const { refetch: refetchAppMeta } = useAppMetaQuery({
    variables: { slug: appSlug },
  })
  const { refetch: refetchAppProduction } = useAppProductionQuery({
    variables: { slug: appSlug },
  })

  const [deactivateAppEnv] = useDeactivateAppEnvMutation({
    variables: { envId },
  })
  const handleDeactivate = useAsyncCallback(async () => {
    await deactivateAppEnv()
    await refetchAppMeta()
    await refetchAppProduction()
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [deactivateAppEnv])

  return (
    <ConfirmationModal
      onConfirm={handleDeactivate}
      text={
        <Trans id="app.environment.deactive.confirmationText">
          Are you sure you want to deactivate the production environment? It
          will not be possible to call any Bank iD endpoints from now on.
        </Trans>
      }
    >
      {(openConfirmation) => (
        <Button
          kind="danger"
          data-cy-id="app.environment.credentials.deactivateButton"
          onClick={openConfirmation}
        >
          <Trans id="app.environment.credentials.deactivateButton">
            Deactivate production environment
          </Trans>
        </Button>
      )}
    </ConfirmationModal>
  )
}

type RegenerateSecretButtonProps = {
  envId: string
  envType: AppEnvironmentType
  appSlug: string
}

const RegenerateSecretButton: React.FC<RegenerateSecretButtonProps> = ({
  envId,
  envType,
  appSlug,
}) => {
  const [generateClientSecret] = useGenerateClientSecretMutation({
    variables: { envId },
  })

  const { refetch } = useAppCredentialsQuery({
    variables: {
      slug: appSlug,
      sandbox: envType === AppEnvironmentType.Sandbox,
      production: envType === AppEnvironmentType.Production,
    },
  })

  const { addToast } = useToasts()

  const handleGenerateSecret = useAsyncCallback(async () => {
    await generateClientSecret()
    await refetch()
    window.scrollTo({ top: 0, behavior: 'smooth' })
    addToast({
      kind: ToastKind.Success,
      content: t`app.environment.regenerateSecret.toastSuccess`,
    })
  }, [generateClientSecret])

  return (
    <ConfirmationModal
      onConfirm={handleGenerateSecret}
      text={
        <Trans id="app.environment.regenerateSecret.confirmationText">
          Are you sure you want to generate a new client secret? Your app will
          stop working until you change the secret.
        </Trans>
      }
    >
      {(openConfirmation) => (
        <Button
          kind="danger"
          data-cy-id="app.environment.credentials.regenerateClientSecretButton"
          onClick={openConfirmation}
        >
          <Trans id="app.environment.credentials.regenerateClientSecretButton">
            Regenerate client secret
          </Trans>
        </Button>
      )}
    </ConfirmationModal>
  )
}

type AppDangerZoneProps = {
  appSlug: string
  envId: string
  envType: AppEnvironmentType
  envState: AppEnvironmentState
}

export const AppDangerZone: React.FC<AppDangerZoneProps> = ({
  appSlug,
  envId,
  envType,
  envState,
}) => {
  const credentialsQuery = useAppCredentialsQuery({
    variables: {
      slug: appSlug,
      sandbox: envType === AppEnvironmentType.Sandbox,
      production: envType === AppEnvironmentType.Production,
    },
  })
  const envSlug =
    envType === AppEnvironmentType.Production ? 'production' : 'sandbox'

  const showDeactivateProduction =
    envType === AppEnvironmentType.Production &&
    (envState === AppEnvironmentState.Activated ||
      envState === AppEnvironmentState.Unsynchronized)
  const showActivateProduction = envState === AppEnvironmentState.Deactivated

  // can regenerate secret only if active and a secret exists
  const showRegenerateSecret =
    envState === AppEnvironmentState.Activated &&
    credentialsQuery.data?.appBySlug?.[envSlug]?.credentials?.clientSecret

  return (
    <QueryLoader queries={[credentialsQuery]}>
      {(showActivateProduction ||
        showDeactivateProduction ||
        showRegenerateSecret) && (
        <h2 className="heading-h2 mt-60">
          <Trans id="app.environment.credentials.dangerZone">Danger Zone</Trans>
        </h2>
      )}

      {showDeactivateProduction && (
        <>
          <p className="my-20 text-grey-900">
            <Trans id="app.environment.credentials.deactivateText">
              By deactivating your app&apos; s production environment,
              immediately turn off your app&apos; s production access.It be
              possible to call the Authorization Endpoint or the Token Endpoint.
            </Trans>
          </p>
          <DeactivateAppEnvButton envId={envId} appSlug={appSlug} />
        </>
      )}

      {showActivateProduction && (
        <>
          <p className="my-20 text-grey-900">
            <Trans id="app.environment.credentials.activateText">
              If there are no more reasons to deactivate the application, you
              activate it again here.After activating, you will need to reapply
              changes to the production environment to confirm.
            </Trans>
          </p>
          <ActivateAppEnvButton envId={envId} appSlug={appSlug} />
        </>
      )}

      {showRegenerateSecret && (
        <>
          <p className="my-20 text-grey-900">
            <Trans id="app.environment.credentials.regenerateSecretText">
              In case client secret is leaked, you are required to generate a
              new client secret. A new client secret will be generated for you
              once you press the regenerate secret button.
            </Trans>
          </p>
          <RegenerateSecretButton
            envId={envId}
            envType={envType}
            appSlug={appSlug}
          />
        </>
      )}
    </QueryLoader>
  )
}
