import { FileThumbnail, FileThumbnailProps } from 'components'
import getConfig from 'next/config'
import qs from 'qs'
import React from 'react'
import { TicketAttachment } from 'types'

const BASE_URL = getConfig().publicRuntimeConfig.BASE_URL

type AttachmentThumbnailProps = {
  attachment: TicketAttachment
  kind?: FileThumbnailProps['kind']
}

export const AttachmentThumbnail: React.FC<AttachmentThumbnailProps> = ({
  attachment,
  kind,
}) => {
  const url = `${BASE_URL}/api/tickets/attachment?${qs.stringify({
    id: attachment.id,
    filename: attachment.filename,
  })}`

  return (
    <FileThumbnail
      key={attachment.id}
      kind={kind}
      filename={attachment.filename}
      url={`data:${attachment.mimeType};base64,${attachment.thumbnail}`}
      link={url}
    />
  )
}
