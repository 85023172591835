import { t, Trans } from '@lingui/macro'
import { Breadcrumbs, Markdown } from 'components'
import React from 'react'
import { useMarkdownDocumentQuery } from 'types/cms'
import { useWatch } from 'utils/hooks'
import { useLocale } from 'utils/withLang'
import { Meta } from './Layout/Meta'
import { MarkdownNavigation } from './MarkdownNavigation'
import { QueryLoader } from './QueryLoader'

interface MarkdownDocumentProps {
  slug: string
}

export const MarkdownDocument: React.FC<MarkdownDocumentProps> = ({ slug }) => {
  const locale = useLocale()
  const markdownDocumentQuery = useMarkdownDocumentQuery({
    variables: { slug, locale },
    context: { endpoint: 'cms' },
  })
  const doc = markdownDocumentQuery.data?.markdownDocuments?.data?.[0]
  const [ref, headings] = useWatch<HTMLDivElement>('h1, h2')

  return (
    <>
      <Meta title={doc?.attributes?.title ?? t`documents.title`} />
      <Breadcrumbs
        path={[
          {
            text: <Trans id="docs.title">Documentation</Trans>,
            path: '/docs',
          },
          {
            text: doc?.attributes?.title,
            path: `/docs/${slug}`,
          },
        ]}
      />
      <div className="flex flex-col lg:flex-row w-full mt-24">
        <MarkdownNavigation activeId={slug} headings={headings} />
        <QueryLoader queries={[markdownDocumentQuery]}>
          <div className="mr-40" />
          {doc?.attributes?.content && (
            <Markdown ref={ref}>{doc.attributes?.content}</Markdown>
          )}
          {!doc?.attributes?.content && (
            <Trans id="document.notFound">Document not found</Trans>
          )}
        </QueryLoader>
      </div>
    </>
  )
}
