import { t, Trans } from '@lingui/macro'
import {
  Code,
  Image,
  Link,
  TooltipWithIndicator,
  useLightBox,
} from 'components'
import React from 'react'
import {
  AppConfigDiffFragment,
  AppEnvironmentScopeDiffTypeFragment,
  BankConfigDiffFragment,
  BooleanDiffTypeFragment,
  MediaDiffTypeFragment,
  ProductsInfoDiffTypeFragment,
  ScopeLevel,
  StringDiffTypeFragment,
  TariffDiff,
  TariffTypeFragment,
  TokenEndpointAuthMethodDiffTypeFragment,
} from 'types'
import { getImageUrl } from 'utils/url'

// not a config field
t`app.environment.config.products`

type DiffItemType =
  | MediaDiffTypeFragment
  | StringDiffTypeFragment
  | AppEnvironmentScopeDiffTypeFragment
  | BooleanDiffTypeFragment
  | TokenEndpointAuthMethodDiffTypeFragment
  | ProductsInfoDiffTypeFragment
  | TariffDiff

type DiffType =
  | DiffItemType
  | DiffItemType[]
  | undefined
  | null
  | 'AppEnvironmentConfigDiff'
  | 'BankEnvironmentConfigDiff'

interface DiffValueProps {
  value: DiffItemType['new'] | DiffItemType['original']
}

const CodeWithEmptyStringIndicator: React.FC<DiffValueProps> = ({ value }) => (
  <Code className="px-6 -mt-4 break-all">
    {value + '' === '' ? (
      <span className="italic">
        <Trans id="app.environments.config.diff.emptyString">
          &lt;empty&gt;
        </Trans>
      </span>
    ) : (
      value + ''
    )}
  </Code>
)

const DiffValue: React.FC<DiffValueProps> = ({ value }) => {
  const [showPreview, ref] = useLightBox()

  if (Array.isArray(value)) {
    return (
      <div className="flex space-x-4 mt-4">
        {value.map((product) => (
          <CodeWithEmptyStringIndicator
            key={product.slug}
            value={product.name}
          />
        ))}
      </div>
    )
  }

  if (typeof value === 'object' && value?.__typename === 'Media') {
    return (
      <div className="mx-8 rounded bg-grey-200 border border-grey-300 p-2 flex">
        <Link
          kind="plain"
          onClick={showPreview?.(getImageUrl(value))}
          data-cy-id="diff.image.showPreview"
        >
          <Image
            media={value}
            size={40}
            className="object-cover"
            alt={t`app.environments.config.diff.logo.alt`}
            ref={ref}
          />
        </Link>
      </div>
    )
  }

  return <CodeWithEmptyStringIndicator value={value} />
}

interface GenericDiffPairProps {
  item: DiffItemType
}

const GenericDiffPair: React.FC<GenericDiffPairProps> = ({ item }) => {
  if (item.original !== null && item.new !== null) {
    // changed
    return (
      <Trans id="app.environments.config.diff.changed">
        Updated from{<>&nbsp;</>}
        <DiffValue value={item.original} />
        {<>&nbsp;</>}
        to{<>&nbsp;</>}
        <DiffValue value={item.new} />
      </Trans>
    )
  }

  if (item.original === null && item.new !== null) {
    // added
    return (
      <Trans id="app.environments.config.diff.added">
        Added{<>&nbsp;</>}
        <DiffValue value={item.new} />
      </Trans>
    )
  }

  if (item.original !== null && item.new === null) {
    // removed
    return (
      <Trans id="app.environments.config.diff.removed">
        Removed{<>&nbsp;</>}
        <DiffValue value={item.original} />
      </Trans>
    )
  }

  // at this point item should have been null
  return null
}

interface AppProductProps {
  item: ProductsInfoDiffTypeFragment
}

const AppProductLabel: React.FC<AppProductProps> = ({ item }) => {
  if (item.original.length === 0 && item.new.length !== 0) {
    return (
      <Trans id="app.environments.config.diff.product">
        Product{<>&nbsp;</>}
        <DiffValue value={item.new} />
        {<>&nbsp;</>}selected
      </Trans>
    )
  }

  if (item.original.length === 0 && item.new.length === 0) {
    return (
      <Trans id="app.environments.config.diff.noproduct">
        No product selected
      </Trans>
    )
  }

  return null
}

interface ScopeLevelProps {
  level?: ScopeLevel
}

const ScopeLevelLabel: React.FC<ScopeLevelProps> = ({ level }) => (
  <>
    {level === ScopeLevel.Required && (
      <span className="font-mono text-utility-red">
        <Trans id="app.environments.config.scopes.required" />
      </span>
    )}
    {level === ScopeLevel.Optional && (
      <span className="font-mono text-primary">
        <Trans id="app.environments.config.scopes.optional" />
      </span>
    )}
    {level === ScopeLevel.Unused && (
      <span className="font-mono">
        <Trans id="app.environments.config.scopes.unused" />
      </span>
    )}
  </>
)

interface ScopeDiffPairProps {
  scopeDiff: AppEnvironmentScopeDiffTypeFragment
}

const ScopeDiffPair: React.FC<ScopeDiffPairProps> = ({ scopeDiff }) => {
  if (scopeDiff.original !== null && scopeDiff.new !== null) {
    // changed
    return (
      <Trans id="app.environments.config.diff.changedScope">
        Scope <Code className="px-6">{scopeDiff.original?.type.scope}</Code>{' '}
        updated from <ScopeLevelLabel level={scopeDiff.original?.scopeLevel} />{' '}
        to <ScopeLevelLabel level={scopeDiff.new?.scopeLevel} />
      </Trans>
    )
  }

  if (scopeDiff.original === null && scopeDiff.new !== null) {
    return (
      <Trans id="app.environments.config.diff.addedScope">
        Added <ScopeLevelLabel level={scopeDiff.new?.scopeLevel} /> scope{' '}
        <Code className="px-6">{scopeDiff.new?.type.scope}</Code>
      </Trans>
    )
  }

  if (scopeDiff.original !== null && scopeDiff.new === null) {
    // removed
    return (
      <Trans id="app.environments.config.diff.removedScope">
        Removed scope{' '}
        <Code className="px-6">{scopeDiff.original?.type.scope}</Code>
      </Trans>
    )
  }

  // at this point item should have been null
  return null
}

interface TariffDiffLableProps {
  item: TariffTypeFragment
}

const TariffDiffLable: React.FC<TariffDiffLableProps> = ({ item }) => {
  const getTariffName = (tariff: any) => {
    switch (tariff) {
      case 'PER_CALL':
        return t({ id: 'app.environments.config.diff.perCall' })
      case 'PER_USER':
        return t({ id: 'app.environments.config.diff.perUser' })
    }
  }

  if (item.original !== null && item.new !== null) {
    // changed
    return (
      <Trans id="app.environments.config.diff.changed">
        Updated from{<>&nbsp;</>}
        <DiffValue value={getTariffName(item.original)} />
        {<>&nbsp;</>}
        to{<>&nbsp;</>}
        <DiffValue value={getTariffName(item.new)} />
      </Trans>
    )
  }

  // at this point item should have been null
  return null
}

interface DiffItemProps {
  value: DiffItemType
}

const DiffItem: React.FC<DiffItemProps> = ({ value }) => {
  if (
    typeof value === 'object' &&
    value.__typename === 'AppEnvironmentScopeDiff'
  ) {
    return <ScopeDiffPair scopeDiff={value} />
  }

  if (
    typeof value === 'object' &&
    value.__typename === 'ProductsInfoDiff' &&
    value.original.length === 0
  ) {
    return <AppProductLabel item={value} />
  }

  if (typeof value === 'object' && value.__typename === 'TariffDiff') {
    return <TariffDiffLable item={value} />
  }

  return <GenericDiffPair item={value} />
}

interface DiffDetailProps {
  value: DiffType
}

const DiffDetail: React.FC<DiffDetailProps> = ({ value }) => {
  if (
    !value ||
    value === 'AppEnvironmentConfigDiff' ||
    value === 'BankEnvironmentConfigDiff'
  ) {
    return null
  }

  if (Array.isArray(value)) {
    return (
      <div>
        {value.map((item, i) => (
          <div key={i}>
            <DiffItem value={item} />
          </div>
        ))}
      </div>
    )
  }

  return <DiffItem value={value} />
}

interface DiffRowProps {
  index: string
  value: DiffType
  type: 'app' | 'bank'
}

const DiffRow: React.FC<DiffRowProps> = ({ index, value, type }) => {
  if (index === '__typename' || value === null) {
    return null
  }

  if (Array.isArray(value) && !value.length) {
    return null
  }

  return (
    <div
      className="flex flex-col lg:flex-row justify-between text-14 mb-10"
      data-cy-id={`diff-${index}`}
    >
      <div className="flex items-center text-grey-900 lg:w-1/4 mr-10 break-words">
        <Trans id={`${type}.environment.config.${index}`} />

        {/* explain that products are changed in app, not in env config */}
        {index === 'products' && (
          <TooltipWithIndicator
            kind="dark"
            displayTip={
              <Trans id="app.environment.diff.productsOriginTooltip">
                Products are set for the whole app under the Products page. If
                products are changed, the change will be applied to the
                production environment the next time changes are applied.
                Product changes cannot be discarded.
              </Trans>
            }
            id="app.environment.diff.productsOriginTooltip"
            className="-mt-4"
          />
        )}
      </div>
      <div className="w-3/4 break-normal -mt-2 flex flex-wrap items-center leading-40">
        <DiffDetail value={value} />
      </div>
    </div>
  )
}

interface ConfigDiffProps {
  diff:
    | NonNullable<AppConfigDiffFragment['pendingConfigChanges']>
    | NonNullable<BankConfigDiffFragment['pendingConfigChanges']>
  type: 'app' | 'bank'
}

export const ConfigDiff: React.FC<ConfigDiffProps> = ({ diff, type }) => (
  <>
    {Object.entries(diff).map(([key, value]) => (
      <DiffRow key={key} index={key} value={value} type={type} />
    ))}
  </>
)
