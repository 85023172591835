import {
  Ability,
  detectSubjectType as caslDetect,
  Subject,
} from '@casl/ability'
import { createContextualCan } from '@casl/react'
import {
  UserInAppMetaQuery,
  UserInBankMetaQuery,
  UserInOrgMetaQuery,
} from 'types'
import CaslClient from 'data/CaslClient'
import React, {
  ComponentProps,
  createContext,
  useCallback,
  useContext,
} from 'react'
import { Trans } from '@lingui/macro'

export const detectSubjectType = (subject: any) => {
  if (subject && typeof subject === 'object' && subject.__typename) {
    return subject.__typename
  }

  return caslDetect(subject)
}

export const AbilityContext = createContext<Ability>(null!)
export const useAbility = () => useContext(AbilityContext)

export function useUpdateAbility() {
  const ability = useAbility()

  return useCallback(async () => {
    const res = await CaslClient.getRules()
    window.RULES = res.data.rules
    ability.update(res.data.rules)
  }, [ability])
}

export const Can = createContextualCan(AbilityContext.Consumer)

// context-specific Can components
export const UserInAppContext = createContext<
  UserInAppMetaQuery['currentUserInApp'] | null | undefined
>(null)
export const UserInBankContext = createContext<
  UserInBankMetaQuery['userInBank'] | null | undefined
>(null)
export const UserInOrgContext = createContext<
  UserInOrgMetaQuery['userInOrganisation'] | null | undefined
>(null)

type ContextAuthorizedProps = Omit<ComponentProps<typeof Can>, 'this'> & {
  I: string
}

export const AppAuthorized: React.FC<ContextAuthorizedProps> = ({
  children,
  ...props
}) => {
  const userInApp = useContext(UserInAppContext)

  return userInApp ? (
    <>
      <Can this={userInApp} {...props}>
        {children}
      </Can>
      <Can not this={userInApp} {...props}>
        <h2 className="heading-h2 mb-16 mt-40">
          <Trans id="permissions.notAllowedToRead">
            You don&apos;t have permission to view this page
          </Trans>
        </h2>
      </Can>
    </>
  ) : null
}

export const BankAuthorized: React.FC<ContextAuthorizedProps> = ({
  children,
  ...props
}) => {
  const userInBank = useContext(UserInBankContext)

  return userInBank ? (
    <>
      <Can this={userInBank} {...props}>
        {children}
      </Can>
      <Can not this={userInBank} {...props}>
        <h2 className="heading-h2 mb-16 mt-40">
          <Trans id="permissions.notAllowedToRead">
            You don&apos;t have permission to view this page
          </Trans>
        </h2>
      </Can>
    </>
  ) : null
}

export const OrgAuthorized: React.FC<ContextAuthorizedProps> = ({
  children,
  ...props
}) => {
  const userInOrg = useContext(UserInOrgContext)

  return userInOrg ? (
    <>
      <Can this={userInOrg} {...props}>
        {children}
      </Can>
      <Can not this={userInOrg} {...props}>
        <h2 className="heading-h2 mb-16 mt-40">
          <Trans id="permissions.notAllowedToRead">
            You don&apos;t have permission to view this page
          </Trans>
        </h2>
      </Can>
    </>
  ) : null
}

function checkMultiplePermissions(
  actions: string | string[],
  subject: Subject | null | undefined,
  ability: Ability
) {
  if (!subject) {
    return false
  }

  if (typeof actions === 'string') {
    return ability.can(actions, subject)
  }

  return actions.some((action) => ability.can(action, subject))
}

export function useAppPermissions() {
  const ability = useAbility()
  const userInApp = useContext(UserInAppContext)

  return useCallback(
    (actions) => checkMultiplePermissions(actions, userInApp, ability),
    [ability, userInApp]
  )
}

export function useBankPermissions() {
  const ability = useAbility()
  const userInBank = useContext(UserInBankContext)

  return useCallback(
    (actions) => checkMultiplePermissions(actions, userInBank, ability),
    [ability, userInBank]
  )
}

export function useOrgPermissions() {
  const ability = useAbility()
  const userInOrg = useContext(UserInOrgContext)

  return useCallback(
    (actions) => checkMultiplePermissions(actions, userInOrg, ability),
    [ability, userInOrg]
  )
}
