import { t, Trans } from '@lingui/macro'
import { Select, SelectProps } from 'components'
import React, { forwardRef } from 'react'
import { ClientType } from 'types'

type ClientTypeLabels = {
  [key in ClientType]: string
}

const labels: ClientTypeLabels = {
  AML_COMP: t`app.environment.config.client_type.AML_COMP`,
  BANK: t`app.environment.config.client_type.BANK`,
  FINANCIAL_COMP: t`app.environment.config.client_type.FINANCIAL_COMP`,
  NONPROFIT_ORG: t`app.environment.config.client_type.NONPROFIT_ORG`,
  OTHER_COMP: t`app.environment.config.client_type.OTHER_COMP`,
  PUBLIC_ENT: t`app.environment.config.client_type.PUBLIC_ENT`,
}

const options = Object.entries(labels).map(([value, label]) => ({
  value,
  label,
}))

export const ClientTypeSelect = forwardRef<
  HTMLSelectElement,
  Omit<SelectProps, 'name' | 'options'>
>((props, ref) => (
  <Select
    ref={ref}
    rules={{ required: true }}
    className="my-16 overflow-hidden"
    options={options}
    name="client_type"
    label={<Trans id="app.environment.config.client_type">Client Type</Trans>}
    labelTooltip={t`app.environment.config.client_type.tooltip`}
    {...props}
  />
))
