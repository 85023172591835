import { t, Trans } from '@lingui/macro'
import { Button } from 'components'
import { useToasts } from 'containers'
import React from 'react'
import {
  ToastKind,
  useAppMetaQuery,
  useUserInAppQuery,
  useUsersInAppQuery,
} from 'types'
import { useAsyncCallback } from 'utils/handleError'
import { Router } from 'utils/router'
import { makeFullName } from 'utils/utils'

interface TransferOwnerProps {
  userId: string
  slug: string
  type: 'app' | 'bank'
}

export const TransferOwner: React.FC<TransferOwnerProps> = ({
  userId,
  slug,
  type,
}) => {
  const appQuery = useAppMetaQuery({ variables: { slug } })
  const app = appQuery.data?.appBySlug

  const userInAppQuery = useUserInAppQuery({
    variables: {
      slug,
      userId,
    },
  })
  const { user } = userInAppQuery.data?.userInApp || {}
  const fullName = makeFullName(user?.firstName, user?.lastName)

  const { refetch: refetchUsersInApp } = useUsersInAppQuery({
    variables: { slug },
  })

  const { addToast } = useToasts()

  const handleOwnershipTransfer = useAsyncCallback(async () => {
    if (!app?.id) {
      throw new Error('Invalid user ID')
    }
    if (!user?.id) {
      throw new Error('Invalid app ID')
    }

    addToast({
      kind: ToastKind.Success,
      content: t`app.users.transferOwner.success`,
    })

    await refetchUsersInApp()

    Router.pushRoute('app users', { slug })
  }, [app, user, addToast, refetchUsersInApp, Router])

  return (
    <>
      <p>
        <Trans id="app.users.transferOwner.confirmation">
          You are about to transfer ownership of <strong>{app?.name}</strong> to{' '}
          <strong>{fullName}</strong> ({user?.email}). Are you sure?
        </Trans>
      </p>
      <div className="mb-24" />
      <Button
        onClick={handleOwnershipTransfer}
        data-cy-id="app.users.transferOwner"
        kind="danger"
      >
        <Trans id="app.users.transferOwner">Transfer ownership</Trans>
      </Button>
    </>
  )
}
