import React from 'react'
import { Trans } from '@lingui/macro'
import { RadioInput, Table } from 'components'
import { TableColumn } from 'components/Table/common'
import { AppEnvironmentScope, AvailabilityType, ScopeLevel } from 'types'

interface ScopeRadioButtonProps {
  value: ScopeLevel
  handleUpdate: (level: ScopeLevel) => void
  checked: boolean
}

const ScopeRadioButton: React.FC<ScopeRadioButtonProps> = ({
  value,
  handleUpdate,
  checked,
}) => {
  return (
    <RadioInput
      name={'allScopes'}
      value={value}
      checked={checked}
      onChange={() => handleUpdate(value)}
    />
  )
}

type ScopesTableColumnsProps = {
  id: ''
  allScopesSelected: {
    unused: boolean
    optional: boolean
    required: boolean
  }
  handleUpdate: (level: ScopeLevel) => void
}

const ScopesTableColumns: TableColumn<ScopesTableColumnsProps>[] = [
  {
    key: 'allScopes',
    width: 'auto',
    render: (row) => (
      <Trans id="app.environments.config.scopes.all">
        Set all available scopes
      </Trans>
    ),
  },
  {
    key: 'unused',
    title: <Trans id="app.environments.config.scopes.unused">Unused</Trans>,
    align: 'center',
    width: 80,
    render: (row) => (
      <ScopeRadioButton
        value={ScopeLevel.Unused}
        checked={row.allScopesSelected.unused}
        handleUpdate={row.handleUpdate}
      />
    ),
  },
  {
    key: 'optional',
    title: <Trans id="app.environments.config.scopes.optional">Optional</Trans>,
    align: 'center',
    width: 80,
    render: (row) => (
      <ScopeRadioButton
        value={ScopeLevel.Optional}
        checked={row.allScopesSelected.optional}
        handleUpdate={row.handleUpdate}
      />
    ),
  },
  {
    key: 'required',
    title: <Trans id="app.environments.config.scopes.required">Required</Trans>,
    align: 'center',
    width: 80,
    render: (row) => (
      <ScopeRadioButton
        value={ScopeLevel.Required}
        checked={row.allScopesSelected.required}
        handleUpdate={row.handleUpdate}
      />
    ),
  },
]

type SetAllScopesProps = {
  handleUpdate: (level: ScopeLevel) => void
  appScopes: AppEnvironmentScope[]
  isTariff: boolean
  envSlug: 'production' | 'sandbox'
}

export const SetAllScopes: React.FC<SetAllScopesProps> = ({
  handleUpdate,
  appScopes,
  isTariff,
  envSlug,
}) => {
  // Get scopes that are available in the current tariff
  const isScopeAvailable = (scope: AppEnvironmentScope) =>
    (!isTariff && scope.available.includes(AvailabilityType.Call)) ||
    (isTariff && scope.available.includes(AvailabilityType.User))

  const availableScopes = appScopes.filter((scope) =>
    envSlug === 'production' ? isScopeAvailable(scope) : true
  )

  // Function to count scopes that are both at a specific level and whose type includes that level in its available levels.
  const countScopesByLevel = (level: ScopeLevel) =>
    availableScopes.filter(
      (scope) =>
        scope.scopeLevel === level && scope.type.availableLevels.includes(level)
    ).length

  // Function to count the total number of scopes whose type includes a specific level in its available levels.
  const totalScopesByLevel = (level: ScopeLevel) =>
    availableScopes.filter((scope) =>
      scope.type.availableLevels.includes(level)
    ).length

  // Object to check if all scopes are selected for each level ('Unused', 'Optional', 'Required').
  const allScopesSelected = {
    // Compares the count of scopes at 'Unused' level with the total count of scopes whose type includes 'Unused'.
    unused:
      countScopesByLevel(ScopeLevel.Unused) ===
      totalScopesByLevel(ScopeLevel.Unused),

    // Compares the count of scopes at 'Optional' level with the total count of scopes whose type includes 'Optional'.
    optional:
      countScopesByLevel(ScopeLevel.Optional) ===
      totalScopesByLevel(ScopeLevel.Optional),

    // Compares the count of scopes at 'Required' level with the total count of scopes whose type includes 'Required'.
    required:
      countScopesByLevel(ScopeLevel.Required) ===
      totalScopesByLevel(ScopeLevel.Required),
  }

  return (
    <div className="px-20 pb-10">
      <Table
        minWidth={800}
        columns={ScopesTableColumns}
        data={[{ allScopesSelected, handleUpdate }]}
      />
    </div>
  )
}
