import axios, { AxiosResponse } from 'axios'
import { JWTPayload } from 'jose/types'
import jwtDecode from 'jwt-decode'
import { NextApiResponse, NextPageContext } from 'next'
import getConfig from 'next/config'
import { destroyCookie } from 'nookies'
import qs from 'qs'
import { setCookie } from './cookie'

export type JWT = {
  exp: number
  azp: string
  realm_access?: {
    roles?: string[]
  }
  email: string
  session_state: string
}

export type Token = {
  token: string
  expiration: Date
  parsed: JWT
}

export type Tokens = {
  accessToken?: Token
  idToken?: Token
  refreshToken?: Token | string // encrypted client-side
  refreshTokenExp?: Date
}

export type SerializedTokens = {
  access_token?: string
  id_token?: string
  refresh_token?: string
  refresh_token_exp?: string
}

export function tokenExpiresIn(token: Token) {
  return Math.max(0, token.expiration.getTime() - Date.now())
}

export function expiresIn(date: string) {
  return Math.max(0, new Date(date).getTime() - Date.now())
}

export function parseToken(token: string): Token {
  const parsed = jwtDecode<JWTPayload>(token)

  if (parsed?.exp) {
    return {
      token,
      parsed: parsed as any,
      expiration: new Date(parsed.exp * 1000),
    }
  } else {
    throw new Error('Failed to parse JWT')
  }
}

export function parseTokens(
  cookies: Record<string, string> | SerializedTokens
): Tokens {
  return {
    accessToken:
      (cookies.access_token && parseToken(cookies.access_token)) || undefined,
    idToken: (cookies.id_token && parseToken(cookies.id_token)) || undefined,
    refreshToken: cookies.refresh_token, // do not parse encrypted refresh token
    refreshTokenExp: cookies.refresh_token_exp
      ? new Date(cookies.refresh_token_exp)
      : undefined,
  }
}

export type NextContextType =
  | Pick<NextPageContext, 'res'>
  | {
      res: NextApiResponse
    }
  | undefined

export function saveTokens(ctx: NextContextType, tokens?: Tokens) {
  if (!tokens) {
    destroyCookie(ctx, 'refresh_token_exp')
    destroyCookie(ctx, 'refresh_token')
    destroyCookie(ctx, 'access_token')
    destroyCookie(ctx, 'id_token')

    return
  }

  if (tokens.refreshTokenExp && tokens.refreshToken) {
    // cookie maxAge is in seconds
    const exp = (new Date(tokens.refreshTokenExp).getTime() - Date.now()) / 1000

    if (typeof window === 'undefined') {
      setCookie(
        ctx,
        'refresh_token',
        typeof tokens.refreshToken === 'string'
          ? tokens.refreshToken
          : tokens.refreshToken.token,
        {
          maxAge: exp,
          httpOnly: true,
        }
      )
    }

    setCookie(
      ctx,
      'refresh_token_exp',
      new Date(tokens.refreshTokenExp).toUTCString(),
      { maxAge: exp }
    )
  }

  if (tokens.accessToken) {
    setCookie(ctx, 'access_token', tokens.accessToken.token, {
      maxAge: tokenExpiresIn(tokens.accessToken) / 1000,
    })
  }

  if (tokens.idToken) {
    setCookie(ctx, 'id_token', tokens.idToken.token, {
      maxAge: tokenExpiresIn(tokens.idToken) / 1000,
    })
  }
}

const baseURL = getConfig().publicRuntimeConfig.BASE_URL

const requestToken = async (token?: string) =>
  axios.post<{ token?: string }, AxiosResponse<SerializedTokens>>(
    `${baseURL}/api/token${qs.stringify(
      { grant_type: 'refresh_token' },
      { addQueryPrefix: true }
    )}`,
    token ? { token } : undefined,
    { withCredentials: true }
  )

export async function refreshTokens(
  ctx?: NextContextType,
  refreshToken?: string
) {
  const res = await requestToken(refreshToken)
  const tokens = parseTokens(res.data)
  saveTokens(ctx, tokens)
  return tokens
}

export function validateAccessToken(accessToken?: Token) {
  return accessToken && tokenExpiresIn(accessToken) > 0
}
