import { Trans } from '@lingui/macro'
import { RadioInput, Table, Tooltip } from 'components'
import { TableColumn } from 'components/Table/common'
import QuestionMark from 'icons/question-mark.svg'
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import { AppEnvironmentScope, AvailabilityType, ScopeLevel } from 'types'
import cn from 'classnames'

interface ScopesContext {
  setScope: (scope: AppEnvironmentScope) => void
  enforceProduct: boolean
}

const ScopesFormContext = createContext<ScopesContext>(null!)
const DisabledScopesContext = createContext<boolean>(false)

interface ScopeRadioButtonProps {
  scope: AppEnvironmentScope
  value: ScopeLevel
}

const ScopeRadioButton: React.FC<ScopeRadioButtonProps> = ({
  scope,
  value,
}) => {
  const { setScope } = useContext(ScopesFormContext)
  const disabled = !scope.type.availableLevels.includes(value)

  return (
    <RadioInput
      name={scope.type.scope}
      value={value}
      checked={scope.scopeLevel === value}
      onChange={() => setScope({ ...scope, scopeLevel: value })}
      disabled={disabled}
    />
  )
}

interface ScopeTitleProps {
  row: AppEnvironmentScope
}

const ScopeTitle: React.FC<ScopeTitleProps> = ({ row }) => {
  const scopesDisabled = useContext(DisabledScopesContext)
  return (
    <p
      className={cn(
        'font-mono text-14',
        scopesDisabled ? 'text-grey-600' : 'text-grey-900'
      )}
      data-cy-id={`app-settings-scopes-${row.type.scope}`}
    >
      {row.type.scope}
      {row.type.description && (
        <Tooltip
          id={`app.environments.config.scopes.${row.type.scope}.tooltip`}
          kind="dark"
          className="inline"
          displayTip={row.type.description}
        >
          <span className="flex-4 xl:flex-3 mr-1">
            <QuestionMark
              width={16}
              height={16}
              className="mx-8 inline"
              data-tip
            />
          </span>
        </Tooltip>
      )}
    </p>
  )
}

const ScopesTableColumns: TableColumn<AppEnvironmentScope>[] = [
  {
    key: 'scope',
    title: <Trans id="app.environments.config.scopes.scope">Scope</Trans>,
    width: 'auto',
    render: (row) => <ScopeTitle row={row} />,
  },
  {
    key: 'claims',
    title: <Trans id="app.environments.config.scopes.claims">Claims</Trans>,
    width: '38%',
    render: (row) => (
      <span className="text-grey-800 break-normal text-14">
        {row.type.claims.join(', ')}
      </span>
    ),
  },
  {
    key: 'unused',
    title: <Trans id="app.environments.config.scopes.unused">Unused</Trans>,
    align: 'center',
    width: 80,
    render: (row) => <ScopeRadioButton scope={row} value={ScopeLevel.Unused} />,
  },
  {
    key: 'optional',
    title: <Trans id="app.environments.config.scopes.optional">Optional</Trans>,
    align: 'center',
    width: 80,
    render: (row) => (
      <ScopeRadioButton scope={row} value={ScopeLevel.Optional} />
    ),
  },
  {
    key: 'required',
    title: <Trans id="app.environments.config.scopes.required">Required</Trans>,
    align: 'center',
    width: 80,
    render: (row) => (
      <ScopeRadioButton scope={row} value={ScopeLevel.Required} />
    ),
  },
]

// these scopes will be at the top of the scopes list
const scopesOrder = ['openid', 'profile.verification']

const sortScopes = (a: AppEnvironmentScope, b: AppEnvironmentScope) => {
  const orderA = scopesOrder.indexOf(a.type.scope)
  const orderB = scopesOrder.indexOf(b.type.scope)

  // if both are on the list, sort according to list order
  if (orderA !== -1 && orderB !== -1) {
    return orderA - orderB
  }

  // if A is on the list and B is not, A goes first
  if (orderA !== -1 && orderB === -1) {
    return -1
  }

  // if B is on the list and A is not, B goes first
  if (orderA === -1 && orderB !== -1) {
    return 1
  }

  // otherwise sort alphabetically
  return a.type.scope.toLowerCase() < b.type.scope.toLowerCase() ? -1 : 1
}

const allProducts = [
  'CONNECT',
  'IDENTIFY',
  'IDENTIFY PLUS',
  'IDENTIFY AML',
  'QSIGN',
]

//Function to get currently active product based on enabled scopes
const getActiveProducts = (scopesInProducts: any) => {
  const productHierarchy = [...allProducts]
  const activeProducts = []
  let shouldIncludeParents = false

  const isProductEnabled = (scopes: AppEnvironmentScope[]) => {
    return scopes?.filter((scope) => scope.scopeLevel !== 'UNUSED').length > 0
  }

  // Loop through the hierarchy
  for (const productName of productHierarchy.reverse()) {
    const productScopes = scopesInProducts[productName]
    const isActive = isProductEnabled(productScopes)

    // If a product is active or if a descendant product was active, include it and all ancestors
    if (isActive || shouldIncludeParents) {
      shouldIncludeParents = productName === 'QSIGN' ? false : true // once a product is active, all its ancestors will be included if product name isn't QSIGN
      activeProducts.push(productName)
    }
  }

  return activeProducts
}

type ProductCategoryCardProps = {
  productName: string
  isEnabled?: boolean
}

const ProductCategoryCard: React.FC<ProductCategoryCardProps> = ({
  children,
  productName,
  isEnabled,
}) => {
  //Get Background color of the product
  const getProductBgColor = (product: string) => {
    switch (product) {
      case 'CONNECT':
        return '#EEF6DF'
      case 'IDENTIFY':
        return '#FEFFE3'
      case 'IDENTIFY PLUS':
        return '#EDF1FF'
      case 'IDENTIFY AML':
        return '#FFE9E9'
      default:
        return '#FFFFFF'
    }
  }

  return (
    <div
      className="flex flex-col xl:flex-col bg-grey-200 p-20 mb-20 rounded-8 shadow-thin"
      style={{
        background: getProductBgColor(productName),
        border: isEnabled ? '2px solid #0F6DFA' : '1px solid #e8e8e8',
      }}
    >
      <h2 className="text-16 font-semibold mb-5">{productName}</h2>
      {children}
    </div>
  )
}

interface ScopesTableProps {
  onChange: (scope: AppEnvironmentScope) => void
  appScopes: AppEnvironmentScope[]
  enforceProduct: boolean
  isTariff: boolean
  envSlug: 'production' | 'sandbox'
  setActiveProducts: (products: string[]) => void
}

export const ScopesTable: React.FC<ScopesTableProps> = ({
  onChange,
  appScopes,
  enforceProduct,
  isTariff,
  envSlug,
  setActiveProducts,
}) => {
  //This function filters scopes into seperated arrays based on the products
  const [scopesInProducts] = useMemo(() => {
    const sortedScopes = [...appScopes].sort(sortScopes)

    // Directly generate scopesInProducts:
    const scopesInProducts: Record<string, AppEnvironmentScope[]> = {}

    sortedScopes.forEach((scope) => {
      // Only filter scopes based on product if it's environment production
      if (envSlug === 'production') {
        if (!isTariff && !scope.available.includes(AvailabilityType.Call))
          return
        if (isTariff && !scope.available.includes(AvailabilityType.User)) return
      }

      if (!scopesInProducts[scope.productName]) {
        scopesInProducts[scope.productName] = []
      }

      scopesInProducts[scope.productName].push(scope)
    })

    // Sort scopesInProducts based on allProducts:
    const sortedScopesInProducts: Record<string, AppEnvironmentScope[]> = {}
    allProducts.forEach((product) => {
      if (scopesInProducts[product]) {
        sortedScopesInProducts[product] = scopesInProducts[product]
      }
    })

    return [sortedScopesInProducts]
  }, [appScopes, isTariff])

  const activeProducts = getActiveProducts(scopesInProducts)

  // Check if the content of active products changed, if it did, update the activeProducts hook to be sent to ProductResult
  const previousActiveProductsRef = useRef<string[]>([])
  useEffect(() => {
    const previousActiveProducts = previousActiveProductsRef.current

    // Check if the arrays have different content
    const hasChanged = !(
      activeProducts.length === previousActiveProducts.length &&
      activeProducts.every(
        (value, index) => value === previousActiveProducts[index]
      )
    )

    if (hasChanged) {
      setActiveProducts(activeProducts)
      previousActiveProductsRef.current = activeProducts
    }
  }, [activeProducts])

  return (
    <ScopesFormContext.Provider value={{ setScope: onChange, enforceProduct }}>
      {/* wrapper divs make the two tables scroll together */}
      <div className="overflow-x-auto">
        <div className="min-w-900">
          {Object.keys(scopesInProducts).map((key) => {
            const productScopes = scopesInProducts[key]
            return productScopes.length > 0 ? (
              <ProductCategoryCard
                productName={key}
                isEnabled={activeProducts.includes(key)}
                key={key}
              >
                <Table
                  minWidth={800}
                  columns={ScopesTableColumns}
                  data={productScopes}
                />
              </ProductCategoryCard>
            ) : null
          })}
        </div>
      </div>
    </ScopesFormContext.Provider>
  )
}
