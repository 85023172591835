import { Trans } from '@lingui/macro'
import { Link, Table } from 'components'
import { TableColumn } from 'components/Table/common'
import { format } from 'date-fns'
import React from 'react'
import { ReportFragment, ReportType } from 'types'
import { formatCurrency } from 'utils/utils'
import DownloadIcon from 'icons/download.svg'
import FileServiceClient from 'data/FileServiceClient'

const dateFormat = 'dd. MM. yyyy'

function getColumns(type: ReportType) {
  return (
    [
      {
        key: 'name',
        width: 'auto',
        maxWidth: type === ReportType.SepInvoice ? 260 : 440,
        title: (
          <>
            {type === ReportType.SepInvoice && (
              <Trans id="reports.invoice.name">Invoice name</Trans>
            )}
            {type !== ReportType.SepInvoice && (
              <Trans id="reports.report.name">Report name</Trans>
            )}
          </>
        ),
        render: (row) => <span className="break-words">{row.name}</span>,
      },
      type === ReportType.SepInvoice && {
        key: 'amount',
        width: 180,
        title: <Trans id="reports.invoice.amount">Amount</Trans>,
        render: (row) => (
          <span>
            {row.metadata?.__typename === 'SepInvoiceMetadata' &&
              formatCurrency(row.metadata.amount)}
          </span>
        ),
      },
      {
        key: 'createdAt',
        width: 130,
        title: <Trans id="reports.report.createdAt">Generated at</Trans>,
        render: (row) => <>{format(new Date(row.createdAt), dateFormat)}</>,
      },
      {
        key: 'dateFrom',
        width: 130,
        title: <Trans id="reports.report.dateFrom">Date from</Trans>,
        render: (row) => <>{format(new Date(row.dateFrom), dateFormat)}</>,
      },
      {
        key: 'dateTo',
        width: 130,
        title: <Trans id="reports.report.dateTo">Date to</Trans>,
        render: (row) => <>{format(new Date(row.dateTo), dateFormat)}</>,
      },
      {
        key: 'download',
        width: 100,
        align: 'center',
        title: <Trans id="reports.report.download">Download</Trans>,
        render: (row) => (
          <Link
            onClick={() =>
              FileServiceClient.downloadFile(
                row.file.id,
                row.type,
                ((row.metadata as any).orgId as any) ??
                  (row.metadata as any).bankId ??
                  '',
                row.file.fileName
              )
            }
            data-cy-id={`reports.report.${row.id}.download`}
          >
            <DownloadIcon className="inline fill-current" />
          </Link>
        ),
      },
    ] as TableColumn<ReportFragment>[]
  ).filter(Boolean)
}

type ReportsListProps = {
  reports: ReportFragment[]
  type:
    | ReportType.SepBusiness
    | ReportType.IdpBusiness
    | ReportType.IdpConversion
    | ReportType.SepInvoice
    | ReportType.SepBusinessDetailed
    | ReportType.Sla
    | ReportType.SepComplaints
}

export const ReportsList: React.FC<ReportsListProps> = ({ reports, type }) => {
  console.log(reports)
  return (
    <>
      <Table columns={getColumns(type)} data={reports} />
      {reports.length === 0 && (
        <p className="text-center my-20 text-grey-900">
          {type === ReportType.SepInvoice && (
            <Trans id="reports.noInvoices">
              There are no invoices to show.
            </Trans>
          )}
          {type !== ReportType.SepInvoice && (
            <Trans id="reports.noReports">There are no reports to show.</Trans>
          )}
        </p>
      )}
    </>
  )
}
