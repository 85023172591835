import cn from 'classnames'
import React, { useState } from 'react'
import { Clickable } from 'components'
import Chevron from 'icons/chevron.svg'

interface CollapsibleProps {
  heading: React.ReactNode
  className: string
  defaultCollapsed?: boolean
  'data-cy-id': string
}

export const Collapsible: React.FC<CollapsibleProps> = ({
  heading,
  className,
  defaultCollapsed,
  children,
  ...props
}) => {
  const [collapsed, setCollapsed] = useState(defaultCollapsed || false)
  return (
    <div className={className}>
      <Clickable
        className="flex items-center"
        onClick={() => setCollapsed((s) => !s)}
        data-cy-id={props['data-cy-id']}
      >
        {heading}
        <span className="flex items-center justify-center w-24 h-24 rounded bg-grey-200">
          <Chevron
            className={cn(
              'fill-primary-darker',
              !collapsed && 'transform -rotate-180'
            )}
          />
        </span>
      </Clickable>
      <div
        className={cn(
          'transition-collapse duration-300 overflow-hidden',
          collapsed ? 'max-h-0 invisible' : 'max-h-infinite'
        )}
      >
        {children}
      </div>
    </div>
  )
}
