import { Trans } from '@lingui/macro'
import classNames from 'classnames'
import { Link, Logo, StatusLight } from 'components'
import {
  AppEnvironmentStatus,
  AppEnvironmentStatusLight,
} from 'containers/AppEnvironmentStatus'
import React from 'react'
import { AppEnvironmentState, AppsQuery } from 'types'
import { useFeature } from 'utils/features'

interface AppCardProps {
  app: NonNullable<
    NonNullable<AppsQuery['currentUser']>['apps']
  >['edges'][0]['node']['app']
}

export const AppCard: React.FC<AppCardProps> = ({ app }) => {
  const enableProduction = useFeature('app_production')
  const enableProduct = useFeature('app_products')
  const hasProduct = app.activeProducts.length !== 0

  return (
    <Link
      kind="plain"
      to={`/apps/${app.slug}`}
      className={classNames(
        'block w-full sm:w-380 h-360 sm:mr-24 mb-24',
        'bg-white-100 shadow-wider hover:shadow rounded-16',
        'flex flex-col text-left'
      )}
      data-cy-id={`myApps.open.${app.slug}`}
    >
      <div className="flex flex-col sm:flex-row p-24">
        <Logo media={app.logo} className="mr-24" />
        <h3 className="heading-h2 truncate">{app.name}</h3>
      </div>
      <p className="flex-grow px-24 text-18 text-grey-600">
        {app.description ?? (
          <Trans id="myApps.app.noDescription">
            This app does not have a description yet
          </Trans>
        )}
      </p>
      <div className="border-t border-grey-300 p-24 leading-30">
        {enableProduct && (
          <div className="flex">
            <div className="flex-1 text-grey-800">
              <Trans id="myApps.app.product">Product:</Trans>
            </div>
            <div className="flex-2 font-medium flex items-center">
              <StatusLight
                kind={hasProduct ? 'success' : 'warning'}
                className="mr-10"
              />
              {hasProduct ? (
                app.activeProducts.map((product) => product.name).join(', ')
              ) : (
                <>N/A</>
              )}
            </div>
          </div>
        )}
        <div className="flex">
          <div className="flex-1 text-grey-800">
            <Trans id="myApps.app.sandbox">Sandbox:</Trans>
          </div>
          <div className="flex-2 font-medium flex items-center">
            <AppEnvironmentStatusLight
              state={app.sandboxState}
              className="mr-10"
            />
            {app.sandboxState === AppEnvironmentState.Activated ? (
              <Trans id="myApps.app.envStatus.activated">Active</Trans>
            ) : (
              <Trans id="myApps.app.envStatus.readyToRegister">Ready</Trans>
            )}
          </div>
        </div>
        {enableProduction && (
          <div className="flex">
            <div className="flex-1 text-grey-800">
              <Trans id="myApps.app.production">Production:</Trans>
            </div>
            <div className="flex-2 font-medium flex items-center">
              <AppEnvironmentStatus state={app.productionState} />
            </div>
          </div>
        )}
      </div>
    </Link>
  )
}
