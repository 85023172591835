import React, { useEffect, useState } from 'react'
import css from './JiraFormatter.module.css'

type JiraFormatterProps = {
  content: string
}

export const JiraFormatter: React.FC<JiraFormatterProps> = ({ content }) => {
  const [html, setHtml] = useState<string>()
  /* eslint-disable */
  const marked = require('marked')

  useEffect(() => {
    ;(async function () {
      const j2m = await import('utils/jira2md')
      let md = j2m.to_markdown(
        content
          .split(/\r?\n/)
          .filter((line) => line.trim() !== '')
          .join('\n')
      )
      md = md.replace(
        /```([^```]+?)```/g,
        '<div style="background: #f2f2f2; padding: 20px; border-radius: 10px"><p style="font-size: 11px">Code</p>$1</div>'
      )
      const html = marked.parse(md)
      setHtml(html)
    })()
  }, [content])

  return html ? (
    <div className={css.jira} dangerouslySetInnerHTML={{ __html: html }} />
  ) : null
}
