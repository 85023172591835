import classNames from 'classnames'
import Chevron from 'icons/chevron-right.svg'
import React, { ReactNode } from 'react'
import { Link } from './Clickable'

type Breadcrumb = {
  text: ReactNode
  path: string
}

type BreadcrumbsProps = {
  path: Breadcrumb[]
  className?: string
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  path,
  className,
}) => (
  <nav aria-label="Breadcrumb" className={className}>
    <ol className="flex flex-wrap space-x-12 text-grey-800 font-light">
      {path.map((breadcrumb, i) => (
        <li
          key={breadcrumb.path}
          className={classNames(
            'leading-32',
            i + 1 === path.length && 'text-black-900'
          )}
        >
          <Link
            to={breadcrumb.path}
            kind="plain"
            className="hover:underline"
            ariaCurrent={i + 1 === path.length ? 'location' : undefined}
            data-cy-id={`breadcrumbs.${i}`}
          >
            {breadcrumb.text}
          </Link>
          {i + 1 !== path.length && (
            <Chevron
              className="ml-12 -mt-4 inline text-grey-600 fill-current"
              aria-hidden
            />
          )}
        </li>
      ))}
    </ol>
  </nav>
)
