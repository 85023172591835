import { Trans } from '@lingui/macro'
import classNames from 'classnames'
import { Button, Link } from 'components'
import React from 'react'

interface NewOrgCardProps {
  first: boolean
}

export const NewOrgCard: React.FC<NewOrgCardProps> = ({ first }) => (
  <div>
    <Link
      to="/dashboard/create-organization"
      scroll={false}
      kind="plain"
      className={classNames(
        'block w-full sm:w-380 h-360 sm:mr-24 mb-12',
        'border border-dashed border-grey-600 rounded-16',
        'flex flex-col justify-center items-center',
        'hover:shadow-inner-thin'
      )}
      data-cy-id="myOrgs.createOrgButton"
    >
      <p className="mb-24 text-21 text-grey-600 font-semibold tracking-wide">
        <Trans id="myOrgs.requestContract">Request to sign a contract</Trans>
      </p>
      <Button
        data-cy-id="myOrgs.createOrgButton.sendRequest"
        className="cursor-pointer"
      >
        <Trans id="myOrgs.createOrgButton">Send a request</Trans>
      </Button>
    </Link>
    <p className="text-grey-800 text-14 text-center sm:mr-24 ">
      <Trans id="myOrgs.contractTemplate">
        Current specimen contract can be found{' '}
        <Link
          href="https://www.bankid.cz/smluvni-dokumentace-pro-firmy"
          openInNewTab
          data-cy-id="myOrgs.bankid.contract"
        >
          here
        </Link>
        .
      </Trans>
    </p>
  </div>
)
