import { t, Trans } from '@lingui/macro'
import axios from 'axios'
import {
  Button,
  Dropzone,
  FilePreview,
  Input,
  Option,
  Select,
  TextArea,
} from 'components'
import { QueryLoader, useToasts } from 'containers'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  CreateTicketFormData,
  ToastKind,
  useAppsQuery,
  useOrganizationsQuery,
} from 'types'
import { useAsyncCallback } from 'utils/handleError'
import { Router } from 'utils/router'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { LOCALE_CODES, useLocale } from 'utils/withLang'
import cs from 'date-fns/locale/cs'
import en from 'date-fns/locale/en-US'
import OutsideClickHandler from 'react-outside-click-handler'
import moment from 'moment'
import { to_jira } from 'utils/jira2md'

const NONE = '__none'

const ticketTypeOptions: Option<CreateTicketFormData['type']>[] = [
  {
    label: t`support.tickets.type.support`,
    value: 'support',
  },
  {
    label: t`support.tickets.type.incident`,
    value: 'incident',
  },
]

const ticketPriorityOptions: Option<CreateTicketFormData['priority']>[] = [
  {
    label: t`support.tickets.priority.critical`,
    value: 'critical',
  },
  {
    label: t`support.tickets.priority.high`,
    value: 'high',
  },
  {
    label: t`support.tickets.priority.medium`,
    value: 'medium',
  },
  {
    label: t`support.tickets.priority.low`,
    value: 'low',
  },
  {
    label: t`support.tickets.priority.lowest`,
    value: 'lowest',
  },
]

const ticketEnvironmentOptions: Option<CreateTicketFormData['environment']>[] =
  [
    {
      label: t`support.tickets.none`,
      value: '',
    },
    {
      label: t`support.tickets.environment.sandbox`,
      value: 'sandbox',
    },
    {
      label: t`support.tickets.environment.production`,
      value: 'production',
    },
  ]

type CreateTicketFormInputs = Omit<CreateTicketFormData, 'attachments'> & {
  attachments?: File[]
}

export const CreateTicketForm: React.FC = () => {
  const { addToast } = useToasts()

  const appsQuery = useAppsQuery()
  const apps = appsQuery.data?.currentUser?.apps?.edges
  const appsOptions = [{ label: t`support.tickets.none`, value: NONE }].concat(
    apps?.map(({ node }) => ({
      label: node?.app?.name ?? '',
      value: node?.app?.id ?? '',
    })) ?? []
  )

  const orgsQuery = useOrganizationsQuery()
  const orgs = orgsQuery.data?.currentUser?.organisations
  const [orgsOptions, setOrgsOptions] = useState<
    { label: string; value: string }[]
  >([])

  const {
    register,
    handleSubmit,
    errors,
    setError,
    reset,
    watch,
    setValue,
    formState,
  } = useForm<CreateTicketFormInputs>()
  const handleCreateTicket = useAsyncCallback(
    handleSubmit(async (data) => {
      if (!data.type) {
        data.type = 'support'
      }

      if (!data.appId) {
        delete data.appId
      }

      if (!data.orgId) {
        delete data.orgId
      }

      try {
        const formData = new FormData()
        Object.entries(data).forEach(([key, value]) =>
          formData.append(key, value as any)
        )
        files.forEach((file) => formData.append('attachments', file))

        if (data.appId !== NONE) {
          const app = apps?.find(({ node }) => node.app.id === data.appId)?.node
            .app

          formData.append('appSlug', app?.slug ?? '')

          if (
            app?.organisation?.activeService &&
            moment().isBetween(
              moment(app.organisation.activeService.dateFrom),
              moment(app.organisation.activeService.dateTo || undefined),
              'days',
              '[]'
            )
          ) {
            formData.append('labels[]', 'SLA')
          }
        }

        if (data.orgId !== NONE) {
          const appsOrg = apps?.find(
            ({ node }) => node?.app?.organisation?.id === data.orgId
          )?.node.app

          const org = orgs?.find(
            ({ organisation }) => organisation.id === data.orgId
          )?.organisation

          if (appsOrg?.organisation && !org) {
            formData.append('orgName', appsOrg?.organisation?.name ?? '')
            formData.append(
              'orgIdNumber',
              appsOrg?.organisationIdentificationNumber ?? ''
            )

            if (
              appsOrg?.organisation?.activeService &&
              moment().isBetween(
                moment(appsOrg?.organisation?.activeService.dateFrom),
                moment(
                  appsOrg?.organisation?.activeService.dateTo || undefined
                ),
                'days',
                '[]'
              )
            ) {
              formData.append('labels[]', 'SLA')
            }
          }

          if (org) {
            formData.append('orgName', org.name)
            formData.append('orgIdNumber', org.identificationNumber)

            if (
              org?.activeService &&
              moment().isBetween(
                moment(org.activeService.dateFrom),
                moment(org.activeService.dateTo || undefined),
                'days',
                '[]'
              )
            ) {
              formData.append('labels[]', 'SLA')
            }
          }
        }

        if (data.orgId === NONE) {
          formData.delete('orgId')
        }

        if (data.appId === NONE) {
          formData.delete('appId')
        }

        if (data.appId !== NONE) {
          const appNode = apps?.find(
            ({ node }) => node.app.id === data.appId
          )?.node

          formData.append('appName', appNode?.app.name ?? '')
        }

        formData.set('description', to_jira(formData.get('description')))
        await axios.post('/api/tickets', formData)

        addToast({
          kind: ToastKind.Success,
          content: t`support.createTicket.ticketCreated`,
        })
        reset()
        Router.pushRoute('/support')
      } catch (ex) {
        addToast({
          kind: ToastKind.Error,
          content: t`error.somethingWentWrong.text`,
        })
      }
    }),
    [addToast]
  )

  const [files, setFiles] = useState<File[]>([])
  const app = watch('appId', NONE)
  const organization = watch('orgId', NONE)

  useEffect(() => {
    // reset orgsOptions
    setOrgsOptions(
      [{ label: t`support.tickets.noOrganization`, value: NONE }].concat(
        orgs?.map((node) => ({
          label: node.organisation.name,
          value: node.organisation.id,
        })) ?? []
      )
    )

    if (app !== NONE) {
      const selectedApp = apps?.filter((x) => x.node.app.id === app)[0]?.node
        .app

      if (!selectedApp?.organisation?.id) {
        setOrgsOptions([
          { label: t`support.tickets.noOrganization`, value: NONE },
        ])
        setValue('orgId', NONE)
        return
      }
      // add org to orgsOptions if not present
      setOrgsOptions([
        {
          label: selectedApp?.organisation?.name ?? '',
          value: selectedApp?.organisation?.id ?? '',
        },
      ])
    } else {
      setValue('orgId', NONE)
    }
  }, [, apps, app])

  // set orgId to selected app's orgId
  useEffect(() => {
    const selectedApp = apps?.filter((x) => x.node.app.id === app)[0]?.node.app

    if (
      app !== NONE &&
      selectedApp?.organisation?.id &&
      orgsOptions.find((x) => x.value !== selectedApp?.organisation?.id)
    ) {
      setValue('orgId', selectedApp?.organisation?.id)
    }
  }, [orgsOptions])

  // reset ticket type if both app and org are unselected
  useEffect(() => {
    if (app === NONE && organization === NONE) {
      setValue('type', 'support')
    }
  }, [app, organization, setValue])

  const locale = useLocale()
  const [issueDate, setIssueDate] = useState<Date>()
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)

  useEffect(() => {
    if (issueDate) {
      setValue('issueDate', issueDate.toLocaleDateString(LOCALE_CODES.cs))
    }
  }, [issueDate])

  const handleCalendarOpen = () => {
    setIsCalendarOpen(true)
  }

  const handleCalendarClose = () => {
    setIsCalendarOpen(false)
  }

  const handleCalendarChange = (date: Date) => {
    setIsCalendarOpen(false)
    setIssueDate(date)
  }

  useEffect(() => {
    registerLocale(LOCALE_CODES[locale], locale === 'cs' ? cs : en)
  }, [])

  return (
    <form onSubmit={handleCreateTicket}>
      <div className="flex items-start justify-between">
        <QueryLoader
          queries={[appsQuery]}
          loaderComponent={
            <div className="flex flex-row items-center h-80 w-full">
              <Trans id="loading.apps">Loading applications...</Trans>
            </div>
          }
        >
          <Select
            name="appId"
            label={
              <Trans id="support.createTicket.associatedApp">
                Associated app
              </Trans>
            }
            labelPosition="top"
            options={appsOptions}
            data-cy-id="support.createTicket.associatedApp"
            fullWidth
            ref={register()}
            errors={errors.appId}
            className="mb-26 min-w-250"
          />
        </QueryLoader>

        <div className="w-40" />

        <QueryLoader
          queries={[orgsQuery]}
          loaderComponent={
            <div className="flex flex-row items-center h-70 w-full">
              <Trans id="loading.orgs">Loading oraganizations...</Trans>
            </div>
          }
        >
          <div>
            {orgsOptions.length > 0 && (
              <Select
                name="orgId"
                label={
                  <Trans id="support.createTicket.associatedOrganization">
                    Associated organization
                  </Trans>
                }
                labelPosition="top"
                options={orgsOptions}
                data-cy-id="support.createTicket.associatedOrganization"
                fullWidth
                ref={register()}
                errors={errors.orgId}
                className="mb-26"
              />
            )}
          </div>
        </QueryLoader>
      </div>

      <Select
        name="type"
        label={<Trans id="support.createTicket.ticketType">Ticket type</Trans>}
        labelPosition="top"
        options={ticketTypeOptions}
        data-cy-id="support.createTicket.ticketType"
        fullWidth
        ref={register({ required: t`form.error.required` })}
        errors={errors.type}
        disabled={app === NONE && organization === NONE}
        labelTooltip={t`support.createTicket.ticketType.tooltip`}
        defaultValue="support"
      />
      <div className="mb-26" />

      <Select
        name="priority"
        label={
          <Trans id="support.createTicket.ticketPriority">
            Ticket priority
          </Trans>
        }
        labelPosition="top"
        options={ticketPriorityOptions}
        data-cy-id="support.createTicket.ticketPriority"
        fullWidth
        ref={register({ required: t`form.error.required` })}
        errors={errors.priority}
        defaultValue="medium"
      />
      <div className="mb-26" />

      <Input
        name="title"
        label={
          <Trans id="support.createTicket.ticketTitle">Ticket title</Trans>
        }
        placeholder={t`support.createTicket.issueSummary`}
        ref={register({ required: t`form.error.required` })}
        errors={errors.title}
        required
        data-cy-id="support.createTicket.ticketTitle"
      />
      <div className="mb-26" />

      <TextArea
        name="description"
        label={
          <Trans id="support.createTicket.ticketDescription">
            Ticket description
          </Trans>
        }
        placeholder={t`support.createTicket.describeIssue`}
        ref={register({ required: t`form.error.required` })}
        errors={errors.description}
        required
        data-cy-id="support.createTicket.ticketDescription"
      />
      <div className="mb-26" />

      <Select
        name="environment"
        label={<Trans id="support.createTicket.environment">Environment</Trans>}
        labelPosition="top"
        options={ticketEnvironmentOptions}
        data-cy-id="support.createTicket.environment"
        fullWidth
        ref={register()}
        labelTooltip={t`support.createTicket.environment.tooltip`}
        defaultValue=""
      />
      <div className="mb-26" />

      <Input
        name="traceId"
        label={<Trans id="support.createTicket.traceId">TraceID</Trans>}
        placeholder={t`support.createTicket.traceId.placeholder`}
        ref={register()}
        labelTooltip={t`support.createTicket.traceId.tooltip`}
        data-cy-id="support.createTicket.traceId"
      />
      <div className="mb-26" />

      <Input
        name="request"
        label={<Trans id="support.createTicket.request">Request</Trans>}
        placeholder={t`support.createTicket.request.placeholder`}
        ref={register()}
        labelTooltip={t`support.createTicket.request.tooltip`}
        maxLength={5000}
        data-cy-id="support.createTicket.request"
      />
      <div className="mb-26" />

      <TextArea
        name="response"
        label={<Trans id="support.createTicket.response">Response</Trans>}
        placeholder={t`support.createTicket.response.placeholder`}
        ref={register()}
        labelTooltip={t`support.createTicket.response.tooltip`}
        data-cy-id="support.createTicket.response"
      />
      <div className="mb-26" />

      <div className="flex items-start justify-between relative z-20">
        <div className="w-full relative">
          <OutsideClickHandler onOutsideClick={handleCalendarClose}>
            <Input
              name="issueDate"
              label={
                <Trans id="support.createTicket.issueDate">Issue date</Trans>
              }
              placeholder={t`support.createTicket.issueDate.placeholder`}
              ref={register()}
              labelTooltip={t`support.createTicket.issueDate.tooltip`}
              onFocus={handleCalendarOpen}
              data-cy-id="support.createTicket.issueDate"
            />

            {isCalendarOpen && (
              <div className="absolute top-full">
                <DatePicker
                  selected={issueDate}
                  onChange={handleCalendarChange}
                  maxDate={new Date()}
                  inline
                  locale={LOCALE_CODES[locale]}
                />
              </div>
            )}
          </OutsideClickHandler>
        </div>

        <div className="w-40" />

        <Input
          name="issueTime"
          label={<Trans id="support.createTicket.issueTime">Issue time</Trans>}
          placeholder={t`support.createTicket.issueTime.placeholder`}
          ref={register()}
          data-cy-id="support.createTicket.issueTime"
        />
      </div>
      <div className="mb-26" />

      <Dropzone
        data-cy-id="support.createTicket.attachments"
        label={<Trans id="support.createTicket.attachments">Attachments</Trans>}
        onSelectFile={(file) => file && setFiles((files) => [...files, file])}
        stateful={false}
      />
      <div className="mb-16" />

      <div className="mt-10 -mx-12 flex flex-wrap">
        {files.map((file) => (
          <FilePreview
            key={file.name}
            file={file}
            onRemove={() =>
              setFiles((files) => files.filter((f) => f !== file))
            }
          />
        ))}
      </div>
      <div className="mb-26" />

      <Button
        data-cy-id="support.createTicket.submit"
        loading={formState.isSubmitting}
        isFormSubmit
      >
        <Trans id="support.createTicket.submit">Create ticket</Trans>
      </Button>
    </form>
  )
}
