import { CookieDisclaimer } from 'containers'
import { NextPage } from 'next'
import Head from 'next/head'
import React, { useCallback, useState } from 'react'
import { setCookie } from './cookie'

const cookieName = 'cookieConsent'

export type Consent = 'all' | 'essential' | undefined

export function withCookieConsent(
  cookies: Record<string, string>,
  nonce: string | undefined,
  Component: any
) {
  const WithCookieConsent: NextPage = (props) => {
    const [consent, setConsent] = useState(cookies[cookieName])
    const handleConsent = useCallback(
      (consent: NonNullable<Consent>) => {
        const expires = new Date()
        expires.setFullYear(expires.getFullYear() + 10)

        setCookie(null, cookieName, consent, { expires })
        setConsent(consent)
      },
      [setConsent]
    )

    return (
      <>
        <Component {...props} />

        {/* We currently don't need to show Cookie Disclaimer */}
        {/* {!consent && <CookieDisclaimer onConsent={handleConsent} />} */}
      </>
    )
  }

  return WithCookieConsent
}
