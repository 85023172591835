import classNames from 'classnames'
import React from 'react'
import { TableColumn } from './common'

interface TableHeaderCellProps {
  column: TableColumn<any>
}

export const TableHeaderCell: React.FC<TableHeaderCellProps> = (props) => {
  const style = {
    width: props.column.width !== 'auto' ? props.column.width : undefined,
  }

  return (
    <div
      role="cell"
      className={classNames(
        'px-16 py-9 text-14 text-grey-800',
        props.column.width === 'auto' && 'flex-grow',
        props.column.align === 'center' && 'text-center',
        props.column.align === 'right' && 'text-right'
      )}
      style={style}
    >
      {props.column.title}
    </div>
  )
}
