import classNames from 'classnames'
import dynamic from 'next/dynamic'
import React, { forwardRef, PropsWithChildren } from 'react'
import * as gfm from 'remark-gfm'
import { slugify } from 'utils/utils'
import { Clickable, Link } from './Clickable'
import { Image } from './Image'
import css from './Markdown.module.css'
import { PrettyPrintCode } from 'components'
import { useLightBox } from './Modal'
import { Code } from './Text'
import LinkIcon from 'icons/link.svg'

function cleanHref(href: string) {
  // docs page
  if (href.match(/\.md$/)) {
    return href.replace(/(?:.*\/|)(.+)\.md$/, '/docs/$1')
  }

  // API page
  if (href.match(/\.yaml/)) {
    return href.replace(/(?:.*\/|)(.+)\.yaml$/, '/docs/api/$1')
  }

  return href
}

const components = {
  a: ({ href, children, node }: any) => {
    // outbound link
    if (/^https?:\/\//.test(href)) {
      return (
        <Clickable
          href={href}
          openInNewTab
          data-cy-id={
            Array.isArray(children) ? `link-${slugify(children[0])}` : undefined
          }
        >
          {children}
        </Clickable>
      )
    }

    return (
      <Clickable
        to={cleanHref(href)}
        data-cy-id={`link${href.replaceAll('/', '-')}`}
      >
        {children}
      </Clickable>
    )
  },
  h1: ({ id, children, node }: any) => (
    <h1
      id={id || Array.isArray(children) ? slugify(children[0]) : undefined}
      className="heading-d2"
    >
      {children}
    </h1>
  ),
  h2: ({ id, children, node }: any) => (
    <div className="flex flex-row items-center w-fit markdown-header-copy">
      <h2
        id={id || Array.isArray(children) ? slugify(children[0]) : undefined}
        className="heading-h1 mr-5"
      >
        {children}
      </h2>
      <button
        onClick={() => {
          // Create a URL object from the provided string
          const parsedUrl = new URL(window.location.href)

          navigator.clipboard.writeText(
            `${parsedUrl.origin + parsedUrl.pathname}#${
              id || Array.isArray(children) ? slugify(children[0]) : 'undefined'
            }`
          )
        }}
        className="cursor-pointer markdown-header-copy-button"
      >
        <LinkIcon className="w-18" />
      </button>
    </div>
  ),
  h3: ({ id, children, node }: any) => (
    <h3
      id={id || Array.isArray(children) ? slugify(children[0]) : undefined}
      className="heading-h2"
    >
      {children}
    </h3>
  ),
  h4: ({ id, children, node }: any) => (
    <h4
      id={id || Array.isArray(children) ? slugify(children[0]) : undefined}
      className="heading-h3"
    >
      {children}
    </h4>
  ),
  img: ({ src, alt, node }: any) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks -- not recognized as a component due to casing
    const [showPreview, ref] = useLightBox()

    return (
      <div className="flex justify-center my-40">
        <Link
          kind="plain"
          onClick={showPreview?.(src, alt)}
          data-cy-id="image.showPreview"
        >
          <Image src={src} alt={alt} ref={ref} opts={{ width: 500 }} />
        </Link>
      </div>
    )
  },
  pre: ({ children, node }: any) => (
    <pre className="relative bg-primary-darker text-white-60 p-14 rounded-16">
      {children}
    </pre>
  ),
  code: ({ children, inline }: any) => {
    if (inline) {
      return <Code>{children}</Code>
    }

    return <PrettyPrintCode kind="plain">{children[0]}</PrettyPrintCode>
  },
}

const ReactMarkdown = dynamic(() => import('react-markdown'))

interface MarkdownProps {
  className?: string
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const Markdown = forwardRef<
  HTMLDivElement,
  PropsWithChildren<MarkdownProps>
>(({ children, className }, ref) => (
  <div
    className={classNames(
      css['markdown-body'],
      'flex-grow flex-shrink overflow-auto',
      className
    )}
    ref={ref}
  >
    <ReactMarkdown components={components} remarkPlugins={[gfm]}>
      {children}
    </ReactMarkdown>
  </div>
))
