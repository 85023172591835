import { Trans } from '@lingui/macro'
import axios from 'axios'
import { Clickable, Spinner } from 'components'
import KeycloakClient from 'data/KeycloakClient'
import ConnectIcon from 'icons/connected.svg'
import LogoWhite from 'icons/logo-white.svg'
import React, { useEffect, useState } from 'react'
import { bankidProviderName } from 'types/keycloak'
import { useLoader } from 'utils/hooks'
import { useAuthContext } from 'utils/withAuth'

type LinkResponse = { linkUrl: string }

export const LinkBankidButton: React.FC = () => {
  const [linkUrl, setLinkUrl] = useState<string>()
  const auth = useAuthContext()
  const accessToken = auth.tokens?.accessToken?.token

  useEffect(() => {
    // access token is valid here because it's checked on navigation
    if (accessToken) {
      axios
        .get<LinkResponse>('/api/link', {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then((res) => setLinkUrl(res.data.linkUrl))
    }
  }, [accessToken])

  return (
    <Clickable
      href={linkUrl}
      loading={!linkUrl}
      className="bg-black-900 rounded-8 text-white-100 text-14 py-9 px-12 inline-flex items-center h-40"
      data-cy-id="profile.linkBankid.button"
    >
      <LogoWhite className="h-30 w-70 pr-10 mr-10 border-r border-white-100" />
      <span className="overflow-ellipsis overflow-hidden">
        <Trans id="profile.linkBankid.buttonText">Connect</Trans>
      </span>
    </Clickable>
  )
}

function useIsBankidLinked() {
  const { data, loading, error } = useLoader(KeycloakClient.getLinkedAccounts)
  const linked = !!data?.data.find(
    (provider) => provider.providerName === bankidProviderName
  )?.connected

  return { linked, loading, error }
}

export const LinkBankidSection: React.FC = () => {
  const { linked, loading, error } = useIsBankidLinked()

  return (
    <>
      <div className="flex items-center mb-24">
        <ConnectIcon
          className="fill-primary-darker inline mr-14 flex-shrink-0"
          height={24}
        />
        <h4 className="heading-h2 inline">
          <Trans id="profile.linkBankid.heading">Login via Bank iD</Trans>
        </h4>
      </div>
      {loading && <Spinner />}
      {!loading && !error && (
        <>
          <p className="mb-24 text-grey-900">
            {!linked && (
              <Trans id="profile.linkBankid.description">
                To increase the security of your login, it is possible to log in
                to the Developer Portal via Bank iD.
              </Trans>
            )}
            {linked && (
              <Trans id="profile.linkBankid.alreadyLinked">
                Your account is already connected.
              </Trans>
            )}
          </p>
          {!linked && <LinkBankidButton />}
        </>
      )}
    </>
  )
}
