import classNames from 'classnames'
import React, { ReactNode } from 'react'
import ReactTooltip, { TooltipProps as ReactTooltipProps } from 'react-tooltip'
import QuestionMark from 'icons/question-mark.svg'

interface TooltipProps extends ReactTooltipProps {
  kind: 'primary' | 'dark'
  displayTip: ReactNode
  className?: string
  id: string
}

const backgroundByKind = {
  primary: '#0F6DFA',
  dark: '#262626',
}

export const Tooltip: React.FC<TooltipProps> = ({
  kind,
  children,
  displayTip,
  className,
  id,
  ...props
}) => {
  return (
    <>
      <div
        className={classNames('cursor-help', className)}
        data-tip
        data-for={id}
      >
        {children}
      </div>
      {displayTip && (
        <ReactTooltip
          {...props}
          className="opacity-100 leading-relaxed max-w-400 padding-small break-normal"
          backgroundColor={kind && backgroundByKind[kind]}
          effect="solid"
          id={id}
        >
          {displayTip}
        </ReactTooltip>
      )}
    </>
  )
}

export const TooltipWithIndicator: React.FC<TooltipProps> = (props) => {
  return (
    <>
      <Tooltip {...props}>
        <QuestionMark width={16} height={16} className="mx-8 inline" data-tip />
      </Tooltip>
    </>
  )
}
