import { Button, Markdown } from 'components'
import { Trans } from '@lingui/macro'
import React from 'react'
import { useCookieDisclaimerQuery } from 'types/cms'
import { Consent } from 'utils/withCookieConsent'
import { useLocale } from 'utils/withLang'

interface CookieDisclaimerProps {
  onConsent: (consent: NonNullable<Consent>) => void
}

export const CookieDisclaimer: React.FC<CookieDisclaimerProps> = ({
  onConsent,
}) => {
  const locale = useLocale()
  const disclaimerQuery = useCookieDisclaimerQuery({
    variables: { locale },
    context: { endpoint: 'cms' },
  })
  const content =
    disclaimerQuery.data?.cookieDisclaimer?.data?.attributes?.content

  return (
    <div className="fixed bottom-0 w-full py-20 bg-white-100 shadow z-20">
      <div className="container mx-auto px-20 flex flex-col sm:flex-row justify-between items-center">
        <Markdown>{content}</Markdown>
        <div className="w-20 h-20" />
        <div className="flex flex-col sm:flex-row justify-center flex-shrink-0 max-w-300 sm:max-w-none">
          <Button
            kind="secondary"
            data-cy-id="cookieDisclaimer.essentialOnly"
            onClick={() => onConsent('essential')}
            className="text-center sm:text-left"
          >
            <Trans id="cookieDisclaimer.essentialOnly">
              Essential cookies only
            </Trans>
          </Button>
          <div className="h-16 w-16" />
          <Button
            kind="primary"
            data-cy-id="cookieDisclaimer.accept"
            onClick={() => onConsent('all')}
            className="text-center sm:text-left"
          >
            <Trans id="cookieDisclaimer.accept">Accept</Trans>
          </Button>
        </div>
      </div>
    </div>
  )
}
