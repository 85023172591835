import { Trans } from '@lingui/macro'
import classNames from 'classnames'
import { Link, StatusLight } from 'components'
import React from 'react'
import { OrganizationsQuery } from 'types'

interface OrgCardProps {
  org: NonNullable<
    NonNullable<OrganizationsQuery['currentUser']>['organisations']
  >[0]['organisation']
}

export const OrgCard: React.FC<OrgCardProps> = ({ org }) => (
  <Link
    kind="plain"
    to={`/organizations/${org.slug}`}
    className={classNames(
      'block w-full sm:w-380 h-360 sm:mr-24 mb-24',
      'bg-white-100 shadow-wider hover:shadow rounded-16',
      'flex flex-col text-left'
    )}
    data-cy-id={`org.open.${org.slug}`}
  >
    <div className="flex-grow flex flex-col sm:flex-row p-24">
      <h3 className="heading-h2 truncate">{org.name}</h3>
    </div>
    <div className="border-t border-grey-300 p-24 leading-30">
      <div className="flex">
        <div className="flex-1 text-grey-800">
          <Trans id="myOrgs.org.contract">Contract:</Trans>
        </div>
        <div className="flex-2 font-medium flex items-center">
          <StatusLight
            kind={org.contractSigned ? 'success' : 'n/a'}
            className="mr-10"
          />
          {org.contractSigned ? (
            <Trans id="myOrgs.org.contractSigned">Signed</Trans>
          ) : (
            <Trans id="myOrgs.org.contractNotSigned">Not signed</Trans>
          )}
        </div>
      </div>
    </div>
  </Link>
)
