import { useCallback, useEffect, useState } from 'react'

/**
 * Watches children of element assigned returned ref for changes
 *
 * @param selector Query selector of children to watch
 *
 * @returns [ref, elements] tuple
 */
export function useWatch<
  TRef extends HTMLElement,
  TWatched extends HTMLElement = HTMLElement
>(selector: string) {
  const [node, setNode] = useState<TRef>()
  const [elements, setElements] = useState<TWatched[]>([])
  const ref = useCallback((node: TRef) => setNode(node), [])

  useEffect(() => {
    if (node) {
      setElements(Array.from(node.querySelectorAll<TWatched>(selector)))
    }
  }, [node, selector])

  return [ref, elements] as const
}
