import classNames from 'classnames'
import { Clickable, Link, Modal } from 'components'
import CaretDown from 'icons/caret-down.svg'
import CaretRight from 'icons/caret-right.svg'
import SearchIcon from 'icons/search.svg'
import React, { useEffect, useState } from 'react'
import { DocsSectionsQuery, useDocsSectionsQuery } from 'types/cms'
import { LocaleType, useLocale } from 'utils/withLang'
import { QueryLoader } from './QueryLoader'

import { MeiliSearch, SearchResponse, Hits } from 'meilisearch'
import { Hit as HitType } from 'meilisearch'
import { useDebouncedCallback } from 'utils/hooks'
import { Trans, t } from '@lingui/macro'
import { slugify } from 'utils/utils'
import getConfig from 'next/config'

const { MEILISEARCH_HOST, MEILISEARCH_KEY } = getConfig().publicRuntimeConfig

type OrganizedHitsType = {
  title: string
  order: number
  items: HitType[]
}

type SearchDocsPopupProps = {
  locale: LocaleType
}

export const SearchDocsPopup = ({ locale }: SearchDocsPopupProps) => {
  const [searchInput, setSearchInput] = useState<string>('')
  const [searchResult, setSearchResult] = useState<SearchResponse | null>(null)
  const [organizedHits, setOrganizedHits] = useState<OrganizedHitsType[]>([])
  const [error, setError] = useState<boolean>(false)

  const client = new MeiliSearch({
    host: MEILISEARCH_HOST,
    apiKey: MEILISEARCH_KEY,
  })

  const fetchSearch = useDebouncedCallback(async () => {
    try {
      const response = await client.index('docs').search(searchInput, {
        attributesToHighlight: ['title', 'content'],
        highlightPreTag: '<mark>',
        highlightPostTag: '</mark>',
        attributesToCrop: ['content'],
        cropMarker: '',
        cropLength: 20,
      })

      setSearchResult(response)
      organizeHits(response)
      return response
    } catch (e) {
      if (e) setError(true)
    }
  }, 0)

  // Organize search results to document sections
  const organizeHits = (items: HitType) => {
    const newData: OrganizedHitsType[] = []

    Object.values(
      items.hits.filter((item: HitType) => item?.locale === locale)
    ).forEach((item: any) => {
      const sectionId = item?.docs_section?.id

      if (!sectionId) return

      if (!newData[sectionId]) {
        newData[sectionId] = {
          title: item?.docs_section?.title,
          order: item?.docs_section?.order,
          items: [],
        }
      }

      newData[sectionId].items.push(item)
    })

    setOrganizedHits(
      Object.values(newData).sort(
        (a, b) => a.order - b.order
      ) as OrganizedHitsType[]
    )
  }

  useEffect(() => {
    searchInput.length > 2 && fetchSearch()
  }, [, searchInput])

  const SearchResultComponent = () => {
    const isQueryPresent = searchResult?.query !== ''
    const hasHits = searchResult?.estimatedTotalHits
      ? searchResult?.estimatedTotalHits > 0
      : false
    const isInputValid = searchInput.length > 2

    // Function to render hits
    const renderHits = () => {
      return organizedHits.map((item) => (
        <React.Fragment key={item.title}>
          <h3 className="text-grey-800 text-14 uppercase mt-20 mb-10">
            {item.title}
          </h3>
          {item?.items
            ?.sort((a, b) => a.order - b.order)
            .map((hit) => (
              <Hit hit={hit} key={hit.key} />
            ))}
        </React.Fragment>
      ))
    }

    // Decide what to render based on conditions
    let content
    if (isInputValid && isQueryPresent) {
      content =
        hasHits && organizedHits.length > 0 ? (
          renderHits()
        ) : (
          <div className="flex justify-center text-grey-600 mt-20">
            <Trans id="docs.search.noResult">No results found</Trans>
          </div>
        )
    } else {
      content = (
        <div className="flex justify-center text-grey-600 mt-20">
          <Trans id="docs.search.empty">Type something to search</Trans>
        </div>
      )
    }

    return <div className="overflow-y-scroll h-500">{content}</div>
  }

  return (
    <div className="flex-1">
      <div className="flex flex-row items-center bg-grey-100 rounded-lg px-16 py-0 border-1 border-grey-300 w-full">
        <SearchIcon />
        <input
          className="w-full bg-transparent ml-8 focus:outline-none py-10"
          name="search-box"
          onChange={(e) => setSearchInput(e.target.value)}
          placeholder={t`docs.search`}
        />
      </div>

      <hr className="mt-20" />

      {error ? (
        <div className="overflow-y-scroll h-500">
          <div className="flex justify-center text-grey-600 mt-20">
            <Trans id="docs.search.error">Something went wrong</Trans>
          </div>
        </div>
      ) : (
        <SearchResultComponent />
      )}
    </div>
  )
}

type HitProps = {
  hit: HitType
  key: string
}

const Hit = ({ hit, key }: HitProps) => {
  // Function to find the heading of a section the search result is in
  const findHeadingForSearchResult = (
    markdownContent: string,
    searchResultSnippet: string
  ) => {
    // Remove <mark> tags and prepare the search text
    const searchText = searchResultSnippet.replace(/<mark>|<\/mark>/g, '')

    // Regex pattern for second-level markdown headings
    const headingPattern = /^##\s(?!#)(.*?)(?=\n##\s|\n\n|$)/gms

    // Find the position of the search text in the markdown content
    const searchPosition = markdownContent.indexOf(searchText)

    if (searchPosition === -1) {
      return ''
    }

    let lastMatch
    let match

    // Iterate through all heading matches
    while ((match = headingPattern.exec(markdownContent)) !== null) {
      if (headingPattern.lastIndex > searchPosition) {
        break
      }
      lastMatch = match
    }

    if (lastMatch) {
      // Convert the heading to the desired case
      const updatedHeading = slugify(lastMatch[1])
      return updatedHeading
    }

    return ''
  }

  return (
    <Clickable
      key={key}
      to={`/docs/${hit?.slug}/#${findHeadingForSearchResult(
        hit.content,
        hit._formatted?.content
      )}`}
      scroll={false}
    >
      <div className="bg-grey-100 hover:bg-grey-200 rounded-lg mb-15 px-16 py-10 cursor-pointer">
        <div className="flex flex-row">
          <div
            dangerouslySetInnerHTML={{ __html: hit._formatted?.title }}
            className="font-medium text-18"
          ></div>
        </div>

        <div
          dangerouslySetInnerHTML={{ __html: hit._formatted?.content }}
          className="font-light text-16 text-grey-800 text-pretty break-words"
        ></div>
      </div>
    </Clickable>
  )
}

type DocsSections = Unpacked<
  NonNullable<NonNullable<DocsSectionsQuery['docsSections']>['data']>
>['attributes']

const ListItem: React.FC<{
  document:
    | NonNullable<
        NonNullable<DocsSections>['api_documents']
      >['data'][0]['attributes']
    | NonNullable<
        NonNullable<DocsSections>['markdown_documents']
      >['data'][0]['attributes']
  isActive: boolean
  isApi: boolean
}> = ({ document, isActive, isApi }) => (
  <Clickable
    to={`/docs${isApi ? '/api' : ''}/${document?.slug}`}
    data-cy-id={`docs.link${isApi ? '-api' : ''}-${document?.slug}`}
    className={classNames(
      'text-18 text-grey-1200 flex items-start',
      isActive ? 'bg-grey-200 rounded px-6' : 'px-8'
    )}
  >
    <div className="mt-10 mr-14 ml-6">
      {isActive ? (
        <CaretDown aria-hidden className="mt-2" />
      ) : (
        <CaretRight aria-hidden />
      )}
    </div>
    {document?.title}
  </Clickable>
)

interface TableOfContentsProps {
  headings: HTMLElement[]
}

const TableOfContents: React.FC<TableOfContentsProps> = ({ headings }) => (
  <div className="ml-34">
    {headings
      .filter((item) => item.id)
      .map((item) => (
        <Link
          key={item.id}
          href={`#${item.id}`}
          scroll
          kind="plain"
          className="block py-5 text-grey-900 font-light"
          onClick={() => (location.hash = item.id)} // to make this work with Swagger
          data-cy-id={`tableOfContents.item.${item.id}`}
        >
          {item.textContent}
        </Link>
      ))}
  </div>
)

interface MarkdownNavigationProps {
  activeId: string
  headings: HTMLElement[]
}

export const MarkdownNavigation: React.FC<MarkdownNavigationProps> = ({
  activeId,
  headings,
}) => {
  const locale = useLocale()
  const sectionsQuery = useDocsSectionsQuery({
    variables: { locale },
    context: { endpoint: 'cms' },
  })
  const sections = sectionsQuery.data?.docsSections?.data

  const [isSearchOpen, setSearchOpen] = useState<boolean>(false)

  return (
    <>
      <QueryLoader queries={[sectionsQuery]} loaderComponent={null}>
        <nav>
          <button
            className="flex flex-row items-center bg-grey-100 hover:bg-grey-200 lg:w-300 w-full rounded-lg mb-24 px-16 py-10 border-1 border-grey-300 cursor-pointer"
            onClick={() => setSearchOpen(true)}
          >
            <SearchIcon />
            <div className="text-16 text-grey font-medium ml-8">
              <Trans id="docs.search">Search</Trans>
            </div>
          </button>

          {sections
            ?.filter(
              (section) =>
                !!section?.attributes?.markdown_documents?.data?.length ||
                !!section?.attributes?.api_documents?.data?.length
            )
            .map((section) => (
              <div
                className="bg-grey-100 lg:w-300 rounded-lg mb-24 px-16 pt-20 pb-2"
                key={section?.id}
              >
                <div className="text-14 text-grey-800 mb-16 uppercase font-medium">
                  {section?.attributes?.title}
                </div>
                {[
                  ...(section?.attributes?.api_documents?.data ?? []),
                  ...(section?.attributes?.markdown_documents?.data ?? []),
                ]
                  .sort((a, b) =>
                    a?.attributes?.order &&
                    b?.attributes?.order &&
                    a.attributes?.order > b.attributes?.order
                      ? 1
                      : -1
                  )
                  .map(
                    (doc) =>
                      doc && (
                        <div className="mb-16" key={doc.id}>
                          <ListItem
                            isApi={doc.attributes?.__typename === 'ApiDocument'}
                            isActive={doc.attributes?.slug === activeId}
                            document={doc.attributes}
                          />
                          {doc.attributes?.slug === activeId && (
                            <TableOfContents headings={headings} />
                          )}
                        </div>
                      )
                  )}
              </div>
            ))}
        </nav>
      </QueryLoader>

      <Modal
        title={t`docs.search.title`}
        data-cy-id="search-docs-popup"
        isOpen={isSearchOpen}
        maxWidth={850}
        onClose={() => setSearchOpen(false)}
      >
        <SearchDocsPopup locale={locale} />
      </Modal>
    </>
  )
}
