import { Trans } from '@lingui/macro'
import classNames from 'classnames'
import { Link } from 'components'
import { useAppPermissions } from 'containers/Layout'
import { QueryLoader } from 'containers/QueryLoader'
import RightArrow from 'icons/arrow-right.svg'
import React from 'react'
import { useAppMetaQuery, useOrganizationsQuery } from 'types'

type ChecklistBoxProps = {
  checked: boolean
}

const ChecklistBox: React.FC<ChecklistBoxProps> = ({ checked }) => (
  <div
    className={classNames(
      'rounded-full border w-20 h-20 inline-block -mb-5 mr-16',
      checked
        ? 'bg-utility-green border-utility-green bg-tick bg-no-repeat bg-center'
        : 'bg-grey-200 border-grey-400'
    )}
  />
)

type AppProductionChecklistProps = {
  appSlug: string
}

export const AppProductionChecklist: React.FC<AppProductionChecklistProps> = ({
  appSlug,
}) => {
  const metaQuery = useAppMetaQuery({ variables: { slug: appSlug } })
  const disabled = metaQuery.data?.appBySlug?.productionState === 'DISABLED'

  const org = metaQuery.data?.appBySlug?.organisation

  const myOrgsQuery = useOrganizationsQuery()
  const canSeeOrg = myOrgsQuery.data?.currentUser?.organisations.some(
    (userInOrg) => userInOrg.organisation.id === org?.id
  )
  const hasPermission = useAppPermissions()

  return (
    <QueryLoader queries={[metaQuery, myOrgsQuery]}>
      <p className="text-grey-900 max-w-500">
        <Trans id="app.environment.credentials.disabledRegistration">
          Before you can generate production environment credentials, you need
          to complete the following step:
        </Trans>
      </p>
      <div className="text-grey-display mt-40 mb-40 font-semibold">
        <ChecklistBox checked={!!org} />
        {!org && hasPermission('APL:W') && (
          <span className="font-normal">
            <Trans id="app.environment.credentials.disabledRegistration.chooseOrganization">
              Choose an organization your app belongs to
            </Trans>
            <Link
              to={`/apps/${appSlug}/config`}
              className="ml-16"
              data-cy-id={`app.environment.credentials.disabledRegistration.chooseOrganization`}
            >
              <Trans id="app.environment.credentials.disabledRegistration.chooseOrganization.link">
                Connect organization
              </Trans>
              <RightArrow className="inline fill-current ml-6 -mt-4" />
            </Link>
          </span>
        )}
        {org && (
          <span className="font-normal">
            <Trans id="app.environment.credentials.disabledRegistration.organizationSelected">
              This app is connected to the organization
            </Trans>
            &nbsp;
            {canSeeOrg ? (
              <Link
                to={`/organizations/${org.slug}`}
                data-cy-id={`app.environment.credentials.disabledRegistration.openOrganization.${org.slug}`}
              >
                {org.name}
              </Link>
            ) : (
              <>{org.name}</>
            )}
          </span>
        )}
      </div>

      {org &&
        (disabled ? (
          <div className="mb-30">
            <Trans id="app.environment.credentials.disabledRegistration.contactSupport">
              Please contact <Link to="/support">Bank iD support</Link> to
              enable production environment.
            </Trans>
          </div>
        ) : (
          <div className="mb-30">
            <Trans id="app.environment.credentials.disabledRegistration.finalStep">
              Now set up your production application and generate credentials.
            </Trans>
          </div>
        ))}
      <hr />
    </QueryLoader>
  )
}
