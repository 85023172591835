import React from 'react'
import css from './Table.module.css'
import { TableHeaderCell } from './TableHeaderCell'
import { TableColumn } from './common'

interface TableheaderRowProps {
  columns: TableColumn<any>[]
}

export const TableHeaderRow: React.FC<TableheaderRowProps> = (props) => {
  return (
    <div className={css.headerRow} role="row">
      {props.columns.map((c) => (
        <TableHeaderCell key={c.key} column={c} />
      ))}
    </div>
  )
}
