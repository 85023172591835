import { Trans } from '@lingui/macro'
import type { StatusLightProps } from 'components'
import { StatusLight } from 'components'
import React, { ReactNode } from 'react'
import { AppEnvironmentState } from 'types'

export function colorCodeEnvState(
  state: AppEnvironmentState | null | undefined
): StatusLightProps['kind'] {
  if (
    state === AppEnvironmentState.DisabledRegistration ||
    state === AppEnvironmentState.Disabled ||
    state === AppEnvironmentState.Deactivated
  ) {
    return 'error'
  }

  if (
    state === AppEnvironmentState.ReadyToRegister ||
    state === AppEnvironmentState.Unsynchronized
  ) {
    return 'warning'
  }

  if (state === AppEnvironmentState.Activated) {
    return 'success'
  }

  return 'n/a'
}

export function describeEnvState(
  state: AppEnvironmentState | null | undefined
): ReactNode {
  switch (state) {
    case AppEnvironmentState.DisabledRegistration:
    case AppEnvironmentState.Deactivated:
      return (
        <Trans id="myApps.app.envStatus.disabledRegistration">Inactive</Trans>
      )
    case AppEnvironmentState.ReadyToRegister:
      return <Trans id="myApps.app.envStatus.readyToRegister">Ready</Trans>
    case AppEnvironmentState.Activated:
      return <Trans id="myApps.app.envStatus.active">Active</Trans>
    case AppEnvironmentState.Unsynchronized:
      return (
        <Trans id="myApps.app.envStatus.unsynchronized">Unsynchronized</Trans>
      )
    case AppEnvironmentState.Disabled:
      return (
        <Trans id="myApps.app.envStatus.disabled">Disabled by Bank iD</Trans>
      )
  }
}

type AppEnvironmentStatusLightProps = {
  state: AppEnvironmentState | null | undefined
  className?: string
}

export const AppEnvironmentStatusLight: React.FC<AppEnvironmentStatusLightProps> =
  ({ state, className }) => (
    <StatusLight kind={colorCodeEnvState(state)} className={className} />
  )

type AppEnvironmentStatusProps = {
  state: AppEnvironmentState | null | undefined
}

export const AppEnvironmentStatus: React.FC<AppEnvironmentStatusProps> = ({
  state,
}) => (
  <div className="flex items-center">
    <AppEnvironmentStatusLight state={state} className="mr-10 -mt-2" />
    {describeEnvState(state)}
  </div>
)
