import { t, Trans } from '@lingui/macro'
import { Layout, PublicLinks } from 'containers'
import React, { ReactNode } from 'react'

interface ErrorComponentProps {
  statusCode?: number
  heading: ReactNode
  text: ReactNode
}

const ErrorComponent: React.FC<ErrorComponentProps> = ({
  statusCode,
  heading,
  text,
}) => (
  <div className="my-50 m-auto container max-w-700 relative px-25 sm:px-0">
    <div className="text-170 sm:text-310 font-mono text-grey-200 absolute top-0 z-0">
      {statusCode}
    </div>
    <div className="relative z-10 pt-100 sm:pt-220 px-25 sm:px-50">
      <h1 className="heading-d1 text-39 sm:text-48 mb-15">{heading}</h1>
      <p className="text-20 mb-30">{text}</p>
      <div className="flex flex-col text-18 space-y-12">
        <PublicLinks kind="text" />
      </div>
    </div>
  </div>
)

export const Error404: React.FC = () => (
  <ErrorComponent
    statusCode={404}
    heading={
      <Trans id="error.404.heading">
        The page you are looking for was not found
      </Trans>
    }
    text={
      <Trans id="error.404.text">
        The requested page was not found. Try looking in some of the following
        directions.
      </Trans>
    }
  />
)

interface ErrorPageProps {
  statusCode?: number
}

export const ErrorPage: React.FC<ErrorPageProps> = ({ statusCode }) => {
  if (statusCode === 404) {
    return (
      <Layout title={t`error.404.title`}>
        <Error404 />
      </Layout>
    )
  }

  return (
    <ErrorComponent
      statusCode={500}
      heading={<Trans id="error.500.heading">Something went wrong</Trans>}
      text={
        <Trans id="error.500.text">
          An unexpected error has occurred. Try going some of the following
          directions.
        </Trans>
      }
    />
  )
}
