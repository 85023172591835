import { Trans } from '@lingui/macro'
import { Button, ConfigDiff } from 'components'
import { useBankPermissions } from 'containers/Layout'
import React from 'react'
import { BankConfigDiffFragment } from 'types'

interface BankConfigDiffProps {
  diff: BankConfigDiffFragment['pendingConfigChanges']
  submitting: boolean
  onReset: () => void
}

export const BankConfigDiff: React.FC<BankConfigDiffProps> = ({
  diff,
  submitting,
  onReset,
}) => {
  const canWrite = useBankPermissions()('ENV:W')

  return (
    <>
      <h2 className="heading-h2 mt-48 mb-24">
        <Trans id="bank.environment.config.unappliedChanges">
          Unapplied changes
        </Trans>
      </h2>

      <div className="border border-grey-300 rounded-16">
        <div className="flex flex-col p-24">
          {diff && <ConfigDiff diff={diff} type="bank" />}
          {!diff && (
            <p data-cy-id="no-unapplied-changes">
              <Trans id="bank.environment.config.noUnappliedChanges">
                There are no pending config changes. Change settings on this
                page to be able to generate new credentials.
              </Trans>
            </p>
          )}
        </div>

        <div className="flex items-center justify-start p-16 bg-grey-200 space-x-16 rounded-b-16">
          {canWrite && (
            <Button
              kind="danger"
              onClick={onReset}
              disabled={!diff}
              data-cy-id="bank.environment.config.discardChanges"
            >
              <Trans id="bank.environment.config.discardChanges">
                Discard changes
              </Trans>
            </Button>
          )}
          {canWrite && (
            <Button
              kind="primary"
              isFormSubmit
              disabled={!diff}
              loading={submitting}
              data-cy-id="bank.environment.config.applyChanges"
            >
              <Trans id="bank.environment.config.applyChanges">
                Apply changes and generate credentials
              </Trans>
            </Button>
          )}
          {!canWrite && (
            <p className="px-10">
              <Trans id="bank.environment.config.missingWritePermission">
                You don&apos;t have the required permission to make changes to
                the config.
              </Trans>
            </p>
          )}
        </div>
      </div>
    </>
  )
}
