import { ApolloClient, Operation, useApolloClient } from '@apollo/client'
import { t } from '@lingui/macro'
import { addToast } from 'containers'
import { GraphQLError } from 'graphql'
import { DependencyList, useCallback } from 'react'
import { ToastKind } from 'types'
import { captureException } from 'utils/sentry'

interface AugmentedError extends Error {
  errorInfo?: any
  graphQLErrors?: GraphQLError[]
}

interface HandleErrorOpts {
  apolloClient?: ApolloClient<unknown>
  operation?: Operation
}

export function handleError(e: AugmentedError, opts: HandleErrorOpts = {}) {
  // USER_NOT_PRESENT_IN_JWT is returned when tokens are missing.
  // When this happens, Apollo error link will redirect user to login.
  // Show a toast and wait for redirect
  if (
    e.graphQLErrors?.some(
      (error) => error.extensions?.errorCode === 'USER_NOT_PRESENT_IN_JWT'
    )
  ) {
    if (opts.apolloClient) {
      const warningToast = {
        kind: ToastKind.Warning,
        content: t`error.loggedOut.text`,
      }
      addToast(warningToast, opts.apolloClient)
    }
    return
  }

  // show a generic toast message
  if (opts.apolloClient) {
    const errorToast = {
      kind: ToastKind.Error,
      content: t`error.somethingWentWrong.text`,
    }
    addToast(errorToast, opts.apolloClient)
  }

  captureException(e, opts)
}

export interface ErrorHandlerOptions {}

export function useErrorHandler<TFN extends (...opts: any[]) => Promise<any>>(
  original: TFN,
  errorHandlerOpts: ErrorHandlerOptions = {}
): TFN {
  const apolloClient = useApolloClient()

  return (async (...opts: any[]) => {
    try {
      await original(...opts)
    } catch (ex: any) {
      handleError(ex, { apolloClient })
    }
  }) as any
}

export function useAsyncCallback<
  T extends (...args: any[]) => Promise<any> | undefined
>(callback: T, deps: DependencyList): T {
  const apolloClient = useApolloClient()

  return useCallback(async (...args: any[]) => {
    try {
      return await callback(...args)
    } catch (ex: any) {
      handleError(ex, { apolloClient })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- can only check array literals
  }, deps) as any
}
