import { t, Trans } from '@lingui/macro'
import { Breadcrumbs } from 'components'
// import getConfig from 'next/config'
import React, { useEffect } from 'react'
import { useApiDocumentQuery } from 'types/cms'
import { useWatch } from 'utils/hooks'
import { useAuthContext } from 'utils/withAuth'
import { Meta } from './Layout/Meta'
import { MarkdownNavigation } from './MarkdownNavigation'
import { QueryLoader } from './QueryLoader'

interface ApiDocumentProps {
  id: string
}

export const ApiDocument: React.FC<ApiDocumentProps> = ({ id: slug }) => {
  const apiDocumentQuery = useApiDocumentQuery({
    variables: { slug },
    context: { endpoint: 'cms' },
  })
  const doc = apiDocumentQuery.data?.apiDocuments?.data?.[0]
  const token = useAuthContext()?.tokens?.accessToken?.token

  const [setRef, headings] = useWatch('h4')

  useEffect(() => {
    ;(async function () {
      const { SwaggerUIBundle, SwaggerUIStandalonePreset } = await import(
        'swagger-ui-dist'
      )
      if (typeof window !== 'undefined' && doc) {
        SwaggerUIBundle({
          url: doc?.attributes?.filename,
          dom_id: '#api-render-element',
          presets: [SwaggerUIBundle.presets.apis, SwaggerUIStandalonePreset],
          // requestInterceptor: (req: any) => ({
          //   ...req,
          //   headers: token ? { authorization: `Bearer ${token}` } : {},
          // }),
          onComplete: () => {
            // find headings
            const div = document.getElementById('api-render-element')
            div && setRef(div)
          },
        })
      }
    })()
  }, [doc, token, setRef])

  return (
    <>
      <Meta title={doc?.attributes?.title ?? t`documents.title`} />
      <Breadcrumbs
        path={[
          {
            text: <Trans id="docs.title">Documentation</Trans>,
            path: '/docs',
          },
          {
            text: doc?.attributes?.title,
            path: `/docs/api/${slug}`,
          },
        ]}
      />
      <div className="flex flex-col lg:flex-row w-full mt-24">
        <QueryLoader queries={[apiDocumentQuery]}>
          <MarkdownNavigation activeId={slug} headings={headings} />
          <div className="mr-40" />
          <div id="api-render-element" className="font-display w-full" />
          {!doc && <Trans id="api.notFound">API not found</Trans>}
        </QueryLoader>
      </div>
    </>
  )
}
