import { Trans } from '@lingui/macro'
import classNames from 'classnames'
import { Link, Logo, StatusLight } from 'components'
import React from 'react'
import { BanksQuery } from 'types'

interface BankCardProps {
  bank: NonNullable<
    NonNullable<BanksQuery['currentUser']>['banks']
  >['edges'][0]['node']['bank']
}

export const BankCard: React.FC<BankCardProps> = ({ bank }) => (
  <Link
    kind="plain"
    to={`/banks/${bank.slug}`}
    className={classNames(
      'block w-full sm:w-380 h-360 sm:mr-24 mb-24',
      'bg-white-100 shadow-wider hover:shadow rounded-16',
      'flex flex-col text-left'
    )}
    data-cy-id={`myBanks.open.${bank.slug}`}
  >
    <div className="flex flex-col sm:flex-row p-24">
      <Logo media={bank.logo} className="mr-24" />
      <h3 className="heading-h2 truncate">{bank.name}</h3>
    </div>
    <p className="flex-grow px-24 text-18 text-grey-600">
      {bank.description ?? (
        <Trans id="myBanks.bank.noDescription">
          This bank does not have a description yet
        </Trans>
      )}
    </p>
    <div className="border-t border-grey-300 p-24 leading-30">
      <div className="flex">
        <div className="flex-1 text-grey-800">
          <Trans id="myBanks.bank.status">Status:</Trans>
        </div>
        <div className="flex-2 font-medium flex items-center">
          <StatusLight
            kind={bank.isActive ? 'success' : 'warning'}
            className="mr-10"
          />
          {bank.isActive ? (
            <Trans id="myBanks.bank.active">Active</Trans>
          ) : (
            <Trans id="myBanks.bank.inactive">Inactive</Trans>
          )}
        </div>
      </div>
    </div>
  </Link>
)
