import { Trans } from '@lingui/macro'
import { Button, ConfigDiff, Link } from 'components'
import { useAppPermissions } from 'containers/Layout'
import React, { ReactNode } from 'react'
import {
  AppConfigDiffFragment,
  AppEnvironmentState,
  AppEnvironmentType,
} from 'types'

interface AppConfigDiffProps {
  diff: AppConfigDiffFragment['pendingConfigChanges']
  env: AppEnvironmentType
  submitting: boolean
  envState?: AppEnvironmentState | null
  appSlug: string
  onReset: () => void
  configWarning?: ReactNode
}

export const AppConfigDiff: React.FC<AppConfigDiffProps> = ({
  diff,
  env,
  submitting,
  onReset,
  envState,
  appSlug,
  configWarning,
}) => {
  const canWrite = useAppPermissions()(
    env === AppEnvironmentType.Sandbox ? 'SBX:W' : 'PROD:W'
  )

  const active =
    envState === AppEnvironmentState.ReadyToRegister ||
    envState === AppEnvironmentState.Activated ||
    envState === AppEnvironmentState.Unsynchronized ||
    envState === AppEnvironmentState.DisabledRegistration

  const disabled = envState === AppEnvironmentState.Disabled

  const envSlug = env === AppEnvironmentType.Sandbox ? 'sandbox' : 'production'

  // can apply if there are changes or if environment is unsynchronized
  const canApply = diff || envState === AppEnvironmentState.Unsynchronized

  // can discard only if there are changes other than in product
  const canDiscard =
    diff &&
    !Object.entries(diff).every(
      ([key, value]) =>
        value === null ||
        (Array.isArray(value) && value.length === 0) ||
        key === 'products' ||
        key === '__typename'
    )

  return (
    <>
      <h2 className="heading-h2 mt-48 mb-24">
        <Trans id="app.environment.config.unappliedChanges">
          Unapplied changes
        </Trans>
      </h2>

      {configWarning}

      <div className="border border-grey-300 rounded-16">
        <div className="flex flex-col p-24">
          {diff && <ConfigDiff diff={diff} type="app" />}
          {!diff && canApply && (
            <p>
              <Trans id="app.environment.config.applyWithNoChanges">
                There are no pending config changes. Press apply changes to
                reactivate the environment with the current config.
              </Trans>
            </p>
          )}
          {!canApply && (
            <p data-cy-id="no-unapplied-changes">
              <Trans id="app.environment.config.noUnappliedChanges">
                There are no pending config changes. Change settings on this
                page to be able to generate new credentials.
              </Trans>
            </p>
          )}
        </div>

        <div className="flex items-center justify-start p-16 bg-grey-200 space-x-16 rounded-b-16">
          {canWrite && active && (
            <>
              <Button
                kind="danger"
                onClick={onReset}
                disabled={!canDiscard}
                data-cy-id="app.environment.config.discardChanges"
              >
                <Trans id="app.environment.config.discardChanges">
                  Discard changes
                </Trans>
              </Button>

              <Button
                kind="primary"
                isFormSubmit
                loading={submitting}
                disabled={!canApply}
                data-cy-id="app.environment.config.applyChanges"
              >
                <Trans id="app.environment.config.applyChanges">
                  Apply changes and generate credentials
                </Trans>
              </Button>
            </>
          )}

          <p className="px-10">
            {canWrite && !active && !disabled && (
              <Trans id="app.environment.config.notActive">
                You cannot apply changes because this environment is not active.
                See the{' '}
                <Link
                  to={`/apps/${appSlug}/${envSlug}/credentials`}
                  data-cy-id="app.environment.config.credentials"
                >
                  credentials
                </Link>{' '}
                tab to find out how to activate the environment.
              </Trans>
            )}

            {canWrite && disabled && (
              <Trans id="app.environment.credentials.disabledRegistration.contactSupport">
                Please contact <Link to="/support">Bank iD support</Link> to
                enable production environment.
              </Trans>
            )}

            {!canWrite && (
              <Trans id="app.environment.config.missingWritePermission">
                You don&apos;t have the required permission to make changes to
                the config.
              </Trans>
            )}
          </p>
        </div>
      </div>
    </>
  )
}
