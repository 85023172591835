import { debounce, DebounceSettings } from 'lodash'
import { useRef } from 'react'
import { useAsyncCallback } from 'utils/handleError'

/**
 * Debounces a function and preserves its dependencies.
 *
 * @param callback  Function to be debounced
 * @param delay     Debounce delay
 * @param options   Lodash DebounceSettings object
 *
 * @returns Debounced function
 */
export function useDebouncedCallback<
  T extends (...args: unknown[]) => Promise<unknown> | undefined
>(callback: T, delay?: number, options?: DebounceSettings) {
  const callbackRef = useRef<T>(null!)
  callbackRef.current = callback
  return useAsyncCallback(
    debounce((...args) => callbackRef.current(...args), delay, options),
    []
  )
}
