import classNames from 'classnames'
import ArrowDown from 'icons/arrow-down.svg'
import ArrowUp from 'icons/arrow-up.svg'
import React, { ReactNode } from 'react'
import { formatNumber, formatPercentage } from 'utils/utils'

type StatBoxProps = {
  title: ReactNode
  subtitle: ReactNode
  value: number
  change?: number
}

export const StatBox: React.FC<StatBoxProps> = ({
  title,
  subtitle,
  value,
  change,
}) => (
  <div className="flex flex-col justify-between border border-grey-300 rounded-8 w-210 p-16 mr-16 mb-16">
    <div>
      <p className="font-medium mb-4">{title}</p>
      <p className="text-12 text-black-600 mb-24">{subtitle}</p>
    </div>
    <div className="flex flex-wrap items-end font-semibold">
      <p className="text-31">{formatNumber(value)}</p>
      {change !== undefined && (
        <p
          className={classNames(
            change < 0 && 'text-utility-red',
            change > 0 && 'text-utility-green'
          )}
        >
          {change < 0 && <ArrowDown className="inline -mt-3" />}
          {change > 0 && <ArrowUp className="inline -mt-3" />}
          {formatPercentage(change)}
        </p>
      )}
    </div>
  </div>
)
