import React from 'react'
import { Trans } from '@lingui/macro'
import { AppEnvironmentState } from 'types'
import WarningIcon from 'icons/warning.svg'
import { ActivateAppEnvButton } from 'containers'
import classNames from 'classnames'

type AppEnvironmentStatusBannerProps = {
  state: AppEnvironmentState | null | undefined
  envId: string
  appSlug: string
}

export const AppEnvironmentStatusBanner: React.FC<AppEnvironmentStatusBannerProps> =
  ({ state, envId, appSlug }) => {
    if (
      !state ||
      ![
        AppEnvironmentState.Unsynchronized,
        AppEnvironmentState.Disabled,
        AppEnvironmentState.Deactivated,
      ].includes(state)
    ) {
      return null
    }

    return (
      <div
        className={classNames(
          '-mx-40 px-40 py-20 text-white-100',
          state === AppEnvironmentState.Unsynchronized && 'bg-utility-danger',
          (state === AppEnvironmentState.Disabled ||
            state === AppEnvironmentState.Deactivated) &&
            'bg-utility-red'
        )}
      >
        <WarningIcon className="inline mr-16 -mt-4 fill-white-100" />
        {state === AppEnvironmentState.Unsynchronized && (
          <Trans id="app.environment.state.banner.unsynchronized">
            Production environment is enabled, but inactive, because changes
            have not been applied.
          </Trans>
        )}
        {state === AppEnvironmentState.Disabled && (
          <Trans id="app.environment.state.banner.disabled">
            Production environment has been deactivated by Bank iD.
          </Trans>
        )}
        {state === AppEnvironmentState.Deactivated && (
          <Trans id="app.environment.state.banner.deactivated">
            Production environment has been deactivated.
          </Trans>
        )}
        {state === AppEnvironmentState.Deactivated && (
          <ActivateAppEnvButton
            envId={envId}
            appSlug={appSlug}
            kind="secondary-white"
            className="ml-16 -my-10"
          />
        )}
      </div>
    )
  }
