import React, { ReactNode } from 'react'

const SidebarSectionHeading: React.FC = ({ children }) => (
  <div className="text-10 text-white-40 uppercase tracking-widest font-semibold mb-8">
    {children}
  </div>
)

interface SidebarSectionProps {
  heading?: ReactNode
}

export const SidebarSection: React.FC<SidebarSectionProps> = ({
  heading,
  children,
}) => (
  <>
    {heading && <SidebarSectionHeading>{heading}</SidebarSectionHeading>}
    {children}
    <div className="mb-24" />
  </>
)

export const Sidebar: React.FC = ({ children }) => {
  return (
    <div className="bg-primary-darker sm:min-w-230 sm:rounded-t-16 sm:rounded-tr-none sm:rounded-l-16 p-32 text-white-80">
      {children}
    </div>
  )
}
