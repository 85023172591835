import classNames from 'classnames'
import type { ValidationContainerProps } from 'components'
import { ValidationContainer } from 'components'
import { CopyToClipboard } from 'components/Text'
import React, { ChangeEventHandler, FocusEventHandler } from 'react'
import type { TestIdProp } from 'types'

const inputMaxLength = 100

interface InputProps extends ValidationContainerProps, Partial<TestIdProp> {
  disabled?: boolean
  type?: string
  placeholder?: string
  className?: string
  containerClassName?: string
  readOnly?: boolean
  clipboard?: boolean
  fullWidth?: boolean
  onChange?: ChangeEventHandler<HTMLInputElement>
  prefix?: React.ReactNode
  defaultValue?: string
  value?: string | null
  maxLength?: number
  autoComplete?: string
  onFocus?: FocusEventHandler<HTMLInputElement>
  required?: boolean
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      disabled,
      type,
      readOnly,
      clipboard,
      placeholder,
      'data-cy-id': testId,
      onChange,
      prefix,
      className,
      containerClassName,
      autoComplete,
      onFocus,
      required,
      ...props
    },
    ref
  ) => (
    <ValidationContainer
      className={containerClassName}
      {...props}
      required={required}
    >
      <div
        className={classNames(
          'leading-relaxed border rounded text-16 text-grey-display focus:outline-none bg-grey-200',
          'flex',
          props.fullWidth && 'w-full',
          props.errors && 'border-utility-error',
          readOnly && 'opacity-75',
          disabled && 'bg-black-50 cursor-not-allowed',
          !disabled && !props.errors && 'border-grey-300',
          !disabled && !props.errors && !readOnly && 'focus:border-primary',
          className
        )}
      >
        {prefix && <div className="bg-grey-300 p-8">{prefix}</div>}

        <input
          ref={ref}
          className={classNames(
            'appearance-none bg-grey-200 rounded overflow-hidden',
            props.fullWidth && 'w-full',
            props.labelPosition === 'left' ? 'py-4 px-8' : 'p-8'
          )}
          id={props.name}
          disabled={disabled}
          type={type}
          placeholder={placeholder}
          name={props.name}
          readOnly={readOnly}
          data-cy-id={testId ?? props.name}
          autoComplete={autoComplete}
          onChange={onChange}
          value={props.value ?? undefined}
          defaultValue={props.defaultValue}
          maxLength={props.maxLength ?? inputMaxLength}
          onFocus={onFocus}
        />

        {clipboard && (
          <CopyToClipboard
            value={props.value}
            testId={testId ?? props.name}
            className="mr-10"
          />
        )}
      </div>
    </ValidationContainer>
  )
)

Input.defaultProps = {
  labelPosition: 'top',
  fullWidth: true,
}

export default Input
