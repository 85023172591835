import React, { ChangeEventHandler, forwardRef } from 'react'
import classNames from 'classnames'

interface CheckboxProps {
  name: string
  label?: React.ReactNode
  className?: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  defaultChecked?: boolean
  value?: string
  disabled?: boolean
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    { name, label, className, onChange, defaultChecked, value, disabled },
    ref
  ) => (
    <>
      <input
        type="checkbox"
        id={name}
        name={name}
        ref={ref}
        onChange={onChange}
        className={classNames(
          'appearance-none w-16 h-16 border rounded-4 flex-shrink-0',
          !disabled
            ? 'border-primary cursor-pointer checked:bg-primary'
            : 'border-black-200 cursor-not-allowed checked:bg-black-200',
          'checked:bg-tick bg-no-repeat bg-center',
          label && 'mr-8 -mt-1 align-bottom',
          className
        )}
        disabled={disabled}
        defaultChecked={defaultChecked}
        value={value}
      />
      {label && (
        <label
          htmlFor={name}
          className={classNames(
            !disabled ? 'cursor-pointer' : 'cursor-not-allowed text-black-600'
          )}
        >
          {label}
        </label>
      )}
    </>
  )
)
