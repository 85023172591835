import React, { HTMLProps, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import { CopyToClipboard } from 'components/Text'

interface PrettyPrintCodeProps extends HTMLProps<HTMLPreElement> {
  children: string
  kind?: 'codeBlock' | 'pre' | 'plain'
  language?: string
}

function prettify(what: any) {
  try {
    return JSON.stringify(JSON.parse(what), null, 2)
  } catch {
    return what
  }
}

export const PrettyPrintCode: React.FC<PrettyPrintCodeProps> = ({
  children,
  kind,
  language,
  ...props
}) => {
  const pretty = useMemo(() => prettify(children), [children])
  const [code, setCode] = useState(pretty)

  useEffect(() => {
    ;(async function () {
      const hljs = (await import('highlight.js')).default
      try {
        setCode(hljs.highlight(pretty, { language: language! }).value)
      } catch (ex) {
        console.error(ex)
      }
    })()
  }, [pretty, language])

  const element = (
    <>
      <code dangerouslySetInnerHTML={{ __html: code }} {...props} />
      <CopyToClipboard
        value={pretty}
        className="absolute top-0 right-0 p-6 bg-primary-darker opacity-75"
      />
    </>
  )

  return kind === 'plain' ? (
    element
  ) : (
    <pre
      className={classNames(
        'relative whitespace-pre-wrap break-all',
        kind === 'codeBlock' &&
          'p-14 bg-white-05 border-2 border-white-10 rounded'
      )}
    >
      {element}
    </pre>
  )
}

PrettyPrintCode.defaultProps = {
  kind: 'codeBlock',
  language: 'json',
}
