import { t, Trans } from '@lingui/macro'
import cn from 'classnames'
import { Avatar, Button, Tooltip } from 'components'
import {
  PermissionsInput,
  QueryLoader,
  UserInAppContext,
  useToasts,
  useUpdateAbility,
  RemoveUserButton,
} from 'containers'
import { PermissionKind } from 'containers/Permissions'
import PendingMark from 'icons/pending-clock-mark.svg'
import CheckMark from 'icons/white-check-mark.svg'
import React, { useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
  ContractRole,
  InvitationState,
  ToastKind,
  useCurrentUserQuery,
  useUpdateUserInAppMutation,
  useUpdateUserInBankMutation,
  useUpdateUserInOrgMutation,
  useUserInAppQuery,
  useUserInBankQuery,
  useUserInOrgQuery,
  useUsersInAppQuery,
  useUsersInBankQuery,
  useUsersInOrgQuery,
} from 'types'
import { useAsyncCallback } from 'utils/handleError'
import { makeFullName } from 'utils/utils'
import { ResendInvitationButton } from './ResendInvitationButton'

interface UserDetailFormData {
  permissions: string[]
}

interface UserDetailProps {
  type: PermissionKind
  slug: string
  userId: string
}

export const UserDetailForm: React.FC<UserDetailProps> = ({
  type,
  slug,
  userId,
}) => {
  const { register, handleSubmit, formState, errors, setValue, reset } =
    useForm<UserDetailFormData>()

  const userInAppQuery = useUserInAppQuery({
    variables: {
      slug,
      userId,
    },
    skip: type !== 'app',
  })
  const userInBankQuery = useUserInBankQuery({
    variables: {
      slug,
      userId,
    },
    skip: type !== 'bank',
  })
  const userInOrgQuery = useUserInOrgQuery({
    variables: {
      slug,
      userId,
    },
    skip: type !== 'organization',
  })
  const node =
    userInAppQuery.data?.userInApp ??
    userInBankQuery.data?.userInBank ??
    userInOrgQuery.data?.userInOrganisation

  const [updateUserInApp] = useUpdateUserInAppMutation()
  const [updateUserInBank] = useUpdateUserInBankMutation()
  const [updateUserInOrg] = useUpdateUserInOrgMutation()
  const { addToast } = useToasts()

  const { refetch: refetchUsersInApp } = useUsersInAppQuery({
    variables: { slug },
    skip: type !== 'app',
  })
  const { refetch: refetchUsersInBank } = useUsersInBankQuery({
    variables: { slug },
    skip: type !== 'bank',
  })
  const { refetch: refetchUsersInOrg } = useUsersInOrgQuery({
    variables: { slug },
    skip: type !== 'organization',
  })

  const currentUserQuery = useCurrentUserQuery()
  const updateAbility = useUpdateAbility()

  const submitForm = useAsyncCallback(
    handleSubmit(async ({ permissions }) => {
      if (!node?.id) {
        throw 'Missing user id'
      }

      if (type === 'app') {
        try {
          await updateUserInApp({
            variables: {
              id: node.id,
              user: {
                permissions,
              },
            },
          })
          await userInAppQuery.refetch()
          await refetchUsersInApp()
        } catch (ex: any) {
          if (
            ex.graphQLErrors?.[0]?.extensions?.errorCode ===
            'TECHNICAL_USER_PERMISSIONS_INVALID'
          ) {
            addToast({
              kind: ToastKind.Error,
              content: t`users.permissions.app.error.technicalUserPermissionsInvalid`,
              timeout: 5000,
            })
            return
          }
          throw ex
        }
      }

      if (type === 'bank') {
        await updateUserInBank({
          variables: {
            id: node.id,
            user: {
              permissions,
            },
          },
        })
        await userInBankQuery.refetch()
        await refetchUsersInBank()
      }

      if (type === 'organization') {
        await updateUserInOrg({
          variables: {
            id: node.id,
            user: {
              permissions,
            },
          },
        })
        await userInOrgQuery.refetch()
        await refetchUsersInOrg()
      }

      // update loaded permissions if editing self
      if (userId === currentUserQuery.data?.currentUser?.id) {
        await updateAbility()
      }

      addToast({
        kind: ToastKind.Success,
        content: t`users.userDetail.editSuccessful`,
      })
    }),
    [
      handleSubmit,
      node?.id,
      type,
      updateUserInApp,
      updateUserInBank,
      refetchUsersInApp,
      refetchUsersInBank,
      userInAppQuery.refetch,
      userInBankQuery.refetch,
      updateAbility,
      userId,
      currentUserQuery.data?.currentUser?.id,
      addToast,
    ]
  )

  useEffect(() => {
    register({ name: 'permissions' })
  }, [register])

  useEffect(() => {
    setValue('permissions', node?.permissions ?? [])
    reset(undefined, { isDirty: false, dirtyFields: false })
  }, [node?.permissions, setValue, reset])

  const handlePermissionChange = (permissions: string[]) =>
    setValue('permissions', permissions, { shouldDirty: true })

  const fullName = makeFullName(node?.user.firstName, node?.user.lastName)
  const isSelf = userId === currentUserQuery.data?.currentUser?.id
  const roles =
    type === 'organization'
      ? userInOrgQuery.data?.userInOrganisation?.contractRoles
      : []

  // disable any changes for owner & changes in USR for self
  // disable changes in permissions defined in contract
  const disabledPermissions = [
    isSelf && 'USR',
    roles?.includes(ContractRole.Financial) && 'FNC',
    roles?.includes(ContractRole.Legal) && 'LGL',
    roles?.includes(ContractRole.Legal) && 'ORG',
    roles?.includes(ContractRole.Technical) && 'ORG',
  ].filter(Boolean) as string[]

  const invitationPending =
    node?.invitation?.state === InvitationState.Pending ||
    node?.invitation?.state === InvitationState.Reminded

  const invitationExpired =
    node?.invitation?.state === InvitationState.Expired ||
    node?.invitation?.state === InvitationState.Seen

  return (
    <QueryLoader
      queries={[
        type === 'app' && userInAppQuery,
        type === 'bank' && userInBankQuery,
        type === 'organization' && userInOrgQuery,
      ]}
    >
      <form onSubmit={submitForm}>
        <div className="flex items-center">
          <Avatar
            media={node?.user.avatar}
            size={64}
            alt={fullName + ' avatar'}
            className="mr-24"
          />
          <div className="flex flex-col">
            <h3 className="heading-h3">{fullName}</h3>
            <div className="flex flex-shrink-0 flex-col">
              <div className="flex flex-row items-center">
                <a
                  className="text-primary mr-4"
                  href={`mailto:${node?.user.email}`}
                >
                  {node?.user.email}
                </a>
                {node?.user.emailVerified ? (
                  <Tooltip
                    kind="dark"
                    id="users.emailVerified"
                    displayTip={
                      <Trans id="users.emailVerified">
                        This e-mail has been verified.
                      </Trans>
                    }
                    className="-mt-4"
                  >
                    <CheckMark className="inline" />
                  </Tooltip>
                ) : (
                  <Tooltip
                    kind="dark"
                    id="users.emailVerificationPending"
                    displayTip={
                      <Trans id="users.emailVerificationPending">
                        Verification of this e-mail is pending.
                      </Trans>
                    }
                    className="-mt-4"
                  >
                    <PendingMark className="inline" />
                  </Tooltip>
                )}
                {isSelf && (
                  <Tooltip
                    kind="dark"
                    id="users.me"
                    displayTip={
                      <Trans id="users.me.tooltip">
                        This is my user profile
                      </Trans>
                    }
                    className="-mt-4 ml-4"
                  >
                    <span className="bg-grey-800 text-white-100 rounded-full p-4 text-10 flex-shrink-0">
                      <Trans id="users.me">It&apos;s me</Trans>
                    </span>
                  </Tooltip>
                )}
              </div>
              {node?.invitation && (
                <div className="flex space-x-5">
                  {invitationPending && (
                    <span className="font-medium text-grey-600">
                      <Trans id="users.pendingInvitation">
                        Pending invitation
                      </Trans>
                    </span>
                  )}
                  {invitationExpired && (
                    <span className="text-red-400 font-semibold">
                      <Trans id="users.expiredInvitation">
                        The invitation has expired
                      </Trans>
                    </span>
                  )}
                  <ResendInvitationButton invitationId={node.invitation.id} />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mb-24"></div>
        <h6 className="text-18 font-medium">
          <Trans id="users.userDetail.UserPermissions">User permissions</Trans>
        </h6>
        <div className="mb-16"></div>
        <PermissionsInput
          type={type}
          onChange={handlePermissionChange}
          userPermissions={node?.permissions}
          disabledPermissions={disabledPermissions}
        />
        {errors.permissions && (
          <p className="text-red-500 text-14 italic py-6">
            {errors.permissions}
          </p>
        )}
        {isSelf && (
          <p className="text-grey-800 text-14 italic py-12 ml-15">
            <Trans id="users.userDetail.cannotRemoveUSR:WFromSelf">
              You cannot remove the USR:RW permission from yourself.
            </Trans>
          </p>
        )}
        <div className="mb-40"></div>
        <h6 className="text-18 font-medium mb-16">
          <Trans id="users.userDetail.dangerZone">Danger zone</Trans>
        </h6>
        <div className="flex">
          {!node?.invitation && type === 'bank' && (
            <Button
              to={`/${type}s/${slug}/users/transfer-owner/${node?.user.id}`}
              kind="danger-transparent"
              data-cy-id="user-detail-transfer-owner"
            >
              <Trans id="users.userDetail.transferBankOwnership">
                Transfer bank ownership
              </Trans>
            </Button>
          )}

          {node && (
            <RemoveUserButton
              type={type}
              slug={slug}
              userId={node.id}
              isSelf={isSelf}
              isContract={roles?.length !== 0}
            />
          )}
        </div>
        <div className="flex justify-end mt-36">
          <Button
            loading={formState.isSubmitting}
            disabled={!formState.isDirty}
            isFormSubmit
            data-cy-id="user-detail-form-submit"
          >
            <Trans id="users.userDetail.saveButton">Save changes</Trans>
          </Button>
        </div>
      </form>
    </QueryLoader>
  )
}
