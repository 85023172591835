import { t, Trans } from '@lingui/macro'
import cn from 'classnames'
import {
  Breadcrumbs,
  Dropdown,
  DropdownLink,
  Error404,
  Link,
  Logo,
} from 'components'
import { Layout, QueryLoader } from 'containers'
import OverviewIcon from 'icons/bar-chart.svg'
import Chevron from 'icons/chevron.svg'
import ConnectedIcon from 'icons/connected.svg'
import LegalIcon from 'icons/legal.svg'
import SupportIcon from 'icons/support.svg'
import UsersIcon from 'icons/users.svg'
import { useRouter } from 'next/router'
import React from 'react'
import {
  OrgMetaQuery,
  useCurrentUserQuery,
  useOrganizationsQuery,
  useOrgMetaQuery,
  useUserInOrgMetaQuery,
} from 'types'
import { useFeature } from 'utils/features'
import { Router } from 'utils/router'
import { useIsLoggedIn } from 'utils/withAuth'
import { useOrgPermissions, UserInOrgContext } from './Can'
import { Sidebar, SidebarSection } from './Sidebar'

interface OrgDetailSidebarProps {
  slug: string
}

const OrgDetailSidebar: React.FC<OrgDetailSidebarProps> = ({ slug }) => {
  const hasPermission = useOrgPermissions()
  const enableSupport = useFeature('support')
  const enableReports = useFeature('reports')

  return (
    <Sidebar>
      <SidebarSection
        heading={<Trans id="org.sidebar.organization">Organization</Trans>}
      >
        <Link
          to={`/organizations/${slug}`}
          kind="sidebar"
          data-cy-id="org.sidebar.overview"
        >
          <OverviewIcon className="inline mr-16" />
          <Trans id="org.sidebar.overview">Overview</Trans>
        </Link>
      </SidebarSection>

      <SidebarSection
        heading={
          <Trans id="org.sidebar.orgManagement">Organization Management</Trans>
        }
      >
        <Link
          to={`/organizations/${slug}/apps`}
          kind="sidebar"
          tooltip={
            !hasPermission('ORG:R') && (
              <Trans id="tooltip.missingReadPermission">
                You don&apos;t have permission to see this.
              </Trans>
            )
          }
          tooltipId="org.missingPermission.ORG:R"
          data-cy-id="org.sidebar.apps"
        >
          <ConnectedIcon className="inline ml-6 mr-12 fill-white-80" />
          <Trans id="org.sidebar.apps">Applications</Trans>
        </Link>

        <Link
          to={`/organizations/${slug}/legal`}
          kind="sidebar"
          disabled={!hasPermission('LGL:R')}
          tooltip={
            !hasPermission('LGL:R') && (
              <Trans id="tooltip.missingReadPermission">
                You don&apos;t have permission to see this.
              </Trans>
            )
          }
          tooltipId="org.missingPermission.LGL:R"
          data-cy-id="org.sidebar.legal"
        >
          <LegalIcon className="inline mr-12" />
          <Trans id="org.sidebar.legal">Legal</Trans>
        </Link>

        {enableReports && (
          <Link
            to={`/organizations/${slug}/reports`}
            kind="sidebar"
            disabled={!hasPermission('REP:R')}
            tooltip={
              !hasPermission('REP:R') && (
                <Trans id="tooltip.missingReadPermission">
                  You don&apos;t have permission to see this.
                </Trans>
              )
            }
            tooltipId="org.missingPermission.REP:R"
            data-cy-id="org.sidebar.reports"
          >
            <LegalIcon className="inline mr-12" />
            <Trans id="org.sidebar.reports">Reports</Trans>
          </Link>
        )}

        <Link
          to={`/organizations/${slug}/users`}
          kind="sidebar"
          disabled={!hasPermission('USR:R')}
          tooltip={
            !hasPermission('USR:R') && (
              <Trans id="tooltip.missingReadPermission">
                You don&apos;t have permission to see this.
              </Trans>
            )
          }
          tooltipId="org.missingPermission.USR:R"
          data-cy-id="org.sidebar.users"
        >
          <UsersIcon className="inline mr-12" />
          <Trans id="org.sidebar.users">Users</Trans>
        </Link>

        {enableSupport && (
          <Link to="/support" kind="sidebar" data-cy-id="org.sidebar.support">
            <SupportIcon className="inline mr-12" />
            <Trans id="app.sidebar.support">Helpdesk</Trans>
          </Link>
        )}
      </SidebarSection>
    </Sidebar>
  )
}

const OrgDropdownMenu: React.FC = () => {
  const location = useRouter()?.asPath

  const orgsQuery = useOrganizationsQuery()
  const orgs = orgsQuery.data?.currentUser?.organisations

  return (
    <ul className="bg-white-100 shadow rounded-lg py-6 mt-10 relative z-10 max-h-half-screen overflow-y-auto">
      <QueryLoader queries={[orgsQuery]}>
        {orgs?.map(({ organisation }) => (
          <DropdownLink
            to={location.replace(
              /^\/organizations\/[^/]+/,
              `/organizations/${organisation.slug}`
            )}
            key={organisation.slug}
          >
            {/* TODO org logo ? */}
            <Logo media={undefined} size={24} className="mr-6 inline-block" />
            {organisation.name}
          </DropdownLink>
        ))}
      </QueryLoader>
    </ul>
  )
}

interface OrgDropdownProps {
  selectedOrg: NonNullable<OrgMetaQuery['organisationBySlug']>
}

const OrgDropdown: React.FC<OrgDropdownProps> = ({ selectedOrg }) => (
  <Dropdown
    display={
      <div className="border border-grey-400 rounded-8 flex items-center cursor-pointer p-6 max-w-230">
        {/* TODO org logo ? */}
        <Logo media={undefined} size={24} className="mr-6 inline-block" />
        <span className="flex-grow text-grey-900 truncate">
          {selectedOrg.name}
        </span>
        <Chevron className="inline fill-grey-600" />
      </div>
    }
    displayClassName="inline-block w-230"
    data-cy-id="orgs.selectOrgDropdown"
  >
    <OrgDropdownMenu />
  </Dropdown>
)

interface OrgDetailProps {
  slug: string
  userId: string
  selectedOrg: NonNullable<OrgMetaQuery['organisationBySlug']>
}

export const OrgDetail: React.FC<OrgDetailProps> = ({
  slug,
  userId,
  selectedOrg,
  children,
}) => {
  const userInOrgQuery = useUserInOrgMetaQuery({
    variables: { slug, userId },
  })
  const userInOrg = userInOrgQuery.data?.userInOrganisation
  const orgsQuery = useOrganizationsQuery()
  const orgs = orgsQuery.data?.currentUser
    ? orgsQuery.data?.currentUser?.organisations
    : []

  return (
    <QueryLoader queries={[userInOrgQuery]}>
      <UserInOrgContext.Provider value={userInOrg}>
        <div className="mb-32 px-24 sm:px-0 flex flex-col sm:flex-row sm:items-end sm:space-x-20 lg:space-x-40">
          {orgs?.length > 1 ? (
            <div className="mb-24 sm:mb-0">
              <div className="text-10 uppercase tracking-widest text-grey-600 font-semibold mb-8">
                <Trans id="org.orgSelector.currentOrg">
                  Current organization
                </Trans>
              </div>
              <OrgDropdown selectedOrg={selectedOrg} />
            </div>
          ) : null}
          <Breadcrumbs
            path={[
              {
                text: <Trans id="dashboard.title">Dashboard</Trans>,
                path: '/dashboard',
              },
              {
                text: selectedOrg.name,
                path: `/organizations/${slug}`,
              },
            ]}
            className="mb-4"
          />
        </div>
        <div className="bg-white-100 sm:rounded-16 shadow-wider flex flex-col sm:flex-row min-h-1000">
          <OrgDetailSidebar slug={slug} />
          <div
            className={cn('px-20 pb-20 lg:px-40 lg:pb-40 w-full overflow-auto')}
          >
            {children}
          </div>
        </div>
      </UserInOrgContext.Provider>
    </QueryLoader>
  )
}

interface OrgDetailLayoutProps {
  slug: string
}

export const OrgDetailLayout: React.FC<OrgDetailLayoutProps> = ({
  slug,
  children,
}) => {
  const isLoggedIn = useIsLoggedIn()
  const currentUserQuery = useCurrentUserQuery({ skip: !isLoggedIn })
  const user = currentUserQuery.data?.currentUser

  const orgQuery = useOrgMetaQuery({
    variables: { slug },
    skip: !isLoggedIn,
  })
  const org = orgQuery.data?.organisationBySlug

  return (
    <Layout title={t`org.title`} requireLogin background="grey">
      <QueryLoader queries={[orgQuery, currentUserQuery]}>
        {!org ? (
          <Error404 />
        ) : (
          <div className="pt-32 pb-50 mx-auto container sm:px-20">
            {user && (
              <OrgDetail slug={slug} userId={user.id} selectedOrg={org}>
                {children}
              </OrgDetail>
            )}
          </div>
        )}
      </QueryLoader>
    </Layout>
  )
}
