import Axios from 'axios'
import getConfig from 'next/config'
import { parseCookies } from 'nookies'

const { KEYCLOAK_ISSUER } = getConfig().publicRuntimeConfig

const axios = Axios.create({
  baseURL: KEYCLOAK_ISSUER,
})

type LinkedAccountsResponse = {
  connected: boolean
  providerAlias: string
  providerName: string
  displayName: string
  linkedUsername: string
  social: boolean
}[]

const KeycloakClient = {
  getLinkedAccounts: () => {
    const token = parseCookies()?.access_token
    return axios.get<LinkedAccountsResponse>('/account/linked-accounts', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  },
}

export default KeycloakClient
