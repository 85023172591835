import classNames from 'classnames'
import type { ClickableProps } from 'components'
import { Clickable, Tooltip } from 'components'
import React from 'react'
import type { TestIdProp } from 'types'

export type ButtonKind =
  | 'primary'
  | 'secondary'
  | 'secondary-blue'
  | 'secondary-white'
  | 'danger'
  | 'danger-transparent'

interface ButtonProps extends ClickableProps, TestIdProp {
  kind?: ButtonKind
  'data-cy-id': string
  tooltip?: React.ReactNode | boolean
}

const kinds: Record<ButtonKind, string> = {
  primary:
    'text-white-100 bg-primary disabled:bg-grey-500 hover:bg-utility-hover active:bg-utility-active',
  secondary: 'text-primary-darker border border-grey-900',
  'secondary-blue': 'text-primary border border-primary',
  'secondary-white': 'text-white-100 border border-white-100',
  danger: 'bg-utility-error text-white-100',
  'danger-transparent': 'border border-utility-red text-utility-red',
}

export const Button: React.FC<ButtonProps> = ({
  kind,
  className,
  ...props
}) => {
  const element = (
    <Clickable
      {...props}
      className={classNames(
        'font-display rounded-lg py-7 px-16 active:outline-none select-none',
        kind && kinds[kind],
        className
      )}
      loadingClassName="text-white"
    >
      {props.children}
    </Clickable>
  )

  if (props.tooltip) {
    return (
      <Tooltip id={props['data-cy-id']} kind="dark" displayTip={props.tooltip}>
        {element}
      </Tooltip>
    )
  }

  return <>{element}</>
}

Button.defaultProps = {
  kind: 'primary',
}
